import React from 'react'

import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'

import { ConfirmPickup } from './confirm-pickup'
import css from './confirm-pickup-scene.less'
import { useLocation } from 'react-router-dom'

export const ConfirmPickupScene = () => {
  const location = useLocation()

  return (
    <StackNavigationBase testID={'confirm-pickup-scene'} header={<SimpleNavigationTitle showAccount />}>
      <div className={css.wrapper}>
        <ConfirmPickup testID={'confirm-pickup'} address={location?.state.address} />
      </div>
    </StackNavigationBase>
  )
}
