import { useTranslation } from 'react-i18next'
import { getCountryConfig } from '@dominos/business/functions'
import {
  getRedirectUri,
  RedirectUriType,
} from '@dominos/components/checkout/checkout-container/payment-container/payment-method/get-redirect-uri'
import { useIsTwoTapCheckoutEnabled } from '@dominos/hooks-and-hocs'

const EDENRED_AUTH_SCOPE: string = 'openid edg-xp-mealdelivery-api offline_access'

export const useEdenredURL = () => {
  const config = getCountryConfig()
  const { i18n } = useTranslation('checkout')
  const isTwoTapCheckoutEnabled = useIsTwoTapCheckoutEnabled()
  const getEdenredURL = (orderId: string | undefined, nonce: string | undefined, pathType: RedirectUriType) => {
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: `${config.EDENRED_CLIENT_ID}`,
      scope: EDENRED_AUTH_SCOPE,
      redirect_uri: getRedirectUri(isTwoTapCheckoutEnabled, pathType),
      state: `${orderId}`,
      nonce: `${nonce}`,
      acr_values: `tenant:${config.EDENRED_ACR_VALUES}`,
      ui_locales: `${i18n.language}`,
    })

    return `${config.EDENRED_URL}/connect/authorize?${params.toString()}`
  }

  const isEdenredAuthScope = (scope: string | null) => scope === EDENRED_AUTH_SCOPE

  return {
    getEdenredURL,
    isEdenredAuthScope,
  }
}
