import React from 'react'
import { svgIconsName } from 'res'
import { StaticSvgIcon } from '@dominos/res'
import css from './store-trade-details.less'

interface IStoreInfoLineProps {
  icon: svgIconsName
  name: string
  value: string
}

export const StoreInfoLine = ({ icon, name, value }: IStoreInfoLineProps) => (
  <>
    <StaticSvgIcon name={icon} isUnstyled={true} fill='#454545' width={18} height={18} />
    <span className={css.storeTradeDetailsName}>{name}</span>
    <span className={css.storeTradeDetailsValue}>{value}</span>
  </>
)
