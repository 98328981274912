import { useMemo } from 'react'
import { useCountryCode } from '@dominos/hooks-and-hocs'
import { useAdditionalDeliveryAddressSearchFeatures } from './use-additional-delivery-address-search-features'

export const useWcaAdditionalFields = () => {
  const countryCode = useCountryCode()

  const {
    autoCompleteDeliveryEnabled,
    autoCompleteDisplayAdditionalFieldsCheckout,
    autoCompleteDeliveryAddressFieldsHidden,
    deliveryAddressSearchFeature,
    deliveryAddressSearchShowAdditionalFieldsCheckout,
  } = useAdditionalDeliveryAddressSearchFeatures()

  const displayAdditionalFieldsWca = useMemo(() => {
    const autoCompleteDeliveryFeatureDisplayFieldsCheckout =
      autoCompleteDeliveryEnabled &&
      autoCompleteDisplayAdditionalFieldsCheckout &&
      !autoCompleteDeliveryAddressFieldsHidden

    const deliveryAddressSearchFeatureShowAdditionalFieldsCheckout =
      deliveryAddressSearchFeature && deliveryAddressSearchShowAdditionalFieldsCheckout

    return autoCompleteDeliveryFeatureDisplayFieldsCheckout || deliveryAddressSearchFeatureShowAdditionalFieldsCheckout
  }, [
    autoCompleteDeliveryEnabled,
    autoCompleteDisplayAdditionalFieldsCheckout,
    autoCompleteDeliveryAddressFieldsHidden,
    deliveryAddressSearchFeature,
    deliveryAddressSearchShowAdditionalFieldsCheckout,
  ])

  const shouldSaveExtraAddress = useMemo(
    () => countryCode === 'JP' && !autoCompleteDeliveryEnabled && !deliveryAddressSearchFeature,
    [autoCompleteDeliveryEnabled, deliveryAddressSearchFeature, countryCode],
  )

  return {
    displayAdditionalFieldsWca,
    shouldSaveExtraAddress,
  }
}
