import { SecurityContextProps } from '@dominos/hooks-and-hocs/logging'
import {
  setupAI,
  setupAppleId,
  setupGTM,
  setupNewRelic,
  setupOneTrust,
  setupRecaptcha,
  setupVWO,
} from '@dominos/business/functions/vendors'
import { Config, loadScript } from '@dominos/business/functions/common'
import { isNativeApp } from '../native-app/is-native-app'
import { useFeatures } from '@dominos/hooks-and-hocs'

export const useSetupVendors = (config: Config): Record<string, SecurityContextProps> => {
  const [
    vwoEnabled,
    isOnetrustEnabled,
    isApplicationInsightsEnabled,
    isGoogleTagManagerEnabled,
    appleAuthEnabled,
    recaptchaEnabled,
  ] = useFeatures('VWO', 'OneTrust', 'ApplicationInsights', 'GoogleTagManager', 'apple-auth', 'Recaptcha')

  if (recaptchaEnabled) {
    setupRecaptcha(config, loadScript)
  }
  if (appleAuthEnabled) {
    setupAppleId(loadScript)
  }
  vwoEnabled && setupVWO(config, loadScript)
  if (isOnetrustEnabled) {
    setupOneTrust(config, loadScript)
  }

  if (!isNativeApp() && isGoogleTagManagerEnabled) {
    setupGTM(config, loadScript)
  }

  return {
    securityClient: {
      client: isApplicationInsightsEnabled ? setupAI(config) : null,
    },
  }
}

export const setupVendorsPreDOMLoad = (config: Config): void => {
  setupNewRelic(config)
}
