/* eslint-disable max-lines-per-function */
import { useCurrentOrderDetails, useFeatures, useReport, useSdkAvailable, useSocialAuth } from '@dominos/hooks-and-hocs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { TFunction } from 'i18next'

import { isNativeApp, sendTokenToNativeApp } from '@dominos/business/functions/native-app'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { ActionButton } from '@dominos/components/buttons'
import { AppleIcon } from '@dominos/res/images/icons/components'
import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ErrorScope, getStatusReasonFromApolloError, useErrorContext } from '@dominos/components/error'

import { NavigationConstants } from '@dominos/navigation'
import css from './apple.less'
import { loginErrors } from '../login-errors'
import { InlineError } from '../inline-error'

interface AppleIdAuthInit {
  clientId: string
  scope?: string
  redirectURI: string
  state?: string
  nonce?: string
  usePopup?: boolean
}

type AppleIdAuthSuccessUser = {
  email?: string
  name?: {
    firstName: string
    lastName: string
  }
}

interface AppleIdAuthSuccess {
  authorization: {
    state: string
    code: string
    id_token: string
  }
  user?: AppleIdAuthSuccessUser
}

interface AppleIdAuthFailure {
  error: string
}

type AppleIdAuthFailureError = 'popup_closed_by_user'

declare global {
  interface Window {
    AppleID?: {
      auth: {
        init: (vars: AppleIdAuthInit) => void
        signIn: () => Promise<AppleIdAuthSuccess>
      }
    }
  }
}
const IGNORED_ERRORS: (AppleIdAuthFailureError | string)[] = ['popup_closed_by_user']

const buildNavState = (
  authCustomerInfo: Bff.SocialLogins.SocialLogin,
  redirectTo: string | undefined,
  t: TFunction,
): Partial<CreateAccountSceneProps> => {
  let customerName
  if (authCustomerInfo?.firstName) {
    customerName = `${authCustomerInfo.firstName} ${authCustomerInfo.lastName || ''}`
  }

  return {
    redirectTo, // passing referral
    title: t('SignUpAppleTitle', { defaultValue: 'Sign in with Apple' }),
    description: t('SignUpSocialDescription', {
      defaultValue: 'Almost there! We just need a few more details to create your account',
    }),
    subtitle: t('SignUpAppleSubtitle', { defaultValue: 'ADDITIONAL DETAILS' }),
    name: customerName,
    email: authCustomerInfo?.email,
    identityProvider: {
      identityProviderId: authCustomerInfo?.identityProviderId,
      externalProviders: [
        {
          providerType: 'apple',
          providerId: authCustomerInfo?.identityProviderId,
        },
      ],
    },
  }
}

interface Props extends Partial<BaseProps> {
  enableLongLived: boolean
  onLogin?: () => void
}

const Apple = ({ testID = 'appleid-signin', onLogin, enableLongLived }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation('login')
  const { found } = useSdkAvailable('AppleID')
  const location = useLocation()
  const [loginFailed, setLoginFailed] = useState(false)
  const redirectTo = (location?.state as { redirectTo?: string })?.redirectTo ?? undefined
  const { reportLogin } = useReport()
  const { signIn, authCustomerInfo, pending, error: signInError } = useSocialAuth()
  const applicationConfig = getCountryConfig()
  const { orderId } = useCurrentOrderDetails()
  const [appleAuthEnabled] = useFeatures('AppleAuthNativeApp')
  const userRef = useRef<AppleIdAuthSuccess['user']>()
  const { notifyError } = useErrorContext()

  useEffect(() => {
    if (found) {
      window.AppleID?.auth.init({
        clientId: applicationConfig.APPLEID_CLIENT_ID,
        scope: 'name email',
        redirectURI: `${window.location.origin}${window.location.pathname}`,
        usePopup: true,
      })
    }
  }, [found])

  useEffect(() => {
    if (signInError) {
      const statusReason = getStatusReasonFromApolloError(signInError)

      if (statusReason) {
        reportLogin({
          enableLongLived,
          status: 'fail',
          order_id: orderId,
          authenticationSource: 'Legacy',
          customerId: authCustomerInfo?.customerId,
          url: window.location.href,
          identityProvider: 'Apple',
          status_reason: statusReason,
        })
      }

      notifyError({
        error: signInError,
        definitions: loginErrors,
        handlers: {},
        scope: ErrorScope.CreateAccount,
      })
    }
  }, [signInError])

  useEffect(() => {
    if (!authCustomerInfo) return

    if (appleAuthEnabled) {
      if (authCustomerInfo.customerId) {
        reportLogin({
          enableLongLived,
          identityProvider: 'Apple',
          status: 'success',
          order_id: orderId,
          authenticationSource: 'Legacy',
          customerId: authCustomerInfo?.customerId,
          url: window.location.href,
        })
        if (isNativeApp()) {
          sendTokenToNativeApp(authCustomerInfo.idToken)
        } else {
          navigate(redirectTo ?? NavigationConstants.home) // using redirectTo
        }
      } else {
        navigate(isNativeApp() ? NavigationConstants.nativeAppCreateAccount : NavigationConstants.createAccount, {
          state: buildNavState({ ...authCustomerInfo, ...userRef.current?.name }, redirectTo, t),
        })
      }
      // Duplicate else block to make it easy to remove feature toggle
      // linting doesn't matter as this code is going to be deleted soon
    } else {
      if (authCustomerInfo.customerId) {
        reportLogin({
          enableLongLived,
          identityProvider: 'Apple',
          status: 'success',
          order_id: orderId,
          authenticationSource: 'Legacy',
          customerId: authCustomerInfo?.customerId,
          url: window.location.href,
        })
        navigate(redirectTo ?? NavigationConstants.home) // using redirectTo
      } else {
        navigate(NavigationConstants.createAccount, {
          state: buildNavState({ ...authCustomerInfo, ...userRef.current?.name }, redirectTo, t),
        })
      }
    }
  }, [authCustomerInfo])

  const appleIdSuccessCallback = useCallback(
    (data: CustomEvent<AppleIdAuthSuccess>) => {
      const success = data.detail
      const providerToken = success.authorization.id_token
      userRef.current = success.user
      signIn({ orderId, enableLongLived, providerToken, provider: 'Apple' })
    },
    [enableLongLived],
  ) as EventListener

  useEffect(() => {
    if (appleAuthEnabled) {
      document.addEventListener('AppleIDSignInOnSuccess', appleIdSuccessCallback)
      const onMessageHandler = (event: MessageEvent) => {
        const appleAuthResponse = JSON.parse(event.data)
        const isAuthMessage = appleAuthResponse && appleAuthResponse.provider && appleAuthResponse.providerToken
        if (isAuthMessage) {
          signIn({ ...appleAuthResponse, enableLongLived })
        }
      }
      if (isNativeApp()) {
        window.addEventListener('message', onMessageHandler)
      }

      return () => {
        document.removeEventListener('AppleIDSignInOnSuccess', appleIdSuccessCallback)
        if (isNativeApp()) {
          window.removeEventListener('message', onMessageHandler)
        }
      }
    }
    document.addEventListener('AppleIDSignInOnSuccess', appleIdSuccessCallback)

    return () => document.removeEventListener('AppleIDSignInOnSuccess', appleIdSuccessCallback)
  }, [enableLongLived])

  const appleIdFailCallback = useCallback((detail: AppleIdAuthFailure) => {
    if (!IGNORED_ERRORS.includes(detail.error)) {
      reportLogin(
        {
          enableLongLived,
          identityProvider: 'Apple',
          status: 'fail',
          order_id: orderId,
          authenticationSource: 'Legacy',
          customerId: authCustomerInfo?.customerId,
          url: window.location.href,
          status_error: detail.error,
        },
        true,
      )
    }

    setLoginFailed(true)
  }, [])

  const handleLogin =
    onLogin ??
    (() => {
      setLoginFailed(false)

      return window.AppleID?.auth.signIn().catch(appleIdFailCallback)
    })

  return found ? (
    <>
      <ActionButton testID={testID} loading={pending} onPress={handleLogin} containerStyle={styles.appleButton}>
        <div className={css.icon}>
          <AppleIcon fill={'#ffffff'} />
        </div>
        <label className={css.buttonLabel}>{t('SignUpAppleTitle', { defaultValue: 'Sign in with Apple' })}</label>
      </ActionButton>
      <InlineError
        showError={loginFailed}
        message={t('CreateAccountGenericErrorText', { defaultValue: 'Something has gone wrong. Please try again' })}
      />
    </>
  ) : null
}

const styles: { [k: string]: CommonViewStyle } = {
  appleButton: {
    width: '100%',
    height: '54px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '10px 0',
    cursor: 'pointer',
    backgroundColor: '#000000',
  },
}

export { Apple, AppleIdAuthSuccess, AppleIdAuthFailure, AppleIdAuthSuccessUser }
