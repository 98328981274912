import React, { ReactNode, useCallback, useState } from 'react'
import { SnackBar } from 'olo-ux'
import { SnackbarContext } from './snackbar-context'
import { SnackbarOptions } from './snackbar-provider.interface'

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [snackbarOptions, setSnackbarOptions] = useState<SnackbarOptions | null>(null)

  const hideSnackbar = useCallback(() => {
    setSnackbarOptions(null)
  }, [])

  const showSnackbar = useCallback(
    ({ message, placement = 'top', type = 'neutral', duration = 7000 }: SnackbarOptions) => {
      setSnackbarOptions({ message, placement, type })

      setTimeout(() => {
        hideSnackbar()
      }, duration)
    },
    [hideSnackbar],
  )

  return (
    <SnackbarContext.Provider value={{ showSnackbar, hideSnackbar }}>
      {children}
      {snackbarOptions && (
        <SnackBar
          content={snackbarOptions.message}
          placement={snackbarOptions.placement}
          onClose={hideSnackbar}
          type={snackbarOptions.type}
        />
      )}
    </SnackbarContext.Provider>
  )
}
