import { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { detailsFieldNames, FormValue, mapCustomerOrderDetails } from '@dominos/components'
import {
  useCustomer,
  useNativeAppStoredData,
  useRememberedCustomerDetails,
  useRememberMyDetails,
  useReport,
  useServiceMethod,
} from '@dominos/hooks-and-hocs'
import { CustomerDetails } from '@dominos/interfaces'

const mapMyDetails = (customerDetails: CustomerDetails | undefined): { [key: string]: FormValue } => ({
  ...(customerDetails?.name ? { [detailsFieldNames.name]: customerDetails.name } : {}),
  ...(customerDetails?.alternateName ? { [detailsFieldNames.alternateName]: customerDetails.alternateName } : {}),
  ...(customerDetails?.emailAddress ? { [detailsFieldNames.email]: customerDetails.emailAddress } : {}),
  ...(customerDetails?.mobileNumber ? { [detailsFieldNames.mobileNumber]: customerDetails.mobileNumber } : {}),
  ...(customerDetails?.organisationName ? { [detailsFieldNames.company]: customerDetails.organisationName } : {}),
  ...(customerDetails?.uniformNumber ? { [detailsFieldNames.uniformNumber]: customerDetails.uniformNumber } : {}),
  ...(customerDetails?.mobileCarrierCode
    ? { [detailsFieldNames.mobileCarrierCode]: customerDetails.mobileCarrierCode }
    : {}),
  ...(customerDetails?.citizenCarrierCode
    ? { [detailsFieldNames.citizenCarrierCode]: customerDetails.citizenCarrierCode }
    : {}),
  ...(customerDetails?.charityCode ? { [detailsFieldNames.charityCode]: customerDetails.charityCode } : {}),
})

// TODO: simplify return and apply this hook to ServiceMethodDetailsForm.placeOrder and DetailsContainer.handleComplete
export const useCheckoutMyDetails = () => {
  const dispatch = useDispatch()
  const { reportRememberMyDetails } = useReport()
  const serviceMethod = useServiceMethod()
  const { isLoggedIn } = useCustomer()
  const { storeNativeAppCustomerDetails } = useNativeAppStoredData()
  const { customerDetails, saveDetails } = useRememberedCustomerDetails()
  const { rememberMyDetailsState, setRememberMyDetailsState, saveRememberMyDetailsCheck } = useRememberMyDetails()

  const [myDetails, setMyDetails] = useState<{ [key: string]: FormValue }>(mapMyDetails(customerDetails))
  const [deliveryInstructions, setDeliveryInstructions] = useState<string>('')
  const customerOrderDetails = useMemo(() => mapCustomerOrderDetails(myDetails, isLoggedIn ?? false), [myDetails])

  const saveCustomerDetails = useCallback(() => {
    const extraDetails = serviceMethod === 'Delivery' ? { deliveryInstructions } : {}
    const customerDetails = { ...customerOrderDetails, ...extraDetails }
    dispatch(rootActions.saveCustomerDetails(customerDetails))
    storeNativeAppCustomerDetails(customerDetails)
  }, [customerOrderDetails, deliveryInstructions])

  const saveCustomerOrderDetails = useCallback(() => {
    reportRememberMyDetails(rememberMyDetailsState)
    saveRememberMyDetailsCheck(rememberMyDetailsState)
    saveDetails(!isLoggedIn && rememberMyDetailsState && customerOrderDetails)
  }, [rememberMyDetailsState, customerOrderDetails])

  const handleMyDetailsChange = (field: string | null, value: FormValue) => {
    if (field === detailsFieldNames.rememberMyDetails) {
      setRememberMyDetailsState(!!value)
    }
    setMyDetails((prev) => ({ ...prev, [field!]: value }))
  }

  return {
    myDetails,
    handleMyDetailsChange,
    rememberMyDetailsState,
    saveRememberMyDetailsCheck,
    customerDetails,
    saveDetails,
    serviceMethod,
    saveCustomerDetails,
    setDeliveryInstructions,
    saveCustomerOrderDetails,
  }
}
