import { BaseProductEditor, BottomBar, ProductCustomiser, useProductContext } from '@dominos/components'
import { useBaseData, useSizeData } from '@dominos/components/product/functions'
import React from 'react'
import { SimpleProductCardProps } from './simple-product-card.interface'

export const SimpleProductCard = ({ testID, onDismiss, addToBasket, isEditing, t }: SimpleProductCardProps) => {
  const {
    dimensionSetState,
    productData,
    createBasketLine,
    formattedPrice,
    formattedPromoPrice,
    isValidForBasket,
    initialQuantity,
  } = useProductContext()
  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)

  const addToBasketHandler = (quantity: number) => {
    addToBasket(createBasketLine(quantity))
    onDismiss(true)
  }

  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }
  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }

  return (
    <>
      <BaseProductEditor
        testID={`${testID}.base-product-editor`}
        onDismiss={onDismiss}
        t={t}
        formattedPrice={formattedPrice}
        formattedPromoPrice={formattedPromoPrice}
        //TODO: MAB-2600
        // energyKJ={energyKJ}
      >
        <ProductCustomiser
          sizeData={sizeData}
          baseData={baseData}
          onSizeChange={onSizeChange}
          onBaseChange={onBaseChange}
        />
      </BaseProductEditor>
      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={addToBasketHandler}
        initialQuantity={initialQuantity}
        isPortionProduct={false}
        forceDisabled={!isValidForBasket}
        isEditing={isEditing}
      />
    </>
  )
}
