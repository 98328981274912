import React, { useEffect, useMemo, useState } from 'react'
import styles from './hard-upsell-container.less'
import { HardUpsellActionButtons } from '../hard-upsell-bottom-button/hard-upsell-action-buttons'
import SelectableProductCard from './selectable-product-card/selectable-product-card'
import { useNavigate } from 'react-router-dom'
import { NavigationConstants } from '@dominos/navigation'
import { useDispatch, useSelector } from 'react-redux'
import { countFromBasketLines } from '@dominos/business/functions/basket'
import { UpsellOfferType, useUpsellOffer } from '@dominos/hooks-and-hocs/offers'
import { rootActions } from '@dominos/business'
import { HardUpsellItem } from '../types'
import { useTranslation } from 'react-i18next'
import { useFeatures, useReport } from '@dominos/hooks-and-hocs'
import { EventSourceType } from '@dominos/hooks-and-hocs/logging/analytics/analytics-interfaces-menu'

import { ActionButton } from '@dominos/components/buttons'

type HardUpsellContainerProps = BaseProps

const DEBOUNCE = 400

export const HardUpsellContainer = ({ testID }: HardUpsellContainerProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation('basket')
  const dispatch = useDispatch()
  const hardUpsellOffers = useUpsellOffer(UpsellOfferType.HardUpsell)
  const [offerItems, setOfferItems] = useState<HardUpsellItem[]>()
  const [isItemSelected, setIsItemSelected] = useState<boolean>(false)
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)
  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])
  const {
    reportHardUpsellProductAddedToCart,
    reportHardUpsellSkip,
    reportHardUpsellInitialised,
    reportProductAddToCart,
  } = useReport()
  const hasBasketItems = itemCount > 0
  const productInfoUrl = t('InlineUpsellProductInformationURL')
  const [inlineUpsellProductInformationEnabled] = useFeatures('InlineUpsellProductInformation')

  const showProductInfo = inlineUpsellProductInformationEnabled && productInfoUrl

  const handleCheckChange = (index: number) => {
    setOfferItems((prevItems) => {
      const updatedItems = prevItems?.map((item, i) => (i === index ? { ...item, isSelected: !item.isSelected } : item))
      const selected = updatedItems?.filter((item) => item?.isSelected)
      if (selected) {
        setIsItemSelected(selected?.length > 0)
      }

      return updatedItems
    })
  }

  useEffect(() => {
    setOfferItems(hardUpsellOffers)
  }, [hardUpsellOffers])

  useEffect(() => {
    if (!hasBasketItems) {
      navigate(NavigationConstants.menu)
    } else {
      reportHardUpsellInitialised()
    }
  }, [])

  const handleSkip = () => {
    reportHardUpsellSkip()
    navigate(NavigationConstants.checkout, { state: { isHardupsell: 'hard-upsell' } })
  }

  const handleAddToOrder = () => {
    const selected = offerItems?.filter((item) => item?.isSelected)
    if (selected) {
      const selectedItems: BasketLine[] = selected?.map((item) => ({
        quantity: 1,
        productCode: item.linkedItem?.itemCode,
        itemNo: 0,
        type: 'BasketLine',
        media: {
          name: item.name,
        },
        totalPrice: null,
        sizeCode: item.linkedItem.subItemCode,
      }))

      dispatch(rootActions.addLinesToBasket({ add: selectedItems }))
      reportHardUpsellProductAddedToCart(selectedItems)
      reportProductAddToCart(selectedItems, EventSourceType.HardUpsell)
      navigate(NavigationConstants.checkout, { state: { isHardupsell: 'hard-upsell' } })
    }
  }

  const handleProductInfoPress = () => {
    window.open(productInfoUrl)
  }

  return (
    <div className={styles.container} data-testid={testID}>
      <h1 data-testid={`${testID}.title`} className={styles.titleText}>
        {t('HardUpsellTitle')}
      </h1>

      <div data-testid={`${testID}.product-container`} className={styles.productContainer}>
        {offerItems?.map((item, index) => (
          <SelectableProductCard
            testID={`${testID}.item.${item.id}`}
            offerItem={item}
            key={index}
            onSelected={() => handleCheckChange(index)}
          />
        ))}
      </div>
      {showProductInfo && (
        <ActionButton
          className={styles.productInfoContainer}
          textStyle={{ color: '#007aff' }}
          debounce={DEBOUNCE}
          testID={`${testID}.product.information`}
          text={t('InlineUpsellProductInformation')}
          onPress={handleProductInfoPress}
          textFontWeight='semibold'
        />
      )}
      <div className={styles.buttonsContainer}>
        <HardUpsellActionButtons
          testID={'hard-upsell'}
          isItemSelected={isItemSelected}
          onSkip={handleSkip}
          onAddToOrder={handleAddToOrder}
        />
      </div>
    </div>
  )
}

export default HardUpsellContainer
