import React from 'react'
import { ActionButton, AddressInfoComponent, GenericCard } from '@dominos/components'
import css from './pre-confirm-address-component.less'
import { IUsePreConfirmAddressComponentProps, usePreConfirmAddressComponent } from 'olo-feature-address'
import { PreConfirmAddressFieldsContainer } from '../pre-confirm-address-fields-component'

const testID = 'pre-confirm-address'

export interface PreConfirmAddressComponentProps extends IUsePreConfirmAddressComponentProps {
  testID: string
}

export const PreConfirmAddressComponent = (props: PreConfirmAddressComponentProps) => {
  const {
    deliveryAddress,
    preConfirmAddressTitle,
    preConfirmAddressButtonLabel,
    preConfirmInfoPlaceholderLabel,
    confirmButtonEnabled,
    onConfirmDeliveryAddress,
    onAddressFieldsChange,
    isLoading,
    shouldHideElementsDueToError,
  } = usePreConfirmAddressComponent(props)

  return (
    <div data-testid={testID} className={css.wrapper}>
      <GenericCard testID={testID} title={preConfirmAddressTitle}>
        <AddressInfoComponent testID={`${testID}.info`} address={props.deliveryAddress} showClearButton />

        <PreConfirmAddressFieldsContainer
          deliveryAddress={deliveryAddress}
          onAddressFieldsChange={onAddressFieldsChange}
        />
        {!shouldHideElementsDueToError && (
          <div data-testid={`${testID}.infoPlaceholder`}>{preConfirmInfoPlaceholderLabel}</div>
        )}

        <ActionButton
          testID={`${testID}.button`}
          onPress={onConfirmDeliveryAddress}
          loading={isLoading}
          text={preConfirmAddressButtonLabel}
          containerStyle={{ width: '100%', marginTop: '8px', height: '40px' }}
          textFontWeight={'semibold'}
          disabled={!confirmButtonEnabled}
        />
      </GenericCard>
    </div>
  )
}
