import { formatSectionName } from '@dominos/business/functions/menu'
import { MenuNavigationProps } from '@dominos/components'

export const getSelectedPage = (props: MenuNavigationProps) => {
  const { menuCategory, menu } = props

  if (!menu) {
    return null
  }

  if (!menuCategory) {
    return menu.pages[0]
  }

  return menu.pages.find((page) => page.code.toLowerCase().endsWith(menuCategory)) ?? menu.pages[0]
}

export const getSelectedPageIndex = (props: MenuNavigationProps) => {
  const { menuCategory, menu } = props

  if (!menu) {
    return null
  }

  if (!menuCategory) {
    return 0
  }

  return menu.pages.findIndex((page) => page.code.toLowerCase().endsWith(menuCategory.toLowerCase())) ?? 0
}

export const getSelectedSection = (props: MenuNavigationProps, code?: string) => {
  const { section } = props

  const selected = getSelectedPage(props)
  const locationCode = code ? code.substr(1) : null

  const hash = locationCode || section || ''

  return selected?.sections?.find((sect) => sect?.code && formatSectionName(sect.code) === hash)
}
