import { MutationHookOptions, useMutation } from '@apollo/client'
import { customerUpdateMutation } from '@dominos/business/queries'
import { UpdateCustomerInput, UpdateCustomerResponse } from '@dominos/business/queries/update-customer'
import { ErrorScope, useErrorContext } from '@dominos/components/error'
import { profileUpdateErrorDefinitions } from '@dominos/components/profile/profile-update-form/profile-update-errors'
import { useCurrentOrderDetails, useReport } from '@dominos/hooks-and-hocs'

type UseUpdateCustomerOptions = Pick<MutationHookOptions<UpdateCustomerResponse, UpdateCustomerInput>, 'onCompleted'>
const noOp = () => {}
export const defaultOptions: UseUpdateCustomerOptions = {
  onCompleted: noOp,
}

export const useUpdateCustomer = (options = defaultOptions) => {
  const { reportAccountWasUpdated } = useReport()
  const { notifyError } = useErrorContext()
  const { orderId } = useCurrentOrderDetails()

  const [updateCustomer, { data, loading, error }] = useMutation<UpdateCustomerResponse, UpdateCustomerInput>(
    customerUpdateMutation,
    {
      onCompleted: options.onCompleted,
      onError(error, clientOptions) {
        reportAccountWasUpdated({
          success: false,
          payload: {
            input: clientOptions?.variables,
            error,
          },
          orderId,
        })
        notifyError({
          error,
          scope: ErrorScope.UpdateProfile,
          definitions: profileUpdateErrorDefinitions,
          handlers: {},
        })
      },
    },
  )

  return {
    updateCustomer,
    loading,
    error,
    data,
  }
}
