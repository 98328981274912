import { useInitiateOrder, usePlaceOrder, useRememberedCustomerDetails } from '@dominos/hooks-and-hocs'
import { useCustomerCheckoutDetails } from '../use-customer-checkout-details'
import { useEffect, useMemo, useState } from 'react'
import { createPaypalEvents } from './create-paypal-events'
import { useNavigate } from 'react-router-dom'
import { isNativeApp } from '@dominos/business/functions'
import { NavigationConstants } from '@dominos/navigation'
import { ApolloError } from '@apollo/client'
import { usePlaceOrderErrorHandler } from '@dominos/hooks-and-hocs/checkout/usePlaceOrderErrorHandler'

export const usePaypalEvents = (paymentAmount: number, providerCode: BffContext.PaymentProviders) => {
  const navigate = useNavigate()
  const customerCheckoutDetails = useCustomerCheckoutDetails()
  const { customerDetails } = useRememberedCustomerDetails()
  const [error, setError] = useState<ApolloError | boolean | undefined>()
  const { orderId, initiateOrder, paymentToken, loading } = useInitiateOrder({
    onReady: () => {
      setError(false)
    },
    handleInitiateOrderError: () => setError(true),
  })
  const { placeOrder, loading: placeOrderLoading } = usePlaceOrder({
    handlePlaceOrderError: (error) => orderErrorHandler(error),
    onOrderConfirmed: () => {
      navigate(isNativeApp() ? NavigationConstants.nativeAppCheckoutProcessing : NavigationConstants.checkoutProcessing)
    },
  })

  /**
   * Here we create the events for the paypal sdk
   * These events can not live inside of a hook because of an issue with the paypal SDK
   * Because whenever the events function references change paypal will still use the old ones
   *
   * paypalEvents is kept up-to-date through the setState in the useEffect hook.
   */
  const onError = () => setError(true)
  const paypalEvents = useMemo(() => createPaypalEvents({ placeOrder, initiateOrder, onError }, providerCode), [])
  useEffect(() => {
    paypalEvents.setState({
      myDetails: customerCheckoutDetails || customerDetails,
      orderId,
      paymentAmount,
      paymentToken,
      error: !!error,
    })
  }, [customerDetails, customerCheckoutDetails, orderId, paymentAmount, paymentToken, error])
  useEffect(() => {
    paypalEvents.setOrderFunctions({
      initiateOrder,
      placeOrder,
      onError,
    })
  }, [initiateOrder, placeOrder])

  /**
   * handle errors
   */
  const orderErrorHandler = usePlaceOrderErrorHandler({
    noRetry: setError,
    retry: paypalEvents.placeOrder,
    canRetry: paypalEvents.canPlaceOrder,
  })

  /**
   * reset error when initiating order
   */
  useEffect(() => {
    if (loading) setError(undefined)
  }, [loading])

  return { paypalEvents, loading: loading || placeOrderLoading }
}
