export interface AllergenLookup {
  name: Bff.Common.TextMedia
}

export interface AllergenLookupDictionary {
  [key: string]: AllergenLookup
}

export const mapAllergenKeys = (): AllergenLookupDictionary => {
  const allergenLookupDictionary: AllergenLookupDictionary = {
    Egg: { name: { value: 'egg' } },
    Fish: { name: { value: 'fish' } },
    Gluten: { name: { value: 'gluten' } },
    Soy: { name: { value: 'soy' } },
    Milk: { name: { value: 'milk' } },
    Peanut: { name: { value: 'peanut' } },
    Lupine: { name: { value: 'lupine' } },
    Mustard: { name: { value: 'mustard' } },
    Sesame: { name: { value: 'sesame' } },
    Celery: { name: { value: 'celery' } },
    Mollusc: { name: { value: 'mollusc' } },
    Crustacean: { name: { value: 'crustacean' } },
    Sulphite: { name: { value: 'sulphite' } },
    TreeNut: { name: { value: 'treeNut' } },
  }

  return allergenLookupDictionary
}
