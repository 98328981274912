import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { OrderStatus } from '../order/order-types'
import { AnalyticSender, EnhancedEcommercePurchaseEvent, FeatureEventSender } from './analytics'
import { SecuritySender } from './security'

export const reportCheckout = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  sendFeatureEvent: FeatureEventSender,
  purchaseEvent: EnhancedEcommercePurchaseEvent | undefined,
  basket?: Basket,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => {
  const reportOrderStatus = (status?: OrderStatus, retryCount: number = 0) => {
    sendSecurity('Flow.OrderStatus.StatusReceived', {
      'Order ID': currentOrder?.orderId,
      'Order Status': status,
      'Retry Count': retryCount,
    })
  }

  const reportEstimatedOrderTime = (timeRange: `${number}-${number}` | null, status: OrderStatus | null) => {
    sendAnalytics('Estimated Order Time', {
      estimated_time_range: timeRange,
      order_status: status,
    })
  }

  const reportPurchase = (event: EnhancedEcommercePurchaseEvent | undefined, callback?: () => void) => {
    sendAnalytics(
      'EE - Purchase',
      basket?.lines?.length === 0 && event
        ? {
            ...event,
            Payment: {
              ...event?.Payment,
              'Service Method': currentOrder?.basketHeaderData?.serviceMethod,
              'Service Method Subtype': currentOrder?.basketHeaderData?.serviceMethodSubType,
              'Payment Method': currentOrder?.paymentMethod?.paymentMethod,
              'Payment Provider': currentOrder?.paymentMethod?.providerCode,
            },
          }
        : purchaseEvent,
      callback,
    )
    sendFeatureEvent('purchases')
    sendFeatureEvent('revenue', purchaseEvent?.Order.Revenue)
    sendFeatureEvent('number-of-items-purchase', basket?.lines?.length)
  }

  const reportInsufficientDataFromMobile = (missingFields: string[]) => {
    sendSecurity('Flow.Checkout.InsufficientDataFromMobile', {
      missingFields: missingFields.join(','),
      'Order ID': basket?.id,
      'Store Number': currentStore?.storeNo,
    })
  }

  const reportOrderReset = (automaticallyReset: boolean = false) => {
    sendSecurity('Flow.Order.Reset', {
      'Order ID': basket?.id,
      'Automatically Reset': automaticallyReset,
    })
  }

  const reportInitOrderPayloadEmpty = (payment: Bff.Orders.InitialiseOrderPayment | undefined) => {
    sendSecurity(
      'Flow.Checkout.InitOrderPayloadEmpty',
      {
        'Order ID': currentOrder?.orderId ?? basket?.id,
        'Store Number': currentStore?.storeNo,
        OrderDetails: JSON.stringify(currentOrder),
        BasketDetails: JSON.stringify(basket),
        PaymentDetails: JSON.stringify(payment),
      },
      true,
    )
  }

  const reportRememberMyDetails = (checked: boolean) => {
    sendAnalytics('select_remember_my_details', {
      event_type: 'select',
      checked,
    })
  }

  const reportDetailsPrefilled = () => {
    sendAnalytics('checkout_detail_prefilled', {
      event_type: 'prefill',
    })
  }

  return {
    reportOrderStatus,
    reportEstimatedOrderTime,
    reportPurchase,
    reportInsufficientDataFromMobile,
    reportOrderReset,
    reportInitOrderPayloadEmpty,
    reportRememberMyDetails,
    reportDetailsPrefilled,
  }
}
