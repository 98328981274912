/* eslint-disable max-lines-per-function */

import { ApolloError, useMutation } from '@apollo/client'
import { updatePasswordMutation } from '@dominos/business/queries/update-password'
import { useBreakpoints, useCurrentOrderDetails, useCustomer, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { myPasswordErrors } from './my-password-errors'
import { MyPasswordForgeRock } from './my-password-forgerock'
import { useClasses } from './my-password.styles'
import { Button, TextField } from 'olo-ux'
import { useValidation } from '@dominos/business/functions/validation'
import { useSnackbar } from '@dominos/components/snackbar'
import { getMappedErrorToDisplay } from '@dominos/components/error/helpers'
import { isNativeApp } from '@dominos/business/functions/native-app'
import { useTextFieldValidation } from '@dominos/hooks-and-hocs/text-field-validation'

export type PasswordFieldValues = Record<string, ValidationField>

interface UpdatePasswordType {
  input: {
    oldPassword: string
    newPassword: string
  }
}

const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

const MyPassword = () => {
  const [changeCustomerPassword, { loading }] = useMutation<{}, UpdatePasswordType>(updatePasswordMutation, {
    onError: (error: ApolloError) => handleError(error),
    onCompleted: () => handleComplete(),
  })
  const { customer } = useCustomer()
  const { t } = useTranslation('login')
  const { t: tValidation } = useTranslation('validation')
  const { reportPasswordChanged } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const [forgeRockIdentityProviderEnabled] = useFeatures('ForgeRockIdentityProvider')
  const { isMobile } = useBreakpoints()
  const classes = useClasses({ isMobile })
  const { showSnackbar } = useSnackbar()
  const { isRequired, isPasswordValid, maxLength, minLength, passwordsMustMatch } = useValidation()

  const [currentPasswordText, setCurrentPasswordText, errorMessageCurrentPassword, currentPasswordTouched] =
    useTextFieldValidation('', [isRequired, isPasswordValid])
  const [passwordText, setPasswordText, errorMessagePassword, passwordTouched] = useTextFieldValidation('', [
    isRequired,
    minLength(8),
    maxLength(250),
    isPasswordValid,
  ])
  const [passwordConfirmText, setPasswordConfirmText, errorMessagePasswordConfirm, passwordConfirmTouched] =
    useTextFieldValidation('', [isRequired, passwordsMustMatch(passwordText)])

  const validationPassed = !errorMessageCurrentPassword && !errorMessagePasswordConfirm && !errorMessagePassword

  const showErrorSnackBar = (error: ApolloError) => {
    const mappedErrors = getMappedErrorToDisplay(error, myPasswordErrors, t, tValidation)
    const message = mappedErrors.popup.map(({ translatedError }) => translatedError)?.join('<br />')
    showSnackbar({ message, type: 'error' })
  }

  const handleComplete = () => {
    showSnackbar({ message: t('ChangePasswordSuccessful', { defaultValue: 'Password changed successfully' }) })
    reportPasswordChanged({ orderId, success: true })
  }

  const handleError = (error: ApolloError) => {
    reportPasswordChanged({ orderId, success: false })
    showErrorSnackBar(error)
  }

  const onPasswordChangeSubmit = async () => {
    await changeCustomerPassword({
      variables: {
        input: {
          oldPassword: currentPasswordText,
          newPassword: passwordText,
        },
      },
    })
  }

  if (!customer) {
    return <></>
  }

  if (customer.identityProvider !== null) {
    return (
      <div className={classes.subContainer}>
        {!isNativeApp() && (
          <h2 className={classes.heading}>{t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}</h2>
        )}
        <p className={classes.paragraph}>
          {t('ChangePasswordUnavailableSocialAccount', {
            identityProvider: customer.identityProvider
              ? capitalizeFirstLetter(customer.identityProvider)
              : 'social media',
            defaultValue:
              'You signed up with a {{identityProvider}} account. To change your password please go to the {{identityProvider}} website.',
          })}
        </p>
      </div>
    )
  }

  if (forgeRockIdentityProviderEnabled) {
    return <MyPasswordForgeRock />
  }

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        {!isNativeApp() && (
          <h2 className={classes.heading}>{t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}</h2>
        )}
        <p className={classes.paragraph}>
          {t('MyAccountPasswordDescription', {
            defaultValue: 'To change your password, please fill out the form:',
          })}
        </p>

        <div className={classes.wrapperInner}>
          <p className={classes.subheading}>{t('CurrentPassword', { defaultValue: 'Current Password' })}</p>
          <TextField
            onChangeText={setCurrentPasswordText}
            placeholder={t('CurrentPassword', { defaultValue: 'Current Password' })}
            value={currentPasswordText || ''}
            hasError={currentPasswordTouched && !!errorMessageCurrentPassword}
            hint={currentPasswordTouched && errorMessageCurrentPassword ? t(errorMessageCurrentPassword) : ''}
            onClear={() => setCurrentPasswordText('')}
            secureTextEntry={true}
          />
        </div>

        <div className={classes.wrapperInner}>
          <p className={classes.subheading}>{t('NewPassword', { defaultValue: 'New Password' })}</p>
          <TextField
            onChangeText={setPasswordText}
            placeholder={t('NewPassword', { defaultValue: 'New Password' })}
            value={passwordText || ''}
            hasError={passwordTouched && !!errorMessagePassword}
            hint={passwordTouched && errorMessagePassword ? t(errorMessagePassword) : ''}
            onClear={() => setPasswordText('')}
            secureTextEntry={true}
            autoComplete='new-password'
          />
        </div>

        <p className={classes.subheading}>{t('ConfirmPasswordLabel', { defaultValue: 'Confirm New Password' })}</p>
        <TextField
          onChangeText={setPasswordConfirmText}
          placeholder={t('ConfirmPasswordLabel', { defaultValue: 'Confirm New Password' })}
          value={passwordConfirmText || ''}
          hasError={passwordConfirmTouched && !!errorMessagePasswordConfirm}
          hint={passwordConfirmTouched && errorMessagePasswordConfirm ? t(errorMessagePasswordConfirm) : ''}
          onClear={() => setPasswordConfirmText('')}
          secureTextEntry={true}
          autoComplete='new-password'
        />
      </div>

      <div className={isMobile ? classes.mobileButtonContainer : classes.buttonContainer}>
        <Button
          onPress={onPasswordChangeSubmit}
          disabled={loading || !validationPassed}
          type='primary'
          size='medium'
          label={t('ChangePassword', { defaultValue: 'Change Password' })}
        />
      </div>
    </div>
  )
}

export { MyPassword }
