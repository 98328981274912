import { isNativeApp } from '@dominos/business/functions'
import { NavigationConstants } from '@dominos/navigation'
import { getBasketId, useIsTwoTapCheckoutEnabled } from '@dominos/hooks-and-hocs'
import { useLocation } from 'react-router-dom'

export const useReturnURL = () => {
  const isTwoTapCheckoutEnabled = useIsTwoTapCheckoutEnabled()
  const orderId = getBasketId(window.location.search)
  const { pathname } = useLocation()

  const getPaymentReturnUrl = (isOrderFree = false) =>
    isOrderFree ? getFreeOrderUrl() : getReturnUrl(`?basketId=${orderId}${window.location.hash}`)

  const getFreeOrderUrl = () => {
    if (isTwoTapCheckoutEnabled) {
      return isNativeApp() ? NavigationConstants.nativeAppCheckout : NavigationConstants.checkout
    }

    return isNativeApp() ? NavigationConstants.nativeAppCheckoutPlaceOrder : NavigationConstants.checkoutPlaceOrder
  }

  const getReturnUrl = (append = '') => {
    if (isTwoTapCheckoutEnabled) {
      return isNativeApp() ? `${NavigationConstants.nativeAppCheckout}${append}` : NavigationConstants.checkout
    }

    return isNativeApp()
      ? `${NavigationConstants.nativeAppCheckoutPayment}${append}`
      : NavigationConstants.checkoutPayment
  }

  const isCheckoutUrlValid = () =>
    (!isTwoTapCheckoutEnabled && pathname.startsWith(NavigationConstants.checkoutPayment)) ||
    (isTwoTapCheckoutEnabled &&
      pathname.startsWith(NavigationConstants.checkout) &&
      !pathname.startsWith(NavigationConstants.checkoutProcessing))

  return {
    getPaymentReturnUrl,
    isCheckoutUrlValid,
  }
}
