import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { AnalyticSender, VoucherCodeDeclinedEvent, VoucherCodeSource, VoucherProcessingStatus } from './analytics'
import { SecuritySender } from './security'

export type Source = 'Deeplink' | 'Organic' | 'Form' | 'Aggregator' | 'Internal' | 'Menu Set'

const voucherCodeSource: Record<Source, VoucherCodeSource> = {
  Form: 'Form',
  Deeplink: 'Link',
  'Menu Set': 'Offers',
  Organic: 'Offers', // Is this an offer?
  Aggregator: 'Offers', // Is this an offer?
  Internal: 'Offers', // Is this an offer?
}

export const reportVoucher = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  basket?: Basket,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => {
  const reportVoucherUsage = (code: string, source: Source) => {
    sendSecurity('Flow.Voucher.VoucherEntered', {
      Code: code,
      Source: source,
      'Order ID': currentOrder?.orderId,
      'Store Number': currentStore?.storeNo,
    })

    sendAnalytics('Voucher Code Entered', {
      'Voucher Code': code,
      'Voucher Code Source': voucherCodeSource[source],
    })
  }

  const reportVoucherAccepted = (
    code: string,
    description: string,
    value?: number,
    clearPartialAnalytics: boolean = false,
  ) => {
    sendSecurity('Flow.Voucher.VoucherProcessed', {
      Code: code,
      'Order ID': currentOrder?.orderId,
    })

    sendAnalytics('Voucher Code Processed', {
      'Voucher Code': code,
      'Voucher Description': description,
      'Voucher Processing Status': 'Accepted',
      'Voucher Value': value,
      ...(clearPartialAnalytics ? { 'Voucher Code Source': '' } : {}),
    })
  }

  const reportVoucherDeclined = (
    status: VoucherProcessingStatus,
    reason?: string,
    clearPartialAnalytics: boolean = false,
  ) => {
    sendSecurity('Flow.Voucher.VoucherProcessed', {
      'Declined Reason': reason,
      'Order ID': basket?.id,
    })

    sendAnalytics('Voucher Code Processed', {
      'Voucher Processing Status': status,
      ...(reason ? { 'Voucher Declined Reason': reason } : {}),
      ...(clearPartialAnalytics
        ? {
            'Voucher Code': '',
            'Voucher Code Source': '',
          }
        : {}),
    } as VoucherCodeDeclinedEvent)
  }

  const reportVoucherRemoved = (code: string, source: Source, value: number) => {
    sendSecurity('Flow.Voucher.VoucherRemoved', {
      Code: code,
      Source: source,
      'Order ID': currentOrder?.orderId,
      'Store Number': currentStore?.storeNo,
    })

    sendAnalytics('Voucher Code Removed', {
      'Voucher Code': code,
      'Voucher Code Source': voucherCodeSource[source],
      'Voucher Value': value,
    })
  }

  return {
    reportVoucherUsage,
    reportVoucherAccepted,
    reportVoucherDeclined,
    reportVoucherRemoved,
  }
}
