import { ApolloLink } from '@apollo/client'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { AKAMAI_CACHE_STATUS_HEADER, akamaiCacheStatusHandler, dpeTokenHandler, ipAddressHandler } from './handlers'
import { FeatureFlagType } from '@dominos/hooks-and-hocs/features'
import { isKioskOrderType } from '@dominos/business/functions/common'

export const afterwareResponseLink = (securitySender: SecuritySender, features: FeatureFlagType) =>
  new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      // handle response data
      const context = operation.getContext()
      const {
        response: { headers },
      } = context

      if (headers) {
        dpeTokenHandler(headers, operation, securitySender)

        const headerCacheStatus = headers.get(AKAMAI_CACHE_STATUS_HEADER)
        const isVoucherSoftPinningLogCacheMetricsEnabled = !!features?.['VoucherSoftPinningLogCacheMetrics']
        const isMenuLogCacheMetricsEnabled = !!features?.['MenuLogCacheMetrics']

        const isOperationAllowed =
          operation.operationName === 'swapSetProducts'
            ? isVoucherSoftPinningLogCacheMetricsEnabled
            : operation.operationName === 'menu'
            ? isMenuLogCacheMetricsEnabled
            : false

        if (headerCacheStatus && isOperationAllowed) {
          akamaiCacheStatusHandler(headerCacheStatus, operation, securitySender)
        }

        if (isKioskOrderType()) {
          ipAddressHandler(headers, operation)
        }
      }

      return response
    }),
  )
