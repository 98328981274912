import React, { createContext, useContext, useEffect, useState } from 'react'
import { ProfileCompletionModal } from '@dominos/components'
import { useCustomer, useFeatures, useLogout } from '@dominos/hooks-and-hocs'
import { isNativeApp } from '@dominos/business/functions'

export const ProfileCompletionPopupHandler = ({ children }: PropsWithChildren) => {
  const [show, setShow] = useState(false)
  const [customerProfileCompletionScreen] = useFeatures('CustomerProfileCompletionScreen')
  const { logout } = useLogout()
  const { customer, loading } = useCustomer()
  const isIncompleteProfile =
    !!customer && customerProfileCompletionScreen && !isCompletedProfile(customer) && !isNativeApp()

  useEffect(() => {
    if (!loading && isIncompleteProfile) {
      setShow(true)
    }
  }, [loading, isIncompleteProfile])

  const hidePopup = () => {
    setShow(false)
    logout()
  }

  const showPopup = () => {
    setShow(isIncompleteProfile)
  }

  const handleSubmit = () => {
    setShow(false)
  }

  const shouldShowPopup = show && isIncompleteProfile

  return (
    <ProfileCompletionPopupContext.Provider
      value={{
        hidePopup,
        showPopup,
        isVisible: show,
      }}
    >
      {children}
      {shouldShowPopup && (
        <ProfileCompletionModal
          testID='profileCompletionModal'
          onSubmit={handleSubmit}
          onClose={hidePopup}
          customer={customer}
        />
      )}
    </ProfileCompletionPopupContext.Provider>
  )
}

export const ProfileCompletionPopupContext = createContext<
  | undefined
  | {
      hidePopup: () => void
      showPopup: () => void
      isVisible: boolean
    }
>(undefined)

export const useProfileCompletionPopup = () => {
  const context = useContext(ProfileCompletionPopupContext)

  if (!context) {
    throw new Error('useProfileCompletionPopup must be used within a ProfileCompletionPopupContext')
  }

  return context
}

const isCompletedProfile = (customer: Bff.Customers.Customer) =>
  customer?.email && customer?.name && customer?.phoneNumber && customer?.termsAndConditionsAccepted
