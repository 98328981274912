import { useCurrentOrderDetails, useFetchProduct, useProductStorage } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'

export const useProducts = () => {
  const { fetchProduct, product: fetchedProduct, loading, error } = useFetchProduct()
  const [product, setProduct] = useState<Bff.Products.ProductBase<Bff.Dimensions.DimensionSet> | undefined>()
  const { basketHeaderData: header } = useCurrentOrderDetails()
  const { storedProductsByCode, setStoredProductsByCode } = useProductStorage()

  const fetchProductByCode = (productCode: string | undefined) => {
    if (!productCode) {
      setProduct(undefined)

      return
    }
    const storedProduct = storedProductsByCode?.[productCode]
    if (storedProduct) {
      setProduct(storedProduct)
    } else {
      fetchProductData(productCode)
    }
  }

  const fetchProductData = (productCode: string) => {
    if (!header || !header.serviceMethod || !header.storeNo || header.time === undefined) {
      return
    } else {
      fetchProduct({
        productCode,
        serviceMethod: header.serviceMethod,
        storeNo: header.storeNo,
        requestTime: header.time,
      })
    }
  }

  useEffect(() => {
    if (loading || error) {
      setProduct(undefined)

      return
    }

    if (fetchedProduct) {
      setProduct(fetchedProduct)
      setStoredProductsByCode({ ...storedProductsByCode, [fetchedProduct.code]: fetchedProduct })
    }
  }, [loading, error, fetchedProduct])

  return {
    fetchProductByCode,
    loading,
    product,
    error,
  }
}
