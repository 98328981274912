import { useState } from 'react'

export const useDictionary = <K>(
  defaultValue: KeyValuePair<K>[],
): [KeyValuePair<K>[], (props: KeyValuePair<K>[]) => void] => {
  const [dictionary, setDictionary] = useState<KeyValuePair<K>[]>(defaultValue)

  const updateDictionary = (props: KeyValuePair<K>[]) => {
    setDictionary((prevState) => {
      const newProps = [...prevState]
      props.forEach((prop) => {
        const index = newProps.findIndex((p) => p.key === prop.key)
        if (index !== -1) {
          newProps[index] = prop
        } else {
          newProps.push(prop)
        }
      })

      return newProps
    })
  }

  return [dictionary, updateDictionary]
}
