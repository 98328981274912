import { useTranslation } from 'react-i18next'

export const useValidation = () => {
  const { t } = useTranslation('validation')
  const { t: tLogin } = useTranslation('login')

  const isRequired: ValidationFunction<string> = (value?: string | null) =>
    value && value.trim().length > 0 ? null : t('RequiredField')

  const minLength = (length: number) => (value?: string | null) =>
    !value || value.length >= length
      ? null
      : `${t('MinimumLengthError', {
          length,
          defaultValue: 'Required min length of {{length}}',
        })}`

  const maxLength = (length: number) => {
    const validationFunc: ValidationFunction<string> = (value?: string | null) =>
      !value || value.length <= length
        ? null
        : `${t('MaximumLengthError', {
            length,
            defaultValue: 'Exceeded max length of {{length}}',
          })}`
    validationFunc.restrictInput = true

    return validationFunc
  }

  const equalLength = (length: number) => (value?: string | null) =>
    !value || value.length === length
      ? null
      : `${t('EqualLengthError', {
          length,
          defaultValue: 'Length should be equal to {{length}}',
        })}`

  const matchRegex = (regex: RegExp, errorMessage: string) => (value?: string | null) =>
    !value || value.match(regex) ? null : errorMessage

  const overMinimum = (minimum: number) => {
    const validationFunc: ValidationFunction<number> = (value?: number | null) =>
      !value || value >= minimum
        ? null
        : `${t('MinimumValueError', {
            min: minimum,
            defaultValue: 'You must enter a value above {{min}}',
          })}`
    validationFunc.restrictInput = true

    return validationFunc
  }

  const underMaximum = (maximum: number) => {
    const validationFunc: ValidationFunction<number> = (value?: number | null) =>
      !value || value <= maximum
        ? null
        : `${t('MaximumValueError', {
            max: maximum,
            defaultValue: 'You must enter a value below {{max}}',
          })}`
    validationFunc.restrictInput = true

    return validationFunc
  }

  const isNumeric: ValidationFunction<string> = matchRegex(/^[0-9]+$/, t('NumbersOnlyError'))
  isNumeric.restrictInput = true

  const isFloat: ValidationFunction<string> = (value?: string | null) => {
    const decimalisedValue = value?.replace(',', '.') // to handle EU decimals

    return matchRegex(
      /^\d+(?:[.]\d+)?$/,
      t('NumbersOnlyError', { defaultValue: 'Only numbers are allowed' }),
    )(decimalisedValue)
  }
  isFloat.restrictInput = true

  const isAlphaNumeric: ValidationFunction<string> = matchRegex(/^[0-9a-zA-Z]+$/, t('AlphanumericOnlyError'))
  isNumeric.restrictInput = true

  const isEmailValid: ValidationFunction<string> = matchRegex(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    t('InvalidEmailAddressError'),
  )

  const isPasswordValid: ValidationFunction<string> = matchRegex(/^[^-\s]{8,}$/, t('InvalidPasswordError'))

  const isNameValid: ValidationFunction<string> = matchRegex(
    /^[^<=>]*$/,
    t('InvalidNameError', { defaultValue: 'Please enter a valid name' }),
  )

  const isUniformNumberValid: ValidationFunction<string> = (value?: string | null) =>
    ValidateUniformNumber(value)
      ? null
      : t('InvalidUniformNumberError', { defaultValue: 'Please enter a valid government uniform invoice number' })

  const isMobileCarrierCodeValid: ValidationFunction<string> = matchRegex(
    /^\/[\dA-Z0-9+-\.]{7}$/,
    t('InvalidMobileCarrierCodeError', { defaultValue: 'Please enter a valid mobile carrier code start with /' }),
  )

  const isCitizenCarrierCodeValid: ValidationFunction<string> = matchRegex(
    /^[A-Z]{2}[0-9]{14}$/,
    t('InvalidCitizenCarrierCodeError', {
      defaultValue: 'Please enter a valid citizen digital certificate carrier code',
    }),
  )

  const ValidateUniformNumber = (value?: string | null): boolean => {
    if (!value) return true
    if (!/^\d{8}$/.test(value)) return false
    const weight = [1, 2, 1, 2, 1, 2, 4, 1]
    const sum = value.split('').reduce((acc, digit, i) => {
      const num = parseInt(digit, 10) * weight[i]

      return acc + Math.floor(num / 10) + (num % 10)
    }, 0)

    return sum % 5 === 0 || ((sum + 1) % 5 === 0 && value[6] === '7')
  }

  const passwordsMustMatch = (passwordMatch?: string) => (value?: string | null) =>
    !value || value === passwordMatch ? null : tLogin('PasswordsMustMatchError')

  return {
    isRequired,
    minLength,
    maxLength,
    equalLength,
    matchRegex,
    overMinimum,
    underMaximum,
    isNumeric,
    isFloat,
    isAlphaNumeric,
    isEmailValid,
    isPasswordValid,
    isNameValid,
    isUniformNumberValid,
    isMobileCarrierCodeValid,
    isCitizenCarrierCodeValid,
    passwordsMustMatch,
  }
}
