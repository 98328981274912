import React, { useRef } from 'react'
import styles from './my-invoice.less'
import { useClearTaxCustomerPortalIframe } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import Markdown from 'markdown-to-jsx'
import { MDTargetBlankAnchor } from '@dominos/components'

export const MyInvoice = () => {
  const { t } = useTranslation('login')
  const iframeContainer = useRef<HTMLDivElement>(null)

  const { hasError } = useClearTaxCustomerPortalIframe(iframeContainer)

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          {hasError ? (
            <p>
              {t('MyAccountInvoiceClearTaxHasErrorText', {
                defaultValue: 'We`re sorry, but something went wrong. Please try again later.',
              })}
            </p>
          ) : (
            <>
              <p data-testid='my-account-invoice'>
                <Markdown data-testid='my-account-invoices-help' options={{ overrides: { a: MDTargetBlankAnchor } }}>
                  {t('MyAccountInvoiceHelpText', {
                    defaultValue: `**Need help?** Check out our [FAQ](https://www.dominos.com.my/einvoice-faq)`,
                  })}
                </Markdown>
              </p>
              <div className={styles.maxHeight} ref={iframeContainer} />
            </>
          )}
        </div>
      </div>
    </>
  )
}
