import React, { useContext } from 'react'
import { Config as ForgeRockConfig } from '@forgerock/javascript-sdk'
import { FORGEROCK_CONSTANT } from '@dominos/business/constants'
import { useSetupVendors } from '@dominos/business/functions'
import {
  getSecurityCommonParams,
  security,
  SecurityContextProps,
  SecuritySender,
  useConfigContext,
} from '@dominos/hooks-and-hocs'

interface VendorsContextProps {
  securityClient: SecurityContextProps
  sendSecurity: SecuritySender
}

const VendorsContext = React.createContext<VendorsContextProps>({
  securityClient: {} as SecurityContextProps,
  sendSecurity: undefined as unknown as SecuritySender,
})

export const VendorsProvider = ({ children }: { children: React.ReactNode }) => {
  const { applicationConfig, applicationEnv } = useConfigContext()
  const { securityClient } = useSetupVendors(applicationConfig)
  const commonParams = getSecurityCommonParams(applicationEnv)
  const { sendSecurity } = security(securityClient.client, commonParams)

  ForgeRockConfig.set({
    serverConfig: {
      baseUrl: applicationConfig.FORGEROCK_AM_URL,
      timeout: FORGEROCK_CONSTANT.TIMEOUT_MILLISECONDS,
    },
    realmPath: FORGEROCK_CONSTANT.REALM_PATH,
    clientId: FORGEROCK_CONSTANT.CLIENT_ID,
    redirectUri: window.location.origin + FORGEROCK_CONSTANT.CALLBACK_PATH,
    scope: FORGEROCK_CONSTANT.SCOPE,
  })

  return (
    <VendorsContext.Provider
      value={{
        securityClient,
        sendSecurity,
      }}
    >
      {children}
    </VendorsContext.Provider>
  )
}

export const useVendorsContext = () => useContext(VendorsContext)
