import { FRUser, TokenStorage } from '@forgerock/javascript-sdk'
import { IForgeRockClient } from './interfaces/IForgerockClient'
import { buildForgerockUrl, getTokenOptions } from '@dominos/business/functions/forgerock'
import type { ServerConfig } from '@dominos/business/constants'

export const ForgeRockClient: IForgeRockClient = {
  login: async (urlParams: IForgerockUrlParams, serverConfig?: ServerConfig) => {
    await getTokenOptions({
      login: 'redirect',
      query: {
        action: 'login',
        market: urlParams.countryCode,
        locale: `${urlParams.language}-${urlParams.countryCode}`,
        application: urlParams.application,
        orderId: urlParams.orderId,
      },
      forceRenew: true,
      serverConfig,
    })
  },
  register: async (urlParams: IForgerockUrlParams, serverConfig?: ServerConfig) => {
    await getTokenOptions({
      login: 'redirect',
      query: {
        action: 'register',
        market: urlParams.countryCode,
        locale: `${urlParams.language}-${urlParams.countryCode}`,
        application: urlParams.application,
        orderId: urlParams.orderId,
      },
      forceRenew: true,
      serverConfig,
    })
  },
  getAuthToken: async (code, state) => await getTokenOptions({ query: { code, state } }),
  logout: async () => await FRUser.logout(),
  getTokenFromStorage: async () => await TokenStorage.get(),
  fetchAuthToken: async () =>
    await getTokenOptions({
      login: 'redirect',
    }),
  updatePassword: (urlParams: IForgerockUrlParams) => {
    window.location.href = buildForgerockUrl(urlParams, 'DPE-UpdatePassword-Global')
  },
  deletAccount: async (urlParams: IForgerockUrlParams) => {
    window.location.href = buildForgerockUrl(urlParams, 'DPE-DeleteUser-Global')
  },
}
