import { ApolloError } from '@apollo/client'
import { svgIconsName } from '@dominos/res'
import { OrderStatus } from '@dominos/hooks-and-hocs/order'
import { Bff as BffErrors } from 'bff-errors'

/*
=========================
Error codes
=========================
*/
export enum GenericErrorCodes {
  NETWORK_ERROR = 'NETWORK_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type ErrorDefinitionCode =
  | keyof typeof GenericErrorCodes
  | keyof typeof BffErrors.Errors
  | Bff.Errors.Customer
  | Bff.Errors.Feedback
  | Bff.Errors.Notification
  | Bff.Errors.Vouchers
  | Bff.Errors.Payment
  | Bff.Errors.Kiosk

export enum ErrorScope {
  Payment = 'payment',
  PaymentBalance = 'paymentBalance',
  CreateAccount = 'createAccount',
  LoginAccount = 'loginAccount',
  ForgotPassword = 'forgotPassword',
  Processing = 'processing',
  SelectStore = 'selectStore',
  TimedOrder = 'timedOrder',
  Tracker = 'tracker',
  Kiosk = 'kiosk',
  UpdateProfile = 'updateProfile',
}

export type ErrorDisplayType = 'none' | 'alert' | 'modal' | 'popup' | 'closable-popup' | 'error-text' | 'boxed-text'

/*
=============================
Translations interfaces/types
=============================
*/

/**
 * Transalation interface for displaying any error title/message
 *  - forces to provide translation key and getOptions function so dev can customise to get defaultValue
 */
export interface TranslationKey {
  key: string
  getOptions: (defaultValue?: string | null) => {
    defaultValue: string | null
  }
}

/**
 * Translation type to provide to error definition
 *  - title is optional as we don't show title for all errors
 */
export interface TranslationType {
  title?: TranslationKey
  message: TranslationKey
  confirmLabel?: TranslationKey
}

/*
==================================
Error definitions interfaces/types
==================================
*/
export interface ErrorDefinition extends BaseProps {
  translation: TranslationType
  displayType: ErrorDisplayType
  icon?: svgIconsName
  id?: string
}

export type ErrorDefinitionsType = PartialRecord<ErrorDefinitionCode, ErrorDefinition>
export type StatusErrorDefinitionsType = (orderStatus: OrderStatus) => ErrorDefinition

/*
=================================
Notifiable error interfaces/types
=================================
*/
export interface NotifiableScope {
  scope: ErrorScope
  handlers: AtLeastOneErrorHandlers
}

export interface NotifiableErrorEvent extends NotifiableScope {
  error: ApolloError
  definitions: ErrorDefinitionsType
}

export interface NotifiableStatusEvent extends NotifiableScope {
  orderStatus: keyof typeof OrderStatus
  definitions: ErrorDefinition
}

export const isNotifiableErrorEvent = (event: NotifiableEvent): event is NotifiableErrorEvent => 'error' in event

export type NotifiableEvent = NotifiableErrorEvent | NotifiableStatusEvent

/*
=======================================================
Displayable error interface (for the error component)
=======================================================
*/
export interface DisplayableError extends BaseProps {
  code: string
  displayType: ErrorDisplayType
  icon?: svgIconsName
  message: string
  onClose: () => void
  title?: string
  id?: string
  confirmLabel?: string
}

/*
================================
Error handlers interfaces/types
================================
*/
export interface ErrorHandler {
  handleErrorDisplayed: Function
  handleErrorClosed?: Function
}

export type ApolloErrorHandlers = Record<
  'networkErrorHandler' | 'graphErrorHandler',
  (error: ApolloError) => ErrorHandler
>

export type GenericError = Record<GenericErrorCodes.UNKNOWN_ERROR, unknown>
export type GenericErrorHandlers = Record<'genericErrorHandler', (error: GenericError) => ErrorHandler>

export type StatusErrorHandlers = Record<'statusErrorHandler', (status: OrderStatus) => ErrorHandler>

export type ErrorHandlers = ApolloErrorHandlers & StatusErrorHandlers & GenericErrorHandlers

export type AtLeastOneErrorHandlers = Partial<ErrorHandlers>
