import { BasketLineData, getDefaultSizeAndEnergy } from '@dominos/business/functions/basket'
import { mapMenuItemToCrustItemData } from '@dominos/business/functions/menu'
import { formatSuperscript } from '@dominos/business/functions/text'
import { CollapsableTitledCard, isProductMenuItemNew } from '@dominos/components'
import { ProductPricingDetails } from '@dominos/components/product/product-pricing-details'
import { BREAKPOINTS, usePricing } from '@dominos/hooks-and-hocs'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GlutenFreeDisclaimer } from '../../menu/menu-section-container/gluten-free-disclaimer'
import css from './product-details.less'
import classNames from 'classnames'

type ProductSize = Bff.Menu.old.ProductSize
type PortionSize = Bff.Menu.old.PortionSize

const isGlutenFreeBase = (product: BasketLineData, size: ProductSize | PortionSize | null | undefined) => {
  const { selected } = mapMenuItemToCrustItemData(product.item as ChangeableProductItem, size?.code!)

  return selected?.code === 'NOGLUTEN' || product.base?.add === 'NOGLUTEN'
}

export const ProductDetailsCard = (props: ProductDetailsProps) => {
  const { testID, product } = props
  const { formattedPrice, formattedSalePrice, formattedPromoPrice, getPrice } = usePricing()
  const { t } = useTranslation('menu')

  // Product data
  const item = product && product.item && !isProductMenuItemNew(product.item) ? product.item : undefined
  const media = item ? item.media : undefined
  const { energyKJ, defaultSize } = getDefaultSizeAndEnergy(item)
  const title = media?.name || ''
  const description = media?.description ? formatSuperscript(media?.description) : ''
  const kj = energyKJ

  const MINIMUM_HEIGHT = 38
  const LARGE_MINIMUM_HEIGHT = 66
  const [open, setOpen] = useState(false)
  const [expandableDescription, setExpandableDescription] = useState(false)

  const [showGlutenFreeDisclaimer, setShowGlutenFreeDisclaimer] = useState<boolean>(() =>
    isGlutenFreeBase(product, defaultSize),
  )

  const toggleText = () => {
    setOpen(!open)
  }

  const ref = useRef<HTMLParagraphElement>(null)

  useEffect(() => {
    getPrice(product)
    setShowGlutenFreeDisclaimer(isGlutenFreeBase(product, defaultSize))
  }, [product])

  useLayoutEffect(() => {
    const paragraphHeight = ref.current && ref.current.clientHeight ? ref.current.clientHeight : 0
    const paragraphWidth = ref.current && ref.current.clientWidth ? ref.current.clientWidth : 0
    const minHeight = paragraphWidth > (BREAKPOINTS.MOBILE.MAX ?? 772) ? LARGE_MINIMUM_HEIGHT : MINIMUM_HEIGHT

    if (paragraphHeight >= minHeight) {
      setExpandableDescription(true)
    }
  }, [description])

  return (
    <CollapsableTitledCard
      primaryTitle={title}
      startCollapsed={false}
      noPadding={false}
      static
      testID={'product-details-card'}
    >
      <div data-testid={`${testID}.subtitle`} className={css.subtitle}>
        <ProductPricingDetails
          testID={`${testID}.subtitle`}
          showPrice={!!formattedPrice}
          price={formattedPrice}
          salePrice={formattedSalePrice}
          promoPrice={formattedPromoPrice}
          kj={kj}
        />
      </div>
      <div ref={ref} data-testid={`${testID}.description`} className={classNames(css.description, !open && css.closed)}>
        {description}
      </div>
      {expandableDescription && (
        <p data-testid={`${testID}.button`} role='button' onClick={toggleText} className={css.more}>
          {`${t('show')} ${open ? t('less') : t('more')}`}
        </p>
      )}
      {showGlutenFreeDisclaimer && <GlutenFreeDisclaimer />}
    </CollapsableTitledCard>
  )
}
