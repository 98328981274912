import { getFullUrl } from '@dominos/business/functions/menu/get-images'
import { ActionButton } from '@dominos/components/buttons'
import { noop, useDevToggles } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MultiItemGrid } from './multi-item-grid'
import { OfferSingleItem } from './offer-single-item'
import css from './offer.less'

const iconWidth = 21
const iconHeight = 21
const icon = 'cross'

interface OfferProps extends BaseProps {
  offerDetails: Bff.Offers.Offer
  onOfferAccepted: (offerItems: Bff.Offers.Item[], interactionType: string) => void
  onClose: (interactionType: string) => void
  onImageLoaded?: () => void
}

export const Offer = ({ testID, offerDetails, onOfferAccepted, onClose, onImageLoaded = noop }: OfferProps) => {
  const { t } = useTranslation('menu')
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const { isEnabled } = useDevToggles()
  const offerAddButtonText = t('OfferAddButton', { defaultValue: 'Add to Order' })
  const offerCloseButtonText = t('OfferCloseButton', { defaultValue: 'No Thanks' })

  const selectedItemsChanged = (items: string[]) => {
    setSelectedItems(items)
  }

  useEffect(() => {
    if (offerDetails.layout === 'SingleItem') {
      setSelectedItems([offerDetails.items[0].id])
    }
  }, [])

  const getImageUrl = (offer: Bff.Offers.Offer) => {
    if (offer.media.popUp && offer.media.popUp.url) {
      if (isEnabled['test-offers-images']) {
        return `https://internetorder.dominostest.co.nz/${offer.media.popUp.url}`
      }

      return getFullUrl(offer.media.popUp.url)
    }

    return undefined
  }

  const handleAddToBasket = (addToBasketButtonText: string) => {
    const itemsToAdd = selectedItems.map((id) => offerDetails.items.find((item) => item.id === id)!)
    onOfferAccepted(itemsToAdd, addToBasketButtonText)
  }

  return (
    <div data-testid={testID} className={css.cardContainer}>
      <div
        data-testid={`${testID}.close-button`}
        role='close'
        className={css.close}
        onClick={() => onClose('Pop-up Close')}
      >
        <div role='button' data-testid={icon} className={css.closeIcon}>
          <StaticSvgIcon name={icon} isUnstyled={true} width={iconWidth} height={iconHeight} />
        </div>
      </div>
      {offerDetails.layout === 'SingleItem' && offerDetails.media.popUp.url && (
        <img
          data-testid={`${testID}-image`}
          className={css.image}
          src={getImageUrl(offerDetails)}
          alt={offerDetails.media.popUp.altText ?? undefined}
          onLoad={onImageLoaded}
        />
      )}
      <div className={css.offerContainer}>
        {offerDetails.layout === 'SingleItem' && (
          <OfferSingleItem
            testID={`${testID}.${offerDetails.layout}`}
            title={offerDetails.media.name}
            description={offerDetails.media.description}
            energy={
              offerDetails.items.length > 0 && offerDetails.items[0].energy ? offerDetails.items[0].energy : undefined
            }
            price={
              offerDetails.items.length > 0 && offerDetails.items[0].price ? offerDetails.items[0].price : undefined
            }
            disclaimers={offerDetails.disclaimers}
          />
        )}

        {(offerDetails.layout === 'MultiItemGrid' || offerDetails.layout === 'MultiItemGridExclusive') && (
          <MultiItemGrid
            testID={`${testID}.${offerDetails.layout}`}
            title={offerDetails.media.name}
            description={offerDetails.media.description}
            disclaimers={offerDetails.disclaimers}
            offerItems={offerDetails.items}
            onSelectedItemsChanged={selectedItemsChanged}
            exclusive={offerDetails.layout === 'MultiItemGridExclusive'}
          />
        )}

        <div className={css.buttonBar}>
          <ActionButton
            testID='Offer.Button.NoThanks'
            containerStyle={{
              backgroundColor: '#4a4a4a',
              flexGrow: 1,
              width: '100%',
              maxWidth: '215px',
              margin: '2px',
            }}
            text={offerCloseButtonText}
            textFontWeight={'bold'}
            onPress={() => onClose(offerCloseButtonText)}
          />
          <ActionButton
            testID='Offer.Button.Add'
            containerStyle={{ flexGrow: 1, width: '100%', maxWidth: '215px', margin: '2px' }}
            text={offerAddButtonText}
            textFontWeight={'bold'}
            onPress={() => handleAddToBasket(offerAddButtonText)}
            disabled={selectedItems.length === 0}
          />
        </div>
      </div>
    </div>
  )
}
