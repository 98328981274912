import React from 'react'
import { ActionButton } from '@dominos/components'
import css from './styles/retry-dialog.less'
import { useTranslation } from 'react-i18next'

export const RetryDialog = (props: RetryDialogProps) => {
  const onPress = () => props.onRetry && props.onRetry()
  const { t } = useTranslation('menu')

  return (
    <div data-testid={props.testID} className={css.container}>
      <div className={css.errorMessage}>{t('MenuLoadFailedMessage')}</div>
      <ActionButton onPress={onPress} text={props.text || t('MenuLoadFailedRetryText')} testID={'retry-button'} />
    </div>
  )
}
