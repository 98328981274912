import React, { useEffect, useState } from 'react'
import { useDominosTheme, useServiceMethod } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import { ActionButton } from '@dominos/components'
import { svgIconsName } from '@dominos/res/images/icons'
import { useTranslation } from 'react-i18next'
import css from './order-details-pill-information.less'
import debounce from 'lodash/debounce'

export interface OrderDetailsPillInformationProps extends BaseProps {
  onDismiss: (neverShowAgain?: boolean) => void
  orderDetailsPillRef?: HTMLDivElement
}

export const OrderDetailsPillInformation = ({
  testID,
  onDismiss,
  orderDetailsPillRef,
}: OrderDetailsPillInformationProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('menu')
  const [lineContainerTop, setLineContainerTop] = useState(0)

  const serviceMethod = useServiceMethod()
  const isPickup = serviceMethod === 'Pickup'
  const serviceMethodIcon: svgIconsName = isPickup ? 'pickUp' : 'delivery'
  const pickupAgainText = t('OrderDetailsPillInformation.PickUpAgain', {
    defaultValue: 'Pick Up\nAgain?',
  })

  const deliveryAgainText = t('OrderDetailsPillInformation.DeliverAgain', {
    defaultValue: 'Delivery\nAgain?',
  })

  const serviceMethodAgainText = isPickup ? pickupAgainText : deliveryAgainText

  const pickupExplanationText = t('OrderDetailsPillInformation.PickUpExplanation', {
    defaultValue:
      "We've set your order up just like your last one.  If you need to change time, change store, or swap to delivery, just tap up here.",
  })

  const deliveryExplanationText = t('OrderDetailsPillInformation.DeliveryExplanation', {
    defaultValue:
      "We've set your order up just like your last one.  If you need to change time, change address, or swap to pickup, just tap up here.",
  })
  const serviceExplanationText = isPickup ? pickupExplanationText : deliveryExplanationText

  useEffect(() => {
    const handleResize = debounce(() => {
      setLineContainerTop(orderDetailsPillRef ? orderDetailsPillRef.getBoundingClientRect().top : 0)
    }, 1000)

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleNeverShowAgain = () => onDismiss(true)

  return (
    <div data-testid={`${testID}.grid`} className={css.spotlightInstructionsContainer}>
      <div data-testid={`${testID}.content`} key={testID} className={css.spotlightContent}>
        <div
          style={{
            top: lineContainerTop > 0 ? lineContainerTop : 'none',
          }}
          className={css.lineContainer}
        >
          <div className={css.line} />
          <div className={css.circle} />
        </div>
        <div className={css.iconContainer}>
          <StaticSvgIcon name={serviceMethodIcon} width={85} height={85} isUnstyled />
        </div>
        <div
          data-testid={`${testID}.service-again`}
          data-servicemethodagain={serviceMethodAgainText}
          className={`${css.serviceMethodAgain} ${isPickup ? css.pickUpAgain : css.deliveryAgain}`}
        >
          {serviceMethodAgainText}
        </div>
        <div data-testid={`${testID}.service-again.explanation`} className={css.serviceMethodExplanation}>
          {serviceExplanationText}
        </div>
        <ActionButton
          onPress={onDismiss}
          testID={`${testID}.acknowledge.button`}
          text={t('OrderDetailsPillInformation.AcknowledgeButtonText', { defaultValue: 'Got it' })}
          textFontWeight='semibold'
          containerStyle={{ backgroundColor: theme.colours.hyperlink, width: '100%' }}
        />
        <div className={css.doNotShowMeAgainContainer}>
          <span
            data-testid={`${testID}.hide.button`}
            className={css.doNotShowMeAgainText}
            onClick={handleNeverShowAgain}
          >
            {t('OrderDetailsPillInformation.DoNotShowMeAgainText', { defaultValue: "Don't show me this again" })}
          </span>
        </div>
      </div>
    </div>
  )
}
