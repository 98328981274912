import { rootActions } from '@dominos/business'
import { ApolloError } from '@apollo/client'
import { Bff as BffErrors } from 'bff-errors'
import { FosEventInfoType, FosInteractionEventType } from 'olo-feature-fos'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import { OrderStatus, useFos, useKiosk, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { AtLeastOneErrorHandlers } from '@dominos/components/error'
import { useDispatch } from 'react-redux'
import { useRestartOrder } from '@dominos/components/footer/simple-footer/use-restart-order'
import { navigate } from '@dominos/navigation'

/*
========================
Error displayed handlers
========================
*/
export const recoverablePaymentErrorHandler =
  (reportPaymentErrorMessage: ReturnType<typeof useReport>['reportPaymentErrorMessage']) =>
  ({ errorTitle, errorMessage, errorCode }: { errorTitle: string; errorMessage: string; errorCode: string }) =>
    reportPaymentErrorMessage(errorTitle, errorMessage, errorCode)

export const paymentErrorHandler =
  (
    reportPaymentErrorMessage: ReturnType<typeof useReport>['reportPaymentErrorMessage'],
    sendFosEvent: ReturnType<typeof useFos>['sendFosEvent'],
  ) =>
  ({ errorTitle, errorMessage, errorCode }: { errorTitle: string; errorMessage: string; errorCode: string }) => {
    sendFosEvent({
      type: FosEventInfoType.InteractionEvent,
      locationPath: `${NavigationConstants.checkout}/${NavigationConstants.processing}`,
      eventType: FosInteractionEventType.PaymentFailed,
    })
    reportPaymentErrorMessage(errorTitle, errorMessage, errorCode)
  }

export const unrecoverablePaymentErrorHandler =
  (reportPaymentErrorMessage: ReturnType<typeof useReport>['reportPaymentErrorMessage']) =>
  ({ errorTitle, errorMessage, errorCode }: { errorTitle: string; errorMessage: string; errorCode: string }) => {
    reportPaymentErrorMessage(errorTitle, errorMessage, errorCode)
  }

/*
=====================
Error closed handlers
=====================
*/

export const orderAlreadyPlacedErrorCloseHandler = () => {
  isNativeApp() ? navigate(NavigationConstants.nativeAppTracker) : navigate(NavigationConstants.tracker)
}

export const orderAlreadyPlacedErrorHandler = () => {
  isNativeApp()
    ? navigate(NavigationConstants.nativeAppCheckoutProcessing)
    : navigate(NavigationConstants.checkoutProcessing)
}

export const unrecoverableErrorNavigation = (resetOrder: ReturnType<typeof useRestartOrder>) => {
  isNativeApp() ? notifyNativeApp('error') : resetOrder()
}

export const unrecoverableErrorCloseHandler =
  (
    sendFosEvent: ReturnType<typeof useFos>['sendFosEvent'],
    dispatch: ReturnType<typeof useDispatch>,
    resetOrder: ReturnType<typeof useRestartOrder>,
    isTwoTapCheckoutEnabled: boolean = false,
  ) =>
  () => {
    dispatch(rootActions.restartOrder())

    sendFosEvent({
      type: FosEventInfoType.InteractionEvent,
      locationPath: isTwoTapCheckoutEnabled ? NavigationConstants.checkout : NavigationConstants.checkoutPayment,
      eventType: FosInteractionEventType.OrderRestarted,
    })

    unrecoverableErrorNavigation(resetOrder)
  }

export const usePaymentErrorHandlers = (isTwoTapCheckoutEnabled: boolean = false): AtLeastOneErrorHandlers => {
  const dispatch = useDispatch()
  const { reportGenericError, reportPaymentErrorMessage } = useReport()
  const { sendFosEvent } = useFos()
  const { isKioskOrder } = useKiosk()
  const resetOrder = useRestartOrder({ deleteVwoCookies: !!isKioskOrder })

  const graphErrorHandler = (error: ApolloError) => {
    switch (error.graphQLErrors[0]?.extensions?.code) {
      case BffErrors.Errors.ORDER_ALREADY_PLACED:
        return {
          handleErrorDisplayed: orderAlreadyPlacedErrorHandler,
        }
      case BffErrors.Errors.UNABLE_TO_CONTACT_STORE:
      case BffErrors.Errors.UNABLE_TO_CONTACT_STORE_TO_PRICE:
      case BffErrors.Errors.UNABLE_TO_PLACE_AT_STORE:
      case BffErrors.Errors.UNABLE_TO_PRICE_AT_STORE:
        return {
          handleErrorDisplayed: unrecoverablePaymentErrorHandler(reportPaymentErrorMessage),
          handleErrorClosed: unrecoverableErrorCloseHandler(
            sendFosEvent,
            dispatch,
            resetOrder,
            isTwoTapCheckoutEnabled,
          ),
        }
      case BffErrors.Errors.PAYMENT_DETAILS_EXPIRED:
      case BffErrors.Errors.PAYMENT_NOT_APPROVED:
      case BffErrors.Errors.PAYMENT_DETAILS_INCORRECT:
      case BffErrors.Errors.PAYMENT_NOT_PROCESSED:
      case BffErrors.Errors.PAYMENT_TIMEOUT:
      case BffErrors.Errors.PAYMENT_VALIDATION:
      case BffErrors.Errors.PAYMENT_INSUFFICIENT:
      case BffErrors.Errors.UNABLE_TO_CONTACT_PAYMENT_PROVIDER:
      case BffErrors.Errors.PAYMENT_PROVIDER_UNKNOWN:
      case BffErrors.Errors.PAYMENT_SESSION_EXPIRED:
        return {
          handleErrorDisplayed: paymentErrorHandler(reportPaymentErrorMessage, sendFosEvent),
        }
      default:
        return {
          handleErrorDisplayed: recoverablePaymentErrorHandler(reportPaymentErrorMessage),
        }
    }
  }

  const statusErrorHandler = (status: OrderStatus) => {
    reportGenericError({ status })
    switch (status) {
      case OrderStatus.Timed:
      case OrderStatus.Making:
      case OrderStatus.Remaking:
      case OrderStatus.Cooking:
      case OrderStatus.Ready:
      case OrderStatus.Leaving:
      case OrderStatus.Complete:
      case OrderStatus.SentToStore:
        return {
          handleErrorDisplayed: recoverablePaymentErrorHandler(reportPaymentErrorMessage),
          handleErrorClosed: orderAlreadyPlacedErrorCloseHandler,
        }
      case OrderStatus.Failed:
      case OrderStatus.Cancelled:
        return {
          handleErrorDisplayed: unrecoverablePaymentErrorHandler(reportPaymentErrorMessage),
          handleErrorClosed: unrecoverableErrorCloseHandler(
            sendFosEvent,
            dispatch,
            resetOrder,
            isTwoTapCheckoutEnabled,
          ),
        }
      default:
        return {
          handleErrorDisplayed: recoverablePaymentErrorHandler(reportPaymentErrorMessage),
        }
    }
  }

  return {
    graphErrorHandler,
    statusErrorHandler,
  }
}
