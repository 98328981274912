import { rootActions } from '@dominos/business'
import { MediaBase, UpsellItem } from '@dominos/components/product/product-upsell/product-upsell.interface'
import { useReport } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from '@dominos/components/snackbar'

export interface WithProductUpsellWrapperProps {
  onUpsellAccepted: <TMedia extends MediaBase>(upsellData: UpsellItem<TMedia>, confirmationTitle: string) => void
  onUpsellDeclined: () => void
}

export interface WithProductUpsellWrapperOptions {
  persistDismissed?: boolean
  showConfirmation?: boolean
  enableTimeout?: boolean
}

const upsellDelayInMilliseconds = 1000
const upsellEndDelayInMilliseconds = 11000

const withProductUpsellWrapper = <P extends object>(
  WrappedComponent: React.ComponentType<P & WithProductUpsellWrapperProps>,
) => {
  const HOC: React.FC<
    P & {
      onUpsellAccepted: <TMedia extends MediaBase>(upsellItem: UpsellItem<TMedia>) => void
      onUpsellDeclined?: () => void
      zoneId: string
      options?: WithProductUpsellWrapperOptions
    }
  > = ({ onUpsellAccepted, onUpsellDeclined, zoneId, options = {}, ...props }) => {
    const { showSnackbar } = useSnackbar()
    const { persistDismissed = false, showConfirmation = false, enableTimeout = false } = options
    const dispatch = useDispatch()
    const { t } = useTranslation('menu')
    const { reportProductUpsellAdded, reportProductUpsellRemoved } = useReport()
    const dismissedZones = useSelector((state: RootReducer) => state.productUpsellReducer.dismissedZones)
    const [isVisible, setIsVisible] = useState(false)
    const hasDismissed = zoneId && dismissedZones.includes(zoneId)

    useEffect(() => {
      if (hasDismissed) return
      if (enableTimeout) {
        const timeoutStartAnimation = setTimeout(() => setIsVisible(true), upsellDelayInMilliseconds)
        const delayTimeoutEnd = setTimeout(handleTimeout, upsellEndDelayInMilliseconds)

        return () => {
          clearTimeout(timeoutStartAnimation)
          clearTimeout(delayTimeoutEnd)
        }
      } else {
        setIsVisible(true)
      }
    }, [enableTimeout])

    const persistUpsellDismissed = (zoneId: string) => {
      dispatch(rootActions.upsellDismissed({ zoneId }))
    }

    const handleUpsellAccepted = <TMedia extends MediaBase>(
      upsellItem: UpsellItem<TMedia>,
      confirmationTitle: string,
    ) => {
      setIsVisible(false)

      if (showConfirmation) {
        showSnackbar({
          message: t('ComponentUpsellConfirmationMessage', {
            defaultValue: 'Great! We have upgraded your item',
          }),
        })
      }

      reportProductUpsellAdded()

      onUpsellAccepted(upsellItem)
    }

    const handleUpsellDeclined = () => {
      setIsVisible(false)
      if (persistDismissed) persistUpsellDismissed(zoneId)
      reportProductUpsellRemoved()
      onUpsellDeclined?.()
    }

    const handleTimeout = () => {
      handleUpsellDeclined()
    }

    return (
      <>
        {isVisible && !hasDismissed && (
          <WrappedComponent
            {...(props as P)}
            onUpsellAccepted={handleUpsellAccepted}
            onUpsellDeclined={handleUpsellDeclined}
          />
        )}
      </>
    )
  }

  return HOC
}

export { withProductUpsellWrapper }
