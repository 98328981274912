import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const useLocationListener = () => {
  const [result, setResult] = useState<{ pathname?: string; search?: string }>({})
  const location = useLocation()

  useEffect(() => {
    setResult({ pathname: location.pathname, search: location.search })
  }, [location])

  return result
}
