import { useLocation } from 'react-router-dom'
import { ApolloError } from '@apollo/client/errors'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FosEventInfoType,
  FosInteractionEventParameter,
  FosInteractionEventParameterConstants,
  FosInteractionEventType,
} from 'olo-feature-fos'

import {
  GenericCardPopup,
  useCurrentOrderDetails,
  useDominosTheme,
  useForgotPassword,
  useFos,
  useReport,
} from '@dominos/hooks-and-hocs'
import { ActionButton } from '@dominos/components/buttons'
import { ErrorScope, useErrorContext } from '@dominos/components'
import { ResetPasswordResponse } from '@dominos/business/queries'
import { ResetEmailForm } from './reset-email-form'
import { forgotPasswordErrors } from './forgot-password-errors'

/* eslint-disable max-lines, max-lines-per-function */
export const ForgottenPassword = ({ onForgot }: { onForgot?: () => void }) => {
  const { t } = useTranslation('login')
  const theme = useDominosTheme()
  const [showResetPopup, setShowResetPopup] = useState(false)
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false)
  const [email, setEmail] = useState<string>()
  const { reportForgottenPasswordResetRequested } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const { sendFosEvent } = useFos()
  const location = useLocation()
  const { notifyError } = useErrorContext()

  const onRequestCompleteCallback = (data: ResetPasswordResponse['resetPasswordRequest']) => {
    if (showResetPopup && data) {
      reportForgottenPasswordResetRequested({
        orderId,
        success: true,
        location: window.location.href,
        authenticationSource: 'Legacy',
      })
      setShowConfirmationPopup(true)
      setShowResetPopup(false)
    }
  }
  const onRequestErrorCallback = (error: ApolloError) => {
    if (showResetPopup) {
      reportAndNotifyError(error)
    }
  }
  const { performForgotPasswordRequest, loading, useRecaptchaV2, renderCheckboxV2 } = useForgotPassword(
    onRequestCompleteCallback,
    onRequestErrorCallback,
  )

  const reportAndNotifyError = async (error: ApolloError) => {
    await reportForgottenPasswordResetRequested({
      orderId,
      success: false,
      forceFlush: true,
      location: window.location.href,
      authenticationSource: 'Legacy',
    })
    notifyError({
      error,
      definitions: forgotPasswordErrors,
      handlers: {},
      scope: ErrorScope.ForgotPassword,
    })
  }
  const styles = {
    resetButton: { width: '100%' },
    forgottenPasswordMessage: {
      padding: '0 0 8px 0',
      fontSize: '10pt',
      fontWeight: 700,
      cursor: 'pointer',
      color: '#007aff',
    },
    contentMessage: { padding: '0 0 8px 0', fontWeight: 400 } as React.CSSProperties,
    socialsContentMessage: {
      padding: '0 0 14px 0',
      fontSize: '9pt',
      color: theme.colours.web.grey,
      textAlign: 'center',
    } as React.CSSProperties,
  }

  const onReset = async () => {
    if (!email) return
    await performForgotPasswordRequest(email)
  }

  const onForgottenPasswordClicked = () => {
    if (onForgot) {
      onForgot()
    } else {
      setShowResetPopup(true)
    }

    const additionalInfo = {
      [FosInteractionEventParameter.Popup]: FosInteractionEventParameterConstants.ForgottenPasswordPopup,
    }

    sendFosEvent({
      additionalInfo,
      type: FosEventInfoType.InteractionEvent,
      locationPath: location.pathname,
      eventType: FosInteractionEventType.ForgotPasswordButtonClicked,
    })
  }

  const onChange = (updatedField: { [key: string]: ValidationField }) => {
    setEmail(updatedField.email && updatedField.email.value)
  }

  const onClose = () => {
    setShowResetPopup(false)
    setShowConfirmationPopup(false)
  }

  const popupTitle = showResetPopup
    ? t('ForgottenPasswordPopupTitle', { defaultValue: 'Reset Password' })
    : t('ForgottenPasswordResultPopupTitle', { defaultValue: 'Reset Sent' })

  const ResetResult = () => (
    <>
      <div style={{ ...styles.contentMessage, padding: '8px 0' }}>
        {t('ForgottenPasswordResultPopupContent1', {
          defaultValue:
            "If your email has a Domino's account associated with it you will receive an email with instructions on how to reset your password. Check your inbox and click on the link provided.",
        })}
      </div>
      <div style={styles.contentMessage}>
        {t('ForgottenPasswordResultPopupContent2', {
          defaultValue: 'Check your inbox and click on the link provided.',
        })}
      </div>
      <div style={styles.socialsContentMessage}>
        {t('ForgottenPasswordResultPopupSocialsContent', {
          defaultValue:
            'If you signed up using Apple, Facebook, Google or PayPal you will need to reset your password with that service.',
        })}
      </div>
      <ActionButton
        onPress={onClose}
        testID={'close.button'}
        containerStyle={styles.resetButton}
        textStyle={{ color: theme.colours.tooltipText }}
        textFontWeight={'semibold'}
        text={t('ForgottenPasswordResultCloseButton', { defaultValue: 'Close' })}
      />
    </>
  )

  return (
    <>
      <div
        style={styles.forgottenPasswordMessage}
        role='button'
        data-testid='forgotten-password.button'
        onClick={onForgottenPasswordClicked}
      >
        {t('forgottenPasswordButton', { defaultValue: 'Forgotten Password?' })}
      </div>
      {(showResetPopup || showConfirmationPopup) && (
        <GenericCardPopup popupTitle={popupTitle} onClose={onClose} testID='forgotten-password'>
          {showResetPopup && (
            <ResetEmailForm
              onReset={onReset}
              onChange={onChange}
              isLoading={loading}
              renderRecaptchaCheckbox={renderCheckboxV2}
              shouldRenderRecaptcha={useRecaptchaV2}
            />
          )}
          {showConfirmationPopup && <ResetResult />}
        </GenericCardPopup>
      )}
    </>
  )
}
