/**
 * If within `X` distance from top of screen return true.
 *
 * @param offset distance from top of screen
 */
const isScrollTop = (offset: number = 50) => {
  const distanceFromTop = document.body.getBoundingClientRect().top * -1

  return distanceFromTop <= offset
}

export { isScrollTop }
