import gql from 'graphql-tag'

export const SIZEBASE_DIMENSIONAL_FIELDS = gql`
  fragment SizeBaseDimensionSetFields on SizeBaseDimensionSet {
    size
    base
    type
  }
`

export const SIZE_DIMENSIONAL_FIELDS = gql`
  fragment SizeDimensionSetFields on SizeDimensionSet {
    size
    type
  }
`

export const NONDIMENSIONAL_FIELDS = gql`
  fragment NonDimensionalFields on NonDimensional {
    type
  }
`

export const BASE_DIMENSIONAL_FIELDS = gql`
  fragment BaseDimensionSetFields on BaseDimensionSet {
    base
    type
  }
`
