import { formatPage } from '@dominos/business/functions/menu'
import { convertToFilterProductItems } from '@dominos/components/basket/coupon-prompt'
import {
  COUPON_MENU_CODE,
  useBreakpoints,
  useCurrentOrderDetails,
  useDominosTheme,
  useFos,
  useKiosk,
  useMenu,
  useSwapSetProducts,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import {
  FosEventInfoType,
  FosInteractionEventParameter,
  FosInteractionEventParameterConstants,
  FosInteractionEventType,
} from 'olo-feature-fos'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ActionRow from '../action-row/action-row'
import Actions from '../actions/actions'
import { DetailRow } from '../detail-row'
import { PortionLine } from '../portion-line'
import Surcharges from '../surcharges/surcharges'
import css from './basket-line.less'
import Row from './row/row'
import Sauce from './sauce/sauce'
import { useBasketLineTitle } from './use-basket-line-title'
import { isProductMenuItemNew } from '@dominos/components'

export const BasketLine = ({
  testID,
  lineData,
  onRemove,
  onEdit,
  hideEditButton = false,
  hideRemoveButton = false,
  hideSwapButton = false,
  hidePrice = false,
  voucherItemNo,
  voucherCode,
  voucherNo,
}: BasketLineProps) => {
  const theme = useDominosTheme()
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation('menu')
  const { itemsByCode: items } = useMenu()
  const item = lineData && items ? items[lineData.productCode] : null
  const detailRowLabel = useBasketLineTitle(lineData, item)
  const { sendFosEvent } = useFos()
  const { basketHeaderData } = useCurrentOrderDetails()
  const navigate = useNavigate()
  const { getSwapSetProducts, swapSetProducts } = useSwapSetProducts(
    basketHeaderData?.storeNo,
    basketHeaderData?.serviceMethod,
  )
  const { isKioskOrder } = useKiosk()

  useEffect(() => {
    if (swapSetProducts?.length) {
      const filterProducts = convertToFilterProductItems(swapSetProducts)
      const url = `${NavigationConstants.menu}/${formatPage(COUPON_MENU_CODE)}`
      navigate(url, {
        state: {
          swapping: lineData,
          filterList: filterProducts,
          voucherNo,
          voucherItemNo,
        },
      })
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [swapSetProducts])

  if (!lineData) {
    return null
  }

  const didPressRemove = () => {
    if (onRemove) {
      onRemove(lineData)
    }
  }

  const didPressEdit = () => {
    if (onEdit && item && !isProductMenuItemNew(item)) {
      onEdit(item, lineData)

      if (!isMobile) {
        sendFosEvent({
          type: FosEventInfoType.InteractionEvent,
          locationPath: location.pathname,
          eventType: FosInteractionEventType.ProductDetailsButtonClicked,
          additionalInfo: {
            [FosInteractionEventParameter.Popup]: FosInteractionEventParameterConstants.ProductDetailsPopup,
            [FosInteractionEventParameter.ProductCode]: item.code,
          },
        })
      }
    }

    if ((isMobile || isKioskOrder) && !onEdit && item && !isProductMenuItemNew(item)) {
      navigate(`${NavigationConstants.product}/${item && item.code}`, {
        state: {
          currentProduct: item,
          currentLine: lineData,
          isEditing: true,
        },
      })
    }
  }

  const didPressSwap = () => {
    if (voucherCode && voucherItemNo) {
      getSwapSetProducts(voucherCode, voucherItemNo)
    }
  }

  const price = hidePrice ? undefined : lineData.totalPrice

  return (
    <div data-testid={testID} className={css.main} style={{ borderTopColor: theme.colours.lightSlate }}>
      <div data-testid={`${testID}.container`} className={css.container}>
        <div data-testid={`${testID}.quantity`} className={css.quantity}>
          {`${lineData.quantity} x`}
        </div>
        <div data-testid={`${testID}.details`} className={css.details}>
          <DetailRow testID={`${testID}.header`} label={detailRowLabel} price={price} />
          <Row testID={`${testID}.size`} label={t('Size')} description={lineData.media && lineData.media.size} />
          <Row testID={`${testID}.crust`} label={t('Crust')} description={lineData.media && lineData.media.base} />
          <Sauce testID={`${testID}.sauce`} sauce={lineData.sauce && lineData.sauce.media} />
          <Surcharges testID={`${testID}.surcharges`} surcharges={lineData.surcharges} />
          <ActionRow testID={`${testID}.options`} items={lineData.options} />
          <ActionRow testID={`${testID}.toppings`} items={lineData.toppings} />

          {lineData.portions &&
            (lineData.portions as BasketLinePortion[]).map((portion: BasketLinePortion, idx: number) => (
              <PortionLine key={`${portion.productCode}.${idx}`} testID={`${testID}.portion`} portion={portion} />
            ))}
        </div>
      </div>
      {lineData.type !== 'ProductLine' && (
        <Actions
          hideEdit={hideEditButton || !item}
          hideRemove={hideRemoveButton}
          onEdit={didPressEdit}
          onRemove={didPressRemove}
          testID={`${testID}.actions`}
          showSwap={!hideSwapButton}
          onSwap={didPressSwap}
        />
      )}
    </div>
  )
}
