import { createUseClasses } from 'olo-ux'

export const useStyles = createUseClasses((theme, { isMobile }: { isMobile: boolean }) => ({
  heading: {
    ...theme.text.heading.xl.bold,
    marginBottom: theme.size[200],
    textTransform: 'uppercase',
    marginTop: 0,
  },
  paragraph: {
    ...theme.text.body.lg.book,
    color: theme.colors.content.secondary,
    margin: 0,
  },
  container: {
    padding: isMobile ? 0 : theme.size[400],
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  subContainer: {
    padding: isMobile ? theme.size[400] : 0,
  },
  buttonContainer: {
    padding: theme.size[400],
    marginTop: 'auto',
    borderTopWidth: theme.borders.width.thin,
    borderTopColor: theme.colors.border.primaryAlpha,
    borderTopStyle: 'solid',
  },
}))
