import { useFeatures, useKiosk, useShowAccountDetails } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import * as Scenes from '@dominos/scenes'
import { NavigateFunction, Route, Routes as RouterRoutes, useLocation, useNavigate, useParams } from 'react-router-dom'
import React from 'react'
import { RouterContexts } from './router-contexts'
import { isNativeApp } from '@dominos/business/functions'
import { withNativeAppBasket } from '@dominos/business/functions/native-app/with-native-app-basket'

export interface RouteComponentProps {
  location?: Location
  navigate?: NavigateFunction
}

interface AuthenticationRouteProps {
  showUniversalAuthentication: boolean
  forgeRockAuthentication: boolean
}

const StoreSearch = () => <Scenes.StoreSearchScene />
const AutoCompleteAddress = () => <Scenes.AutoCompleteAddressScene />
const PickupStoreSearchAutocompleteScene = () => <Scenes.PickupStoreSearchAutocomplete />

const AutoCompleteDelivery = () => <Scenes.AutoCompleteDeliveryScene />

const DeliveryAddressSearchAutocomplete = () => <Scenes.DeliveryAddressSearchAutoCompleteScene />

const ConfirmAddress = () => <Scenes.ConfirmAddressScene />
const AutoCompletePickup = () => <Scenes.AutoCompletePickupScene />
const TimedOrder = () => <Scenes.TimedOrderScene />

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const Menu = () => {
  const { menuCategory, variety, selection } = useParams()
  const props = { menuCategory, variety, selection }

  return isNativeApp() ? withNativeAppBasket(Scenes.MenuScene)(props) : <Scenes.MenuScene {...props} />
}

const Product = () => (isNativeApp() ? withNativeAppBasket(Scenes.ProductScene)({}) : <Scenes.ProductScene />)

const Basket = (props: RouteComponentProps) =>
  isNativeApp() ? (
    withNativeAppBasket(Scenes.BasketScene)({ ...props, excludeNavigation: true })
  ) : (
    <Scenes.BasketScene />
  )
const DeliveryAddress = () => <Scenes.DeliveryAddressScene />
const AddressSearchResults = () => <Scenes.AddressSearchResultsScene />
const Checkout = () => <Scenes.CheckoutScene />
const NativeAppCheckout = () => <Scenes.NativeAppCheckoutScene />
const Tracker = () => <Scenes.TrackerScene />
const NativeAppTracker = () => <Scenes.NativeAppTrackerScene />
const HardUpsell = () => <Scenes.HardUpsellScene />

const Login = (props: AuthenticationRouteProps) =>
  props.showUniversalAuthentication || props.forgeRockAuthentication ? (
    <Scenes.HomeScene showAuthPopup='login' />
  ) : (
    <Scenes.LoginScene />
  )

const NativeAppLogin = () => <Scenes.NativeAppLoginScene />
const NativeAppProfileCompletion = () => <Scenes.NativeProfileCompletionScene />
const CreateAccount = (props: AuthenticationRouteProps) => {
  const location = useLocation()

  return props.showUniversalAuthentication || props.forgeRockAuthentication ? (
    <Scenes.HomeScene showAuthPopup='register' />
  ) : (
    <Scenes.CreateAccountScene {...(location.state as Scenes.CreateAccountSceneProps)} />
  )
}

const NativeCreateAccount = () => {
  const location = useLocation()

  return <Scenes.NativeAppCreateAccountScene {...(location.state as Scenes.CreateAccountSceneProps)} />
}
const MyAccount = () => <Scenes.MyAccountScene />
const NativeAppMyAccount = () => <Scenes.NativeAppMyAccountScene />
const ResetPassword = () => <Scenes.ResetPasswordScene />
const FormResolver = () => <Scenes.FormResolverScene />

export const Routes = () => {
  const navigate = useNavigate()
  const isShowAccountDetailsEnabled = useShowAccountDetails()
  const { isKioskOrder } = useKiosk()
  const [
    customerEnabled,
    universalAuthenticationRedirectEnabled,
    autoCompleteDeliveryEnabled,
    forgeRockIdentityProviderEnabled,
    autoCompleteListMapDeliveryAddressEnabled,
    deliveryAddressSearchAutocompleteFeatureEnabled,
    pickupStoreSearchAutocompleteFeatureEnabled,
  ] = useFeatures(
    'customers',
    'UniversalAuthenticationRedirectHome',
    'AutoCompleteDeliveryAddress',
    'ForgeRockIdentityProvider',
    'AutoCompleteListMapDeliveryAddress',
    'DeliveryAddressSearch-Feature',
    'PickupStoreSearch-Feature',
  )

  const Home = (_props: RouteComponentProps) => (isKioskOrder ? <Scenes.KioskWelcomeScene /> : <Scenes.HomeScene />)

  const DeliveryList = (_props: RouteComponentProps) => {
    if (deliveryAddressSearchAutocompleteFeatureEnabled) {
      navigate(NavigationConstants.deliveryAddressAutoComplete, { replace: true })

      return null
    }

    return <Scenes.DeliveryListScene />
  }

  const renderPickupStoreSearchFeature = () => {
    if (pickupStoreSearchAutocompleteFeatureEnabled) {
      return (
        <Route path={NavigationConstants.pickupAddressAutoComplete} element={<PickupStoreSearchAutocompleteScene />} />
      )
    }

    return <Route path={NavigationConstants.pickupAddressAutoComplete} element={<AutoCompletePickup />} />
  }
  const renderDeliveryAddressSearchFeature = () => {
    if (deliveryAddressSearchAutocompleteFeatureEnabled) {
      return (
        <>
          <Route
            path={NavigationConstants.deliveryAddressAutoComplete}
            element={<DeliveryAddressSearchAutocomplete />}
          />
          <Route path={NavigationConstants.confirmAddress} element={<ConfirmAddress />} />
        </>
      )
    }

    if (autoCompleteDeliveryEnabled && autoCompleteListMapDeliveryAddressEnabled) {
      return (
        <>
          <Route path={NavigationConstants.deliveryAddressAutoComplete} element={<AutoCompleteDelivery />} />
          <Route path={NavigationConstants.confirmAddress} element={<ConfirmAddress />} />
        </>
      )
    }

    if (autoCompleteDeliveryEnabled) {
      return (
        <>
          <Route path={NavigationConstants.deliveryAddressAutoComplete} element={<AutoCompleteAddress />} />
          <Route path={NavigationConstants.confirmAddress} element={<ConfirmAddress />} />
        </>
      )
    }

    return (
      <>
        <Route path={`${NavigationConstants.deliveryAddress}/*`} element={<DeliveryAddress />} />
        <Route path={NavigationConstants.addressSearchResults} element={<AddressSearchResults />} />
      </>
    )
  }

  return (
    <RouterContexts>
      <RouterRoutes>
        <Route path={NavigationConstants.home} element={<Home />} />
        <Route path={NavigationConstants.storeSearch} element={<StoreSearch />} />
        {renderPickupStoreSearchFeature()}
        {renderDeliveryAddressSearchFeature()}
        <Route path={NavigationConstants.deliveryAddressList} element={<DeliveryList />} />
        <Route path={NavigationConstants.timedOrder} element={<TimedOrder />} />
        <Route path={NavigationConstants.basket} element={<Basket />} />
        <Route path={NavigationConstants.menu} element={<Menu />} />
        <Route path={`${NavigationConstants.menu}/:menuCategory`} element={<Menu />} />
        <Route path={`${NavigationConstants.menu}/:menuCategory/:variety`} element={<Menu />} />
        <Route path={`${NavigationConstants.product}/:productCode`} element={<Product />} />
        <Route path={NavigationConstants.hardUpsell} element={<HardUpsell />} />
        <Route path={`${NavigationConstants.deliveryAddress}/*`} element={<DeliveryAddress />} />
        <Route path={NavigationConstants.addressSearchResults} element={<AddressSearchResults />} />
        <Route path={`${NavigationConstants.checkout}/*`} element={<Checkout />} />
        <Route path={`${NavigationConstants.nativeAppCheckout}/*`} element={<NativeAppCheckout />} />
        <Route path={NavigationConstants.tracker} element={<Tracker />} />
        <Route path={NavigationConstants.nativeAppTracker} element={<NativeAppTracker />} />
        {customerEnabled && (
          <>
            <Route
              path={NavigationConstants.login}
              element={
                <Login
                  showUniversalAuthentication={universalAuthenticationRedirectEnabled}
                  forgeRockAuthentication={forgeRockIdentityProviderEnabled}
                />
              }
            />
            <Route
              path={NavigationConstants.createAccount}
              element={
                <CreateAccount
                  showUniversalAuthentication={universalAuthenticationRedirectEnabled}
                  forgeRockAuthentication={forgeRockIdentityProviderEnabled}
                />
              }
            />
            {isShowAccountDetailsEnabled && (
              <Route path={`${NavigationConstants.myAccount}/*`} element={<MyAccount />} />
            )}
            <Route path={`${NavigationConstants.nativeAppMyAccount}/*`} element={<NativeAppMyAccount />} />
          </>
        )}
        <Route path={NavigationConstants.nativeAppProfileCompletion} element={<NativeAppProfileCompletion />} />
        <Route path={NavigationConstants.nativeAppLogin} element={<NativeAppLogin />} />
        <Route path={NavigationConstants.nativeAppCreateAccount} element={<NativeCreateAccount />} />
        <Route path={`${NavigationConstants.resetPassword}/*`} element={<ResetPassword />} />
        <Route path={NavigationConstants.formResolver} element={<FormResolver />} />
        <Route path={NavigationConstants.oopsApp} element={<Home />} />
        <Route path={'*'} element={<Home />} />
      </RouterRoutes>
    </RouterContexts>
  )
}
