export const useJapaneseKeyboard = () => {
  const endsWithDakutenOrHandakuten = (input: string): { endsWith: boolean; type: 'dakuten' | 'handakuten' | null } => {
    const dakutenRegex = /\u3099$/
    const handakutenRegex = /\u309A$/

    if (dakutenRegex.test(input)) {
      return { endsWith: true, type: 'dakuten' }
    } else if (handakutenRegex.test(input)) {
      return { endsWith: true, type: 'handakuten' }
    } else {
      return { endsWith: false, type: null }
    }
  }

  const isValidForDakutenOrHandakuten = (input: string, type: 'dakuten' | 'handakuten' | null): boolean => {
    if (input.length < 2) {
      return false
    }

    const validChars = {
      dakuten: 'カキクケコサシスセソタチツテトハヒフヘホ',
      handakuten: 'ハヒフヘホ',
    }

    const secondToLastChar = input.charAt(input.length - 2)

    return type ? validChars[type]?.includes(secondToLastChar) ?? false : false
  }

  return {
    endsWithDakutenOrHandakuten,
    isValidForDakutenOrHandakuten,
  }
}
