import React, { Fragment } from 'react'

import { productChangesTransform, validateProductWithChanges } from '@dominos/business/functions/product'
import { CollapsableTitledCard, ProductIngredientItem } from '@dominos/components'
import { useBreakpoints, useMarket, useReport } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import css from './product-ingredient-card.less'

const ANIMATION_DELAY = 800
const LINE_HEIGHT_MOBILE = 41
const LINE_HEIGHT_DESKTOP = 71

export const ProductIngredientCard = (props: IngredientCollapsableCardProps) => {
  let timer: NodeJS.Timeout
  const {
    testID,
    primaryTitle,
    secondaryTitle,
    recipeIngredientCodes,
    currentIngredients: currents,
    possibleIngredients: possibles,
    rules,
    onItemChange,
    toppingLineChange,
    onSectionChange,
    calculateCurrentIngredientCount,
    showCurrentIngredients = true,
    innerContainerStyle,
    startCollapsed,
  } = props
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation('menu')
  const { getMediaForIngredient } = useMarket()
  const LINE_HEIGHT = isMobile ? LINE_HEIGHT_MOBILE : LINE_HEIGHT_DESKTOP
  let currentToppingLineChange: BasketLineChange[] = [...toppingLineChange]
  const { reportValidationMessage } = useReport()

  const didChangeQuantity = (quantity: number, ingredientCode: string, previousQuantity: number) => {
    if (
      // Return window.alert() if min/max reached
      rules &&
      !validateProductWithChanges(rules, quantity, previousQuantity, currents, t, reportValidationMessage)
    ) {
      return
    }

    const isRecipeIngredient = recipeIngredientCodes.includes(ingredientCode)
    const ingredientMedia = getMediaForIngredient('Topping', ingredientCode)
    const toppings = productChangesTransform(
      quantity,
      ingredientCode,
      ingredientMedia?.name?.value,
      isRecipeIngredient,
      currentToppingLineChange,
    )
    currentToppingLineChange = [...toppings]

    if (!isRecipeIngredient) {
      maybeWaitForUserRetraction(currentToppingLineChange, previousQuantity, quantity)
    } else if (onItemChange) {
      onItemChange(currentToppingLineChange)
    }

    return true
  }

  const maybeWaitForUserRetraction = (toppings: BasketLineChange[], previousQuantity: number, quantity: number) => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if (onSectionChange) {
        const newCurrents = calculateCurrentIngredientCount(toppings)
        const difference = newCurrents - currents.length
        onSectionChange(difference * LINE_HEIGHT)
      }
      if (onItemChange) {
        onItemChange(toppings)
      }
    }, getAnimationDelay(previousQuantity, quantity)) as unknown as NodeJS.Timeout
  }

  // sets the delay time if new topping being added or current topping being removed completely
  const getAnimationDelay = (previousQuantity: number, quantity: number) => {
    if (previousQuantity === 0 || (previousQuantity === 1 && quantity === 0)) {
      return ANIMATION_DELAY
    }

    return 0
  }

  const currentIngredients = currents.map((currentIngredient) => (
    <ProductIngredientItem
      {...currentIngredient}
      key={`current.ingredient.${currentIngredient.code}`}
      testID='current.ingredient'
      showPrice={false}
      showMinimumQuantity={true}
      onQuantityChange={didChangeQuantity}
    />
  ))

  const possibleIngredients = possibles.map((possibleIngredient) => (
    <ProductIngredientItem
      {...possibleIngredient}
      key={`possible.ingredient.${possibleIngredient.code}`}
      testID='possible.ingredient'
      onQuantityChange={didChangeQuantity}
      innerContainerStyle={innerContainerStyle}
    />
  ))

  return currents.length > 0 ? (
    <CollapsableTitledCard
      testID={testID}
      id={testID}
      primaryTitle={primaryTitle}
      startCollapsed={startCollapsed}
      noPadding={false}
    >
      {showCurrentIngredients && <IngredientListSection key='currentIngredientSection' list={currentIngredients} />}
      {possibles.length > 0 && rules?.allowAdd !== false && (
        <IngredientListSection
          key='possibleIngredientSection'
          sectionHeader={secondaryTitle}
          list={possibleIngredients}
        />
      )}
    </CollapsableTitledCard>
  ) : null
}

const IngredientListSection = ({ sectionHeader, list }: { sectionHeader?: string; list: JSX.Element[] }) => (
  <Fragment>
    {!!sectionHeader && (
      <h3 data-testid='Ingredient-Grid-Item.Section-Header' key='header' className={css.sectionHeader}>
        {sectionHeader}
      </h3>
    )}
    {list}
  </Fragment>
)
