import { LoadScriptProps } from '../common/load-script'

export const setupAppleId = (loadScript: (props: LoadScriptProps) => void) => {
  window._APPLEID_ = 'appleid'
  loadScript({
    id: 'AppleID',
    setupScriptFunc: (script) => {
      script.async = true
      script.defer = true
      script.type = 'text/javascript'
      script.src = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js`
      script.crossOrigin = 'anonymous'
    },
  })
}
