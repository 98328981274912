import gql from 'graphql-tag'

export interface UpdateCustomerResponse {
  updateCustomer: {
    id: string
  }
}

export interface UpdateCustomerInput {
  customerId: string
  input: Bff.Customers.UpdateCustomerInput
}

export const customerUpdateMutation = gql`
  mutation updateCustomer($customerId: ID!, $input: UpdateCustomerInput!) {
    updateCustomer(customerId: $customerId, input: $input) {
      id
    }
  }
`
