import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 16,
  height: 17,
}

const StoreNoLogoIcon = (props: SvgIconProps) => {
  const fill = props.fill || 'white'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 16 17' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.91363 2.06641H13.0862C13.2921 2.06641 13.4646 2.13828 13.6039 2.28203C13.7432 2.42578 13.8128 2.60391 13.8128 2.81641C13.8128 3.02891 13.7432 3.20703 13.6039 3.35078C13.4646 3.49453 13.2921 3.56641 13.0862 3.56641H2.91363C2.70776 3.56641 2.53519 3.49453 2.39592 3.35078C2.25665 3.20703 2.18702 3.02891 2.18702 2.81641C2.18702 2.60391 2.25665 2.42578 2.39592 2.28203C2.53519 2.13828 2.70776 2.06641 2.91363 2.06641ZM2.91363 14.0664C2.70776 14.0664 2.53519 13.9945 2.39592 13.8508C2.25665 13.707 2.18702 13.5289 2.18702 13.3164V9.56641H2.05986C1.82977 9.56641 1.64206 9.47578 1.49674 9.29453C1.35142 9.11328 1.30298 8.90391 1.35142 8.66641L2.07803 4.91641C2.11436 4.74141 2.19913 4.59766 2.33234 4.48516C2.46556 4.37266 2.61693 4.31641 2.78648 4.31641H13.2134C13.3829 4.31641 13.5343 4.37266 13.6675 4.48516C13.8007 4.59766 13.8855 4.74141 13.9218 4.91641L14.6484 8.66641C14.6969 8.90391 14.6484 9.11328 14.5031 9.29453C14.3578 9.47578 14.1701 9.56641 13.94 9.56641H13.8128V13.3164C13.8128 13.5289 13.7432 13.707 13.6039 13.8508C13.4646 13.9945 13.2921 14.0664 13.0862 14.0664C12.8803 14.0664 12.7078 13.9945 12.5685 13.8508C12.4292 13.707 12.3596 13.5289 12.3596 13.3164V9.56641H9.45314V13.3164C9.45314 13.5289 9.38351 13.707 9.24424 13.8508C9.10497 13.9945 8.9324 14.0664 8.72653 14.0664H2.91363ZM3.64025 12.5664H7.99992V9.56641H3.64025V12.5664Z'
        fill='#4A4A4A'
      />
    </svg>
  )
}

export { StoreNoLogoIcon }
