import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import React from 'react'

import { Portal } from '../portal'
import css from './closable-pop-up-notification.less'

const iconWidth = 45
const iconHeight = 45

export interface ClosablePopUpNotificationProps extends BaseProps {
  heading: string
  message?: string
  onClose?: () => void
  icon?: svgIconsName
}

export const ClosablePopUpNotification = ({
  testID,
  heading,
  message,
  onClose,
  icon = 'infoIcon',
}: ClosablePopUpNotificationProps) => (
  <Portal id='notification'>
    <div role='dialog' data-testid={`${testID}-notification`}>
      <div className={css.wrapper}>
        <div
          data-testid={`${testID}-notification.close-button`}
          role='close'
          className={css.closeButton}
          onClick={onClose}
        >
          <div role='button' className={css.closeIcon}>
            <StaticSvgIcon name='cross' width={21} height={21} isUnstyled />
          </div>
        </div>
        <div data-testid={icon} aria-hidden={true} className={css.iconWrapper}>
          <StaticSvgIcon name={icon} isUnstyled width={iconWidth} height={iconHeight} />
        </div>
        <div className={css.message}>
          <h2 data-testid={`${testID}-notification.title`} className={css.messageHeading} role='alert'>
            {heading}
          </h2>
          {!!message && (
            <div data-testid={`${testID}-notification.message`} className={css.messageText}>
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  </Portal>
)
