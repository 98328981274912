import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useFosOrderButtonClickedEvent } from '@dominos/components/checkout/checkout-container/payment-container/use-fos-order-button-clicked-event'
import { useCustomerCheckoutDetails } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/use-customer-checkout-details'
import { isNativeApp } from '@dominos/business/functions/native-app'
import { useAlert, useBasket, useInitiateOrder, UseInitiateOrderProps, usePlaceOrder } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { usePlaceOrderErrorHandler } from '../usePlaceOrderErrorHandler'

// TODO: apply this hook to PaymentMethod and reduce dupe code
interface CheckoutPlaceOrderProps {
  adyenPaymentMethodType: string
  adyenPaymentMethodObj: object | undefined
  paymentDetails: Bff.Orders.InitialiseOrderPayment
}

export const useCheckoutPlaceOrder = ({
  adyenPaymentMethodType,
  adyenPaymentMethodObj,
  paymentDetails,
}: CheckoutPlaceOrderProps) => {
  const { basket } = useBasket()
  const { id: basketId } = basket
  const sendOrderButtonClickedEvent = useFosOrderButtonClickedEvent()
  const customerCheckoutDetails = useCustomerCheckoutDetails()
  const navigate = useNavigate()
  const { showAlert } = useAlert()
  const { t } = useTranslation('checkout')

  const [showProcessingOverlay, setShowProcessingOverlay] = useState<boolean>(false)
  const [updatedPayment, setUpdatedPayment] = useState<Bff.Orders.PlaceOrder.Payment | undefined>(undefined)

  const onInitiatedOrder: UseInitiateOrderProps['onReady'] = ({ properties: propertiesFromInitOrder }) => {
    const passingThroughProps = propertiesFromInitOrder?.map(({ key, value }) => ({ key, value }))
    const payment = {
      ...paymentDetails,
      transactionToken: JSON.stringify(adyenPaymentMethodObj),
      properties: passingThroughProps,
    } as Bff.Orders.PlaceOrder.Payment
    setUpdatedPayment(payment)

    placeOrder(basketId, payment)
  }

  const handlePlaceOrderError = usePlaceOrderErrorHandler({
    retry: () => {
      if (updatedPayment) placeOrder(basketId, updatedPayment)
    },
    noRetry: () => {
      // TODO: use standard payment error alert message, see paymentErrors with notifyError usage
      showAlert(
        t('ExpressCheckoutPlaceOrderError', {
          defaultValue: 'Sorry, an unexpected error has occurred. Please try again.',
        }),
        'ExpressCheckoutPlaceOrderError',
      )
      setShowProcessingOverlay(false)
    },
    canRetry: !!updatedPayment,
  })

  const {
    placeOrder,
    loading: placingOrder,
    isPurchaseReported: placeOrderCompleted,
  } = usePlaceOrder({ handlePlaceOrderError })

  const { initiateOrder, loading: initiatingOrder } = useInitiateOrder({
    onReady: onInitiatedOrder,
    handleInitiateOrderError: () => {
      // TODO: use standard payment error alert message, see paymentErrors with notifyError usage
      showAlert(
        t('ExpressCheckoutInitiateOrderError', {
          defaultValue: 'Sorry, an unexpected error has occurred. Please try again.',
        }),
        'ExpressCheckoutInitiateOrderError',
      )
      setShowProcessingOverlay(false)
    },
  })

  const initAndPlaceOrder = () => {
    setShowProcessingOverlay(true)
    sendOrderButtonClickedEvent()
    initiateOrder(paymentDetails, customerCheckoutDetails, paymentDetails.providerCode, adyenPaymentMethodType)
  }

  useEffect(() => {
    if (placeOrderCompleted) {
      navigate(isNativeApp() ? NavigationConstants.nativeAppCheckoutProcessing : NavigationConstants.checkoutProcessing)
    }
  }, [placeOrderCompleted])

  return {
    loading: initiatingOrder || placingOrder,
    showProcessingOverlay,
    initAndPlaceOrder,
  }
}
