import {
  AllowedFeatures,
  IDeliveryAddress,
  IDeliveryAddressEvaluation,
  IGraphCallerResult,
  ILogEvent,
  IOptions,
  IUseAnalyticsReporter,
  IUseCustomer,
  IUseCustomerDeliveryAddresses,
  IUseFeatures,
  IUseGraphCaller,
  IUseLanguage,
  IUseLogger,
  IUsePageNavigation,
  IUseSourceDispatch,
  IUseStoreSelect,
  IUseTranslation,
  mapAddressToStoredCustomerAddress,
  mapStoredCustomerAddressToAddress,
} from 'olo-feature-address'
import {
  getSecurityCommonParams,
  security,
  SecurityEventNames,
  useSecurityContext,
} from '@dominos/hooks-and-hocs/logging/security'
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useMemo } from 'react'
import { ApplicationContext } from '@dominos/hooks-and-hocs/application/application-context'
import { useLanguages } from '@dominos/hooks-and-hocs/languages/use-languages'
import { rootActions } from '@dominos/business/root.actions'
import { useRecentAddressesBatch, useSavedAddressesBatch } from '@dominos/hooks-and-hocs/delivery-address'
import { useNavigate } from 'react-router-dom'
import { NavigationConstants } from '@dominos/navigation'
import { useStoreSelect } from '@dominos/hooks-and-hocs/ordering/use-store-select/use-store-select'
import { useFeatures, useLaunchDarklyContext } from '@dominos/hooks-and-hocs/features'
import { useTranslation } from 'react-i18next'
import { ApolloError, useApolloClient } from '@apollo/client'
import gql from 'graphql-tag'
import useFeatureEvent from '../../features/launch-darkly/use-feature-event'
import { analytics } from '../../logging'

/**
     Address Provider hook to fetch feature toggles, should only be used in the Address Provider for use in olo.feature.address
  **/
export const useAddressProviderFeatures = (): IUseFeatures => ({
  // eslint-disable-next-line react-hooks/rules-of-hooks
  featureEnabled: (...feats: AllowedFeatures[]) => useFeatures(...(feats as AllowedBooleanFeatures[])),
})

/**
    Address Provider hook to log events, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderLogger = (): IUseLogger => {
  const { client } = useSecurityContext()
  const settings = useSelector((store: RootReducer) => store.applicationReducer)
  const { sendSecurity } = security(client, getSecurityCommonParams(settings))

  const logEvent = <T>(event: ILogEvent<T>, forceFlush?: boolean) =>
    sendSecurity(event.name as SecurityEventNames, event.data, forceFlush)

  return {
    logEvent,
  }
}

/**
    Address Provider hook for customer related actions, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderCustomer = (): IUseCustomer => {
  const { launchDarklyID } = useLaunchDarklyContext()
  const dispatch = useDispatch()
  const { countryCode } = useContext(ApplicationContext)

  const getCountryCode = () => {
    if (!countryCode) {
      throw new Error('Country code is not set in the application context.')
    }

    return countryCode
  }

  return {
    customerId: launchDarklyID,
    currentCountryCode: getCountryCode(),
    lastSessionDeliverySearchValue: useSelector((state: RootReducer) => state.customerReducer.searchAddressInput),
    storedAddresses: useSelector((state: RootReducer) => state.customerReducer.addresses),
    storedDeliveryAddresses: useSelector((state: RootReducer) => state.customerReducer.deliveryAddresses),
    setLastSessionDeliverySearchValue: (value: string) => dispatch(rootActions.saveSearchState(value)),
    addDeliveryAddress: (address: IDeliveryAddress) => {
      const storedCustomerAddress = mapAddressToStoredCustomerAddress(address)
      dispatch(rootActions.saveCustomerAddress(storedCustomerAddress))
    },
    setStoredDeliveryAddresses: (addresses: IDeliveryAddress[]) =>
      dispatch(rootActions.setDeliveryAddresses(addresses)),
  }
}

/**
    Address Provider hook for language, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderLanguage = (): IUseLanguage => {
  const { language, setLanguage } = useLanguages()

  return { language, setLanguage }
}

/**
  Address Provider hook for dispatch, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderDispatch = (): IUseSourceDispatch => {
  const dispatch = useDispatch()

  const resetCurrentStore = () => dispatch(rootActions.resetCurrentStore())

  const resetAutoCompleteScenario = () => dispatch(rootActions.resetAutoCompleteScenario())

  const setServiceMethod = (serviceMethod: BffContext.ServiceMethods) =>
    dispatch(rootActions.selectServiceMethod(serviceMethod))

  const addCustomerAddressToOrder = (address: IDeliveryAddressEvaluation) => {
    const storedCustomerAddress = mapAddressToStoredCustomerAddress(address)
    dispatch(rootActions.addCustomerAddressToOrder(storedCustomerAddress))
  }

  const setStore = (store: Bff.Stores.Store) => dispatch(rootActions.storeSelected(store, true))

  return {
    setStore,
    addCustomerAddressToOrder,
    resetCurrentStore,
    resetAutoCompleteScenario,
    setServiceMethod,
  }
}

/**
 Address Provider hook for getting customer recent and saved addresses, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderCustomerAddresses = (): IUseCustomerDeliveryAddresses => {
  const recentAddresses = useRecentAddressesBatch()
  const savedAddresses = useSavedAddressesBatch()
  const { countryCode } = useContext(ApplicationContext)
  const { language } = useLanguages()

  const recentDeliveryAddresses = useMemo(() => {
    if (!countryCode) return []

    return recentAddresses.map((address) => mapStoredCustomerAddressToAddress(address, countryCode, language))
  }, [recentAddresses, countryCode])

  const savedDeliveryAddresses = useMemo(() => {
    if (!countryCode) return []

    return savedAddresses.map((address) => mapStoredCustomerAddressToAddress(address, countryCode, language))
  }, [savedAddresses, countryCode])

  return {
    recentDeliveryAddresses,
    savedDeliveryAddresses,
  }
}

/**
 Address Provider hook for getting customer recent and saved addresses, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderPageNavigation = (): IUsePageNavigation => {
  const navigate = useNavigate()
  const navigateToTimedOrderPage = () => navigate(NavigationConstants.timedOrder)

  const navigateToFallbackDeliverySearch = () => navigate(NavigationConstants.deliveryAddress)

  const navigateToLegacyPickupSearch = () => navigate(NavigationConstants.storeSearch)

  const navigateToAutoCompletePickupSearch = () => navigate(NavigationConstants.pickupAddressAutoComplete)

  return {
    navigateToTimedOrderPage,
    navigateToFallbackDeliverySearch,
    navigateToLegacyPickupSearch,
    navigateToAutoCompletePickupSearch,
  }
}

/**
 Address Provider hook for getting customer recent and saved addresses, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderSelectStore = (): IUseStoreSelect => {
  const { getStore, store, error, loading } = useStoreSelect()

  return {
    getStore,
    store,
    error,
    isLoading: loading,
  }
}

/**
 Address Provider hook for using translations, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderTranslation = (ns: string): IUseTranslation => {
  const { t, i18n, ready } = useTranslation(ns)

  // @ts-ignore
  return { t, i18n, ready }
}

/**
 Address Provider hook for using apollo client which has been abstracted out so there no dependencies on client, should only be used in the Address Provider for use in olo.feature.address
 **/
export const useAddressProviderGraphCaller = (): IUseGraphCaller => {
  const client = useApolloClient()

  const query = async <T>(query: string, options: IOptions): Promise<IGraphCallerResult<T>> => {
    try {
      const { fetchOptions, variables } = options
      const { data, error } = await client.query<T>({
        query: gql(query),
        fetchPolicy: fetchOptions,
        variables: variables,
      })

      if (error) {
        return {
          data: undefined,
          error: { reason: error.message },
        }
      }

      return { data }
    } catch (error: unknown) {
      const apolloError = error as ApolloError

      return {
        data: undefined,
        error: { reason: apolloError.message || 'An unexpected error occurred' },
      }
    }
  }

  return { query }
}

export const useAnalyticsReporter = (): IUseAnalyticsReporter => {
  const { trackEvent } = useFeatureEvent()
  const { sendAnalytics } = analytics()
  const sendInteractionEvent = (eventType: string, eventLabel: string) => {
    // Google Analytic
    sendAnalytics('interaction', {
      label: eventLabel,
      value_string: eventType,
    })
    // LD events
    trackEvent(eventType)
  }

  return {
    sendInteractionEvent,
  }
}
