import gql from 'graphql-tag'

export const orderQuery = gql`
  query orderQuery($id: String!) {
    order(id: $id) {
      id
      status {
        status
        estimatedDuration
        elapsedTime
      }
      eta {
        min
        max
      }
      details {
        header {
          pulseId
          orderTime
          serviceMethod
          deliveryAddress {
            displayAddress
            buildingName
            buildingAccessCode
            address {
              unitNo
              streetNo
              street
              suburb
              postCode
              state
              floorNo
              location {
                longitude
                latitude
              }
            }
            locale
          }
          customer {
            name
            alternateName
            phoneNumber
            email
          }
          arrivalNotificationSent
          serviceMethodSubType
          tip
        }
        basket {
          total
          totalDiscount
          lines {
            ... on OrderDetailsBasketVoucherLine {
              type
              voucherNo
              code
              dealType
              status
              discount
              voucherMedia {
                name
                description
              }
              isLoyaltyRedemption
              items {
                itemNo
                type
                productCode
                sizeCode
                totalPrice
                quantity
                voucherNo
                voucherItemNo
                base {
                  add
                  remove
                  media {
                    add
                    remove
                  }
                }
                sauce {
                  add
                  remove
                  media {
                    add
                    remove
                  }
                }
                toppings {
                  action
                  code
                  quantity
                  media {
                    name
                  }
                }
                options {
                  action
                  code
                  quantity
                  media {
                    name
                  }
                }
                surcharges {
                  code
                  name
                  price
                }
                media {
                  name
                  base
                  size
                }
                portions {
                  productCode
                  sauce {
                    add
                    remove
                    media {
                      add
                      remove
                    }
                  }
                  toppings {
                    action
                    code
                    quantity
                    media {
                      name
                    }
                  }
                  options {
                    action
                    code
                    quantity
                    media {
                      name
                    }
                  }
                  surcharges {
                    code
                    name
                    price
                  }
                  media {
                    name
                    base
                    size
                  }
                }
              }
            }
            ... on OrderDetailsBasketProductLine {
              itemNo
              type
              productCode
              sizeCode
              totalPrice
              quantity
              base {
                add
                remove
                media {
                  add
                  remove
                }
              }
              sauce {
                add
                remove
                media {
                  add
                  remove
                }
              }
              toppings {
                action
                code
                quantity
                media {
                  name
                }
              }
              options {
                action
                code
                quantity
                media {
                  name
                }
              }
              surcharges {
                code
                name
                price
              }
              media {
                name
                base
                size
              }
              portions {
                productCode
                sauce {
                  add
                  remove
                  media {
                    add
                    remove
                  }
                }
                toppings {
                  action
                  code
                  quantity
                  media {
                    name
                  }
                }
                options {
                  action
                  code
                  quantity
                  media {
                    name
                  }
                }
                surcharges {
                  code
                  name
                  price
                }
                media {
                  name
                }
              }
            }
          }
          surcharges {
            code
            name
            price
          }
          priceExcludingTaxes
          taxes {
            code
            name
            amount
          }
          adjustments {
            code
            name
            amount
          }
        }
        payments {
          media {
            displayName
          }
          amount
          orderPaymentId
          paymentMethod
          providerCode
        }
        donations {
          amount
          status
        }
      }
      store {
        storeNo
        media {
          name
          displayAddress
          tradeName
          tradeIdentifier
        }
        geo {
          longitude
          latitude
        }
        timeZone
        phoneNo
        pickupSubTypes
        deliverySubTypes
        surcharges {
          surchargeCode
          media {
            mediaName
            content
            language
          }
        }
        features {
          featureName
          isEnabled
          settings {
            ... on TipTheDriverSettings {
              minimumTip
              maximumTip
              presets {
                value
                label
              }
            }
          }
        }
      }
    }
  }
`
