import { NavigationConstants } from '@dominos/navigation'
import React, { useEffect } from 'react'
import { LoginSceneMessage, postWebViewMessage } from '@dominos/business/functions/native-app'
import { NativeProfileCompletionContainer } from '@dominos/components'
import { useFeatures } from '@dominos/hooks-and-hocs'

export const NativeProfileCompletionScene = () => {
  const [customerProfileCompletionScreen] = useFeatures('CustomerProfileCompletionScreen')

  useEffect(() => {
    postWebViewMessage<LoginSceneMessage>({ route: NavigationConstants.nativeAppProfileCompletion })
  }, [])

  if (!customerProfileCompletionScreen) {
    return null
  }

  return <NativeProfileCompletionContainer testID='native-profile-completion-container' />
}
