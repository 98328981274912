import { getAdyenPaymentMethods } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/adyen'
import { AdyenPaymentMethodExtended } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/adyen/payment-adyen.interface'
import { useAdyenInstance } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/adyen/use-adyen-instance'
import { PaymentSetting, useCurrentOrderDetails, usePaymentsAvailableAtStore } from '@dominos/hooks-and-hocs'

const isExpressCheckoutPreferredMethod = (adyenPaymentMethodType: string) => adyenPaymentMethodType === 'applepay'

const emptyAdyenInstanceConfig = { adyenPaymentMethod: { type: '', name: '' }, paymentSetting: {} as PaymentSetting }

export const useExpressCheckout = () => {
  const { basketHeaderData } = useCurrentOrderDetails()
  const { paymentSettings } = usePaymentsAvailableAtStore(
    basketHeaderData?.storeNo,
    basketHeaderData?.serviceMethod,
    basketHeaderData?.serviceMethodSubType,
    basketHeaderData?.time ?? undefined,
  )

  let adyenPaymentMethod: AdyenPaymentMethodExtended | undefined
  const paymentSetting = paymentSettings?.find((paymentSetting) => {
    const adyenPaymentMethods = getAdyenPaymentMethods(paymentSetting)
    adyenPaymentMethod = adyenPaymentMethods.find((m) => isExpressCheckoutPreferredMethod(m.type))

    return !!adyenPaymentMethod
  })

  const adyenInstanceConfig =
    !paymentSetting || !adyenPaymentMethod ? emptyAdyenInstanceConfig : { adyenPaymentMethod, paymentSetting }
  const { isMethodAvailable } = useAdyenInstance(adyenInstanceConfig)

  return {
    isAvailable: !!paymentSetting && !!adyenPaymentMethod && isMethodAvailable,
    paymentSetting,
    adyenPaymentMethod,
  }
}
