import { formatSectionName, isScrollBottom, isScrollTop } from '@dominos/business/functions/menu'

/**
 * Returns the code of the visible section, or fallbacks if none in view.
 *
 * @param code the current item being viewed
 * @param categoryData current category (for fallbacks)
 * @returns visible section code
 */
const getVisibleSectionCode = (code: string, categoryData: ResolvedMenuSection[] | null) => {
  if (isScrollTop()) {
    const firstCategoryCode = (categoryData && categoryData[0]?.code) || ''

    return formatSectionName(firstCategoryCode)
  }
  if (isScrollBottom()) {
    const lastCategoryCode = (categoryData && categoryData[categoryData.length - 1]?.code) || ''

    return formatSectionName(lastCategoryCode)
  }

  return formatSectionName(code)
}

export { getVisibleSectionCode }
