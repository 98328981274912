export const productDetailLocationType: string = 'ProductDetails'

export interface ProductUpsellData {
  media: UpsellMedia
  price: string | null | undefined
  code: string
  showBanner: boolean
  showPopUp: boolean
  componentType: string | null
  componentName: string
}

export enum UpsellType {
  Banner = 'Banner',
  Popup = 'Popup',
  List = 'List',
}

export enum MediaType {
  Banner = 'Banner',
  Popup = 'Popup',
  None = 'None',
}
export interface MediaBase {
  type: MediaType
}

export interface BannerMedia extends MediaBase {
  type: MediaType.Banner
  name: Bff.Common.TextMedia
  description?: Bff.Common.TextMedia | null
  image: Bff.Common.ImageMedia
}

export interface PopupMedia extends MediaBase {
  type: MediaType.Popup
  name: Bff.Common.TextMedia
  description?: Bff.Common.TextMedia | null
  image: Bff.Common.ImageMedia
}

export enum ItemType {
  Dimension = 'Dimension',
  Ingredient = 'Ingredient',
}

export interface UpsellTriggerContextBase {
  itemType: ItemType
}

export interface UpsellDimensionTriggerContext extends UpsellTriggerContextBase {
  itemType: ItemType.Dimension
  dimension: Bff.Common.DimensionTypeCode
  selectedCode: string
}

export interface UpsellIngredientTriggerContext extends UpsellTriggerContextBase {
  itemType: ItemType.Ingredient
  ingredient: Bff.Common.IngredientTypeCode
  selectedCodes: string[]
}

export interface UpsellItemBase<TMedia> {
  itemType: ItemType
  media: TMedia
  price: string | null | undefined
}

export interface UpsellItemDimensional<TMedia> extends UpsellItemBase<TMedia> {
  itemType: ItemType.Dimension
  dimensionType: Bff.Common.DimensionTypeCode
  dimensionCode: string
}

export interface UpsellItemIngredient<TMedia> extends UpsellItemBase<TMedia> {
  itemType: ItemType.Ingredient
  ingredientType: Bff.Common.IngredientTypeCode
  ingredientCode: string
}

export type UpsellItem<TMedia> = UpsellItemDimensional<TMedia> | UpsellItemIngredient<TMedia>

export enum ProductUpsellComponentType {
  Crust = 'Crust',
  Sauce = 'Sauce',
  Topping = 'Topping',
}

export interface ProductUpsellProps extends BaseProductProps {
  currentProduct: BasketLineData | undefined
  productUpsellVariation?: ProductUpsellVariationTypes
  allowableComponentTypes?: string[]
  onUpsellSelected: (code: TouchableGridElement, componentType: string | null) => void
}

export enum ProductComponentUpsellLDVariation {
  VariationNone = 'variation-none', //Represents a variation that disable the upsell feature
  VariationEnable = 'variation-enable', // Represents a variation that enables the upsell feature. The action button is displayed without price text.
  VariationPriceEnable = 'variation-price-enable', // Represents a variation that enables the upsell feature. The action button is displayed with price text.
}

export enum ProductUpsellVariationTypes {
  VariationNone = 'variation-none',
  VariationBanner = 'variation-banner',
  VariationPopup = 'variation-popup',
}

export interface UpsellMedia {
  name: Bff.Common.TextMedia
  description?: Bff.Common.TextMedia | null
  popupImage: Bff.Common.ImageMedia
  bannerImage: Bff.Common.ImageMedia
}

export interface UpsellProps {
  productUpsellPopupDismissed?: boolean
}

export enum ProductUpsellZoneId {
  ProductBaseUpsellBanner = 'product_base_upsell_banner',
  ProductUpsellPopup = 'product_upsell_popup',
  ProductToppingUpsellList = 'product_topping_upsell_list',
}

export interface ProductUpsellZones {
  zoneId: string
}

export interface UpsellFilter {
  componentType: ProductUpsellComponentType
  upsellType: UpsellType
  allowedLayout: BffContext.Layouts
  locationType: string
}

export interface UpsellData<TMedia> {
  offerId: string
  upsellItems: UpsellItem<TMedia>[]
}
