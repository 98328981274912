import { isNativeApp } from '@dominos/business/functions/native-app'
import { NavigationConstants } from '@dominos/navigation'
const createAsiaPayReturnUrl = (
  basePath: string,
  nativeAppBasePath: string,
  excludeMobileDeepLinks: boolean = false,
  googleId?: string,
): string => {
  const url = new URL(basePath, window.location.origin)

  if (googleId) {
    url.searchParams.set('ga_redirect_id', googleId)
  }

  if (isNativeApp()) {
    url.pathname = nativeAppBasePath

    if (!excludeMobileDeepLinks) {
      const searchParams = url.searchParams.toString()

      return `dominos://payment${url.pathname}${searchParams ? '?' : ''}${searchParams}`
    }
  }

  return url.toString()
}

export const getAsiaPayReturnSuccessUrl = (
  orderId: string,
  orderPaymentId: string,
  excludeMobileDeepLinks: boolean = false,
  googleId?: string,
): string =>
  createAsiaPayReturnUrl(
    `${NavigationConstants.checkoutProcessing}/${orderId}/${orderPaymentId}`,
    `${NavigationConstants.nativeAppCheckoutProcessing}/${orderId}/${orderPaymentId}`,
    excludeMobileDeepLinks,
    googleId,
  )

export const getAsiaPayReturnFailUrl = (
  excludeMobileDeepLinks: boolean = false,
  isTwoTapCheckoutEnabled: boolean,
  googleId?: string,
): string =>
  createAsiaPayReturnUrl(
    isTwoTapCheckoutEnabled ? NavigationConstants.checkout : NavigationConstants.checkoutPayment,
    isTwoTapCheckoutEnabled ? NavigationConstants.nativeAppCheckout : NavigationConstants.nativeAppCheckoutPayment,
    excludeMobileDeepLinks,
    googleId,
  )

export const getAsiaPayReturnCancelUrl = (
  excludeMobileDeepLinks: boolean = false,
  isTwoTapCheckoutEnabled: boolean,
  googleId?: string,
): string =>
  createAsiaPayReturnUrl(
    isTwoTapCheckoutEnabled ? NavigationConstants.checkout : NavigationConstants.checkoutPayment,
    isTwoTapCheckoutEnabled ? NavigationConstants.nativeAppCheckout : NavigationConstants.nativeAppCheckoutPayment,
    excludeMobileDeepLinks,
    googleId,
  )
