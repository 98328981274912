import {
  ItemType,
  ProductUpsellComponentType,
  ProductUpsellZoneId,
  UpsellData,
  UpsellItem,
  UpsellTriggerContextBase,
  UpsellType,
} from '@dominos/components/product/product-upsell/product-upsell.interface'
import { useOffersContext } from '@dominos/hooks-and-hocs/offers'
import { productUpsellFilterList } from './offers-helpers'
import { mapUpsellZoneIdToUpsellFilter } from './upsell-zone-mapper'
import { isOfferAvailable } from './utils'

type IngredientType = Bff.Common.IngredientTypeCode
type DimensionType = Bff.Common.DimensionTypeCode

export interface OfferAvailableFilters {
  componentType: ProductUpsellComponentType
  availableCodes: string[]
  selectedCodes: string[]
}

export interface UpsellDimensionTriggerContext extends UpsellTriggerContextBase {
  itemType: ItemType.Dimension
  dimensionType: DimensionType
  selectedCode: string
  availableCodes: string[]
}

export interface UpsellIngredientTriggerContext extends UpsellTriggerContextBase {
  itemType: ItemType.Ingredient
  ingredientType: IngredientType
  selectedCodes: string[]
  availableCodes: string[]
}

export interface ProductUpsellProps {
  zoneId: ProductUpsellZoneId
  triggerContexts: (UpsellDimensionTriggerContext | UpsellIngredientTriggerContext)[]
}

const transformMedia = <TMedia>(media: Bff.Offers.OfferMedia, upsellType: UpsellType): TMedia => {
  switch (upsellType) {
    case UpsellType.Banner:
      return {
        name: {
          value: media.name,
        },
        description: {
          value: media.description,
        },
        image: {
          uri: media.banner.url,
          altText: media.banner.altText,
        },
      } as TMedia

    case UpsellType.Popup:
      return {
        name: {
          value: media.name,
        },
        description: {
          value: media.description,
        },
        image: {
          uri: media.popUp.url,
          altText: media.popUp.altText,
        },
      } as TMedia

    case UpsellType.List:
      return {} as TMedia
  }
}

const getRelevantOfferContext = (
  triggerContext: (UpsellDimensionTriggerContext | UpsellIngredientTriggerContext)[],
  componentType: ProductUpsellComponentType,
) => {
  switch (componentType) {
    case 'Crust':
      return triggerContext.find(
        (context) => context.itemType === ItemType.Dimension && context.dimensionType === 'Base',
      )
    case 'Sauce':
      return triggerContext.find(
        (context) => context.itemType === ItemType.Ingredient && context.ingredientType === 'Sauce',
      )
    case 'Topping':
      return triggerContext.find(
        (context) => context.itemType === ItemType.Ingredient && context.ingredientType === 'Topping',
      )
    default:
      return undefined
  }
}

const createUpsellOffer = <TMedia>(
  upsellType: UpsellType,
  linkedItem: Bff.Offers.LinkedItem,
  media: Bff.Offers.OfferMedia,
  price: string,
  showBanner: boolean,
  showPopUp: boolean,
  contextType: ItemType,
): UpsellItem<TMedia> | undefined => {
  if ((showBanner && upsellType === 'Banner') || (showPopUp && upsellType === 'Popup') || upsellType === 'List') {
    if (contextType === ItemType.Dimension && linkedItem.componentType === ProductUpsellComponentType.Crust) {
      return {
        itemType: contextType,
        price,
        dimensionCode: linkedItem.itemCode,
        media: transformMedia<TMedia>(media, upsellType),
        dimensionType: 'Base' as Bff.Common.DimensionTypeCode,
      }
    } else if (
      contextType === ItemType.Ingredient &&
      (linkedItem.componentType === ProductUpsellComponentType.Topping ||
        linkedItem.componentType === ProductUpsellComponentType.Sauce)
    ) {
      return {
        itemType: contextType,
        price,
        ingredientCode: linkedItem.itemCode,
        media: transformMedia<TMedia>(media, upsellType),
        ingredientType: linkedItem.componentType as Bff.Common.IngredientTypeCode,
      }
    }
  }

  return undefined
}

export const useProductUpsell = <TMedia>({ zoneId, triggerContexts }: ProductUpsellProps): UpsellData<TMedia>[] => {
  const { offers: allOffers = [] } = useOffersContext()

  const upsellFilter = mapUpsellZoneIdToUpsellFilter(zoneId)
  if (!upsellFilter) return []

  const upsellData: UpsellData<TMedia>[] = []

  upsellFilter.forEach((upsellFilter) => {
    const { componentType, upsellType } = upsellFilter
    const filteredOffers = productUpsellFilterList(allOffers, upsellFilter)

    filteredOffers.forEach((filteredOffer) => {
      const upsellItems: UpsellItem<TMedia>[] = []
      filteredOffer.items.forEach((item) => {
        const { media, locations } = filteredOffer
        const { linkedItem, price } = item

        const triggerContext = getRelevantOfferContext(triggerContexts, componentType)

        if (
          triggerContext &&
          isOfferAvailable(
            {
              componentType,
              availableCodes: triggerContext.availableCodes,
              selectedCodes:
                triggerContext.itemType === ItemType.Dimension
                  ? [triggerContext.selectedCode]
                  : triggerContext.selectedCodes,
            },
            linkedItem,
          )
        ) {
          const upsellItem = createUpsellOffer<TMedia>(
            upsellType,
            linkedItem,
            media,
            price,
            locations[0].showBanner,
            locations[0].showPopUp,
            triggerContext.itemType,
          )
          if (upsellItem) {
            upsellItems.push(upsellItem)
          }
        }
      })

      if (upsellItems.length > 0) {
        upsellData.push({
          offerId: filteredOffer.offerId,
          upsellItems: upsellItems,
        })
      }
    })
  })

  return upsellData
}
