import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@dominos/components'
import { MyDetailsFormReadOnlyProps } from './my-details-form.interface'
import { StaticSvgIcon } from '@dominos/res'
import { useCountryCode, useLanguages } from '@dominos/hooks-and-hocs'
import styles from './my-details.less'

export const MyDetailsFormReadOnly = (props: MyDetailsFormReadOnlyProps) => {
  const { t } = useTranslation('checkout')
  const countryCode = useCountryCode()
  const { language } = useLanguages()
  const isJapan = !!countryCode && countryCode === 'JP'
  const isJALanguage = !!language && language === 'ja'

  const iconProps = {
    width: '16',
    height: '16',
    fill: '#007aff',
  }

  return (
    <>
      <div className={styles.yourDetails} data-testid={props.testID}>
        <h2 className={styles.sectionTitle} data-testid={`${props.testID}.your-details.title`}>
          {t('YourDetails', { defaultValue: 'Your Details' })}
        </h2>
        <ActionButton
          testID={`${props.testID}.edit`}
          onPress={() => props.setEditMyDetails(true)}
          text={t('Edit', { defaultValue: 'Edit', ns: 'menu' })}
          textStyle={{ color: '#007AFF', fontSize: '14px' }}
          textFontWeight={'bold'}
          containerStyle={{ backgroundColor: '#007AFF33', width: 60, minHeight: 25 }}
        />
      </div>
      <div>
        <div className={styles.yourDetails}>
          <StaticSvgIcon name={'account'} {...iconProps} />
          <label>{t('Name')}</label>
          <p>{props.initialDetails.name}</p>
        </div>
        {isJapan && isJALanguage && (
          <div className={styles.yourDetails}>
            <StaticSvgIcon name={'account'} {...iconProps} />
            <label>{t('AlternateName')}</label>
            <p>{props.initialDetails.alternateName}</p>
          </div>
        )}
        <div className={styles.yourDetails}>
          <StaticSvgIcon name={'phone'} {...iconProps} />
          <label>{t('Mobile Number')}</label>
          <p>{props.initialDetails.mobileNumber}</p>
        </div>
        <div className={styles.yourDetails}>
          <StaticSvgIcon name={'mail'} {...iconProps} />
          <label>{t('Email Address')}</label>
          <p>{props.initialDetails.emailAddress}</p>
        </div>
        {props.showDeliveryInfo && (
          <>
            <div className={styles.yourDetails} data-testid={`${props.testID}.delivery-type`}>
              <StaticSvgIcon name={'deliveryType'} {...iconProps} />
              <label>{t('DeliveryType')}</label>
              <p>{'Receive Directly'}</p>
            </div>
            <div className={styles.yourDetails} data-testid={`${props.testID}.delivery-instructions`}>
              <StaticSvgIcon name={'deliveryInstructions'} {...iconProps} />
              <label>{t('Delivery Instructions')}</label>
              <p>{'None'}</p>
            </div>
          </>
        )}
      </div>
    </>
  )
}
