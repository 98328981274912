import React, { useRef, useState } from 'react'
import { ServiceMethodAdditionalDetailsProps } from '@dominos/components'
import { DeliveryInstructions } from './delivery-instructions'
import { noop } from '@dominos/hooks-and-hocs'
import { DeliveryWCAAddressFields } from './delivery-wca-address-fields/delivery-wca-address-fields'

const DeliveryDetailsForm = ({
  testID,
  renderInlineSingleItemOffer,
  renderServiceMethodType,
  renderTermsAndConditions,
  renderOrderButton,
}: ServiceMethodAdditionalDetailsProps) => {
  const [deliveryInstructions, setDeliveryInstructions] = useState<string>('')
  const placeOrderHookForAddressRef = useRef<() => void>(noop)

  const placeOrderHook = () => {
    placeOrderHookForAddressRef.current()

    return { deliveryInstructions }
  }

  return (
    <React.Fragment>
      <DeliveryWCAAddressFields placeOrderHookForAddressRef={placeOrderHookForAddressRef} testID={testID} />
      {renderInlineSingleItemOffer(`${testID}.my-details-offers`)}
      {renderServiceMethodType({})}
      <DeliveryInstructions testID={`${testID}.delivery-instructions`} onChange={setDeliveryInstructions} />
      {renderTermsAndConditions()}
      {renderOrderButton({ placeOrderHook })}
    </React.Fragment>
  )
}

export default DeliveryDetailsForm
