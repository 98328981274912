import {
  AddressField,
  IPreConfirmAddressAttributeField,
  IUsePreConfirmAddressFieldsContainerProps,
  usePreConfirmAddressFieldsContainer,
} from 'olo-feature-address'
import React from 'react'

export const PreConfirmAddressFieldsContainer = (props: IUsePreConfirmAddressFieldsContainerProps) => {
  const { addressFields } = usePreConfirmAddressFieldsContainer(props)

  if (!addressFields) return <></>

  const style = getStyles()

  return (
    <div style={style.addressFieldsContainer}>
      {addressFields.map((field: IPreConfirmAddressAttributeField, index: number) => (
        <AddressField
          key={index}
          addressAttributeName={field.addressAttributeName}
          onChange={field.onChange}
          initialValue={field.initialValue}
          fieldInputHandling={'Editable'}
          fieldValidationRules={field.fieldValidationRules}
          inputWidth={field.inputWidth}
        />
      ))}
    </div>
  )
}

interface Style {
  addressFieldsContainer: React.CSSProperties
}

const getStyles = (): Style => ({
  addressFieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: '5px',
  },
})
