import {
  FormattedVarietyItem,
  getBaseProductPrice,
  getMenuItemByCode,
  getSectionsFromCategoryCode,
  getVarieties,
  isScrollBottom,
  isScrollTop,
  resolveMenuSections,
} from '@dominos/business/functions/menu'
import { FlatList, MenuColumnLayout, MenuSection } from '@dominos/components'
import { COUPON_MENU_CODE, useFeatures, useFilterProductItems, useMenuCategory } from '@dominos/hooks-and-hocs'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getVisibleSectionCode } from '../menu-page/functions'
import { MenuSectionHeader } from './menu-section-header'

type SwapSetBaseProduct = Bff.Menu.old.SwapSetBaseProduct

const DELAY_SCROLL = 200

const NAV_HEIGHT_DESKTOP = 97
const NAV_HEIGHT_DESKTOP_WITH_PADDING = NAV_HEIGHT_DESKTOP + 10
const ITEM_CARD_HEIGHT_DESKTOP = 329

interface MenuSectionContainerProps extends BaseProps {
  menu?: Menu
  toggleProductCard?: (
    isEditing: boolean,
    item?: MenuItemDependents,
    lineData?: BasketLineItem,
    swapping?: BasketLine,
    addDirectlyToBasket?: boolean,
  ) => void
  setSection?: (item: string) => void
}

const MenuSectionContainer = ({ menu, testID, setSection, toggleProductCard }: MenuSectionContainerProps) => {
  const params = useParams()
  const { selection, variety } = params
  const menuCategory = useMenuCategory(menu?.pages[0])
  const [categoryData, setCategoryData] = useState<ResolvedMenuSection[] | null>(null)
  const [formattedVarietyItems, setFormattedVarietyItems] = useState<FormattedVarietyItem[] | null>(null)
  const { isFiltered, voucherNo, voucherItemNo } = useFilterProductItems()
  const [halfHalfMenuWorkaround] = useFeatures('HalfHalfMenuWorkaround')
  const [activeSection, setActiveSection] = useState<string | null>(null)
  const sectionRefs = useRef<Record<string, HTMLDivElement | null>>({})
  const scrollTimeout = useRef<number | null>(null)

  const handleScroll = () => {
    if ((isScrollBottom() || isScrollTop()) && setSection) {
      const visibleSection = getVisibleSectionCode('', categoryData)
      setSection(visibleSection)
      setActiveSection(visibleSection)
    }

    if (scrollTimeout.current) return

    scrollTimeout.current = window.setTimeout(() => {
      let closestSection: string | null = null
      let closestOffset = Number.POSITIVE_INFINITY

      const fullyVisibleSections = Object.entries(sectionRefs.current).filter(([_, element]) => {
        if (!element) return false
        const top = element.getBoundingClientRect().top

        return top + NAV_HEIGHT_DESKTOP >= 0 && top <= window.innerHeight
      })

      if (fullyVisibleSections.length === 1) {
        ;[closestSection] = fullyVisibleSections[0]
      } else {
        Object.entries(sectionRefs.current).forEach(([sectionCode, element]) => {
          if (element) {
            const rect = element.getBoundingClientRect()
            const offset = Math.abs(rect.top - NAV_HEIGHT_DESKTOP_WITH_PADDING)
            if (
              isScrollBottom() ||
              (rect.top >= NAV_HEIGHT_DESKTOP && rect.top <= ITEM_CARD_HEIGHT_DESKTOP && offset < closestOffset)
            ) {
              closestOffset = offset
              closestSection = sectionCode
            }
          }
        })
      }

      if (closestSection && activeSection !== closestSection) {
        setActiveSection(closestSection)
        setSection && setSection(getVisibleSectionCode(closestSection, categoryData))
      }

      scrollTimeout.current = null
    }, DELAY_SCROLL)
  }

  useEffect(() => {
    const onScroll = () => window.requestAnimationFrame(handleScroll)

    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)
    handleScroll()

    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
        scrollTimeout.current = null
      }
    }
  }, [activeSection])

  useEffect(() => {
    // TODO: move this categoryData code into the useMenu hook.
    if (menu) {
      const currentMenuSection = isFiltered
        ? resolveMenuSections(menu.pages.find((page) => page.code === COUPON_MENU_CODE)?.sections)
        : getSectionsFromCategoryCode(menu, menuCategory)
      setCategoryData(currentMenuSection)
      const varieties = getVarieties(currentMenuSection)
      setFormattedVarietyItems(varieties ? varieties.getFromCode(variety) : null)
      if (currentMenuSection && currentMenuSection.length) {
        const sectionCode = currentMenuSection[0]?.code || ''
        const visibleSection = getVisibleSectionCode(sectionCode, currentMenuSection)
        setSection && setSection(visibleSection)
        setActiveSection(visibleSection)
      }
    }
  }, [menu, menuCategory, variety, isFiltered])

  const getBaseProductPriceFromMenu = (baseProduct: SwapSetBaseProduct | undefined) =>
    getBaseProductPrice(categoryData, baseProduct)

  const getMenuItemFromProductCode = (productCode: string) => getMenuItemByCode(categoryData, productCode)

  const toggleProductCardMenu = (
    menuItem?: MenuItemDependents,
    lineData?: BasketLineItem,
    swapping?: BasketLine,
    addDirectlyToBasket?: boolean,
  ) => toggleProductCard && toggleProductCard(false, menuItem, lineData, swapping, addDirectlyToBasket)

  const keyExtractor = (item: ResolvedMenuSection, index: number) => item.code

  const renderMenuSection = (menuSection: ResolvedMenuSection, index: number) => {
    const isJPHalfHalfMenuWorkAround = halfHalfMenuWorkaround && menuSection.code === 'Menu.Pizza.Designa'

    if (menuSection.data === null || isJPHalfHalfMenuWorkAround) {
      return null
    }

    return (
      <div
        ref={(el) => (sectionRefs.current[menuSection.code] = el)}
        data-testid={`${testID}.${menuSection.code}.menu-section`}
        key={index}
      >
        <MenuSectionHeader menuSection={menuSection} />
        <MenuColumnLayout testID={`${testID}.menu-data-column`}>
          <MenuSection
            testID={testID}
            menuItemsInSection={menuSection.data}
            index={index}
            key={index}
            toggleProductCard={toggleProductCardMenu}
            menuCategory={menuCategory}
            selection={selection}
            isFiltered={isFiltered}
            voucherNo={voucherNo}
            voucherItemNo={voucherItemNo}
            getBaseProductPrice={getBaseProductPriceFromMenu}
            getMenuItem={getMenuItemFromProductCode}
          />
        </MenuColumnLayout>
      </div>
    )
  }

  return (
    <FlatList
      data={formattedVarietyItems ? formattedVarietyItems : categoryData}
      renderItem={renderMenuSection}
      keyExtractor={keyExtractor}
    />
  )
}

export { MenuSectionContainer }
