import { useTranslation } from 'react-i18next'
import { useValidation } from '..'

export const useValidationJP = () => {
  const { matchRegex } = useValidation()
  const { t } = useTranslation('validation')

  const validatePostalCode: ValidationFunction<string> = matchRegex(/^\d{3}-?\d{4}$/, t('InvalidPostalCodeError'))

  const validateHouseNumber: ValidationFunction<string> = matchRegex(
    /^(?!(0))[0-9]+$|^(?!(0))[0-9]+(-(?!(0))[0-9]+)+$/,
    t('InvalidHouseNumberError'),
  )

  const validatePhoneNumber: ValidationFunction<string> = matchRegex(
    /^(?=(?!000|010|020|030|040|060)\d*(-\d*){0,3}$)(?=-*(\d-*){10,11}$).*$|^\d(?!){10,11}$/,
    t('InvalidPhoneNumberError'),
  )

  // Regex copied from Classic olo's field configuration.
  // Dominos.OLO.Html5/App_Data/NavGraph/JP/ja.common.json
  const validateIsKatakana: ValidationFunction<string> = matchRegex(
    /^[ァ-ン][ァ-ン 　ー\u3099\u309A$#]*$/,
    t('InvalidKatakanaError'),
  )

  const isNumeric: ValidationFunction<string> = matchRegex(/^\p{N}+$/u, t('NumbersOnlyError'))
  isNumeric.restrictInput = true

  return {
    validateHouseNumber,
    validatePostalCode,
    validatePhoneNumber,
    validateIsKatakana,
    isNumeric,
  }
}
