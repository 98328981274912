import { NavigationBackButtonProps } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './navigation-back-button.less'
import classnames from 'classnames'

export const NavigationBackButton = (props: NavigationBackButtonProps) => {
  const { backScreen } = props
  const navigate = useNavigate()
  const { t } = useTranslation('navigation')
  const onClick = () => {
    if (!props.onBack || !props.onBack()) {
      navigate(-1)
    }
  }

  return (
    <div
      role='button'
      aria-label={t('BackButton', { defaultValue: 'Back button' })}
      data-testid='back-button'
      className={classnames(
        css.container,
        props.backBackground && css.backBackground,
        !props.showInNavigationBar && css.notInNavBar,
      )}
      onClick={onClick}
    >
      <div className={css.innerContainer}>
        <StaticSvgIcon name='chevron' direction='left' isUnstyled />
        <p data-testid='back-button.title' className={css.text}>
          {backScreen || t('BackButton', { defaultValue: 'Back' })}
        </p>
      </div>
    </div>
  )
}
