import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { FosEventInfoType, FosInteractionEventType } from 'olo-feature-fos'
import { rootActions } from '@dominos/business'
import { notifyNativeApp } from '@dominos/business/functions/native-app'
import { APP_START_FROM_NATIVE_APP, CheckoutContext, Error, SingleColumn, TrackerPage } from '@dominos/components'
import {
  OrderStatus,
  SuccessfulOrderStatus,
  useCurrentOrderDetails,
  useCurrentStore,
  useFos,
  useNativeAppBasketData,
  useOrder,
  useOrderId,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation/navigation-constants'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import nativeAppTrackerErrors from './native-app-tracker-errors'

export const NativeAppTrackerScene = () => {
  useNativeAppBasketData('tracker')

  const { t } = useTranslation('tracker')
  const { id: orderId, errors } = useOrderId({ inspectQuery: true, useIdFromBasket: true })
  const { fetchOrder, order } = useOrder()
  const dispatch = useDispatch()
  const { sendFosEvent } = useFos()
  const { pathname } = useLocation()
  const { basketHeaderData, customerDetailsData } = useCurrentOrderDetails()
  const currentStore = useCurrentStore()
  const navigate = useNavigate()

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId, 'cache-first')
    }
    if (orderId === null) {
      notifyNativeApp('error')
    }
  }, [orderId])

  useEffect(() => {
    if (order?.id && !SuccessfulOrderStatus.includes(order?.status.status as OrderStatus)) {
      navigate(NavigationConstants.nativeAppCheckoutProcessing)
    }
  }, [order])

  const shouldSendNavigationEvent = !!(currentStore?.storeNo && basketHeaderData?.serviceMethod)
  useEffect(() => {
    if (!order) return
    if (order?.id && SuccessfulOrderStatus.includes(order?.status.status as OrderStatus) && shouldSendNavigationEvent) {
      sendFosEvent({
        type: FosEventInfoType.InteractionEvent,
        locationPath: pathname,
        eventType: FosInteractionEventType.FosSessionFinalised,
      }).then(() =>
        dispatch(
          rootActions.orderFlowSuccess({
            orderId: order.id,
            createdOn: Date.now(),
          }),
        ),
      )
    } else {
      dispatch(rootActions.saveOrderToLocalState(order!))
    }
  }, [order, shouldSendNavigationEvent])

  return (
    <CheckoutContext.Provider value={{ appStartFrom: APP_START_FROM_NATIVE_APP }}>
      <SingleColumn>
        {errors && <Error tns={t} errorDefinitions={nativeAppTrackerErrors} error={errors} />}
        {order && <TrackerPage order={order} customerDetails={customerDetailsData} />}
      </SingleColumn>
    </CheckoutContext.Provider>
  )
}
