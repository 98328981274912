import { useProductContext } from '@dominos/components'
import { PortionCustomisationEditor, PortionProductEditor } from '@dominos/components/product/portion-product-card'
import React, { useState } from 'react'
import { PortionProductCardProps } from './portion-product-card.interface'

export const PortionProductCard = ({ testID, onDismiss, addToBasket, isEditing, t }: PortionProductCardProps) => {
  const [customisingPortionIndex, setCustomisingPortionIndex] = useState<number | undefined>()
  const { dimensionSetState, productData } = useProductContext()
  const [isSwapPortionsActive, setIsSwapPortionsActive] = useState<boolean>(false)
  const isSauceCustomisable =
    productData?.isIngredientCommonForPortions('Sauce', dimensionSetState?.selectedDimensionSet) || false
  const isToppingCustomisable =
    productData?.isIngredientCommonForPortions('Topping', dimensionSetState?.selectedDimensionSet) || false

  const onPortionCustomise = (portionIndex: number) => {
    setCustomisingPortionIndex(portionIndex)
  }

  const onCustomisationCompleted = () => {
    setCustomisingPortionIndex(undefined)
  }

  return customisingPortionIndex !== undefined ? (
    <PortionCustomisationEditor
      testID={testID}
      t={t}
      customisingPortionIndex={customisingPortionIndex}
      allowSauceCustomisation={!isSauceCustomisable}
      allowToppingCustomisation={!isToppingCustomisable}
      onDismiss={onDismiss}
      onCustomisationCompleted={onCustomisationCompleted}
    ></PortionCustomisationEditor>
  ) : (
    <PortionProductEditor
      testID={testID}
      t={t}
      isEditing={isEditing}
      allowSauceCustomisation={isSauceCustomisable}
      addToBasket={addToBasket}
      onDismiss={onDismiss}
      onPortionCustomise={onPortionCustomise}
      isSwapPortionsActive={isSwapPortionsActive}
      setIsSwapPortionsActive={setIsSwapPortionsActive}
    ></PortionProductEditor>
  )
}
