import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { deleteVWOCookies, isKioskOrderType } from '@dominos/business/functions'
import { useFos, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { FosEventInfoType, FosInteractionEventType } from 'olo-feature-fos'

const generateHomeUrl = () => {
  const hostname = window.location.hostname
  const homeUrl = hostname?.endsWith('amazonaws.com') ? '/index.html' : NavigationConstants.home
  const timestamp = isKioskOrderType() ? `?t=${new Date().getTime()}` : ''

  return `${homeUrl}${timestamp}`
}

export const useRestartOrder = ({
  report = true,
  fos = true,
  deleteVwoCookies = false,
}: { report?: boolean; fos?: boolean; deleteVwoCookies?: boolean } = {}) => {
  const { reportOrderReset } = useReport()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const { sendFosEvent } = useFos()
  const navigate = useNavigate()

  const homePath = generateHomeUrl()

  return () => {
    dispatch(rootActions.restartOrder())
    if (report) reportOrderReset()
    if (fos)
      sendFosEvent({
        type: FosEventInfoType.InteractionEvent,
        locationPath: pathname,
        eventType: FosInteractionEventType.OrderRestarted,
      })
    if (deleteVwoCookies) {
      deleteVWOCookies()
        .catch(() => {})
        .finally(() => {
          window.location.href = homePath
        })
    } else {
      navigate(NavigationConstants.home)
    }
  }
}
