import React from 'react'
import { AllergenInfoViewProps } from './allergen-info.interface'
import css from './allergen-info.less'

export const AllergenInfoView = ({ viewModel, testID }: AllergenInfoViewProps): JSX.Element => {
  const { allergens, allergensHeader } = viewModel

  return (
    <div data-testid={`${testID}.allergen-info-view`}>
      <div className={css.title} data-testid={`${testID}.allergies-header`}>
        {allergensHeader}
      </div>
      <div className={css.allergensList} data-testid={`${testID}.allergies`}>
        {allergens}
      </div>
    </div>
  )
}
