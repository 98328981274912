import { isDimensionalPortionProduct } from '@dominos/components'
import { findEntityByDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'

const isIngredientCommonForPortions = (
  ingredientType: Bff.Common.IngredientTypeCode,
  dimensionalProduct: Bff.Products.ProductBase<Bff.Dimensions.DimensionSet> | undefined,
  dimensionSet?: Bff.Dimensions.DimensionSet,
) => {
  if (
    !isDimensionalPortionProduct(dimensionalProduct) ||
    dimensionalProduct.portionProductIngredientTypeRules.length === 0
  ) {
    return false
  }

  const dimensionalIngredientTypeRules = dimensionalProduct.portionProductIngredientTypeRules.filter(
    (ingredient) => ingredient.type === ingredientType,
  )

  return (
    dimensionalIngredientTypeRules.some((rule) => {
      const dimensionIngredientTypeRule = findEntityByDimensionSet(rule.rules, dimensionSet)

      return dimensionIngredientTypeRule?.isCommon === true
    }) || false
  )
}

export { isIngredientCommonForPortions }
