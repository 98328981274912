/* eslint-disable max-lines-per-function */
import {
  ReportLoginData,
  useConfigContext,
  useCurrentOrderDetails,
  useLanguages,
  useReport,
  useSessionStorage,
} from '@dominos/hooks-and-hocs'
import { useLocation } from 'react-router-dom'
import { ForgeRockClient } from './forgerock-client'
import { NavigationConstants } from '@dominos/navigation'
import { applicationName, getCustomerId } from '@dominos/business/functions'
import { ServerConfig } from '@dominos/business/constants'

export const useForgeRock = () => {
  const client = ForgeRockClient
  const { setValue: setForgerockCallbackPath } = useSessionStorage({ key: 'forgerock-callback-path' })
  const { pathname, search } = useLocation()
  const { reportLogin, reportLogout, reportPasswordChanged, reportAccountDelete, reportRegistration } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const { applicationConfig } = useConfigContext()
  const { language } = useLanguages()
  const application = applicationName()
  const countryCode = applicationConfig.COUNTRY

  const getPathName = () => pathname.replace(NavigationConstants.login, '')

  const getServerConfig = (): ServerConfig => ({
    baseUrl: applicationConfig.FORGEROCK_AM_URL,
    timeout: 1,
  })

  const loginForgeRock = async (path?: string) => {
    setForgerockCallbackPath(path ?? getPathName() + search)
    try {
      countryCode && (await client.login({ language, countryCode, application, orderId }, getServerConfig()))
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to get tokens from ForgeRock on Login'
      reportLoginError(errorMessage)
    }
  }

  const registerForgeRock = async (path?: string) => {
    setForgerockCallbackPath(path ?? getPathName() + search)
    try {
      countryCode && (await client.register({ language, countryCode, application, orderId }, getServerConfig()))
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to get tokens from ForgeRock on Register'
      reportRegisterError(errorMessage)
    }
  }
  const updatePasswordForgeRock = async (path?: string) => {
    setForgerockCallbackPath(path ?? pathname + search)
    try {
      if (countryCode) {
        client.updatePassword({ language, countryCode, application, orderId })
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to update password from ForgeRock'
      reportPasswordChangedError(errorMessage)
    }
  }
  const deleteAccountForgeRock = async (path?: string) => {
    setForgerockCallbackPath(path ?? pathname + search)
    try {
      if (countryCode) {
        client.deletAccount({ language, countryCode, application, orderId })
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to delete account from ForgeRock'
      reportAccountDelete({
        success: false,
        orderId: orderId,
        deleteNote: errorMessage,
      })
    }
  }

  const logoutForgeRock = async () => {
    try {
      await client.logout()
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to logout from ForgeRock'
      reportLogout(orderId, {
        status: 'fail',
        authenticationSource: 'ForgeRock',
        status_error: errorMessage,
        url: window.location.href,
      })
    }
  }

  const tokenExchange = async (code: string, state: string) => {
    try {
      const tokens = await client.getAuthToken(code, state)

      return tokens ? tokens.accessToken : null
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to exchange tokens from ForgeRock'
      reportLoginError(errorMessage, {
        payload: { code, state },
      })

      return null
    }
  }

  const forceFetchForgeRockToken = async () => {
    try {
      if (countryCode) {
        const tokens = await client.fetchAuthToken()

        return tokens ? tokens.accessToken : null
      }

      return null
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to force fetch tokens from ForgeRock'
      reportLoginError(errorMessage)

      return null
    }
  }

  const getForgeRockTokenFromStorage = async () => {
    try {
      const tokens = await client.getTokenFromStorage()

      return tokens ? tokens.accessToken : null
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to get tokens from storage'
      reportLoginError(errorMessage)

      return null
    }
  }

  const reportLoginError = (error: string, data?: Pick<ReportLoginData, 'payload'>) => {
    reportLogin({
      status: 'fail',
      authenticationSource: 'ForgeRock',
      identityProvider: 'ForgeRock',
      order_id: orderId,
      url: window.location.href,
      customerId: undefined,
      status_error: error,
      payload: data?.payload,
    })
  }

  const reportRegisterError = (error: string) => {
    reportRegistration({
      success: false,
      orderId: orderId,
      url: window.location.href,
      customerId: undefined,
      identityProvider: 'ForgeRock',
      authenticationSource: 'ForgeRock',
      error: error,
    })
  }

  const reportPasswordChangedError = (error: string) => {
    reportPasswordChanged({ orderId, success: false, note: '', customerId: getCustomerId() ?? '' })
  }

  return {
    tokenExchange,
    loginForgeRock,
    registerForgeRock,
    logoutForgeRock,
    forceFetchForgeRockToken,
    getForgeRockTokenFromStorage,
    updatePasswordForgeRock,
    deleteAccountForgeRock,
  }
}
