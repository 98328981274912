import { NavigationConstants } from '@dominos/navigation'
import { useLocation } from 'react-router-dom'

const LAST_ORDER_PATH_NAMES = [
  '/index.html', // to support PR branch home route
  NavigationConstants.home,
  NavigationConstants.storeSearch,
  NavigationConstants.deliveryAddressList,
]

export const isAllowedLastOrderNavigationPath = (pathname?: string) => LAST_ORDER_PATH_NAMES.includes(pathname)

export const useAllowedLastOrderNavigationPath = () => {
  const { pathname } = useLocation()

  return {
    allowedLastOrderNavigationPath: isAllowedLastOrderNavigationPath(pathname),
  }
}
