import { GenericBottomBarButton, PortionProductList, ProductEditor } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { TFunction } from 'react-i18next'

interface PortionProductEditorContentEmptyPortionProps extends BaseProps {
  onDismiss: (addedToBasket?: boolean) => void
  t: TFunction<'menu'>
  productList: { code: string; name: string }[]
  onValueChange: (value: string) => void
  setSelectingProductIndex: (value: React.SetStateAction<number | undefined>) => void
}

const PortionProductSelector = ({
  testID,
  onDismiss,
  productList,
  t,
  onValueChange,
  setSelectingProductIndex,
}: PortionProductEditorContentEmptyPortionProps) => {
  const theme = useDominosTheme()

  return (
    <ProductEditor
      testID={`${testID}.product-editor-portion-list`}
      onDismiss={onDismiss}
      showBackButton={false}
      showCloseButton={false}
    >
      <PortionProductList testID={testID} productList={productList} onValueChange={onValueChange} t={t} theme={theme} />
      <GenericBottomBarButton
        testID={`${testID}.generic-to-basket-portion`}
        buttonText={t('PortionProductCard.ListCancel', 'Cancel')}
        onClick={() => setSelectingProductIndex(undefined)}
      />
    </ProductEditor>
  )
}

export { PortionProductSelector }
