/**
 * If within `X` distance from bottom of screen return true.
 *
 * @param offset distance from bottom of screen
 */
const isScrollBottom = (offset: number = 50) => {
  const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY

  return distanceFromBottom <= offset
}

export { isScrollBottom }
