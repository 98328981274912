import { LayoutItem } from 'simple-keyboard-layouts/build/interfaces'

/**
 * Layout Items: Japanese Katakana
 */
export default {
  layout: {
    default: [
      '\u30CC \u30D5 \u30A2 \u30A6 \u30A8 \u30AA \u30E4 \u30E6 \u30E8 \u30EF \u30DB \u30D8 \u30FC {bksp}',
      '\u30BF \u30C6 \u30A4 \u30B9 \u30AB \u30F3 \u30CA \u30CB \u30E9 \u30BB \u3099 \u309A',
      '\u30C1 \u30C8 \u30B7 \u30CF \u30AD \u30AF \u30DE \u30CE \u30EA \u30EC \u30B1 \u30E0',
      '{shift} \u30C4 \u30B5 \u30BD \u30D2 \u30B3 \u30DF \u30E2 \u30CD \u30EB \u30E1 \u30ED {shift}',
      '{space}',
    ],
    shift: [
      '  \u30A1 \u30A5 \u30A7 \u30A9 \u30E3 \u30E5 \u30E7 \u30F2    {bksp}',
      '  \u30A3         ',
      '           ',
      '{shift} \u30C3        \u30FB  {shift}',
      '{space}',
    ],
  },
} as LayoutItem
