import { CollapsableTitledCard, OrderAdjustments, OrderSubtotal, OrderTaxes } from '@dominos/components'
import { useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DetailRow } from '../../detail-row'
import Surcharges from '../../surcharges/surcharges'
import { AboutPricing } from './about-pricing'
import css from './order-details-card.less'
import { LoyaltyCompletionTrack } from '../../../loyalty/loyaltyTwo/LoyaltyCompletionTrack'
import { OrderDetailsBasketItem } from './order-details-basket-item'
import { OrderDetailsOrderButton } from './order-details-order-button'
import { OrderDetailedSummary } from './order-detailed-summary'

export interface OrderDetailsCardProps {
  testID: string
  orderData?: Partial<BasketData>
  basketError?: Error | string | null
  onPlaceOrder: () => void
  removeLine?: (lineItem: BasketCoupon | BasketLineItem) => void
  editLine?: BasketLineProps['onEdit']
  hideOrderButton?: boolean
  hideEditButton?: boolean
  hideSwapButton?: boolean
  hideRemoveButton?: boolean
  loading?: boolean
}

export const OrderDetailsCard = ({
  testID,
  orderData,
  basketError,
  onPlaceOrder,
  removeLine,
  editLine,
  hideOrderButton = false,
  hideEditButton = false,
  hideSwapButton = false,
  hideRemoveButton = false,
  loading,
}: OrderDetailsCardProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('menu')
  const [
    showMinimumOrderValueEnabled,
    showVoucherNotBestValueErrorMessage,
    showAboutOurPricingMessage,
    showOrderTaxes,
    basketSummaryDetailedPricing,
  ] = useFeatures(
    'ShowMinimumOrderValue',
    'ShowVoucherNotBestValueErrorMessage',
    'ShowAboutOurPricingMessage',
    'ShowOrderTaxes',
    'BasketSummaryDetailedPricing',
  )
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)
  const taxes = basketData.taxes || []
  const adjustments = basketData.adjustments || []
  const shouldShowOrderTaxDetails = !!basketData.priceExcludingTaxes && !!taxes.length && showOrderTaxes

  return (
    <CollapsableTitledCard
      primaryTitle={t('Order Details')}
      startCollapsed={false}
      noPadding={true}
      testID={`${testID}.order-details-card`}
    >
      <div className={css.orderDetails}>
        {(basketData.lines.length > 0 &&
          basketData.lines.map((line: BasketLine | BasketCoupon, idx: number) => (
            <OrderDetailsBasketItem
              key={idx}
              testID={testID}
              line={line}
              editLine={editLine}
              removeLine={removeLine}
              hideEditButton={hideEditButton}
              hideRemoveButton={hideRemoveButton}
              hideSwapButton={hideSwapButton}
              showVoucherNotBestValueErrorMessage={showVoucherNotBestValueErrorMessage}
            />
          ))) || (
          <div data-testid={`${testID}.prompt`} className={css.prompt}>
            {t('add some items prompt', { defaultValue: 'Add some items from the menu to get started!' })}
          </div>
        )}
        <LoyaltyCompletionTrack />
        {basketSummaryDetailedPricing ? (
          <OrderDetailedSummary testID={`${testID}.orderDetailedSummary`} basketData={basketData} />
        ) : (
          <div style={{ paddingLeft: '18px', paddingRight: '18px' }}>
            <Surcharges testID={`${testID}.surcharges`} surcharges={basketData.surcharges} />
            {shouldShowOrderTaxDetails && (
              <OrderSubtotal amount={basketData.priceExcludingTaxes!} testId={`${testID}.subtotal`} />
            )}
            {shouldShowOrderTaxDetails && <OrderTaxes taxes={taxes} testId={`${testID}.taxes`} />}
            {!!adjustments.length && <OrderAdjustments adjustments={adjustments} testId={`${testID}.adjustments`} />}
            <DetailRow testID={`${testID}.total-value`} label={t('TOTAL')} price={basketData.total} />

            {basketData.totalDiscount > 0 && (
              <DetailRow
                testID={`${testID}.discount-value`}
                label={t('YOU SAVED')}
                price={basketData.totalDiscount}
                textStyle={{ color: theme.colours.actionStandard }}
              />
            )}
            {showMinimumOrderValueEnabled && basketData.minimumPrice !== undefined && basketData.minimumPrice > 0 && (
              <DetailRow
                testID={`${testID}.minimum-order-value`}
                label={t('Minimum order amount')}
                price={basketData.minimumPrice}
                fontWeight='normal'
                textStyle={{ fontSize: '13px' }}
              />
            )}
          </div>
        )}
        {showAboutOurPricingMessage && <AboutPricing testID={testID} />}
        <OrderDetailsOrderButton
          testID={testID}
          basketError={basketError}
          onPlaceOrder={onPlaceOrder}
          hideOrderButton={hideOrderButton}
          loading={loading}
        />
      </div>
    </CollapsableTitledCard>
  )
}
