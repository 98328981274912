import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import classnames from 'classnames'
import { isNativeApp } from '@dominos/business/functions'
import { ErrorNotifier, OrderDetailsPill, RoundUpForCharityCheckout } from '@dominos/components'
import { DeliveryInstructions, detailsFieldNames } from '@dominos/components/checkout'
import { MyDetailsForm } from '@dominos/components/checkout/checkout-container'
import { DeliveryWCAAddressFields } from '@dominos/components/checkout/checkout-container/details-container/delivery-wca-address-fields/delivery-wca-address-fields'
import {
  AdyenPaymentMethodExtended,
  AdyenState,
} from '@dominos/components/checkout/checkout-container/payment-container/payment-method/adyen/payment-adyen.interface'
import { useAdyenInstance } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/adyen/use-adyen-instance'
import { PaymentOverlay } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/payment-overlay/payment-overlay'
import { useCorrectOrderId } from '@dominos/components/checkout/checkout-container/payment-container/use-correct-order-id'
import { renderInlineSingleItemOffer } from '@dominos/components/checkout/common/render-single-item-offer'
import { TermsAndConditionsGeneric } from '@dominos/components/terms-and-conditions'
import {
  GenericCardPopup,
  noop,
  PaymentSetting,
  useBasketValidation,
  useCheckoutDetails,
  useCheckoutMyDetails,
  useCheckoutPlaceOrder,
  useFeatures,
  useKiosk,
  usePaymentAmount,
  useReport,
} from '@dominos/hooks-and-hocs'
import css from './express-checkout-order-popup.less'

interface ExpressCheckoutOrderPopupProps extends BaseProps {
  adyenPaymentMethod: AdyenPaymentMethodExtended
  paymentSetting: PaymentSetting
  onClose: () => void
  donationEnabled: boolean
}

// despite Adyen component can be mounted once DOM element is available,
// an immediate mount will not succeed and cause the follow-up update to fail.
// this delay is to ensure the DOM element can be used before mounting.
const MOUNT_DELAY = 500

/* eslint-disable max-lines-per-function */
export const ExpressCheckoutOrderPopup = ({
  testID,
  adyenPaymentMethod,
  paymentSetting,
  onClose,
  donationEnabled,
}: ExpressCheckoutOrderPopupProps) => {
  const { t } = useTranslation(['checkout', 'menu'])
  const { orderId } = useCorrectOrderId()
  const orderPaymentId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderPaymentId)
  const { reportDetailsPrefilled } = useReport()
  const [checkoutOffersEnabled] = useFeatures('CheckoutMyDetailsOffers')
  const { roundUpAmount, adyenAmount, paymentAmount } = usePaymentAmount(donationEnabled)
  const { isKioskOrder } = useKiosk()
  const { isBasketValid } = useBasketValidation()
  const {
    rememberMyDetailsState,
    handleMyDetailsChange,
    customerDetails,
    serviceMethod,
    setDeliveryInstructions,
    saveCustomerDetails,
    saveCustomerOrderDetails,
  } = useCheckoutMyDetails()

  const { isAdditionalDetailsValid, isMyDetailsValid, setIsAdditionalDetailsValid, setIsMyDetailsValid } =
    useCheckoutDetails()

  const [adyenComponentState, setAdyenComponentState] = useState<AdyenState>()
  const [triggerAutoPlaceOrder, setTriggerAutoPlaceOrder] = useState(false)
  const placeOrderHookForAddressRef = useRef<() => void>(noop)

  const shouldOrderButtonDisabled = !isMyDetailsValid || !isAdditionalDetailsValid
  const methodId = `${paymentSetting.paymentMethod}-${adyenPaymentMethod.type}`
  const elementId = `adyen-${methodId}-component-container`
  const { isMethodAvailable, adyenComponentMounted, mountAdyenInstance, updateAdyenInstance, paymentMethodProperties } =
    useAdyenInstance(
      { adyenPaymentMethod, paymentSetting },
      { elementId, orderId, orderPaymentId, adyenAmount, adyenComponentState },
      { setAdyenComponentState, setTriggerAutoPlaceOrder },
    )
  const { showProcessingOverlay, initAndPlaceOrder } = useCheckoutPlaceOrder({
    adyenPaymentMethodType: adyenPaymentMethod.type,
    paymentDetails: {
      properties: paymentMethodProperties,
      paymentMethod: paymentSetting.paymentMethod,
      providerCode: paymentSetting.providerCode,
      amount: paymentAmount,
    },
    adyenPaymentMethodObj: adyenComponentState?.data.paymentMethod,
  })

  const handleComplete = useCallback((): boolean => {
    placeOrderHookForAddressRef.current()
    if (!isBasketValid() || shouldOrderButtonDisabled) return false
    saveCustomerDetails()
    saveCustomerOrderDetails()

    return true
  }, [saveCustomerDetails, saveCustomerOrderDetails, shouldOrderButtonDisabled])

  useEffect(() => {
    if (!!customerDetails?.name) {
      reportDetailsPrefilled()
      handleMyDetailsChange(detailsFieldNames.rememberMyDetails, true)
      setIsMyDetailsValid(true)
    }

    if (serviceMethod === 'Pickup') {
      setIsAdditionalDetailsValid(true)
    }
  }, [])

  useEffect(() => {
    if (isMethodAvailable) {
      setTimeout(() => {
        mountAdyenInstance()
      }, MOUNT_DELAY)
    }
  }, [isMethodAvailable])

  useEffect(() => {
    if (adyenComponentMounted) {
      updateAdyenInstance({
        onClick: (resolve: () => void) => {
          handleComplete() && resolve()
        },
      })
    }
  }, [adyenComponentMounted, handleComplete])

  useEffect(() => {
    if (triggerAutoPlaceOrder) {
      initAndPlaceOrder()
      setTriggerAutoPlaceOrder(false)
    }
  }, [triggerAutoPlaceOrder])

  return (
    <ErrorNotifier namespace='checkout'>
      {showProcessingOverlay && <PaymentOverlay showProcessing={true} />}
      <GenericCardPopup testID='express-checkout-popup' popupTitle='' suppressBackgroundScroll={true} onClose={onClose}>
        <h2 className={css.popupTitle} data-testid={`${testID}.title`}>
          {t('Order Details', { defaultValue: 'Order Details', ns: 'menu' })}
        </h2>
        <hr className={css.hr} />
        <div className={css.wrapper}>
          <MyDetailsForm
            initialDetails={customerDetails}
            initialRememberDetails={rememberMyDetailsState}
            onChange={handleMyDetailsChange}
            readOnly={true}
            testID={`${testID}.my-details`}
            hideEmptyErrorContainer={true}
          />
        </div>

        {serviceMethod === 'Delivery' && (
          <DeliveryWCAAddressFields
            testID={`${testID}.delivery-address`}
            placeOrderHookForAddressRef={placeOrderHookForAddressRef}
            className={classnames(css.wrapper, css.wcaAddressContainer)}
          />
        )}
        <div className={classnames(css.wrapper, css.orderDetailsPill)}>
          {!isNativeApp() && !isKioskOrder && (
            <OrderDetailsPill testID={testID} disableClick={true} isExpressCheckout={true} />
          )}
        </div>
        {checkoutOffersEnabled && (
          <div className={classnames(css.wrapper, css.inlineOfferItem)}>
            {renderInlineSingleItemOffer(`${testID}.checkout-offers`, true)}
          </div>
        )}
        {donationEnabled && (
          <div className={classnames(css.wrapper, css.roundUpForCharity)}>
            <RoundUpForCharityCheckout testID={`${testID}`} amount={roundUpAmount} />
          </div>
        )}
        {serviceMethod === 'Delivery' && (
          <div className={classnames(css.wrapper, css.deliveryInstructions)}>
            <DeliveryInstructions testID={`${testID}.delivery-instructions`} onChange={setDeliveryInstructions} />
          </div>
        )}
        <div className={classnames(css.wrapper, css.link)}>
          <TermsAndConditionsGeneric
            testID={`${testID}.terms-and-conditions`}
            text={t('ExpressCheckoutT&C')}
            forceBlock
            isHyperlink={false}
          />
        </div>
        <div className={css.wrapper} style={{ position: 'relative' }}>
          {shouldOrderButtonDisabled && <div className={css.payButtonOverlay} role='none'></div>}
          <div
            id={elementId}
            data-testid={elementId}
            style={{ pointerEvents: shouldOrderButtonDisabled ? 'none' : 'auto' }}
            role='button'
            aria-label='Place Order'
            aria-disabled={shouldOrderButtonDisabled}
          />
        </div>
      </GenericCardPopup>
    </ErrorNotifier>
  )
}
