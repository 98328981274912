import { createUseClasses } from 'olo-ux'

export const useStyles = createUseClasses((theme) => ({
  tile: {
    borderWidth: theme.borders.width.thick,
    borderColor: theme.colors.border.primaryAlpha,
    borderStyle: 'solid',
    borderRadius: theme.borders.radius.md,
    marginBottom: theme.size[200],
  },
}))
