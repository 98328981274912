import { useCurrentOrderDetails, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'

import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getCreateAccountSceneProps } from './helper'

const useNavigation = () => {
  const { t } = useTranslation('login')
  const location = useLocation()
  const navigate = useNavigate()
  const { reportLogin } = useReport()

  const [customer, updateCustomerForNavigation] = useState<Bff.SocialLogins.SocialLogin | undefined>()
  const [enableLongLived, setEnableLongLived] = useState<boolean | undefined>()
  const { orderId } = useCurrentOrderDetails()

  const redirectTo = (location.state as { redirectTo?: string })?.redirectTo

  useEffect(() => {
    const redirect = async () => {
      if (customer) {
        if (customer?.customerId) {
          await reportLogin({
            enableLongLived,
            order_id: orderId,
            status: 'success',
            identityProvider: 'Google',
            url: window.location.href,
            authenticationSource: 'Legacy',
            customerId: customer.customerId,
          })
          await navigate(NavigationConstants.home)
        } else {
          await navigate(NavigationConstants.createAccount, {
            state: getCreateAccountSceneProps(redirectTo, t, {
              name: `${customer.firstName} ${customer.lastName}`,
              email: customer.email,
              id: customer.identityProviderId,
            }),
          })
        }
      }
    }

    redirect()
  }, [customer])

  return {
    updateCustomerForNavigation: (socialLogin: Bff.SocialLogins.SocialLogin | undefined, enableLongLived?: boolean) => {
      setEnableLongLived(enableLongLived)
      updateCustomerForNavigation(socialLogin)
    },
  }
}

export default useNavigation
