import React from 'react'
import { DetailsContainer, PaymentContainer } from '../'

export const CondensedCheckoutContainer: React.FC<{ onComplete: () => Promise<void> }> = ({ onComplete }) => {
  const [paymentCardCollapsed, setPaymentCardCollapsed] = React.useState<boolean>(true)
  const [detailsCardCollapsed, setDetailsCardCollapsed] = React.useState<boolean>(false)

  const onDetailsComplete = async () => {
    await onComplete()

    setPaymentCardCollapsed(false)
    setDetailsCardCollapsed(true)
  }

  return (
    <div data-testid='condensed-checkout-container'>
      <DetailsContainer testID={'details-container'} collapsed={detailsCardCollapsed} onComplete={onDetailsComplete} />
      <PaymentContainer collapsed={paymentCardCollapsed} />
    </div>
  )
}
