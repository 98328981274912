import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { NavigationConstants } from '@dominos/navigation'
import { useCurrentOrderStore } from '@dominos/hooks-and-hocs'

const pathsToIgnoreStoreNo = [
  NavigationConstants.home,
  NavigationConstants.storeSearch,
  NavigationConstants.deliveryAddressList,
  NavigationConstants.deliveryAddress,
  NavigationConstants.deliveryAddressAddress,
  NavigationConstants.deliveryAddressSearch,
  NavigationConstants.deliveryAddressSearchList,
  NavigationConstants.addressSearchResults,
]

export const useLegalUrl = () => {
  const { pathname } = useLocation()
  const { t } = useTranslation('footer')
  const currentStore = useCurrentOrderStore()

  if (pathsToIgnoreStoreNo.includes(pathname)) {
    return t('LegalUrl', {
      storeNo: '',
    })
  }

  return t('LegalUrl', {
    storeNo: currentStore?.storeNo,
  })
}
