import { useBreakpoints, useForgeRock } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavigationConstants } from '@dominos/navigation'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import { useClasses } from './my-password.styles'
import { Button } from 'olo-ux'

const MyPasswordForgeRock = () => {
  const { t } = useTranslation('login')
  const { updatePasswordForgeRock } = useForgeRock()
  const { isMobile } = useBreakpoints()
  const classes = useClasses({ isMobile })

  const onForgeRockPasswordChange = async () => {
    if (isNativeApp()) {
      notifyNativeApp('update-oauth-password')

      return
    }
    await updatePasswordForgeRock(`${NavigationConstants.myAccount}/password`)
  }

  return (
    <div className={classes.subContainer}>
      {!isNativeApp() && (
        <h2 className={classes.heading}>{t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}</h2>
      )}

      <p className={classes.paragraph}>
        {t('MyAccountForgeRockPasswordDescription', {
          defaultValue: 'To change your password, please click on the button to be redirected',
        })}
      </p>

      <Button
        onPress={onForgeRockPasswordChange}
        type='primary'
        size='medium'
        label={t('ChangePassword', { defaultValue: 'Change Password' })}
        iconRight='open-in-new'
      />
    </div>
  )
}

export { MyPasswordForgeRock }
