import { createUseClasses } from 'olo-ux'

export const useStyles = createUseClasses((theme) => ({
  title: {
    ...theme.text.heading.xl.bold,
    textTransform: 'uppercase',
    marginBottom: theme.size[200],
    marginTop: 0,
  },
  container: {
    padding: theme.size[400],
    flex: 1,
  },
  text: {
    ...theme.text.body.lg.book,
    color: theme.colors.content.secondary,
    margin: 0,
    marginBottom: theme.size[800],
  },
  sheetText: {
    ...theme.text.body.lg.book,
    padding: theme.size[400],
    color: theme.colors.content.secondary,
  },
  modal: {
    maxWidth: 340,
  },
}))
