import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'

export const useLocationExtended = () => {
  const useLocationProps = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(useLocationProps.search), [useLocationProps.search])

  return {
    ...useLocationProps,
    searchParams,
  }
}
