import { createUseClasses } from 'olo-ux'

export const useClasses = createUseClasses((theme, { isMobile }: { isMobile: boolean }) => ({
  container: {
    padding: theme.size[400],
  },
  heading: {
    ...theme.text.heading.xl.bold,
    textTransform: 'uppercase',
    marginBottom: theme.size[200],
    marginTop: 0,
  },
  paragraph: {
    ...theme.text.body.lg.book,
    color: theme.colors.content.secondary,
    margin: 0,
    marginBottom: theme.size[isMobile ? 400 : 800],
  },
  tile: {
    borderWidth: theme.borders.width.thick,
    borderColor: theme.colors.border.primaryAlpha,
    borderStyle: 'solid',
    borderRadius: theme.borders.radius.md,
    marginBottom: theme.size[200],
  },
  content: {
    ...theme.text.body.lg.book,
    color: theme.colors.content.secondary,
    margin: theme.size[400],
  },
}))
