import { isNativeApp } from '@dominos/business/functions/native-app'
import { NavigationConstants } from '@dominos/navigation'

export const getPaymentReturnUrl = (orderId: string, orderPaymentId: string, googleId?: string) => {
  const url = new URL(`${NavigationConstants.checkoutProcessing}/${orderId}/${orderPaymentId}`, window.location.origin)

  if (googleId) {
    url.searchParams.set('ga_redirect_id', googleId)
  }

  if (isNativeApp()) {
    url.pathname = `${NavigationConstants.nativeAppCheckoutProcessing}/${orderId}/${orderPaymentId}`
  }

  return url.toString()
}
