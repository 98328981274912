import { useState } from 'react'

export const useTextFieldValidation = (
  defaultValue: string = '',
  validationRules: ValidationFunction<string>[],
): [string, (value: string) => void, string | null, boolean] => {
  const [value, setValue] = useState(defaultValue)
  const [touched, setTouched] = useState(false)

  const handleChange = (newValue: string) => {
    setValue(newValue)
    setTouched(true)
  }

  const error = validationRules.reduce<string | null>((acc, rule) => acc || rule(value), null)

  return [value, handleChange, error, touched]
}
