import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SpotlightContext, useHighlightElement } from '@dominos/hooks-and-hocs/spotlight'
import { Modal } from '@dominos/components'
import css from './spotlight.less'
import { SpotlightKey } from './spotlight-keys'

type ElementsToHighlightMap = {
  [key in SpotlightKey]?: {
    elementToHighlight: HTMLElement
    contentToDisplay: JSX.Element
  }
}

interface SpotlightProviderProps {
  children?: React.ReactNode
}

export const SpotlightProvider: React.FC<SpotlightProviderProps> = ({ children }: { children?: React.ReactNode }) => {
  const [spotlightKey, setSpotlightKey] = useState<SpotlightKey>('')
  const elementsToHighlight = useRef<ElementsToHighlightMap>()

  const { isHighlight, highlightSingleElement, removeHighlightSingleElement } = useHighlightElement()

  const contentToDisplay = useMemo(
    () => (elementsToHighlight.current ?? {})[spotlightKey]?.contentToDisplay ?? null,
    [spotlightKey],
  )

  const elementToHighlight = useMemo(
    () => (elementsToHighlight.current ?? {})[spotlightKey]?.elementToHighlight ?? null,
    [spotlightKey],
  )

  const registerToSpotlight = (element: HTMLElement, content: JSX.Element): SpotlightKey => {
    const spotlightKey = element.getAttribute('data-spotlight-key') as SpotlightKey

    if (!spotlightKey) {
      throw Error('Element is missing data-spotlight-key attribute')
    }

    elementsToHighlight.current = {
      ...elementsToHighlight.current,
      [spotlightKey]: {
        elementToHighlight: element,
        contentToDisplay: content,
      },
    }

    return spotlightKey
  }

  const unregisterFromSpotlight = (key: SpotlightKey) => {
    if (elementsToHighlight.current?.hasOwnProperty(key)) {
      delete elementsToHighlight.current[key]
    }
  }

  const displaySpotlight = (key: SpotlightKey) => {
    setSpotlightKey(key)
  }

  const hideSpotlight = () => {
    setSpotlightKey('')
  }

  const isSpotlightOn = (key?: SpotlightKey) => isHighlight && (key ? spotlightKey === key : true)

  useEffect(() => {
    if (spotlightKey === '') {
      Object.values(elementsToHighlight.current || {}).forEach(({ elementToHighlight }) =>
        removeHighlightSingleElement(elementToHighlight),
      )
    }
  }, [spotlightKey])

  useEffect(() => {
    if (!!elementToHighlight) {
      highlightSingleElement(elementToHighlight)
    }
  }, [elementToHighlight])

  return (
    <SpotlightContext.Provider
      value={{
        displaySpotlight,
        registerToSpotlight,
        unregisterFromSpotlight,
        hideSpotlight,
        isSpotlightOn,
        spotlightKey,
      }}
    >
      {children}
      {isHighlight && !!contentToDisplay && (
        <Modal testID='spotlight' suppressBackgroundScroll childrenContainerClassNames={css.spotlightContainer}>
          {contentToDisplay}
        </Modal>
      )}
    </SpotlightContext.Provider>
  )
}
