import React from 'react'
import { useNavigate } from 'react-router-dom'

import { StaticSvgIcon } from '@dominos/res'
import { useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'

import css from './address-info.less'

interface AddressInfoProps extends BaseProps {
  address: DeliveryAddressRequest
  showClearButton?: boolean
}

export const AddressInfo = ({ testID, address, showClearButton }: AddressInfoProps) => {
  const navigate = useNavigate()
  const theme = useDominosTheme()
  const [autoCompleteListMapDeliveryAddress] = useFeatures('AutoCompleteListMapDeliveryAddress')

  return (
    <div data-testid={testID} className={css.wrapper}>
      <div data-testid={`${testID}.icon`} className={css.icon}>
        <StaticSvgIcon
          name={'storeDetailsDelivery'}
          isUnstyled
          fill={theme.colours.defaultButtonText}
          width={30}
          height={30}
        />
      </div>

      {
        //TODO: temporary solution until we make structured address mapping for in the same way for predictions and geocoding response https://dominos.atlassian.net/browse/CIT-763
      }
      {autoCompleteListMapDeliveryAddress ? (
        <p data-testid={`${testID}.subtitle`} className={css.subtitle}>
          {[address.structuredAddress.title, address.structuredAddress.subtitle].join(' ')}
        </p>
      ) : (
        <div>
          <p data-testid={`${testID}.title`} className={css.title}>
            {address.structuredAddress.title}
          </p>
          <p data-testid={`${testID}.subtitle`} className={css.subtitle}>
            {address.structuredAddress.subtitle}
          </p>
        </div>
      )}

      {/* TODO: add correct icon */}
      {showClearButton && (
        <StaticSvgIcon
          name={'cross'}
          isUnstyled
          fill={theme.colours.defaultButtonText}
          width={30}
          height={30}
          onClick={() => navigate(-1)}
        />
      )}
    </div>
  )
}
