import React, { useEffect, useState } from 'react'
import { useLastOrderCheck } from '@dominos/hooks-and-hocs/last-order-handler'
import { NavigationConstants } from '@dominos/navigation'
import { useNavigate } from 'react-router-dom'

interface LastOrderHandlerProps {
  children?: React.ReactNode
}

export const LastOrderHandler: React.FC<LastOrderHandlerProps> = ({ children }) => {
  const [waitingForNavigation, setWaitingForNavigation] = useState(true)
  const { startOnMenu, loading } = useLastOrderCheck()
  const navigate = useNavigate()

  useEffect(() => {
    if (startOnMenu) {
      navigate(NavigationConstants.menu, { state: { userHasStartedJourneyOnMenu: true } })
      setWaitingForNavigation(false)
    } else if (!loading) {
      setWaitingForNavigation(false)
    }
  }, [startOnMenu, loading])

  return <>{!loading && !waitingForNavigation && <>{children}</>}</>
}
