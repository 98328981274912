import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import { checkStatesAreEqual, isCurrentOrderValid } from '@dominos/business/functions/validation/validate-current-order'
import { rootActions } from '@dominos/business/root.actions'
import { NavigationConstants } from '@dominos/navigation'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isDeepLinkPathName } from '../deep-links'

// Hack alert: I believe this was created to solve issues with state management
// which is a problem on olo.web as too much is stored in redux in localstorage.
// and therefore needs to be manually/specifically managed.
// Once a strategy is defined and implemented to deal with state better,
// this sort of state resetting may be able to be removed as it adds complexity.
export const useOrderValidation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector((state: RootReducer) => state, checkStatesAreEqual)
  const validateAndRedirect = () => {
    if (isCurrentOrderValid(state)) {
      return
    }

    if (isNativeApp()) {
      notifyNativeApp('error')

      return
    }

    dispatch(rootActions.restartOrder())

    if (!isDeepLinkPathName(window.location.pathname)) {
      navigate(NavigationConstants.home)
    }
  }

  useEffect(() => {
    validateAndRedirect()
  }, [])
}
