import { MenuPageHeader, useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { useContext } from 'react'
import { MenuContext } from './menu-provider'

interface UseMenuResponse {
  menu?: Menu
  menuPages?: MenuPageHeader[]
  itemsByCode?: MenuItemDependentsDictionary | null
  disclaimers?: string[]
  termsAndConditions?: string
  errorOccurred?: boolean
  loadMenu: () => void
  refetchMenu: () => void
  menuLoading?: boolean
  storeNo?: number | undefined
}

const useMenu = (): UseMenuResponse => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error("'useMenu' must be a child of 'MenuProvider'")
  }

  const { menu, menuPages, itemsByCode, disclaimers, termsAndConditions, errorOccurred, menuLoading } = context.data
  const { fetchMenu, refetchMenu } = context.methods

  const { basketHeaderData: header } = useCurrentOrderDetails()

  const loadMenu = () => {
    if (header && header.serviceMethod && header.storeNo && header.time !== undefined) {
      fetchMenu({
        storeNo: header.storeNo,
        serviceMethod: header.serviceMethod,
        serviceMethodSubtype: header.serviceMethodSubType,
        requestTime: header.time,
      })
    }
  }

  return {
    menu,
    menuPages,
    itemsByCode,
    disclaimers,
    termsAndConditions,
    errorOccurred,
    loadMenu,
    refetchMenu,
    menuLoading,
    storeNo: header?.storeNo,
  }
}

export { UseMenuResponse, useMenu }
