import { ApolloError, useMutation } from '@apollo/client'
import { customerMutation } from '@dominos/business/queries'
import { useCountryCode, useCustomer, useLanguages, useReport } from '@dominos/hooks-and-hocs'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs/selectors/use-current-order-details'
import { AccountDetails } from './account-details'
import { ErrorHandler } from './error-handler'
import { Button } from 'olo-ux'
import { useStyles } from './my-details.styles'
import { useBreakpoints } from '@dominos/hooks-and-hocs'
import { useSnackbar } from '@dominos/components/snackbar'

const MyDetails = () => {
  const { isMobile } = useBreakpoints()
  const style = useStyles({ isMobile })
  const oldAccountDetails = useRef<Bff.Customers.Customer | undefined>()
  const [accountDetailsValid, setAccountDetailsValid] = useState(false)
  const [errorForHandler, setErrorForHandler] = useState<ApolloError | undefined>()
  const { showSnackbar } = useSnackbar()
  const [accountDetails, setAccountDetails] = useState<
    Pick<Bff.Customers.UpsertCustomer, 'name' | 'phoneNumber' | 'alternateName'>
  >({
    name: 'Name',
    phoneNumber: 'Phone number',
    alternateName: 'Alternate name',
  })
  const { reportAccountWasUpdated } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const { t } = useTranslation('login')
  const { customer } = useCustomer()
  const [updateCustomerAccount, { loading, error, data }] = useMutation<
    { customer: Bff.Customers.Customer },
    { input: Bff.Customers.UpsertCustomer }
  >(customerMutation)
  const countryCode = useCountryCode()
  const { language } = useLanguages()
  const shouldShowAlternateName = useMemo(() => countryCode === 'JP' && language === 'ja', [countryCode, language])

  useEffect(() => {
    if (customer) {
      oldAccountDetails.current = customer
      setAccountDetails({
        name: customer.name,
        phoneNumber: customer.phoneNumber,
        alternateName: customer.alternateName,
      })
    }
  }, [customer])

  useEffect(() => {
    if (data) {
      reportAccountWasUpdated({ orderId, success: true, fields: {} })
    }

    if (!!error) {
      setErrorForHandler(error)
      reportAccountWasUpdated({ orderId, success: false, fields: {} })
    }

    if (data && !error) {
      showSnackbar({ message: t('AccountDetailsUpdated', { defaultValue: 'Account details updated successfully' }) })
    }
  }, [data, error])

  if (!customer) {
    return <></>
  }

  const detailsChanged =
    accountDetails.name !== oldAccountDetails.current?.name ||
    accountDetails.phoneNumber !== oldAccountDetails.current?.phoneNumber ||
    accountDetails.alternateName !== oldAccountDetails.current?.alternateName

  const onFormValidationChange = (isValid: boolean) => setAccountDetailsValid(isValid)

  const onDetailsChange = (field: string, value: string | undefined) => {
    setErrorForHandler(undefined)
    setAccountDetails((prev) => ({ ...prev, [field]: value }))
  }

  const updateDetails = async () => {
    if (accountDetailsValid) {
      await updateCustomerAccount({
        variables: {
          input: {
            id: customer.id,
            name: accountDetails.name,
            alternateName: accountDetails.alternateName,
            email: customer.email,
            phoneNumber: accountDetails.phoneNumber,
          },
        },
      }).catch(() => null)
      // ^ see https://github.com/apollographql/react-apollo/issues/2614#issuecomment-530803601
    }
  }

  return (
    <div className={style.container}>
      <div className={style.subContainer}>
        <h2 className={style.heading}>{t('AccountDetailsHeading', { defaultValue: 'My Details' })}</h2>
        <p className={style.paragraph}>
          {t('AccountDetailsDescription', { defaultValue: 'To update your details, edit the information below:' })}
        </p>

        <AccountDetails
          details={{ ...accountDetails, email: customer.email }}
          shouldShowAlternateName={shouldShowAlternateName}
          onValidationChange={onFormValidationChange}
          onChange={onDetailsChange}
        />
      </div>
      <div className={isMobile ? style.buttonContainer : undefined}>
        <Button
          onPress={updateDetails}
          disabled={!accountDetailsValid || loading || !detailsChanged}
          type='primary'
          size='medium'
          label={t('SaveChanges', { defaultValue: 'Save Changes' })}
        />
      </div>

      <ErrorHandler apolloError={errorForHandler} />
    </div>
  )
}

export { MyDetails }
