import { ValidationTextField } from '@dominos/components'
import { useMobileValidationRules } from '@dominos/hooks-and-hocs'
import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

interface MobileFieldProps {
  onChange: (updatedField: { [key: string]: ValidationField }) => void
  fieldName: string
  value?: string
  defaultValue?: string
  style?: CSSProperties
  hideEmptyErrorContainer?: boolean
  allowInitialValueResetWhenUndefined?: boolean
}

export const MobileField = (props: MobileFieldProps) => {
  const { t } = useTranslation('login')
  const { mobileValidationRules } = useMobileValidationRules()

  return (
    <ValidationTextField
      key={props.fieldName}
      fieldName={props.fieldName}
      placeholder={t('MobileNumberLabel', { defaultValue: 'Mobile Number' })}
      testID={`${props.fieldName}.field`}
      validationRules={mobileValidationRules}
      onChange={props.onChange}
      value={props.value}
      defaultValue={props.defaultValue}
      style={props.style}
      type='number'
      inputMode='tel'
      hideEmptyErrorContainer={props.hideEmptyErrorContainer}
      allowInitialValueResetWhenUndefined={props.allowInitialValueResetWhenUndefined}
    />
  )
}
