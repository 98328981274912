import { createContext, useContext } from 'react'
import { SnackbarContextProps } from './snackbar-context.interface'

export const SnackbarContext = createContext<SnackbarContextProps>({ showSnackbar: () => {}, hideSnackbar: () => {} })

export const useSnackbar = () => {
  const context = useContext(SnackbarContext)
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider')
  }

  return context
}
