import { ActionButton } from '@dominos/components'
import { createProductUpsellCardViewModel } from '@dominos/components/product/product-upsell/product-upsell-component-banner/create-product-upsell-card-view-model'
import { BannerMedia, UpsellItem } from '@dominos/components/product/product-upsell/product-upsell.interface'
import {
  withProductUpsellWrapper,
  WithProductUpsellWrapperProps,
} from '@dominos/components/product/product-upsell/with-product-upsell-wrapper/with-product-upsell-wrapper'
import { StaticSvgIcon } from '@dominos/res'
import React from 'react'
import { TFunction } from 'react-i18next'
import css from './product-upsell-component-banner.less'

interface ProductUpsellComponentBannerProps {
  testID?: string
  t: TFunction<'menu'>
  upsellItem: UpsellItem<BannerMedia>
}

const ProductUpsellComponentBanner: React.FC<ProductUpsellComponentBannerProps & WithProductUpsellWrapperProps> = ({
  t,
  testID,
  upsellItem,
  onUpsellAccepted,
  onUpsellDeclined,
}) => {
  const viewModel = createProductUpsellCardViewModel({ t: t, upsellItem })

  const handleUpsellConfirmation = () => {
    if (upsellItem) {
      onUpsellAccepted(upsellItem, viewModel.upsellName)
    }
  }

  const handleUpsellBannerRejection = () => {
    onUpsellDeclined()
  }

  return (
    <section data-testid={testID} className={css.upsellContainer}>
      <span className={css.imgColumn}>
        <img src={viewModel.bannerImageUrl} className={css.image} />
        <span data-testid='close-button' className={css.iconButtonContainerStyle} onClick={handleUpsellBannerRejection}>
          <StaticSvgIcon name={'crossCloseDark'} isUnstyled />
        </span>
      </span>
      <span className={css.titleContainer}>
        <span className={css.title}>{viewModel.upsellName}</span>
        <ActionButton
          testID={`${testID}.upgrade-button`}
          className={css.upgradeButton}
          onPress={handleUpsellConfirmation}
          text={viewModel.upgradeButtonText}
          textSize={16}
          textFontWeight={'bold'}
          textStyle={{ color: '#ffffff', textAlign: 'center' }}
        />
      </span>
    </section>
  )
}

const WrappedProductUpsellComponentBanner = withProductUpsellWrapper(ProductUpsellComponentBanner)

export { WrappedProductUpsellComponentBanner as ProductUpsellComponentBanner }
