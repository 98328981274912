import { useCallback, useEffect, useRef } from 'react'

const useFocus = (onDismiss: () => void) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const handleGlobalClick = useCallback(
    (event: MouseEvent) => !containerRef.current?.contains(event.target as Node) && onDismiss && onDismiss(),
    [onDismiss],
  )

  useEffect(() => {
    document.body.addEventListener('click', handleGlobalClick, { capture: true })

    return () => {
      document.body.removeEventListener('click', handleGlobalClick, { capture: true })

      return onDismiss && onDismiss()
    }
  }, [])

  return containerRef
}

export { useFocus }
