import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavigationConstants } from '@dominos/navigation'
import { useCurrentOrderDetails, useForgeRock, useReport, useSessionStorage } from '@dominos/hooks-and-hocs'
import { ReportLoginData } from '@dominos/hooks-and-hocs/logging'
import { idTokenUtils } from '@dominos/business/functions/customer/id-token-utils'
import { FORGEROCK_CONSTANT } from '@dominos/business'

type ReportingStatusType = ReportLoginData['status']

const authenticationSource = 'ForgeRock'

export const useProcessAuthCallback = () => {
  const location = useLocation()
  const isOAuthCallbackPath = location.pathname === FORGEROCK_CONSTANT.CALLBACK_PATH
  const [status, setStatus] = useState<'loading' | 'idle'>(isOAuthCallbackPath ? 'loading' : 'idle')
  const navigate = useNavigate()
  const { orderId } = useCurrentOrderDetails()
  const { reportLogin } = useReport()
  const { tokenExchange, logoutForgeRock, getForgeRockTokenFromStorage, forceFetchForgeRockToken } = useForgeRock()
  const { deleteToken, saveToken } = idTokenUtils()
  const { storedValue: previousPage, clearValue } = useSessionStorage({ key: 'forgerock-callback-path' })
  const { search } = location

  useEffect(() => {
    const handleCallback = async () => {
      if (!isOAuthCallbackPath) {
        setStatus('idle')

        return
      }
      const params = new URLSearchParams(search)
      const [code, state, error, accountDeleted] = ['code', 'state', 'error', 'account_deleted'].map((key) =>
        params.get(key),
      )

      if (error) {
        deleteToken()
        setStatus('idle')
      }

      if (accountDeleted) {
        handleDeleteAccountCallback()

        return
      }

      if (!code || !state) {
        navigateToPreviousPage()

        return
      }

      const accessToken = (await tokenExchange(code, state)) ?? (await retryFetchToken())

      if (!accessToken) {
        reportData('fail', {
          customerId: undefined,
          status_reason: 'Failed to get ForgeRock auth token',
          payload: { code, state },
        })
      } else {
        saveToken(accessToken)
        reportData('success')
      }

      navigateToPreviousPage()
    }

    handleCallback()

    return () => {
      clearValue()
    }
  }, [search, isOAuthCallbackPath])

  // When forgot password journey continues on a new tab it doesn't return valid token
  // In that case we need to fetch token again
  // https://backstage.forgerock.com/support/tickets?id=104732
  const retryFetchToken = async () => (await forceFetchForgeRockToken()) ?? (await getForgeRockTokenFromStorage())

  // Navigates back to the page where the user initiated the auth process
  const navigateToPreviousPage = () => {
    setStatus('idle')
    navigate(previousPage || NavigationConstants.home, { replace: true })
  }

  const handleDeleteAccountCallback = () => {
    deleteToken()
    logoutForgeRock()
    setStatus('idle')
    navigate(NavigationConstants.home, { replace: true })
  }

  const reportData = (
    status: ReportingStatusType,
    data?: Pick<ReportLoginData, 'customerId' | 'status_reason' | 'payload'>,
  ) => {
    const logPayload: ReportLoginData = {
      status,
      identityProvider: 'ForgeRock',
      authenticationSource,
      url: window.location.href,
      order_id: orderId,
      status_error: data?.status_reason,
      customerId: data?.customerId,
      payload: data?.payload,
    }
    reportLogin(logPayload)
  }

  return {
    loading: status === 'loading',
  }
}
