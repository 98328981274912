/* eslint-disable @typescript-eslint/no-explicit-any, no-console */
import { NetworkError } from '@apollo/client/errors'
import { onError } from '@apollo/client/link/error'
import { ServerError } from '@apollo/client/link/utils'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { GraphQLFormattedError } from 'graphql'

const CRITICAL_ERROR_PROMPT = `Sorry, we’re currently experiencing technical issues. You may be redirected.`

const config = getCountryConfig()
export const handleErrors = (
  operation: { operationName: string; variables: Record<string, any> },
  graphQLErrors: readonly GraphQLFormattedError[] | undefined,
  networkError: NetworkError | undefined,
  securitySender: SecuritySender,
) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ extensions, message, locations, path }) =>
      securitySender(
        'ApolloClientError.GraphQLError',
        {
          message,
          path: path && (path as (string | number)[]),
          graphOperationName: operation.operationName,
          code: extensions?.code,
          exception: extensions?.exception,
          locations: JSON.stringify(locations),
        },
        true,
      ),
    )

    if (__DEV__) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}, OperationName: ${operation.operationName}, variables: ${JSON.stringify(
            operation.variables,
          )}`,
        ),
      )
    }
  }
  if (networkError) {
    securitySender(
      'ApolloClientError.NetworkError',
      {
        graphOperationName: operation.operationName,
        response: (networkError as ServerError).response,
        statusCode: (networkError as ServerError).statusCode,
        timeout: (networkError as any).timeout,
        message: networkError.message,
      },
      true,
    )

    if (__DEV__) {
      console.log(
        `[Network error]: ${networkError}, OperationName: ${operation.operationName}, variables: ${JSON.stringify(
          operation.variables,
        )}`,
      )
    }

    if (
      config.ROLLOUT &&
      (networkError as ServerError).statusCode === 418 &&
      document.cookie.length > 0 &&
      confirm(CRITICAL_ERROR_PROMPT)
    ) {
      const expirationDate = new Date()
      const cookies = decodeURIComponent(document.cookie).split(';')

      cookies.forEach((cookie) =>
        cookie.replace(/^\s+/, '').replace(/=.*/, `=;expires=${expirationDate.toUTCString()};path=/`),
      )

      window.location.replace('/')
    }
  }
}

export const errorLink = (securitySender: SecuritySender) =>
  onError(({ graphQLErrors, networkError, operation }) =>
    handleErrors(operation, graphQLErrors, networkError, securitySender),
  )
