/* eslint-disable no-redeclare */

import React, { CSSProperties, useMemo } from 'react'
import { ActionButton, IListGroupCollection, IListGroupCollectionItem } from '@dominos/components'
import {
  DeliverySearchViewMode,
  IAddressPredictionDisplay,
  IBaseDeliveryAddressComponentProps,
  IUseFallbackSearchComponentProps,
  NavigationAction,
  useFallbackSearchComponent,
} from 'olo-feature-address'
import { ListGroupCollection } from '@dominos/components/lists/list-group/list-group-collection'
import { DominosTheme, useDominosTheme } from '@dominos/hooks-and-hocs'

const TEST_ID = 'delivery-address-predictions-results'

export interface DeliveryAddressPredictions extends Pick<IBaseDeliveryAddressComponentProps, 'deliveryAddressChange'> {
  predictions: IAddressPredictionDisplay[]
  onSelectAddress: (addressId: string) => void
  navigationAction: (navigationAction: NavigationAction) => void
  showFallbackSearchButton: boolean
  isLoading: boolean
  itemLoadingKey: string | undefined
  viewMode: DeliverySearchViewMode
  toggleViewMode: () => void
  searchInput?: string
}

export const DeliveryAddressPredictions = (props: DeliveryAddressPredictions) => {
  const {
    predictions,
    onSelectAddress,
    showFallbackSearchButton,
    navigationAction,
    isLoading,
    itemLoadingKey,
    viewMode,
    toggleViewMode,
    searchInput,
    deliveryAddressChange,
  } = props
  const theme = useDominosTheme()
  const fallbackSearchComponentProps: IUseFallbackSearchComponentProps = {
    navigationAction,
    viewMode,
    toggleViewMode,
    searchInput,
    deliveryAddressChange,
  }
  const fallbackSearchButton = useFallbackSearchComponent(fallbackSearchComponentProps)

  const style = useMemo(() => getStyle(theme), [theme])

  if (predictions.length === 0 && !showFallbackSearchButton) return <></>

  return (
    <div style={style.container}>
      <ListGroupCollection
        collection={mapPredictionsToListGroupCollection(predictions)}
        onPress={onSelectAddress}
        showBorderAboveTitle={false}
        showBorderOnFirstItem={false}
        testID={TEST_ID}
        isLoading={isLoading}
        itemLoadingKey={itemLoadingKey}
      />
      {showFallbackSearchButton && (
        <div data-testid={`${TEST_ID}.fallbacksearch.button`} style={style.fallbackSearchButtonWrapper}>
          <ActionButton
            testID={`${TEST_ID}.button`}
            onPress={fallbackSearchButton.onPress}
            text={fallbackSearchButton.text}
            textStyle={style.fallbackSearchButton}
            containerStyle={style.fallbackButtonContainer}
            textFontWeight={'semibold'}
          />
        </div>
      )}
    </div>
  )
}

const mapPredictionsToListGroupCollection = (predictions: IAddressPredictionDisplay[]): IListGroupCollection => ({
  title: undefined,
  prefix: '',
  items: predictions.map((prediction, index) => mapPredictionToIListGroupCollectionItem(prediction, index)),
})

const mapPredictionToIListGroupCollectionItem = (
  prediction: IAddressPredictionDisplay,
  index: number,
): IListGroupCollectionItem => ({
  id: prediction.addressId,
  index: index,
  title: prediction.title,
  subtitle: prediction.subtitle,
  icon: prediction.icon,
  suffix: prediction.suffix,
})

interface DeliveryAddressPredictionsStyle {
  container: CSSProperties
  fallbackSearchButtonWrapper: CSSProperties
  fallbackSearchButton: CSSProperties
  fallbackButtonContainer: CSSProperties
}

const getStyle = (theme: DominosTheme): DeliveryAddressPredictionsStyle => ({
  container: {
    backgroundColor: theme.colours.defaultBackground,
    margin: '-16px 0 0 0',
  },
  fallbackSearchButtonWrapper: {
    flex: 1,
  },
  fallbackButtonContainer: {
    backgroundColor: theme.colours.deemphasisedBlue,
    width: '100%',
    marginTop: '8px',
  },
  fallbackSearchButton: {
    color: theme.colours.actionBlue,
  },
})
