import { TFunction } from 'i18next'
import { ErrorDefinition, ErrorDefinitions } from '@dominos/interfaces/errors'
import { genericErrorDefinitions } from '../generic-errors'
import { ErrorType } from '../error'

enum GenericErrors {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
}

export const getMappedErrorToDefinition = (
  code: string,
  source: 'Graph' | 'Network',
  errorDefinitions: ErrorDefinitions,
  tns: TFunction,
  tValidation: TFunction,
): ErrorType => {
  const errorCode = code

  // has custom error defined
  if (errorDefinitions[errorCode]) {
    const mappedError: ErrorDefinition = errorDefinitions[errorCode]

    return {
      ...mappedError,
      translatedError: tns(mappedError.translation.key, mappedError.translation.options),
    }
  }

  // matches generic error code
  if (genericErrorDefinitions[errorCode]) {
    const mappedError: ErrorDefinition = genericErrorDefinitions[errorCode]

    return {
      ...mappedError,
      translatedError: tValidation(mappedError.translation.key, mappedError.translation.options),
    }
  }

  // does not match known error
  const otherError: ErrorDefinition =
    genericErrorDefinitions[source === 'Graph' ? GenericErrors.UNKNOWN_ERROR : GenericErrors.NETWORK_ERROR]

  return {
    ...otherError,
    translatedError: tValidation(otherError.translation.key, otherError.translation.options),
  }
}
