/* eslint-disable max-lines-per-function */
import { MutableRefObject, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { useBasketValidation, useCustomer, useDictionary, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import { NextStep } from '@dominos/hooks-and-hocs/checkout/place-order.interface'
import { AdyenCheckoutConfig, AdyenPaymentMethodExtended, AdyenState, ReturnUrl } from '../payment-adyen.interface'
import { useAdyenConfig } from '../use-adyen-config'
import { useCustomerCheckoutDetails } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/use-customer-checkout-details'

export const HandledByAdditionalDetails: string = 'handled_by_additional_details'

export const useAdyenCheckoutConfig = (
  adyenPaymentMethod: AdyenPaymentMethodExtended,
  adyenComponentState: AdyenState | undefined,
  returnUrl: MutableRefObject<ReturnUrl>,
  setAdyenComponentState?: React.Dispatch<React.SetStateAction<AdyenState | undefined>>,
  paymentNextStep?: NextStep,
  setHidePaymentButton?: React.Dispatch<React.SetStateAction<boolean>>,
  setTriggerAutoPlaceOrder?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { i18n, t } = useTranslation('checkout')
  const { isBasketValid } = useBasketValidation()
  const { isLoggedIn } = useCustomer()
  const config = getCountryConfig()
  const customerCheckoutDetails = useCustomerCheckoutDetails()
  const { reportPaymentCompleted } = useReport()
  const [savePaymentEnabled] = useFeatures('SavePaymentAdyenOnCheckout')
  const adyenCoreConfig = useAdyenConfig(adyenPaymentMethod, returnUrl, setAdyenComponentState)
  const [paymentMethodProperties, updatePaymentMethodProperties] = useDictionary([
    {
      key: 'browserInfo',
      value: JSON.stringify({
        userAgent: window.navigator.userAgent,
        acceptHeader: '*/*',
      }),
    },
    { key: 'origin', value: window.location.origin },
  ])

  const handleAdditionalDetails = (state: AdyenState) => {
    reportPaymentCompleted(undefined, 'handleAdditionalDetails')
    const url = new URL(returnUrl.current.additionalDetails)
    url.searchParams.append(HandledByAdditionalDetails, 'true')
    url.searchParams.append('payload', state.data.details!.payload)
    window.location.href = url.toString()
  }

  const [adyenCheckoutConfig, setAdyenCheckoutConfig] = useState<AdyenCheckoutConfig>({
    onPaymentCompleted: adyenCoreConfig.onPaymentCompleted,
    onError: adyenCoreConfig.onError,
  })

  const updateAdyenCheckoutConfig = (options: AdyenCheckoutConfig) => {
    setAdyenCheckoutConfig((prevConfig) => ({
      ...prevConfig,
      ...(typeof options === 'object' ? options : {}),
    }))
  }

  useEffect(() => {
    updatePaymentMethodProperties([
      { key: 'savePayment', value: (!!adyenComponentState?.data.storePaymentMethod).toString() },
    ])
  }, [adyenComponentState?.data.storePaymentMethod])

  useEffect(() => {
    switch (adyenPaymentMethod.type) {
      case 'scheme':
      case 'bcmc':
        updateAdyenCheckoutConfig({
          enableStoreDetails: isLoggedIn && savePaymentEnabled,
          hasHolderName: true,
          holderNameRequired: true,
          hideCVC: adyenPaymentMethod.type === 'bcmc',
          onBrand: (data: { brand: string }) => {
            updatePaymentMethodProperties([{ key: 'brand', value: data.brand }])
          },
          onBinValue: (data: { binValue: string }) => {
            updatePaymentMethodProperties([{ key: 'binValue', value: data.binValue }])
          },
        })
        setHidePaymentButton!(false)
        break
      case 'directEbanking':
      case 'ideal':
      case 'bcmc_mobile':
      case 'mobilepay':
      case 'paypal':
      case 'paynow':
      case 'duitnow':
      case 'grabpay_MY':
      case 'grabpay_SG':
      case 'afterpaytouch':
      case 'touchngo':
      case 'molpay_ebanking_fpx_MY':
      case 'paypay':
        setHidePaymentButton!(false)
        setAdyenComponentState!({
          data: {
            paymentMethod: { type: adyenPaymentMethod.type },
          },
          isValid: true,
        })

        if (adyenPaymentMethod.type === 'duitnow') {
          updateAdyenCheckoutConfig({
            countdownTime: 5, // mins
          })
        }

        updateAdyenCheckoutConfig({
          showPayButton: false,
          onAdditionalDetails:
            adyenPaymentMethod.type === 'bcmc_mobile' ||
            adyenPaymentMethod.type === 'paynow' ||
            adyenPaymentMethod.type === 'duitnow' ||
            adyenPaymentMethod.type === 'molpay_ebanking_fpx_MY'
              ? handleAdditionalDetails
              : undefined,
        })
        break
      case 'applepay':
      case 'paywithgoogle':
      case 'googlepay':
      case 'clicktopay':
        const tempAdyenCheckoutConfig = {
          countryCode: config.COUNTRY,
          onChange: (state: AdyenState) => {
            setAdyenComponentState!(state)
            setTriggerAutoPlaceOrder!(true)
          },
          onSubmit: (_: AdyenState) => {
            // This is needed to avoid Adyen component triggering the onError event
          },
          onClick: (resolve: () => void) => {
            if (isBasketValid()) {
              resolve()
            }
          },
        }

        if (adyenPaymentMethod.type === 'applepay') {
          updateAdyenCheckoutConfig({
            ...tempAdyenCheckoutConfig,
            supportedNetworks: ['amex', 'masterCard', 'visa'],
          })
        } else if (adyenPaymentMethod.type === 'clicktopay') {
          updateAdyenCheckoutConfig({
            ...tempAdyenCheckoutConfig,
            merchantDisplayName: t('ClickToPayDisplayName', {
              defaultValue: "Domino's Pizza Enterprises Ltd",
            }),
            shopperEmail: customerCheckoutDetails.emailAddress,
          })
        } else {
          updateAdyenCheckoutConfig({
            ...tempAdyenCheckoutConfig,
            buttonLocale: i18n.language.substring(0, 2),
          })
        }
        break
      case 'giftcard':
        setHidePaymentButton!(false)
        updateAdyenCheckoutConfig({
          onChange: (state: AdyenState) => {
            setAdyenComponentState!(state)
          },
        })
        break
    }
  }, [paymentNextStep, customerCheckoutDetails?.emailAddress])

  return {
    adyenCoreConfig,
    paymentMethodProperties,
    adyenCheckoutConfig,
    updateAdyenCheckoutConfig,
  }
}
