import React from 'react'
import { useTranslation } from 'react-i18next'
import { AddressList, GenericCard } from '@dominos/components'
import styles from './search-list-container.less'

interface SearchListContainerProps {
  addresses: AddressLine[]
  onLinePress: (addressLine: AddressLine) => void
}

export const SearchListContainer = (props: SearchListContainerProps) => {
  const { t } = useTranslation('delivery-address')

  return (
    <GenericCard
      testID='search-list-container.card'
      title={t('PostCodeChomeListTitle', { defaultValue: 'Select Your Chome' })}
    >
      {props.addresses && props.addresses.length > 0 ? (
        <AddressList
          testID='address-list'
          addresses={props.addresses}
          onPress={props.onLinePress}
          swapNameAndAddress={true}
          narrowHeight={true}
        />
      ) : (
        <div data-testid='none-found-message' className={styles.notFoundMessage}>
          {t('NoPostCodeFoundMessage', { default: 'None Found' })}
        </div>
      )}
    </GenericCard>
  )
}
