import { ErrorDefinitionsType, getDefaultValue } from '@dominos/components/error'

export const profileUpdateErrorDefinitions: ErrorDefinitionsType = {
  UNABLE_TO_RESOLVE: {
    displayType: 'popup',
    translation: {
      message: {
        key: 'CreateAccountGenericErrorText',
        getOptions: () => getDefaultValue('Something has gone wrong. Please try agaifadfadfdasn'),
      },
    },
    testID: 'unable-to-resolve-error',
  },
  BAD_USER_INPUT: {
    displayType: 'popup',
    translation: {
      message: {
        key: 'CreateAccountBackEndValidationErrorText',
        getOptions: () =>
          getDefaultValue('You have entered incorrect information. Please change your details and try again'),
      },
    },
    testID: 'bad-user-input-error',
  },
}
