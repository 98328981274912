import { getNativeFeatures } from '@dominos/business/functions/native-app'
import { useFeatures } from '@dominos/hooks-and-hocs/features'

export const useAdditionalDeliveryAddressSearchFeatures = () => {
  const nativeAppAutoCompleteDeliveryAddress = getNativeFeatures('AutoCompleteDeliveryAddress')
  const nativeAppAutoCompleteDisplayAdditionalFieldsCheckout = getNativeFeatures(
    'AutoCompleteDeliveryAddressFieldsDisplayCheckout',
  )
  const nativeAppAutoCompleteDeliveryAddressFieldsHidden = getNativeFeatures('AutoCompleteDeliveryAddressFieldsHidden')
  const nativeDeliveryAddressSearchFeature = getNativeFeatures('DeliveryAddressSearch-Feature')
  const nativeDeliveryAddressSearchShowAdditionalFieldsCheckout = getNativeFeatures(
    'DeliveryAddressSearch-ShowAdditionalFieldsCheckout',
  )

  const [
    webAutoCompleteDeliveryEnabled,
    webAutoCompleteDisplayAdditionalFieldsCheckout,
    webAutoCompleteDeliveryAddressFieldsHidden,
    webDeliveryAddressSearchFeature,
    webDeliveryAddressSearchShowAdditionalFieldsCheckout,
  ] = useFeatures(
    'AutoCompleteDeliveryAddress',
    'AutoCompleteDeliveryAddressFieldsDisplayCheckout',
    'AutoCompleteDeliveryAddressFieldsHidden',
    'DeliveryAddressSearch-Feature',
    'DeliveryAddressSearch-ShowAdditionalFieldsCheckout',
  )

  const autoCompleteDeliveryEnabled = webAutoCompleteDeliveryEnabled || nativeAppAutoCompleteDeliveryAddress
  const autoCompleteDisplayAdditionalFieldsCheckout =
    webAutoCompleteDisplayAdditionalFieldsCheckout || nativeAppAutoCompleteDisplayAdditionalFieldsCheckout
  const autoCompleteDeliveryAddressFieldsHidden =
    webAutoCompleteDeliveryAddressFieldsHidden || nativeAppAutoCompleteDeliveryAddressFieldsHidden
  const deliveryAddressSearchFeature = webDeliveryAddressSearchFeature || nativeDeliveryAddressSearchFeature
  const deliveryAddressSearchShowAdditionalFieldsCheckout =
    webDeliveryAddressSearchShowAdditionalFieldsCheckout || nativeDeliveryAddressSearchShowAdditionalFieldsCheckout

  return {
    autoCompleteDeliveryEnabled,
    autoCompleteDisplayAdditionalFieldsCheckout,
    autoCompleteDeliveryAddressFieldsHidden,
    deliveryAddressSearchFeature,
    deliveryAddressSearchShowAdditionalFieldsCheckout,
  }
}
