import { createUseClasses } from 'olo-ux'

export const useStyles = createUseClasses((theme) => ({
  navigationBase: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingBottom: 0,
  },
}))
