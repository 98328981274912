import {
  Legends,
  ProductDescription,
  ProductEditor,
  ProductEditorContentWrapper,
  ProductImage,
  useProductContext,
} from '@dominos/components'
import { isBaseGlutenFree } from '@dominos/components/product/functions'
import { ProductNutritionalCard } from '@dominos/components/product/product-nutritional-feature-product'
import { useBreakpoints, useKiosk, useMarket } from '@dominos/hooks-and-hocs'
import React from 'react'
import { BaseProductEditorProps } from './base-product-editor.interface'
import css from './base-product-editor.less'

export const BaseProductEditor = ({
  testID,
  onDismiss,
  formattedPrice,
  formattedPromoPrice,
  children,
  t,
}: BaseProductEditorProps) => {
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isMobileLayout = isMobile || isKioskOrder
  const { getMediaForLegend } = useMarket()

  const { productData, dimensionSetState } = useProductContext()

  const getLegends = (): MenuLegend[] =>
    productData?.legends?.reduce((acc, legend) => {
      const media = getMediaForLegend(legend?.code)
      if (media?.icon?.uri) {
        acc.push({
          code: legend?.code ?? '',
          image: { url: media?.icon?.uri ?? '' },
          text: media?.description?.value ?? '',
        })
      }

      return acc
    }, [] as MenuLegend[]) ?? []

  const legends: MenuLegend[] = getLegends()

  const energyKj = productData
    ?.getNutritionals('Weighted', dimensionSetState?.selectedDimensionSet)
    ?.nutritionals.find((nutritional) => nutritional.code === 'EnergyKj')?.value

  return (
    <ProductEditor
      testID={`${testID}.product-editor`}
      onDismiss={onDismiss}
      showBackButton={!!isMobileLayout}
      showCloseButton={!isMobileLayout}
    >
      <ProductImage testID={testID} uri={productData?.media?.largeImage?.uri ?? ''}></ProductImage>
      <ProductEditorContentWrapper testID={`${testID}.basic-product-card.children`} className={css.baseEditorContent}>
        <Legends className={css.legend} legends={legends} testID={`${testID}.legends`}></Legends>
        <ProductDescription
          testID={testID}
          media={productData?.media}
          t={t}
          formattedPrice={formattedPrice}
          formattedPromoPrice={formattedPromoPrice}
          energyKJ={energyKj}
          showGlutenFreeDisclaimer={isBaseGlutenFree(dimensionSetState)}
        ></ProductDescription>
        {children}
        <ProductNutritionalCard t={t} testID='nutritional-card' />
      </ProductEditorContentWrapper>
    </ProductEditor>
  )
}
