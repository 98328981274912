import {
  ProductUpsellComponentType,
  ProductUpsellZoneId,
  UpsellFilter,
  UpsellType,
} from '@dominos/components/product/product-upsell/product-upsell.interface'

export const mapUpsellZoneIdToUpsellFilter = (zoneId: ProductUpsellZoneId): UpsellFilter[] | undefined => {
  switch (zoneId) {
    case ProductUpsellZoneId.ProductBaseUpsellBanner:
      return [
        {
          componentType: ProductUpsellComponentType.Crust,
          upsellType: UpsellType.Banner,
          allowedLayout: 'ProductList',
          locationType: 'ProductDetails',
        },
      ]
    case ProductUpsellZoneId.ProductUpsellPopup:
      return [
        {
          componentType: ProductUpsellComponentType.Crust,
          upsellType: UpsellType.Popup,
          allowedLayout: 'ProductList',
          locationType: 'ProductDetails',
        },
        {
          componentType: ProductUpsellComponentType.Sauce,
          upsellType: UpsellType.Popup,
          allowedLayout: 'ProductList',
          locationType: 'ProductDetails',
        },
        {
          componentType: ProductUpsellComponentType.Topping,
          upsellType: UpsellType.Popup,
          allowedLayout: 'ProductList',
          locationType: 'ProductDetails',
        },
      ]
    case ProductUpsellZoneId.ProductToppingUpsellList:
      return [
        {
          componentType: ProductUpsellComponentType.Topping,
          upsellType: UpsellType.List,
          allowedLayout: 'ProductIngredientList',
          locationType: 'ProductDetails',
        },
      ]
    default:
      return undefined
  }
}
