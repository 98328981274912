import { ApolloError } from '@apollo/client'

export const getErrorCodeFromApolloError = (error?: ApolloError) => {
  let statusCode = null

  if (error && error.graphQLErrors?.length) {
    statusCode = error.graphQLErrors.map(({ extensions }) => extensions?.code).join('\n')
  }

  return statusCode
}
