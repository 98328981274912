import { useCurrentOrderDetails, useFeatures } from '@dominos/hooks-and-hocs'
import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isNativeApp } from '@dominos/business/functions'
import { NativeAppFosData } from '@dominos/interfaces'
import { rootActions } from '@dominos/business'

interface FosSessionImportProps {
  children?: React.ReactNode
}

export const FosSessionImport: React.FC<FosSessionImportProps> = ({ children }) => {
  const location = useLocation()
  const { advanceOrder } = useCurrentOrderDetails()
  const [isFosFeatureEnabled] = useFeatures('IsFosVersionThreeEnabled')
  const dispatch = useDispatch()
  const nativeAppFosData = window.nativeAppFosData as NativeAppFosData

  useEffect(() => {
    if (isNativeApp() && nativeAppFosData && advanceOrder.id !== nativeAppFosData?.id && isFosFeatureEnabled) {
      dispatch(rootActions.importFosSessionFromNativeApp(nativeAppFosData))
    }
  }, [location, isFosFeatureEnabled])

  return <>{children}</>
}
