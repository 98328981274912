import { Operation } from '@apollo/client'

export const X_TRUE_CLIENT_IP = 'x-true-client-ip'

export const ipAddressHandler = (headers: Headers, operation: Operation) => {
  const ipAddress: string | null = headers.get(X_TRUE_CLIENT_IP)

  if (ipAddress && operation.operationName === 'kioskConfig' && window.newrelic?.setCustomAttribute) {
    window.newrelic.setCustomAttribute('ipAddress', ipAddress, true)
  }
}
