import {
  ErrorNotifier,
  ErrorScope,
  SimpleNavigationTitle,
  StackNavigationBase,
  TimedOrderPickerContainer,
  useErrorContext,
} from '@dominos/components'
import { useBreakpoints, useCurrentStoreIsLoading, useTimedOrder } from '@dominos/hooks-and-hocs'
import { useCurrentStore, useServiceMethod } from '@dominos/hooks-and-hocs/selectors'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { CSSProperties, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import externalStyles from './timed-order-scene.less'
import { useTranslation } from 'react-i18next'

const TimedOrderSceneWrapper = () => {
  const { fetchOrderingTimeSlots, openingHours: openingHours, loading: loading, error: error } = useTimedOrder()
  const locationId = useSelector((state: RootReducer) => state.customerReducer.searchPickupStoreLocationId)
  const { notifyError } = useErrorContext()
  const { state } = useLocation()
  const serviceMethod = useServiceMethod()
  const currentStore = useCurrentStore()
  const { isMobile } = useBreakpoints()
  const isCurrentStoreLoading = useCurrentStoreIsLoading()
  const { t, i18n } = useTranslation('timed-order')
  const navigate = useNavigate()

  const isAsapAvailable = useMemo(
    () =>
      (openingHours?.currentStoreStatus === 'Available' ||
        openingHours?.currentStoreStatus === 'ServiceMethodDiverted') ??
      false,
    [openingHours?.currentStoreStatus],
  )

  const navigationData: TimedOrderData | undefined = state as TimedOrderData

  const navigateBackAction = (): boolean => {
    if (navigationData?.navigationBackPath) {
      navigate(navigationData.navigationBackPath)

      return true
    }

    return false
  }

  useEffect(() => {
    if (!isCurrentStoreLoading && !currentStore) {
      navigate(-1)
    }
  }, [currentStore, isCurrentStoreLoading])

  useEffect(() => {
    if (serviceMethod && currentStore) {
      fetchOrderingTimeSlots(currentStore.storeNo, serviceMethod, locationId)
    }
  }, [currentStore, serviceMethod])

  useEffect(() => {
    if (error) {
      notifyError({
        error: error,
        scope: ErrorScope.TimedOrder,
        definitions: {},
        handlers: {},
      })
    }
  }, [error, openingHours?.currentStoreStatus])

  const styles = buildStyles(isMobile)

  if (!serviceMethod || !currentStore) {
    return null
  }

  return (
    <StackNavigationBase
      testID='store-search-scene'
      header={<SimpleNavigationTitle showAccount showBackButton onBack={navigateBackAction} />}
    >
      <div className={externalStyles.outer}>
        <div className={externalStyles.container} style={styles.container}>
          <TimedOrderPickerContainer
            testID='timed-order-picker-container'
            isAsapAvailable={isAsapAvailable}
            serviceMethod={serviceMethod}
            currentStore={currentStore}
            openingHours={openingHours}
            loading={loading}
            t={t}
            language={i18n.language}
          />
        </div>
      </div>
    </StackNavigationBase>
  )
}

interface Styles {
  container: CSSProperties
}

const magicStyles = {
  mobile: {
    staticCardWidth: 360,
  },
  desktop: {
    staticCardWidth: 376,
  },
}

const buildStyles = (isMobile: boolean): Styles => ({
  container: {
    maxWidth: isMobile ? magicStyles.mobile.staticCardWidth : magicStyles.desktop.staticCardWidth,
  },
})

export const TimedOrderScene = () => (
  <ErrorNotifier>
    <TimedOrderSceneWrapper />
  </ErrorNotifier>
)

export interface TimedOrderData {
  navigationBackPath?: string
}
