import { useNavigate } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { MenuNavigationProps, NavigationLogo, SimpleNavigationTitle } from '@dominos/components'
import { useHasTopMenuWrapped } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import less from '../menu-navigation.less'
import { MenuPageTab } from './menu-page-tab'

export const MenuNavCategories = (props: MenuNavigationProps) => {
  const { menu, showMenu } = props
  const topMenuWrapped = useHasTopMenuWrapped()
  const dispatch = useDispatch()
  const { t } = useTranslation('menu')
  const navigate = useNavigate()

  const handleOnBack = () => {
    dispatch(rootActions.viewedProductOnMobile(false))
    navigate(NavigationConstants.menu)

    return true
  }

  return (
    <>
      {topMenuWrapped ? (
        <SimpleNavigationTitle
          showBackButton={props.showBackButton}
          backScreen={t('Menu', { defaultValue: 'Menu' })}
          onBack={handleOnBack}
          forceMobileLayout
          evenLogoWidth
        />
      ) : (
        <section className={`${less.desktopTopSection}`}>
          <div className={less.logo}>
            <NavigationLogo />
          </div>

          {showMenu && menu && menu.pages && (
            <ul className={`${less.tabs} ${less.group}`}>
              {menu.pages.map((page, index) => (
                <MenuPageTab key={index} {...props} menuPage={page} />
              ))}
            </ul>
          )}

          <div className={less.empty} />
        </section>
      )}
    </>
  )
}
