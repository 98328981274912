import {
  ErrorDefinition,
  ErrorDefinitionCode,
  GenericErrorCodes,
  isNotifiableErrorEvent,
  NotifiableErrorEvent,
  NotifiableEvent,
  NotifiableStatusEvent,
} from '../interfaces'
import { genericErrors, networkErrors } from '../definitions'

const getErrorDefinitionForNotifiableError = (notifiableError: NotifiableErrorEvent): ErrorDefinition => {
  const { error, definitions } = notifiableError
  const errorCode = error?.graphQLErrors[0]?.extensions?.code as ErrorDefinitionCode

  const allErrorDefinitions = {
    ...genericErrors,
    ...definitions,
  }

  if (errorCode && allErrorDefinitions[errorCode]) {
    return allErrorDefinitions[errorCode] as ErrorDefinition
  } else if (error.networkError) {
    return networkErrors[GenericErrorCodes.NETWORK_ERROR] as ErrorDefinition
  }

  return genericErrors[GenericErrorCodes.UNKNOWN_ERROR] as ErrorDefinition
}

const getErrorDefinitionForStatus = (notifiableError: NotifiableStatusEvent): ErrorDefinition => {
  const { definitions } = notifiableError

  return definitions as ErrorDefinition
}

export const getErrorDefinition = (notifiableError: NotifiableEvent): ErrorDefinition => {
  if (isNotifiableErrorEvent(notifiableError)) {
    return getErrorDefinitionForNotifiableError(notifiableError as NotifiableErrorEvent)
  } else {
    return getErrorDefinitionForStatus(notifiableError as NotifiableStatusEvent)
  }
}
