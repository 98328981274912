import React from 'react'

import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 25,
  height: 25,
}

const DeliveryInstructionsIcon = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 11.2C6 10.7582 6.35817 10.4 6.8 10.4H13.2C13.6418 10.4 14 10.7582 14 11.2C14 11.6418 13.6418 12 13.2 12H6.8C6.35817 12 6 11.6418 6 11.2Z'
        fill='#007AFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 8C6 7.55817 6.35817 7.2 6.8 7.2H13.2C13.6418 7.2 14 7.55817 14 8C14 8.44183 13.6418 8.8 13.2 8.8H6.8C6.35817 8.8 6 8.44183 6 8Z'
        fill='#007AFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6 4.8C6 4.35817 6.35817 4 6.8 4H13.2C13.6418 4 14 4.35817 14 4.8C14 5.24183 13.6418 5.6 13.2 5.6H6.8C6.35817 5.6 6 5.24183 6 4.8Z'
        fill='#007AFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 4.8C2 4.35817 2.35817 4 2.8 4H3.6C4.04183 4 4.4 4.35817 4.4 4.8C4.4 5.24183 4.04183 5.6 3.6 5.6H2.8C2.35817 5.6 2 5.24183 2 4.8Z'
        fill='#007AFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 8C2 7.55817 2.35817 7.2 2.8 7.2H3.6C4.04183 7.2 4.4 7.55817 4.4 8C4.4 8.44183 4.04183 8.8 3.6 8.8H2.8C2.35817 8.8 2 8.44183 2 8Z'
        fill='#007AFF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 11.2C2 10.7582 2.35817 10.4 2.8 10.4H3.6C4.04183 10.4 4.4 10.7582 4.4 11.2C4.4 11.6418 4.04183 12 3.6 12H2.8C2.35817 12 2 11.6418 2 11.2Z'
        fill='#007AFF'
      />
    </svg>
  )
}
export { DeliveryInstructionsIcon }
