import React from 'react'
import { InlineSingleItemOffer } from '@dominos/components'

export const renderInlineSingleItemOffer = (testID: string, compactView: boolean = false) => (
  <InlineSingleItemOffer
    testID={testID}
    filterLocation={{
      locationType: 'Page',
      locationCode: 'PaymentSelection',
      showBanner: true,
    }}
    compactView={compactView}
  />
)
