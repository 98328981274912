import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { rootActions } from '@dominos/business/root.actions'
import { SuccessfulOrderStatus, useCurrentOrderDetails, useLocationListener, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { isExcludedRoute } from './is-excluded-route'
import { orderWithin24Hours } from './order-within-24-hours'

export const useTrackOrResetOrder = () => {
  const navigate = useNavigate()
  const currentOrder = useCurrentOrderDetails()
  const { pathname } = useLocationListener()
  const dispatch = useDispatch()
  const { reportOrderReset } = useReport()

  const prompt = useMemo(
    () =>
      !!currentOrder.orderStatus &&
      !!SuccessfulOrderStatus.includes(currentOrder.orderStatus) &&
      !!currentOrder.basketHeaderData &&
      !!currentOrder.basketHeaderData.time &&
      orderWithin24Hours(currentOrder.basketHeaderData.time) &&
      !isExcludedRoute(pathname),
    [currentOrder, pathname],
  )

  useEffect(() => {
    if (
      !!currentOrder.orderStatus &&
      !!SuccessfulOrderStatus.includes(currentOrder.orderStatus) &&
      !!currentOrder.basketHeaderData &&
      !!currentOrder.basketHeaderData.time &&
      !orderWithin24Hours(currentOrder.basketHeaderData.time)
    ) {
      reportOrderReset(true)
      dispatch(rootActions.restartOrder())
      navigate(NavigationConstants.home)
    }
  }, [currentOrder, pathname])

  return { prompt }
}
