import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 16,
  height: 16,
}

const NumberIcon = (props: SvgIconProps) => {
  const fill = props.fill || 'white'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 16 16' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_64_1249'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <rect width='16' height='16' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_64_1249)'>
        <path
          d='M5.99999 10.6667L5.44999 12.85C5.41665 12.9944 5.34443 13.1111 5.23332 13.2C5.12221 13.2889 4.98887 13.3333 4.83332 13.3333C4.62221 13.3333 4.44999 13.25 4.31665 13.0833C4.18332 12.9167 4.14443 12.7333 4.19999 12.5333L4.66665 10.6667H2.84999C2.62776 10.6667 2.44999 10.5806 2.31665 10.4083C2.18332 10.2361 2.14443 10.0444 2.19999 9.83333C2.23332 9.67778 2.3111 9.55556 2.43332 9.46667C2.55554 9.37778 2.69443 9.33333 2.84999 9.33333H4.99999L5.66665 6.66667H3.84999C3.62776 6.66667 3.44999 6.58056 3.31665 6.40833C3.18332 6.23611 3.14443 6.04445 3.19999 5.83333C3.23332 5.67778 3.3111 5.55556 3.43332 5.46667C3.55554 5.37778 3.69443 5.33333 3.84999 5.33333H5.99999L6.54999 3.15C6.58332 3.00556 6.65554 2.88889 6.76665 2.8C6.87776 2.71111 7.0111 2.66667 7.16665 2.66667C7.37776 2.66667 7.54999 2.75 7.68332 2.91667C7.81665 3.08333 7.85554 3.26667 7.79999 3.46667L7.33332 5.33333H9.99999L10.55 3.15C10.5833 3.00556 10.6555 2.88889 10.7667 2.8C10.8778 2.71111 11.0111 2.66667 11.1667 2.66667C11.3778 2.66667 11.55 2.75 11.6833 2.91667C11.8167 3.08333 11.8555 3.26667 11.8 3.46667L11.3333 5.33333H13.15C13.3722 5.33333 13.55 5.41945 13.6833 5.59167C13.8167 5.76389 13.8555 5.95556 13.8 6.16667C13.7667 6.32222 13.6889 6.44445 13.5667 6.53333C13.4444 6.62222 13.3055 6.66667 13.15 6.66667H11L10.3333 9.33333H12.15C12.3722 9.33333 12.55 9.41945 12.6833 9.59167C12.8167 9.76389 12.8555 9.95556 12.8 10.1667C12.7667 10.3222 12.6889 10.4444 12.5667 10.5333C12.4444 10.6222 12.3055 10.6667 12.15 10.6667H9.99999L9.44999 12.85C9.41665 12.9944 9.34443 13.1111 9.23332 13.2C9.12221 13.2889 8.98888 13.3333 8.83332 13.3333C8.62221 13.3333 8.44999 13.25 8.31665 13.0833C8.18332 12.9167 8.14443 12.7333 8.19999 12.5333L8.66665 10.6667H5.99999ZM6.33332 9.33333H8.99999L9.66665 6.66667H6.99999L6.33332 9.33333Z'
          fill='#4A4A4A'
        />
      </g>
    </svg>
  )
}

export { NumberIcon }
