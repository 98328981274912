import { TFunction } from 'i18next'
import { GenericErrorCodes, isNotifiableErrorEvent, NotifiableEvent, TranslationType } from '../interfaces'

export const getErrorVariables = (notifiableEvent: NotifiableEvent, translation: TranslationType, t: TFunction) => {
  let errorCode = null
  let errorTitle = null
  let errorMessage = null

  if (isNotifiableErrorEvent(notifiableEvent)) {
    const { error } = notifiableEvent

    if (error.graphQLErrors?.length) {
      const { extensions, message } = error.graphQLErrors[0]

      errorCode = extensions?.code
      errorTitle = extensions?.title
      errorMessage = message
    } else if (error.networkError) {
      errorMessage = error.networkError.message
      errorCode = GenericErrorCodes.NETWORK_ERROR
    } else {
      errorCode = GenericErrorCodes.UNKNOWN_ERROR
      errorMessage = error.message
    }
  } else {
    const { orderStatus } = notifiableEvent
    errorCode = orderStatus
  }

  return {
    errorCode,
    errorMessage: t(translation.message.key, translation.message.getOptions(errorMessage)),
    ...(translation.confirmLabel
      ? {
          confirmLabel: t(translation.confirmLabel.key, 'Ok'),
        }
      : {}),
    ...(translation.title
      ? {
          errorTitle: t(translation.title.key, translation.title.getOptions(errorTitle)),
        }
      : {}),
  }
}
