import { useLazyQuery } from '@apollo/client'
import { quickReorderQuery } from '@dominos/business/queries'
import { useCustomer, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import { MAX_QUICK_REORDER_PRODUCTS } from '@dominos/business/constants'
import { QuickReorderQueryResponse } from '@dominos/hooks-and-hocs/quick-reorder/quick-reorder.interface'
import { useEffect } from 'react'

export const useQuickReorder = () => {
  const [quickReorder] = useFeatures('QuickReorder')
  const { customer } = useCustomer()
  const { reportQuickReorderLoaded } = useReport()

  const [fetch, { data, error, loading }] = useLazyQuery<QuickReorderQueryResponse>(quickReorderQuery)

  const fetchQuickReorderProducts = () => {
    if (!quickReorder || !customer) return

    fetch({
      variables: {
        maxProducts: MAX_QUICK_REORDER_PRODUCTS,
      },
    })
  }

  useEffect(() => {
    if (data) {
      reportQuickReorderLoaded(data?.quickReorder?.length || 0)
    }
  }, [data])

  useEffect(() => {
    fetchQuickReorderProducts()
  }, [customer])

  return {
    quickReorderProducts: data,
    error,
    loading,
  }
}
