import {
  useCurrentOrderDetails,
  useFeatures,
  useLogout,
  useReport,
  useShowAccountDetails,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation/navigation-constants'
import { NewVouchersIndicator } from '@dominos/components/new-vouchers-indicator'
import { StaticSvgIcon } from '@dominos/res'
import { useNavigate } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccountContainer } from '../account-container'
import styles from './customer-account-button.less'
import { Dropdown } from './dropdown'

const BLACK = 'black'

export const CustomerAccountButton = ({ customer }: { customer: Bff.Customers.Customer }) => {
  const navigate = useNavigate()
  const { logout, loading } = useLogout()
  const { orderId } = useCurrentOrderDetails()
  const isShowAccountDetailsEnabled = useShowAccountDetails()
  const { reportLogout } = useReport()
  const { t } = useTranslation('login')
  const [myRewards, notificationBadgeCustomerAccount] = useFeatures(
    'loyalty-backend',
    'NotificationBadgeCustomerAccount',
  )

  const onLogoutClick = async () => {
    if (loading) {
      return
    }
    await logout({
      variables: {
        orderId,
      },
    })

    await reportLogout(orderId)
  }

  const onMyAccountClick = async () => {
    navigate(NavigationConstants.myAccount)
  }

  const onMyRewardsClick = async () => {
    navigate(NavigationConstants.myRewards)
  }

  const myAccountLabel = t('CustomerMenuDropdownMyAccountLabel', { defaultValue: 'My Account' })
  const myRewardsLabel = t('CustomerMenuDropdownMyRewardsLabel', { defaultValue: 'My Rewards' })
  const logoutLabel = t('logout-button', 'Log Out')

  const STYLE = styles['drop-down-item']

  return (
    <Dropdown className={styles.list}>
      <Dropdown.Button>
        <AccountContainer testID='customer-account' title={customer.name}>
          <StaticSvgIcon name='accountNoNeck' isUnstyled />
          {notificationBadgeCustomerAccount && <NewVouchersIndicator showCount={false} />}
        </AccountContainer>
      </Dropdown.Button>
      {isShowAccountDetailsEnabled && (
        <Dropdown.Item onClick={onMyAccountClick} label={myAccountLabel} testId='my-account.button'>
          <span className={STYLE}>
            <>
              <StaticSvgIcon name={'accountNoNeck'} isUnstyled fill={BLACK} />
              {myAccountLabel}
            </>
          </span>
        </Dropdown.Item>
      )}
      {myRewards && (
        <Dropdown.Item onClick={onMyRewardsClick} label={myRewardsLabel} testId='my-rewards.button'>
          <span className={STYLE}>
            <>
              <StaticSvgIcon name={'rewardsNavIcon'} isUnstyled fill={BLACK} />
              {myRewardsLabel}
            </>
          </span>
        </Dropdown.Item>
      )}
      <Dropdown.Item onClick={onLogoutClick} label={logoutLabel} testId='logout.button'>
        <span className={STYLE} style={{ color: '#ff3b30' }}>
          <>
            <StaticSvgIcon name={loading ? 'loadingOval' : 'logout'} isUnstyled fill={loading ? BLACK : 'white'} />
            {logoutLabel}
          </>
        </span>
      </Dropdown.Item>
    </Dropdown>
  )
}
