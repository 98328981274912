import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NextStep } from '@dominos/hooks-and-hocs/checkout/place-order.interface'
import { useCheckoutDetails, useDevToggles, usePaymentAmount } from '@dominos/hooks-and-hocs'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'
import { AdyenAdditionalFields } from './payment-adyen-additional-fields'
import { useAdyenNativePayment } from './use-adyen-native-payment'
import { AdyenPaymentMethodExtended, AdyenState } from './payment-adyen.interface'
import { useAdyenInstance } from './use-adyen-instance'
import './payment-adyen.css'

declare global {
  interface WindowEventMap {
    'native-adyen-submit': CustomEvent
  }
}

interface AdyenPaymentMethodProps extends PaymentMethodProps {
  adyenPaymentMethod: AdyenPaymentMethodExtended
}

const shouldAutoMount = (methodId: string) => methodId !== 'ClickToPay-clicktopay'

export const PaymentAdyen = ({
  paymentSetting,
  adyenPaymentMethod,
  onOutstandingBalance,
  outstandingBalance,
  orderId,
  selectedPaymentSetting,
  ...props
}: AdyenPaymentMethodProps) => {
  const { isEnabled } = useDevToggles()
  const { t } = useTranslation('checkout')
  const { adyenAmount, displayAmount } = usePaymentAmount(true, outstandingBalance?.orderOutstandingBalance)
  const orderPaymentId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderPaymentId)
  const { isAdditionalDetailsValid, isMyDetailsValid } = useCheckoutDetails()

  const [adyenComponentState, setAdyenComponentState] = useState<AdyenState>()
  const [paymentNextStep, setPaymentNextStep] = useState<NextStep>()
  const [extraTransactionTokenFields, setExtraTransactionTokenFields] = useState<{ [key: string]: string }>({})
  const [hidePaymentButton, setHidePaymentButton] = useState(true)
  const [triggerAutoPlaceOrder, setTriggerAutoPlaceOrder] = useState(false)
  const methodId = `${paymentSetting.paymentMethod}-${adyenPaymentMethod.type}`
  const elementId = `adyen-${methodId}-component-container`
  const isSelected = selectedPaymentSetting?.methodId === methodId
  const shouldOrderButtonDisabled = isEnabled['two-tap-checkout'] && (!isMyDetailsValid || !isAdditionalDetailsValid)

  const {
    adyenCheckout,
    remountAdyenInstance,
    mountAdyenInstance,
    isMethodAvailable,
    adyenComponentMounted,
    paymentMethodProperties,
  } = useAdyenInstance(
    { adyenPaymentMethod, paymentSetting },
    { elementId, orderId, orderPaymentId, paymentNextStep, adyenAmount, adyenComponentState },
    { onOutstandingBalance, setAdyenComponentState, setHidePaymentButton, setTriggerAutoPlaceOrder },
  )
  const startNativePayment = useAdyenNativePayment({
    setAdyenComponentState,
    setTriggerAutoPlaceOrder,
    adyenPaymentMethod,
    adyenCheckout,
    isMethodAvailable,
    isSelected,
    adyenAmount,
  })

  const onPaymentNextStep = (nextStep?: NextStep) => {
    setPaymentNextStep(nextStep)
  }

  const handleAdditionalTransactionTokenFieldChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setExtraTransactionTokenFields({ ...extraTransactionTokenFields, [target.name]: target.value || '' })
  }

  const handleInitiateOrderError = () => {
    if (adyenPaymentMethod.type === 'clicktopay') {
      remountAdyenInstance()
    }
  }

  useEffect(() => {
    if (
      isMethodAvailable &&
      // always mount next step actions
      (paymentNextStep?.nextStepType === 'NextStepAction' ||
        // when not next step and not mounted, mount if auto mount is enabled or the method is selected
        (!paymentNextStep && !adyenComponentMounted && (shouldAutoMount(methodId) || isSelected)))
    ) {
      mountAdyenInstance()
    }
  }, [isMethodAvailable, paymentNextStep, isSelected])

  return isMethodAvailable ? (
    <PaymentMethod
      {...props}
      methodId={`${paymentSetting.paymentMethod}-${adyenPaymentMethod.type}`}
      paymentSetting={paymentSetting}
      selectedPaymentSetting={selectedPaymentSetting}
      invalid={!adyenComponentState?.isValid}
      hidePaymentButton={hidePaymentButton}
      transactionToken={JSON.stringify({
        ...adyenComponentState?.data.paymentMethod,
        ...extraTransactionTokenFields,
      })}
      properties={paymentMethodProperties}
      onPaymentNextStep={onPaymentNextStep}
      outstandingBalance={outstandingBalance}
      adyenPaymentMethodType={adyenPaymentMethod.type}
      adyenPaymentDefaultName={adyenPaymentMethod.name}
      shouldAutoPlaceOrder={triggerAutoPlaceOrder}
      paymentButtonTitle={t('Pay Now')}
      paymentButtonIncludeTotal={true}
      paymentButtonTotal={displayAmount}
      onInitiateOrderError={handleInitiateOrderError}
    >
      <AdyenAdditionalFields
        adyenPaymentMethod={adyenPaymentMethod}
        onChange={handleAdditionalTransactionTokenFieldChange}
      />
      <div style={{ position: 'relative' }} onClick={!shouldOrderButtonDisabled ? startNativePayment : undefined}>
        {shouldOrderButtonDisabled && (
          <div
            role='none'
            style={{
              cursor: 'not-allowed',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1,
            }}
          />
        )}
        <div
          id={elementId}
          data-testid={elementId}
          style={{ pointerEvents: startNativePayment || shouldOrderButtonDisabled ? 'none' : 'auto' }}
          aria-disabled={shouldOrderButtonDisabled}
        />
      </div>
    </PaymentMethod>
  ) : null
}
