import { useEffect, useRef, useState } from 'react'
import AdyenCheckout from '@adyen/adyen-web'
import ApplePay from '@adyen/adyen-web/dist/types/components/ApplePay'
import ClickToPay from '@adyen/adyen-web/dist/types/components/ClickToPay'
import GooglePay from '@adyen/adyen-web/dist/types/components/GooglePay'
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement'
import Core from '@adyen/adyen-web/dist/types/core'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { useBasketTotal, useReport } from '@dominos/hooks-and-hocs'
import { analytics } from '@dominos/hooks-and-hocs/logging/analytics'
import { NextStep } from '@dominos/hooks-and-hocs/checkout/place-order.interface'
import { PaymentSetting } from '@dominos/hooks-and-hocs/checkout/use-payments-available-at-store/use-payments-available-at-store'
import { SplitPaymentOutstandingBalance } from '../../../split-payment/split-payment'
import { getAdyenReturnUrl } from '../get-adyen-return-url'
import { AdyenCheckoutConfig, AdyenPaymentMethodExtended, AdyenState, ReturnUrl } from '../payment-adyen.interface'
import { useAdyenCheckoutConfig } from '../use-adyen-checkout-config'

const isAdyenAvailable = async (
  adyenPaymentMethodType: string,
  adyenInstance: UIElement | undefined,
): Promise<boolean | void> => {
  if (adyenPaymentMethodType === 'applepay') {
    return (adyenInstance as ApplePay).isAvailable()
  }

  if (['paywithgoogle', 'googlepay'].indexOf(adyenPaymentMethodType) !== -1) {
    return (adyenInstance as GooglePay).isAvailable()
  }

  if (adyenPaymentMethodType === 'clicktopay') {
    return (adyenInstance as ClickToPay).isAvailable()
  }

  return Promise.resolve(true)
}

interface UseAdyenInstanceProps {
  configs: {
    adyenPaymentMethod: AdyenPaymentMethodExtended
    paymentSetting: PaymentSetting
  }
  data: {
    elementId?: string
    orderId?: string
    orderPaymentId?: string
    paymentNextStep?: NextStep
    adyenAmount?: number
    adyenComponentState?: AdyenState
  }
  eventHandlers: {
    onOutstandingBalance?: (outStandingBalance: SplitPaymentOutstandingBalance) => void
    setAdyenComponentState?: React.Dispatch<React.SetStateAction<AdyenState | undefined>>
    setHidePaymentButton?: React.Dispatch<React.SetStateAction<boolean>>
    setTriggerAutoPlaceOrder?: React.Dispatch<React.SetStateAction<boolean>>
  }
}

export const useAdyenInstance = (
  { adyenPaymentMethod, paymentSetting }: UseAdyenInstanceProps['configs'],
  {
    elementId,
    orderId,
    orderPaymentId,
    paymentNextStep,
    adyenAmount,
    adyenComponentState,
  }: UseAdyenInstanceProps['data'] = {},
  {
    onOutstandingBalance,
    setAdyenComponentState,
    setHidePaymentButton,
    setTriggerAutoPlaceOrder,
  }: UseAdyenInstanceProps['eventHandlers'] = {},
) => {
  const adyenInstance = useRef<UIElement>()
  const returnUrl = useRef<ReturnUrl>({ standard: '', additionalDetails: '' })

  const { getGoogleID } = analytics()
  const countryConfig = getCountryConfig()
  const basketTotal = useBasketTotal()
  const { reportNextAction, reportOutstandingBalance } = useReport()
  const { adyenCoreConfig, adyenCheckoutConfig, paymentMethodProperties } = useAdyenCheckoutConfig(
    adyenPaymentMethod,
    adyenComponentState,
    returnUrl,
    setAdyenComponentState,
    paymentNextStep,
    setHidePaymentButton,
    setTriggerAutoPlaceOrder,
  )

  const [adyenCheckout, setAdyenCheckout] = useState<Core>()
  const [isMethodAvailable, setIsMethodAvailable] = useState(false)
  const [adyenComponentMounted, setAdyenComponentMounted] = useState(false)

  const mountAdyenInstance = () => {
    adyenInstance.current?.mount(`#${elementId}`)
    setAdyenComponentMounted(true)
  }

  const remountAdyenInstance = () => {
    adyenInstance.current?.remove()
    createAdyenInstance({ amount: { currency: countryConfig.CURRENCY_CODE, value: adyenAmount } })
    mountAdyenInstance()
  }

  const createAdyenInstance = (customConfig?: Partial<AdyenCheckoutConfig>) => {
    adyenInstance.current = adyenCheckout?.create(adyenPaymentMethod.type, {
      ...adyenCheckoutConfig,
      ...customConfig,
    })
  }

  const handlePaymentNextStep = () => {
    if (paymentNextStep?.nextStepType === 'NextStepAction') {
      const nextAction = JSON.parse(paymentNextStep.nextStepActionPayload)
      reportNextAction({ ...nextAction, userAgent: window.navigator.userAgent })
      adyenInstance.current = adyenCheckout?.createFromAction(nextAction, adyenCheckoutConfig)
    }

    if (paymentNextStep?.nextStepType === 'OutstandingBalance' && onOutstandingBalance) {
      reportOutstandingBalance(paymentNextStep.outstandingBalance)
      onOutstandingBalance({
        orderTotal: basketTotal!,
        processedPaymentName: paymentSetting.paymentMethod,
        orderOutstandingBalance: paymentNextStep.outstandingBalance,
      })
    }
  }

  const updateAdyenInstance = (props: UIElement['props']) => {
    adyenInstance.current?.update(props)
  }

  useEffect(() => {
    returnUrl.current = {
      standard: getAdyenReturnUrl(adyenPaymentMethod.type, orderId!, orderPaymentId!, false, getGoogleID()),
      additionalDetails: getAdyenReturnUrl(adyenPaymentMethod.type, orderId!, orderPaymentId!, true),
    }
  }, [adyenPaymentMethod.type, orderId, orderPaymentId])

  useEffect(() => {
    if (adyenPaymentMethod.type) {
      AdyenCheckout(adyenCoreConfig).then((instance) => setAdyenCheckout(instance))
    }
  }, [adyenPaymentMethod.type, paymentNextStep])

  useEffect(() => {
    if (adyenCheckout) {
      paymentNextStep ? handlePaymentNextStep() : createAdyenInstance()

      isAdyenAvailable(adyenPaymentMethod.type, adyenInstance.current)
        .then(() => {
          setIsMethodAvailable(true)
        })
        .catch(() => {
          setIsMethodAvailable(false)
        })
    }
  }, [adyenCheckout, adyenCheckoutConfig, paymentNextStep])

  useEffect(() => {
    if (
      adyenComponentMounted &&
      ['applepay', 'paywithgoogle', 'googlepay', 'clicktopay'].indexOf(adyenPaymentMethod.type) !== -1
    ) {
      updateAdyenInstance({ amount: { currency: countryConfig.CURRENCY_CODE, value: adyenAmount } })
    }
  }, [adyenComponentMounted, adyenAmount])

  return {
    adyenCheckout,
    mountAdyenInstance,
    remountAdyenInstance,
    updateAdyenInstance,
    isMethodAvailable,
    adyenComponentMounted,
    paymentMethodProperties,
  }
}
