import React, { CSSProperties } from 'react'
import {
  DeliverySearchViewMode,
  IUsePreConfirmDeliverySearchActionContainerProps,
  usePreConfirmDeliverySearchActionContainer,
} from 'olo-feature-address'

import { PreConfirmAddressComponent } from '../pre-confirm-address-component'

export interface DeliverySearchActionPreConfirmContainerProps extends IUsePreConfirmDeliverySearchActionContainerProps {
  viewMode: DeliverySearchViewMode
}

export const DeliverySearchActionPreConfirmContainer = (props: DeliverySearchActionPreConfirmContainerProps) => {
  const {
    navigationAction,
    displayConfirmAddressContainer,
    deliveryAddressChange,
    deliveryAddress,
    currentError,
    setCurrentError,
  } = usePreConfirmDeliverySearchActionContainer(props)

  const containerStyle = getStyles()

  if (displayConfirmAddressContainer)
    return (
      <div style={containerStyle.container}>
        <PreConfirmAddressComponent
          deliveryAddress={deliveryAddress}
          deliveryAddressChange={deliveryAddressChange}
          testID={'delivery-search-action-pre-confirm'}
          navigationAction={navigationAction}
          currentError={currentError}
          setCurrentError={setCurrentError}
        />
      </div>
    )

  return null
}

interface DeliverySearchActionConfirmStyle {
  container: CSSProperties
}

const getStyles = (): DeliverySearchActionConfirmStyle => ({
  container: {
    width: '360px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0.5px 10px 0px',
    borderRadius: '15px',
    backgroundColor: 'white',
    pointerEvents: 'auto',
    top: '8px',
  },
})
