/* eslint-disable max-lines-per-function */
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { getCustomerId } from '@dominos/business/functions/customer'
import { removeSavedPaymentMethodMutation, savedPaymentDetailsQuery } from '@dominos/business/queries'
import { CreditCard, Error, Spinner } from '@dominos/components'
import { useBreakpoints, useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { SavedPaymentProps } from 'packages/interfaces'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MySavedPaymentErrors } from './my-saved-payments-errors'
import { MySavedEdenred } from './my-saved-edenred'
import { useClasses } from './my-saved-payments.styles'
import { ListItem } from 'olo-ux'
import { RemovePaymentModalSheet } from './remove-payment-modal-sheet'
import { useSnackbar } from '@dominos/components/snackbar'

const MySavedPayments = () => {
  const { t } = useTranslation('login')
  const [paymentMethods, setPaymentMethods] = useState<Bff.Customers.CustomerPaymentMethod[] | undefined>(undefined)
  const [savedPaymentRemoved, setSavedPaymentRemoved] = useState<SavedPaymentProps>()
  const [error, setError] = useState<ApolloError | undefined>()
  const [showConfirm, setShow] = useState(false)
  const customerId = getCustomerId()
  const { orderId } = useCurrentOrderDetails()
  const { isMobile } = useBreakpoints()
  const classes = useClasses({ isMobile })
  const { showSnackbar } = useSnackbar()

  const { data: customerData, loading } = useQuery<{ customer: Bff.Customers.Customer | null }>(
    savedPaymentDetailsQuery,
    {
      variables: {
        customerId,
      },
      fetchPolicy: 'no-cache',
    },
  )

  const [removePaymentMethodMutation] = useMutation<
    {},
    { token: string; providerCode?: string; paymentMethod?: string; orderId?: string }
  >(removeSavedPaymentMethodMutation, {
    onError: (err: ApolloError) => {
      setError(err)
      setSavedPaymentRemoved(undefined)
    },
    onCompleted: () => {
      showSnackbar({ message: t('PaymentMethodRemoved', { defaultValue: 'Saved payment method removed' }) })
      removePaymentMethodElementFromList(savedPaymentRemoved?.paymentTokenRemoved!)
      setSavedPaymentRemoved(undefined)
    },
  })

  const removePaymentMethodElementFromList = (tokenRemoved: string) => {
    setPaymentMethods(paymentMethods?.filter((pm) => pm.tokenId !== tokenRemoved))
  }

  const removePaymentMethod = (token: string, providerCode?: string, paymentMethod?: string) => {
    setShow(true)
    setSavedPaymentRemoved({
      paymentTokenRemoved: token,
      paymentMethodRemoved: paymentMethod,
      providerCodeRemoved: providerCode,
    })
  }

  useEffect(() => {
    setPaymentMethods(customerData?.customer?.paymentMethods)
  }, [customerData])

  const onRemove = useCallback(() => {
    setShow(false)
    if (savedPaymentRemoved?.paymentTokenRemoved) {
      removePaymentMethodMutation({
        variables: {
          orderId,
          token: savedPaymentRemoved.paymentTokenRemoved,
          providerCode: savedPaymentRemoved.providerCodeRemoved,
          paymentMethod: savedPaymentRemoved.paymentMethodRemoved,
        },
      })
    }
  }, [savedPaymentRemoved])

  const onModalSheetClose = () => {
    setShow(false)
    setSavedPaymentRemoved(undefined)
  }

  return (
    <div className={classes.container} data-testid='account-saved-payments'>
      {error && <Error tns={t} errorDefinitions={MySavedPaymentErrors} error={error} />}
      <h2 className={classes.heading}>{t('MyAccountPaymentsHeading', { defaultValue: 'My Saved Payments' })}</h2>
      <p className={classes.paragraph}>
        {t('MyAccountPaymentsDescription', {
          defaultValue:
            'To save new payment details, next time you order, select Save Payment Details when entering your credit card.',
        })}
      </p>

      <div>
        {loading ? (
          <Spinner testID='payment-spinner' />
        ) : paymentMethods && paymentMethods.length > 0 ? (
          paymentMethods
            .sort((pa, pb) =>
              pa.paymentProvider < pb.paymentProvider ? -1 : pa.paymentProvider > pb.paymentProvider ? 1 : 0,
            )
            .map((pm) => {
              const removing = savedPaymentRemoved?.paymentTokenRemoved === pm.tokenId
              switch (pm.paymentMethod) {
                case 'CreditCard':
                  return <CreditCard key={pm.tokenId} card={pm} removing={removing} onRemove={removePaymentMethod} />
                case 'Edenred':
                  return (
                    <MySavedEdenred
                      key={pm.tokenId}
                      paymentMethods={paymentMethods}
                      tokenId={pm.tokenId}
                      removePaymentMethod={removePaymentMethod}
                      removing={removing}
                    />
                  )
              }
            })
        ) : (
          <div className={classes.tile}>
            <ListItem
              label={t('MyAccountNoSavedPaymentsTitle', { defaultValue: 'No Card Saved' })}
              text={t('MyAccountNoSavedPayments', { defaultValue: 'You have not saved any payment methods.' })}
              disabled={true}
              media='credit-card'
            />
          </div>
        )}
      </div>
      {showConfirm && <RemovePaymentModalSheet onClose={onModalSheetClose} onRemove={onRemove} />}
    </div>
  )
}

export { MySavedPayments }
