import { findEntityByDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'

type DimensionSet = Bff.Dimensions.DimensionSet

export type Nutritionals = {
  type: Bff.Common.NutritionalGroupType
  measure: string
  nutritionals: Bff.Common.Nutritional[]
}

const flattenNutritionalsForDimensionSet = (
  dimensionSet: DimensionSet | undefined,
  product: Bff.Products.ProductBase<DimensionSet> | undefined,
  nutritionalType: Bff.Common.NutritionalGroupType,
): Nutritionals => {
  const nutritionals = product?.nutritionals
    .filter((item) => item.type === nutritionalType)
    .flatMap((item) => {
      const nutritional = findEntityByDimensionSet(item.nutritionals, dimensionSet)

      return nutritional ?? []
    })

  return {
    type: nutritionalType,
    measure: '',
    nutritionals: nutritionals ?? [],
  } as Nutritionals
}

export { flattenNutritionalsForDimensionSet }
