import { countFromBasketLines } from '@dominos/business/functions/basket'
import {
  ActionButton,
  KioskTermsAndConditions,
  MenuPageCard,
  OmniBar,
  OrderButton,
  OrderDetailsPill,
} from '@dominos/components'
import { useDominosTheme, useFeatures, useKiosk, useSpotlightContext } from '@dominos/hooks-and-hocs'
import useSpecialOffer from '@dominos/hooks-and-hocs/offers/use-special-offer'
import { NavigationConstants } from '@dominos/navigation'
import { useNavigate } from 'react-router-dom'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SpecialOffers from './special-offers/special-offers'
import css from './styles/top-level-menu-container.less'
import { TopLevelMenuContainerProps } from './top-level-menu-container.interfaces'
import { SPOTLIGHT_KEY_ORDER_DETAILS_PILL, SpotlightElement } from '@dominos/components/spotlight'
import { useSpotlightOrderDetails } from '@dominos/hooks-and-hocs/order-details'
import { isNativeApp } from '@dominos/business/functions'

export const TopLevelMenuContainer = ({ menuPages, testID }: TopLevelMenuContainerProps) => {
  const navigate = useNavigate()
  const pages = menuPages || []
  const { t } = useTranslation('menu')
  const theme = useDominosTheme()
  const { isSpotlightOn } = useSpotlightContext()
  const [orderDetailsPillRef] = useSpotlightOrderDetails()
  const { isKioskOrder } = useKiosk()
  const [specialOffersMobileViewEnabled, enableMenuPlaceOrderAndShoppingCartButtons, kioskLegalTerms] = useFeatures(
    'SpecialOffersMobileView',
    'EnableMenuPlaceOrderAndShoppingCartButtons',
    'KioskLegalTerms',
  )
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)
  const basketError = useSelector((state: RootReducer) => state.basketReducer.error)
  const basketLoading = useSelector((state: RootReducer) => state.basketReducer.pending)

  const specialOffer = useSpecialOffer()

  const onVoucherPress = async () => {
    await navigate(NavigationConstants.basket, {
      state: { voucherEnter: true },
    })
  }

  const onPressOrderButton = async () => {
    await navigate(NavigationConstants.basket)
  }

  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])

  return (
    <div data-testid={`${testID}.wrapper`} style={{ width: 'inherit', maxWidth: 'inherit' }}>
      <div className={css.wrapper}>
        {!isNativeApp() && !isKioskOrder && (
          <SpotlightElement ref={orderDetailsPillRef} spotlightKey={SPOTLIGHT_KEY_ORDER_DETAILS_PILL}>
            <OrderDetailsPill
              testID={`${testID}.order-details-pill`}
              disableClick={isSpotlightOn(SPOTLIGHT_KEY_ORDER_DETAILS_PILL)}
            />
          </SpotlightElement>
        )}
        <ActionButton
          className={css.addVoucherButton}
          testID='top-menu-add-voucher'
          text={t('AddVoucherCode')}
          textFontWeight='bold'
          containerStyle={{
            padding: '11px',
            backgroundColor: theme.colours.defaultError,
            width: '100%',
            borderRadius: theme.webSmallBorderRadius,
          }}
          textStyle={{ fontSize: 16, color: 'white' }}
          onPress={onVoucherPress}
        />
      </div>

      {specialOffer && specialOffersMobileViewEnabled && <SpecialOffers offer={specialOffer} />}

      {pages && pages.map((page, index) => <MenuPageCard page={page} index={index} key={page.code} />)}

      {kioskLegalTerms && <KioskTermsAndConditions style={{ margin: '40px 0 -40px' }} />}

      {enableMenuPlaceOrderAndShoppingCartButtons ? (
        <div className={css.omniBarContainer} style={{ bottom: 0 }}>
          <OmniBar
            testID={'omni-bar.mobile'}
            itemCount={itemCount}
            price={basketData.total || 0}
            text={t('Place Order - Improved Omnibar')}
            loading={basketLoading}
          />
        </div>
      ) : (
        <div className={css.stickyButton}>
          <OrderButton
            testID={'place-order.mobile'}
            itemCount={itemCount}
            price={basketData.total || 0}
            onPress={onPressOrderButton}
            text={!itemCount ? t('OrderButtonViewBasket', { defaultValue: 'View Basket' }) : t('View Order')}
            disabled={!!basketError}
            fill
            loading={basketLoading}
          />
        </div>
      )}
    </div>
  )
}
