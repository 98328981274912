import React, { useState } from 'react'
import { CardHeader } from '@dominos/components'
import { useTranslation } from 'react-i18next'
import { ResetPasswordForm } from './reset-password-form'
import { ResetPasswordConfirmation } from './reset-password-confirmation'
import { ApolloError } from '@apollo/client/errors'

import {
  useAuthenticationPopup,
  useCurrentOrderDetails,
  useForgotPassword,
  useQuickUniversalAccountComponent,
  useReport,
} from '@dominos/hooks-and-hocs'
import { ErrorScope, useErrorContext } from '@dominos/components'
import { ResetPasswordResponse } from '@dominos/business/queries'
import { forgotPasswordErrors } from './reset-password-errors'
import { isNativeApp } from '@dominos/business/functions'

export const ResetPassword = ({ testID }: BaseProps) => {
  const { t } = useTranslation('login')
  const { reportForgottenPasswordResetRequested } = useReport()
  const { orderId } = useCurrentOrderDetails()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const { previousStep, hasPrevious } = useQuickUniversalAccountComponent()
  const { hidePopup } = useAuthenticationPopup()
  const { notifyError } = useErrorContext()
  const isNative = isNativeApp()

  const onRequestCompleteCallback = (data: ResetPasswordResponse['resetPasswordRequest']) => {
    if (!data) return

    reportForgottenPasswordResetRequested({
      orderId,
      success: true,
      location: window.location.href,
      authenticationSource: 'UniversalAuth',
    })
    setShowConfirmation(true)
  }
  const onRequestErrorCallback = (error: ApolloError) => {
    reportAndNotifyError(error)
  }
  const { performForgotPasswordRequest, loading, useRecaptchaV2, renderCheckboxV2 } = useForgotPassword(
    onRequestCompleteCallback,
    onRequestErrorCallback,
  )

  const reportAndNotifyError = async (error: ApolloError) => {
    await reportForgottenPasswordResetRequested({
      orderId,
      success: false,
      forceFlush: true,
      location: window.location.href,
      authenticationSource: 'UniversalAuth',
    })
    notifyError({
      error,
      definitions: forgotPasswordErrors,
      handlers: {},
      scope: ErrorScope.ForgotPassword,
    })
  }

  const handlePreviousAction = hasPrevious ? () => previousStep() : undefined
  const handleResetPasswordClose = () => (isNative ? handlePreviousAction?.() : hidePopup())

  const handleForgotPassword = async (email: string) => {
    await performForgotPasswordRequest(email)
  }

  const popupTitle = showConfirmation
    ? t('ForgottenPasswordResultPopupTitle', { defaultValue: 'Reset Sent' })
    : t('ForgottenPasswordPopupTitle', { defaultValue: 'Reset Password' })

  return (
    <div data-testid={testID}>
      {!isNative && <CardHeader testID={`${testID}.header`} title={popupTitle} onAction={handlePreviousAction} />}
      {showConfirmation ? (
        <ResetPasswordConfirmation testID={`${testID}.confirm`} onClick={handleResetPasswordClose} />
      ) : (
        <ResetPasswordForm
          testID={`${testID}.form`}
          onSubmit={handleForgotPassword}
          isLoading={loading}
          renderRecaptchaCheckbox={renderCheckboxV2}
          shouldRenderRecaptcha={useRecaptchaV2}
        />
      )}
    </div>
  )
}
