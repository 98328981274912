import { CollapsableTitledCard } from '../..'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StoreInfoLine } from './store-info-line'
import { useFeatures } from '@dominos/hooks-and-hocs'
import css from './store-trade-details.less'

interface IStoreDetailsProps {
  store?: Bff.Stores.Store
  serviceMethod: BffContext.ServiceMethods
}

const StoreTradeDetails = ({ store, serviceMethod }: IStoreDetailsProps) => {
  const { t } = useTranslation('tracker')
  const [storeDetailsEnabled] = useFeatures('OloTrackerStoreDetails')

  const cardStyles = {
    marginTop: '8px',
  }

  if (!storeDetailsEnabled || store == null || store.media.tradeName == null || store.media.tradeIdentifier == null)
    return null

  return (
    <CollapsableTitledCard
      testID='store-trade-details-section'
      primaryTitle={t('StoreTradeDetails')}
      static
      style={cardStyles}
    >
      <section className={css.storeTradeDetailsContainer}>
        {serviceMethod == 'Delivery' && (
          <StoreInfoLine icon='storeNoLogo' name={t('StoreTradeDetailsName')} value={store.media.name} />
        )}
        <StoreInfoLine
          icon='storeTradeDetailsGlobe'
          name={t('StoreTradeDetailsCompanyName')}
          value={store.media.tradeName!}
        />
        <StoreInfoLine
          icon='number'
          name={t('StoreTradeDetailsRegisteredNumber')}
          value={store.media.tradeIdentifier!}
        />
      </section>
    </CollapsableTitledCard>
  )
}

export { StoreTradeDetails }
