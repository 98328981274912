import { ActionButton, GenericCard } from '@dominos/components'
import { useAlert, useLoyalty } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation/navigation-constants'
import { PizzaGauge, PizzaGaugeBadge } from '@dominos/res/images/icons/components/rewards'
import { useNavigate } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { mapRewardErrorTranslation } from './map-error-translation'
import css from './reward-balance.less'

export const RewardBalance = ({ testID, balance }: { balance?: Bff.Customers.CustomerLoyaltyBalance } & BaseProps) => {
  const { t } = useTranslation('loyalty')
  const { showAlert } = useAlert()
  const navigate = useNavigate()
  const {
    loading,
    errors,
    clearValidationErrors,
    redeemPizza,
    totalRedeemedPizzas,
    totalAvailablePizzas,
    isAvailable,
  } = useLoyalty()
  const [initialPizzas] = useState(totalRedeemedPizzas)

  const redirectHome = useCallback(() => {
    if (location.pathname.includes(NavigationConstants.myAccount)) {
      navigate(NavigationConstants.home)
    }
  }, [location.pathname])

  useEffect(() => {
    if (!loading && totalRedeemedPizzas > initialPizzas) {
      redirectHome()
    }
  }, [loading, totalRedeemedPizzas])

  useEffect(() => {
    if (errors.length > 0) {
      errors.forEach((error) => showAlert(mapRewardErrorTranslation(error, t)))
      clearValidationErrors()
    }
  }, [errors])

  return (
    <GenericCard
      testID={testID}
      title={t('RewardBalanceTitle', {
        defaultValue: `Points Balance`,
      })}
      width={'100%'}
      noPadding
    >
      {balance && (
        <div className={css.content}>
          <div data-testid={`${testID}.balance`} className={css.balance}>
            <PizzaGauge
              testID={`${testID}.gauge`}
              size={220}
              points={balance?.pointsPerPizza - balance?.pointsToNextFreePizza || 0}
              max={balance?.pointsPerPizza}
            />

            <div className={css.remaining}>
              <span className={css.points}>
                {t('RewardBalancePoints', {
                  points: balance?.pointsToNextFreePizza || 0,
                  defaultValue: `{{points}} points`,
                })}
              </span>
              {t('RewardBalanceLabel', {
                defaultValue: `away from your next free pizza`,
              })}
            </div>

            <div className={css.pending}>
              {t('RewardBalancePending', {
                points: balance?.pendingPoints || 0,
                defaultValue: `{{points}} pending points not included`,
              })}
            </div>

            <div className={css.disclaimer}>
              {t('RewardPointsDisclaimer', {
                defaultValue: `Pending points may take up to 48 hours to be added to your total.`,
              })}
            </div>
          </div>

          {totalAvailablePizzas > 0 && (
            <div data-testid={`${testID}.redeem`} className={css.redeem}>
              <PizzaGaugeBadge count={totalAvailablePizzas} size={36} />
              <div className={css.label}>
                {t('AvailablePizzasLabel', {
                  defaultValue: `Free Pizzas Available`,
                })}
              </div>
              <ActionButton
                testID={`${testID}.action`}
                onPress={redeemPizza}
                text={t('RewardRedeemButton', {
                  defaultValue: `Redeem Now`,
                })}
                textFontWeight={'bold'}
                disabled={loading || !isAvailable}
              />
            </div>
          )}
        </div>
      )}
    </GenericCard>
  )
}
