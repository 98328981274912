import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useValidation, useValidationJP } from '@dominos/business/functions/validation'
import { useStyles } from './account-details.styles'
import { TextField } from 'olo-ux'
import { validate } from '@dominos/business/functions/validation'
import { useMobileValidationRules } from '@dominos/hooks-and-hocs'
import { useBreakpoints } from '@dominos/hooks-and-hocs'

const maxLengthName = 250

const fieldNames = {
  name: 'name',
  alternateName: 'alternateName',
  phoneNumber: 'phoneNumber',
}

interface AccountDetailsProps {
  onChange: (fieldName: string, value: string | undefined) => void
  onValidationChange: (isValid: boolean) => void
  details?: { [key: string]: string | undefined }
  shouldShowAlternateName?: boolean
}

export const AccountDetails = (props: AccountDetailsProps) => {
  const { isMobile } = useBreakpoints()
  const style = useStyles({ isMobile })
  const { t } = useTranslation('login')
  const { mobileValidationRules } = useMobileValidationRules()
  const { isRequired, maxLength, isNameValid } = useValidation()
  const { validateIsKatakana } = useValidationJP()
  const [errorMessage, setErrorMessage] = useState<{ [key: string]: string | null }>({})

  const validationRules = {
    name: [isRequired, maxLength(maxLengthName), isNameValid],
    alternateName: props.shouldShowAlternateName ? [isRequired, validateIsKatakana] : [],
    phoneNumber: mobileValidationRules,
  }

  type FieldName = keyof typeof validationRules

  useEffect(() => {
    const { validation, errorMessage } = (Object.keys(fieldNames) as FieldName[]).reduce(
      (acc, fieldName) => {
        const value = props.details?.[fieldName]
        const validationResult = (value !== undefined && validate(value, ...validationRules[fieldName])) || null
        acc.errorMessage[fieldName] = validationResult
        acc.validation[fieldName] = validationResult == null

        return acc
      },
      {
        validation: {} as { [key in FieldName]: boolean },
        errorMessage: {} as { [key in FieldName]: string | null },
      },
    )

    setErrorMessage(errorMessage)

    if (props.onValidationChange) {
      props.onValidationChange(Object.values(validation).every((isValid) => isValid))
    }
  }, [props.details])

  return (
    <div className={style.container}>
      <p className={style.subheading}>{t('PersonalInformationHeading', { defaultValue: 'Personal Information' })}</p>
      <div className={style.wrapperInner}>
        <TextField
          onChangeText={(text) => props.onChange(fieldNames.name, text)}
          placeholder={t('NameLabel', { defaultValue: 'Name' })}
          value={props.details?.name ?? ''}
          hasError={!!errorMessage[fieldNames.name]}
          hint={errorMessage[fieldNames.name] || ''}
          onClear={() => props.onChange(fieldNames.name, '')}
        />
      </div>

      {props.shouldShowAlternateName && (
        <div className={style.wrapperInner}>
          <TextField
            onChangeText={(text) => props.onChange(fieldNames.alternateName, text)}
            placeholder={t('AlternateNameLabel', { defaultValue: 'Alternate Name' })}
            value={props.details?.alternateName ?? ''}
            hasError={!!errorMessage[fieldNames.alternateName]}
            hint={errorMessage[fieldNames.alternateName] || ''}
            onClear={() => props.onChange(fieldNames.alternateName, '')}
          />
        </div>
      )}

      <p className={style.subheading}>{t('MobileNumberLabel', { defaultValue: 'Mobile Number' })}</p>
      <div className={style.wrapperInner}>
        <TextField
          onChangeText={(text) => props.onChange(fieldNames.phoneNumber, text)}
          placeholder={t('MobileNumberLabel', { defaultValue: 'Mobile Number' })}
          value={props.details?.phoneNumber ?? ''}
          hasError={!!errorMessage[fieldNames.phoneNumber]}
          hint={errorMessage[fieldNames.phoneNumber] || ''}
          onClear={() => props.onChange(fieldNames.phoneNumber, '')}
        />
      </div>

      <p className={`${style.subheading} ${style.disabledContent}`}>
        {t('EmailLabel', { defaultValue: 'Email Address' })}
      </p>
      <div className={style.wrapperInner}>
        <TextField
          disabled={true}
          onChangeText={() => {}}
          placeholder={t('EmailLabel', { defaultValue: 'Email Address' })}
          value={props.details?.email}
          hint={t('EmailNotEditable', { defaultValue: 'Email address cannot be edited' })}
        />
      </div>
    </div>
  )
}
