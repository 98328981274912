import React, { createContext, useContext, useState } from 'react'

interface CheckoutDetailsContextData {
  isMyDetailsValid: boolean
  setIsMyDetailsValid: (value: boolean) => void
  isAdditionalDetailsValid: boolean
  setIsAdditionalDetailsValid: React.Dispatch<React.SetStateAction<boolean>>
}

const CheckoutDetailsContext = createContext<CheckoutDetailsContextData>({
  isMyDetailsValid: false,
  setIsMyDetailsValid: () => {},
  isAdditionalDetailsValid: false,
  setIsAdditionalDetailsValid: () => {},
})

export const CheckoutDetailsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isMyDetailsValid, setIsMyDetailsValid] = useState<boolean>(false)
  const [isAdditionalDetailsValid, setIsAdditionalDetailsValid] = useState<boolean>(false)

  return (
    <CheckoutDetailsContext.Provider
      value={{
        isMyDetailsValid,
        setIsMyDetailsValid,
        isAdditionalDetailsValid,
        setIsAdditionalDetailsValid,
      }}
    >
      {children}
    </CheckoutDetailsContext.Provider>
  )
}

export const useCheckoutDetails = () => useContext(CheckoutDetailsContext)
