import { getCustomerId, getFormattedPrice, isNativeApp } from '@dominos/business/functions'
import { BoxedMessage, ErrorScope, useErrorContext } from '@dominos/components'
import usePaymentFeatureToggles from '@dominos/components/checkout/checkout-container/payment-container/use-payment-feature-toggles'
import { paymentBalanceErrors } from '@dominos/components/error/definitions'
import { useEdenredURL, useLocalStorage, usePaymentBalance } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { useLocation, useNavigate } from 'react-router-dom'
import { SHA256 } from 'crypto-js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'uuid'
import { Button, Divider, ListItem } from 'olo-ux'
import { useClasses, useStyles } from './my-saved-edenred.styles'

interface MySavedEdenredProps {
  paymentMethods?: Bff.Customers.CustomerPaymentMethod[]
  tokenId?: string
  removePaymentMethod?: (token: string, providerCode?: string | undefined, paymentMethod?: string | undefined) => void
  removing?: boolean
}

export const MySavedEdenred = ({ paymentMethods, tokenId, removePaymentMethod, removing }: MySavedEdenredProps) => {
  const { t } = useTranslation('checkout')
  const { t: tValidation } = useTranslation('validation')
  const featureToggles = usePaymentFeatureToggles()
  const [authorisationCode, setAuthorisationCode] = useState<string | null>(null)
  const [redirecting, setRedirecting] = useState(false)
  const {
    storedValue: edenredNonce,
    setValue: setEdenredNonce,
    clearValue: clearEdenredNonce,
  } = useLocalStorage<string>({ key: 'edenred-nonce' })
  const { getEdenredURL, isEdenredAuthScope } = useEdenredURL()
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const { fetchPaymentBalance, paymentBalance, error, loading } = usePaymentBalance()
  const { notifyError } = useErrorContext()
  const classes = useClasses()
  const styles = useStyles()

  const customerId = getCustomerId() ?? undefined

  const onEdenredLogin = () => {
    setRedirecting(true)
    const nonce = SHA256(uuid()).toString()
    setEdenredNonce(nonce)
    const edenredUrl = getEdenredURL(customerId, nonce, 'saved-payment')
    navigate(edenredUrl)
  }

  useEffect(() => {
    if (!search || loading) {
      return
    }

    const searchParams = new URLSearchParams(search)
    if (!isEdenredAuthScope(searchParams.get('scope')) || searchParams.get('state') !== customerId) {
      return
    }

    setAuthorisationCode(searchParams.get('code'))

    if (!isNativeApp()) {
      window.history.replaceState({}, '', pathname)
    }
  }, [search, loading])

  useEffect(() => {
    if (!authorisationCode) {
      fetchPaymentBalance('Edenred', 'Edenred', customerId)

      return
    }

    if (!edenredNonce) {
      return
    }

    const redirectUri = `${origin}${
      isNativeApp() ? NavigationConstants.nativeAppSavedPayment : NavigationConstants.savedPayment
    }`
    fetchPaymentBalance('Edenred', 'Edenred', customerId, authorisationCode, edenredNonce, redirectUri)
    clearEdenredNonce()
  }, [customerId, authorisationCode])

  useEffect(() => {
    if (error) {
      notifyError({ error, handlers: {}, definitions: paymentBalanceErrors, scope: ErrorScope.PaymentBalance })
    }
  }, [error])

  if (!featureToggles.edenredEnabled) {
    return null
  }

  return paymentMethods?.some((pm) => pm.paymentMethod === 'Edenred') ? (
    <>
      {paymentMethods?.some((pm) => pm.paymentMethod === 'CreditCard') && (
        <Divider
          label={t('MyAccountOtherSavedPayment', {
            defaultValue: 'Other Saved Payment',
          })}
          style={styles.divider}
        />
      )}
      {loading || paymentBalance?.success ? (
        <>
          <div className={classes.tile}>
            <ListItem
              label={t('Edenred', { defaultValue: 'Edenred' })}
              text={t('EdenredBalance', {
                defaultValue: 'Balance {{amount}}',
                amount: getFormattedPrice(paymentBalance?.amount || 0),
              })}
              media='edenred'
              control='action'
              actionLabel={t('RemoveButton', { defaultValue: 'Remove' })}
              actionType='destructive'
              onPress={() => tokenId && removePaymentMethod?.(tokenId, 'Edenred', 'Edenred')}
              disabled={loading || removing || !tokenId}
            />
          </div>
          {!loading && paymentBalance?.amount === 0 && (
            <BoxedMessage testID='payment-balance-zero-balance' icon='exclamation' role='status' color='secondary'>
              {tValidation('PaymentBalanceZeroBalanceMessage', {
                defaultValue: 'Your balance today is {{amount}}. Please choose another payment method for your order.',
                amount: getFormattedPrice(0),
              })}
            </BoxedMessage>
          )}
        </>
      ) : (
        <>
          <Button
            type='accent'
            size='large'
            label={t('LoginEdenred', { defaultValue: 'Sign in to MyEdenred' })}
            onPress={onEdenredLogin}
            iconLeft='edenred'
            disabled={redirecting}
          />
          <div id='payment-balance-error' />
        </>
      )}
    </>
  ) : null
}
