import { SimpleFooter, SimpleNavigationTitle, StackNavigationBaseProps } from '@dominos/components'
import React from 'react'
import css from './stack-navigation.less'
import { useFeatures } from '@dominos/hooks-and-hocs'
import classnames from 'classnames'

export const StackNavigationBase = (props: React.PropsWithChildren<StackNavigationBaseProps>) => {
  const {
    testID,
    header = <SimpleNavigationTitle {...props} />,
    children,
    footer = <SimpleFooter testID={testID} />,
    modification,
    className,
  } = props
  const [displayFooter] = useFeatures('DisplayFooter')

  return (
    <div data-testid={testID} className={css.wrapper}>
      {header}
      <div
        className={classnames(
          modification === 'map-view' ? css.main_map_view : css.main,
          !displayFooter && css.noFooter,
          className,
        )}
      >
        {children}
      </div>
      {displayFooter && footer}
    </div>
  )
}
