import { Config } from '../common'
import { LoadScriptProps } from '../common/load-script'

export const setupOneTrust = (applicationConfig: Config, loadScript: (props: LoadScriptProps) => void) => {
  loadScript({
    id: 'onetrust_button_order',
    setupScriptFunc: (script) => {
      script.type = 'text/javascript'
      script.text = `function OptanonWrapper() {
                           const cookieSettingsButton = document.getElementById("onetrust-pc-btn-handler");
                           const acceptButton = document.getElementById("onetrust-accept-btn-handler");
                           const rejectButton = document.getElementById("onetrust-reject-all-handler");
                           const buttonsContainer = document.getElementById("onetrust-button-group");

                           // Here we want to have next order: ACCEPT->REJECT->SETTINGS
                           // We don't want to clean the root div and place element because we could have another children in the future
                           if (buttonsContainer) {
                               // So, we can move REJECT before SETTINGS (SETTINGS can be NULL here, insertBefore can have null for the second argument)
                               if (rejectButton) {
                                   rejectButton.style.float = "none";
                                   buttonsContainer.insertBefore(rejectButton, cookieSettingsButton);
                               }

                               // And doesn't matter who is the first element (SETTINGS, REJECT or null) - we move accept to the first position
                               if (acceptButton) {
                                   acceptButton.style.float = "none";
                                   buttonsContainer.insertBefore(acceptButton, buttonsContainer.firstChild);
                               }

                               // In the view (465px - 896px: when we have buttons under the text) we have a float attribute that doesn't allow us to switch the buttons
                               if (cookieSettingsButton) {
                                   cookieSettingsButton.style.float = "none";
                               }
                           }
                       }`
      script.crossOrigin = 'anonymous'
    },
  })

  loadScript({
    id: 'onetrust_cookies_consent',
    setupScriptFunc: (script) => {
      script.setAttribute('data-domain-script', applicationConfig.ONE_TRUST_KEY)
      script.type = 'text/javascript'
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      script.crossOrigin = 'anonymous'
    },
  })
}
