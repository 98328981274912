import { BasketReducerProps } from '@dominos/business/reducers/basket.reducer'

export const getPendingLoyaltyCouponState = (state: BasketReducerProps, action: RootActions) =>
  action.payload.validateBasket.basket.id === '' ? state.pendingLoyaltyCoupons : 0

export const getValidateBasketSuccessState = (state: BasketReducerProps, action: RootActions) => ({
  ...state,
  basket: action.payload.validateBasket.basket,
  validationErrors: action.payload.validateBasket.validationErrors,
  pending: false,
  error: undefined,
  loadedAt: new Date().toJSON(),
  pendingCoupons: [],
  pendingLoyaltyCoupons: getPendingLoyaltyCouponState(state, action),
  showSalePrice: action.payload.validateBasket.showSalePrice,
  pendingDeepLinkProduct: null,
  success: true,
})

export const getValidateBasketFailureState = (state: BasketReducerProps, action: RootActions) => ({
  ...state,
  pending: false,
  pendingCoupons: [],
  pendingLoyaltyCoupons: 0,
  pendingDeepLinkProduct: null,
  error: action.payload,
  loadedAt: new Date().toJSON(),
  success: false,
})
