import {
  CreateProductNutritionalLineViewModelProps,
  ProductNutritionalLineViewModel,
} from './product-nutritional-line.interface'

export const createProductNutritionalLineViewModel = ({
  t,
  nutritionalInfo,
  keyName,
  defaultUnit,
}: CreateProductNutritionalLineViewModelProps): ProductNutritionalLineViewModel => {
  const nutritionalValue = nutritionalInfo ? Math.round(nutritionalInfo * 100) / 100 : null
  const nutritionalName = t(keyName)
  const nutritionalUnit = t(`${keyName}-unit`, { defaultValue: defaultUnit })

  return {
    keyName,
    nutritionalName,
    nutritionalValue,
    nutritionalUnit,
  }
}
