import { AnalyticSender } from './analytics'

export const reportQuickReorder = (sendAnalytics: AnalyticSender) => {
  const reportQuickReorderLoaded = (productCount: number) => {
    sendAnalytics('quick_reorder_loaded', {
      product_count: productCount,
    })
  }

  return {
    reportQuickReorderLoaded,
  }
}
