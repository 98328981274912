import { useCustomer } from '@dominos/hooks-and-hocs/customer'
import React from 'react'
import { Card, ErrorNotifier, ProfileUpdateForm, SplashScreen } from '@dominos/components'

import css from './native-profile-completion-container.less'
import { notifyNativeApp } from '@dominos/business/functions'

export const NativeProfileCompletionContainer = ({ testID }: { testID: string }) => {
  const { customer, loading } = useCustomer()

  const handleUpdate = () => {
    notifyNativeApp('profile-completion-successful')
  }

  if (!customer || loading) {
    return <SplashScreen testID={`${testID}-loading-splash`} />
  }

  return (
    <Card testID={`${testID}.card`} className={css.container}>
      <ErrorNotifier namespace='login'>
        <ProfileUpdateForm customer={customer} onSuccessfulUpdate={handleUpdate} />
      </ErrorNotifier>
    </Card>
  )
}
