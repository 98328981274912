import { flattenBasketLines } from '@dominos/business/functions/basket/flatten-basket'

/**
 * Checks if a given offer item is present in the basket.
 *
 * @param offerItem - The offer item to check for in the basket.
 * @param basket - The basket to search within.
 * @returns A boolean indicating whether the offer item is in the basket.
 */
export const isOfferItemInBasket = (offerItem: Bff.Offers.Item, basket: Basket) => {
  const allBasketLines = flattenBasketLines(basket.lines)
  const isProductMatchWithProductCode = (line: BasketLine) => line.productCode === offerItem.linkedItem.itemCode
  const isProductMatchWithSizeCode = offerItem.linkedItem.subItemCode
    ? (line: BasketLine) => line.sizeCode === offerItem.linkedItem.subItemCode
    : () => true

  return allBasketLines.some((line) => isProductMatchWithProductCode(line) && isProductMatchWithSizeCode(line))
}
