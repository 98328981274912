const mapIngredients = ({
  code,
  quantity,
}: Pick<BasketLineChange, 'code' | 'quantity'>): Bff.PriceProduct.PriceProductComponent => ({
  quantity,
  componentCode: code,
})
const calculateQuantity = (customisedIngredient: BasketLineChange): number =>
  customisedIngredient.action === 'Add' ? customisedIngredient.quantity : customisedIngredient.quantity - 1

export const getIngredientsForPricing = (
  basketLineIngredients: BasketLineChange[],
  recipeIngredients: IngredientModel[],
) => {
  const recipeToppings = recipeIngredients.map((recipeIngredient) => {
    const customisedIngredient = basketLineIngredients.find((topping) => topping.code === recipeIngredient.code)

    if (customisedIngredient) {
      return {
        ...recipeIngredient,
        quantity: calculateQuantity(customisedIngredient),
      }
    }

    return { ...recipeIngredient, quantity: 1 }
  })

  const recipeToppingCodes = recipeToppings.map((t) => t.code)
  const customisedToppings = basketLineIngredients.filter(({ code }) => !recipeToppingCodes.includes(code))

  return [...recipeToppings, ...customisedToppings].map(mapIngredients)
}
