import { ISwrveEmbeddedMessageConfig, SwrveSDK } from '@swrve/web-sdk'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import { rootActions } from '@dominos/business'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { getCustomerId } from '@dominos/business/functions/customer'
import { isNativeApp } from '@dominos/business/functions/native-app/is-native-app'
import { ICampaign, SwrveCampaignPopup } from '@dominos/components/swrve-campaign-popup'
import { useCountryCode, useFeatures, useLaunchDarklyContext, useOneTrust, useReport } from '@dominos/hooks-and-hocs'
import { tryCatchWrapper } from '@dominos/hooks-and-hocs/utils'
import { IPayLoad, SwrveClient, SwrveContext, SwrveEventKeys } from './swrve-context'

export const SwrveProvider = ({ children }: { children: React.ReactNode }) => {
  const [swrveCampaign, setSwrveCampaign] = useState<ICampaign>({
    shouldDisplay: false,
  })
  const { SWRVE_API_KEY: apiKey, SWRVE_STACK: stack, SWRVE_APP_ID } = getCountryConfig()
  const { launchDarklyID } = useLaunchDarklyContext()
  const dispatch = useDispatch()
  const { reportGenericError } = useReport()
  const [isSwrveNotificationsEnabled] = useFeatures('SwrveWebSdk')
  const countryCode = useCountryCode()
  const consents = useOneTrust()

  const isEnabled = useMemo(
    () => consents.Functional && isSwrveNotificationsEnabled && !isNativeApp(),
    [consents.Functional, isSwrveNotificationsEnabled],
  )

  useEffect(() => {
    if (isEnabled && validConfig()) {
      const customerId = getCustomerId()
      const externalUserId = customerId || launchDarklyID
      initialise(externalUserId, () => onInitialisationCallback(!!customerId, countryCode!))

      return () => {
        shutdown()
      }
    }
  }, [isEnabled, launchDarklyID, countryCode])

  const onInitialisationCallback = (isLoggedIn: boolean, country: BffContext.Countries) => {
    SwrveSDK.userUpdate({
      country_code: country,
    })
    event('account_status', { loggedIn: String(isLoggedIn) })
  }

  const validConfig = () => [apiKey, stack, SWRVE_APP_ID].every(Boolean)

  const initialise = (externalUserId = launchDarklyID, onLoaded = () => {}, autoPushSubscribe = false) => {
    if (!isEnabled) return

    const embeddedMessageConfig: ISwrveEmbeddedMessageConfig = {
      embeddedCallback: (message) => {
        try {
          const campaignMessage = JSON.parse(message.data)
          setSwrveCampaign({ shouldDisplay: true, campaign: campaignMessage })
        } catch (error) {
          reportGenericError({
            operationName: 'Failed to parse swrve campaign message',
            reason: message.data,
          })
        }
      },
    }

    const [initError] = tryCatchWrapper(() => {
      SwrveSDK.initWithConfig({
        appId: parseInt(SWRVE_APP_ID as string),
        apiKey,
        stack,
        externalUserId,
        userVisibleOnly: true,
        autoPushSubscribe: autoPushSubscribe, //Prompts for push notification permission
        serviceWorker: 'SwrveWorker.js',
        embeddedMessageConfig,
      })
      SwrveSDK.checkCoreInstance().onCampaignLoaded(onLoaded)
      const swrveId = SwrveSDK.getUserId()
      dispatch(rootActions.updateWebPushNotification({ webPushNotificationClientId: swrveId }))
    })

    if (initError) {
      reportGenericError({
        operationName: 'Failed to initialise Swrve',
        reason: initError,
      })
    }
  }

  const shutdown = () => {
    const [shutdownError] = tryCatchWrapper(SwrveSDK.shutdown.bind(SwrveSDK))
    if (shutdownError) {
      reportGenericError({
        operationName: 'Failed to shutdown Swrve',
        error: shutdownError,
      })

      return false
    }

    return true
  }

  const reset = (customerId?: string, onReset?: () => void, autoPushSubscribe?: boolean) => {
    if (!isEnabled) return

    const hasShutdown = shutdown()
    if (!hasShutdown) return

    const [initError] = tryCatchWrapper(() => {
      initialise(customerId, onReset, autoPushSubscribe)
    })

    if (initError) {
      reportGenericError({
        operationName: 'Failed to reset Swrve',
        error: initError,
      })
    }
  }

  const event = (eventName: SwrveEventKeys, payload?: IPayLoad) => {
    if (!isEnabled) return

    SwrveSDK.event(eventName, payload)
  }

  const value: SwrveClient = {
    reset,
    event,
  }

  const handleCampaignClosePopup = () => {
    setSwrveCampaign({ shouldDisplay: false })
  }

  return (
    <SwrveContext.Provider value={value}>
      <SwrveCampaignPopup onClose={handleCampaignClosePopup} swrveCampaign={swrveCampaign} />
      {children}
    </SwrveContext.Provider>
  )
}
