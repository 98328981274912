import React from 'react'
import { useTranslation } from 'react-i18next'
import { ListItem } from 'olo-ux'
import { useStyles } from './credit-card.styles'

interface CreditCardProps {
  onRemove?: (token: string) => void
  removing?: boolean
  card: Bff.Customers.CustomerPaymentMethod
}

const CreditCard = (props: CreditCardProps) => {
  const { t } = useTranslation('login')
  const lastFourDigits = props.card.name.slice(-4)
  const styles = useStyles()

  const handleRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.card.tokenId)
    }
  }

  return (
    <div className={styles.tile}>
      <ListItem
        text={`XXXX-${lastFourDigits}`}
        label={t('SavedCreditCard', { defaultValue: 'Credit Card' })}
        media='credit-card'
        control='action'
        actionLabel={t('RemoveButton', { defaultValue: 'Remove' })}
        actionType='destructive'
        onPress={handleRemove}
        disabled={props.removing}
        testID={`saved-payment-${lastFourDigits}`}
      />
    </div>
  )
}

export { CreditCard }
