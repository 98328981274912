import { createUseClasses } from 'olo-ux'
import { isNativeApp } from '@dominos/business/functions'
import { Dimensions } from 'react-native'

export const useStyles = createUseClasses((theme, { isMobile }: { isMobile: boolean }) => ({
  page: {
    backgroundColor: theme.colors.surface.primary,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: isNativeApp() ? Dimensions.get('window').height : undefined,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  submenu: {
    width: isMobile ? '100%' : undefined,
    maxWidth: isMobile ? undefined : 320,
    borderRightWidth: theme.borders.width.thin,
    borderRightColor: theme.colors.border.primaryAlpha,
    borderRightStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
  },
  contentPane: {
    padding: theme.size[400],
    maxWidth: 520,
  },
  signoutContainer: {
    padding: theme.size[400],
    marginTop: 'auto',
    borderTopWidth: theme.borders.width.thin,
    borderTopColor: theme.colors.border.primaryAlpha,
    borderTopStyle: 'solid',
  },
}))
