import { useApolloClient } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions/validation'
import { deliveryStreetsQuery } from '@dominos/business/queries'
import { FancyDropdownField, fancyDropdownFieldFetchDataHelper, ValidationTextField } from '@dominos/components'
import { NavigationConstants } from '@dominos/navigation'

import css from './delivery-address-be-layout.less'

const STREET_NO_MAX_LENGTH = 9
const ADDITION = 'unitNo' // based on current production
const STREET = 'street'
const INFO = `Can’t find your street? Sorry, we’re afraid we don’t yet deliver here. Please try to search for a store for pickup. `

export const DeliveryAddressBeLayout = (props: DeliveryAddressLayoutBaseProps) => {
  const navigate = useNavigate()
  const [streetValue, setStreetValue] = useState<string>('')
  const [showInfo, setShowInfo] = useState(false)

  const client = useApolloClient()
  const { t } = useTranslation('delivery-address')
  const { isRequired, maxLength } = useValidation()

  useEffect(() => {
    props.onInitialise({
      [STREET]: {
        isValid: false,
      },
      streetNo: {
        isValid: false,
      },
      [ADDITION]: {
        isValid: true,
      },
    })
  }, [])

  const fetchStreetData = fancyDropdownFieldFetchDataHelper<Bff.Addresses.DeliveryStreet>(
    client,
    (value: string) => ({
      query: deliveryStreetsQuery,
      variables: {
        street: value,
      },
    }),
    'deliveryStreets',
    (street) => `${street.name} (${street.suburb} ${street.postCode})`,
    () => {
      setShowInfo(true)
    },
  )

  const streetOnChange = (value: string) => {
    setStreetValue(value)
    props.onChange({
      [STREET]: {
        value,
        isValid: false,
      },
    })
  }

  const streetOnItemSelect = (data: KeyValuePair<Bff.Addresses.DeliveryStreet>) => {
    setStreetValue(data.value)
    props.onChange({
      [STREET]: {
        value: data.key.name,
        isValid: true,
      },
      postCode: {
        value: data.key.postCode,
        isValid: true,
      },
      suburb: {
        value: data.key.suburb,
        isValid: true,
      },
    })
  }

  const onStreetInfoClick = () => {
    navigate(NavigationConstants.storeSearch)
  }

  return (
    <>
      <FancyDropdownField
        testID='street.field'
        style={{ width: '100%' }}
        fetchData={fetchStreetData}
        placeholder={t('Street (Select from List)')}
        onChange={streetOnChange}
        onItemSelect={streetOnItemSelect}
        value={streetValue}
        maxItemLength={100}
        info={
          (showInfo && (
            <div id='streetInfo' role='link' className={css.streetInfo} onClick={onStreetInfoClick}>
              {t('Belgium Street Info', { defaultValue: INFO })}
            </div>
          )) ||
          undefined
        }
      />
      <ValidationTextField
        fieldName='streetNo'
        style={{ width: '100%' }}
        placeholder={t('Street Number')}
        testID={'streetNo.field'}
        showAsteriskWhenRequired
        validationRules={[isRequired, maxLength(STREET_NO_MAX_LENGTH)]}
        onChange={props.onChange}
      />
      <ValidationTextField
        testID='addition.field'
        fieldName={ADDITION}
        style={{ width: '100%' }}
        placeholder={t('Addition')}
        showAsteriskWhenRequired
        onChange={props.onChange}
      />
      <label className={css.additionalAddressInfoLabel}>
        {t('Delivery Address Addition Info', {
          defaultValue:
            'Additional address information (floor, entry code, etc.) may be added later in the ordering process in the delivery instructions.',
        })}
      </label>
    </>
  )
}
