import {
  useAccountDetailsSubmenuToggles,
  useBreakpoints,
  useCurrentOrderDetails,
  useCustomer,
  useLogout,
  useReport,
} from '@dominos/hooks-and-hocs'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigationConstants } from '../../common/navigation/navigation-constants'
import { DeleteAccount } from './delete-account'
import { MyDetails } from './my-details'
import { MyPassword } from './my-password'
import { MyRewards } from './my-rewards'
import { MySavedPayments } from './my-saved-payments'
import { MySavedVouchers } from './my-saved-vouchers'
import { SubMenuToggles } from '@dominos/interfaces'
import { MyInvoice } from './my-invoice'
import { Button, ListItem } from 'olo-ux'
import { useStyles } from './my-account-layout.styles'

interface SubMenuProps {
  subMenuToggles: SubMenuToggles
}

const RoutesComponent = (showSubMenu: boolean, subMenuToggles: SubMenuToggles, redirect?: string) => (
  <Routes>
    {redirect && <Route path='/' element={<Navigate replace to={redirect} />} />}
    {showSubMenu && <Route path='/' element={<SubMenu subMenuToggles={subMenuToggles} />} />}
    {subMenuToggles?.myRewards && <Route path='my-rewards' element={<MyRewards testID={''} />} />}
    {!subMenuToggles?.myRewards && <Route path='my-rewards' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountDetails && <Route path='details' element={<MyDetails />} />}
    {!subMenuToggles?.myAccountDetails && <Route path='details' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountPassword && <Route path='password' element={<MyPassword />} />}
    {!subMenuToggles?.myAccountPassword && <Route path='password' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountSavedPayments && <Route path='payment-details' element={<MySavedPayments />} />}
    {!subMenuToggles?.myAccountSavedPayments && <Route path='payment-details' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.savedVouchers && <Route path='vouchers' element={<MySavedVouchers />} />}
    {!subMenuToggles?.savedVouchers && <Route path='vouchers' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountInvoices && <Route path='invoice' element={<MyInvoice />} />}
    {!subMenuToggles?.myAccountInvoices && <Route path='invoice' element={<Navigate replace to='/' />} />}
    {subMenuToggles?.myAccountDelete && <Route path='delete' element={<DeleteAccount />} />}
    {!subMenuToggles?.myAccountDelete && <Route path='delete' element={<Navigate replace to='/' />} />}
  </Routes>
)

const MyAccountLayout = () => {
  const { isMobile } = useBreakpoints()
  const { isLoggedIn } = useCustomer()
  const { subMenuToggles, getFirstActiveToggle } = useAccountDetailsSubmenuToggles()
  const toggles = subMenuToggles()
  const firstActiveToggle = getFirstActiveToggle()
  const navigate = useNavigate()
  const styles = useStyles({ isMobile })

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate(NavigationConstants.home)
    }
  }, [isLoggedIn])

  return isLoggedIn ? (
    <div className={styles.page} data-testid='account-navigation'>
      <div className={styles.wrapper}>
        {isMobile ? RoutesComponent(true, toggles) : <SubMenu subMenuToggles={toggles} />}
        {!isMobile && (
          <div className={styles.contentPane} data-testid='my-account-content-pane'>
            {RoutesComponent(true, toggles, firstActiveToggle)}
          </div>
        )}
      </div>
    </div>
  ) : null
}

const SubMenu = ({ subMenuToggles }: SubMenuProps) => {
  const { t } = useTranslation('login')
  const { isMobile } = useBreakpoints()
  const styles = useStyles({ isMobile })
  const { logout, loading } = useLogout()
  const { orderId } = useCurrentOrderDetails()
  const { reportLogout } = useReport()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const onLogoutClick = async () => {
    if (loading) return
    await logout({
      variables: {
        orderId,
      },
    })
    reportLogout(orderId)
  }

  return (
    <div className={styles.submenu} data-testid='my-account-submenu'>
      {subMenuToggles.myAccountDetails && (
        <ListItem
          label={t('AccountDetailsHeading', { defaultValue: 'My Details' })}
          control='navigation'
          media='id-card'
          text={t('AccountDetailsSubheading', { defaultValue: "Manage your Domino's profile" })}
          hasDivider
          active={pathname === '/my-account/details'}
          onPress={() => navigate('/my-account/details', { replace: true })}
          testID='my-account-details'
        />
      )}
      {subMenuToggles.myAccountPassword && (
        <ListItem
          label={t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}
          control='navigation'
          media='password'
          text={t('MyAccountPasswordSubheading', { defaultValue: "Manage your Domino's account security" })}
          hasDivider
          active={pathname === '/my-account/password'}
          onPress={() => navigate('/my-account/password', { replace: true })}
          testID='my-account-password'
        />
      )}
      {subMenuToggles.myAccountSavedPayments && (
        <ListItem
          label={t('MyAccountPaymentsHeading', { defaultValue: 'My Saved Payments' })}
          control='navigation'
          media='credit-card'
          text={t('MyAccountPaymentsSubheading', { defaultValue: 'Manage your saved payment methods' })}
          hasDivider
          active={pathname === '/my-account/payment-details'}
          onPress={() => navigate('/my-account/payment-details', { replace: true })}
          testID='my-account-payment-details'
        />
      )}
      {subMenuToggles.myRewards && (
        <ListItem
          label={t('MyAccountRewardsHeading', { defaultValue: 'My Rewards' })}
          control='navigation'
          media='featured-seasonal-and-gifts'
          text={t('MyAccountRewardsSubheading', { defaultValue: 'View and redeem your rewards' })}
          hasDivider
          active={pathname === '/my-account/my-rewards'}
          onPress={() => navigate('/my-account/my-rewards', { replace: true })}
          testID='my-account-rewards'
        />
      )}
      {subMenuToggles.savedVouchers && (
        <ListItem
          label={t('MyAccountSavedVouchersTitle', { defaultValue: 'My Saved Vouchers' })}
          control='navigation'
          media='local-activity'
          text={t('MyAccountSavedVouchersSubheading', { defaultValue: 'Saved deals will appear here' })}
          hasDivider
          active={pathname === '/my-account/vouchers'}
          onPress={() => navigate('/my-account/vouchers', { replace: true })}
          testID='my-account-vouchers'
        />
      )}
      {subMenuToggles.myAccountInvoices && (
        <ListItem
          label={t('MyAccountSubMenuInvoice', { defaultValue: 'Request an E-Invoice' })}
          control='navigation'
          media='receipt-long'
          text={t('MyAccountSubMenuInvoiceSubheading', {
            defaultValue: 'Request to view your transaction history',
          })}
          hasDivider
          active={pathname === '/my-account/invoice'}
          onPress={() => navigate('/my-account/invoice', { replace: true })}
          testID='my-account-invoice'
        />
      )}
      {subMenuToggles.myAccountDelete && (
        <ListItem
          label={t('MyAccountSubMenuDeleteAccount', { defaultValue: 'Delete Account' })}
          control='navigation'
          media='person-cancel'
          hasDivider
          active={pathname === '/my-account/delete'}
          onPress={() => navigate('/my-account/delete', { replace: true })}
          testID='my-account-delete'
        />
      )}
      <div className={styles.signoutContainer}>
        <Button label={t('logout-button', 'Log Out')} onPress={onLogoutClick} type='destructive' size='medium' />
      </div>
    </div>
  )
}

export { MyAccountLayout }
