import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 20,
  height: 20,
}

const PhoneIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 20 20' fill='none'>
      <path
        d='M17.0667 18C15.2148 18 13.3852 17.5963 11.5778 16.7889C9.77037 15.9815 8.12593 14.837 6.64444 13.3556C5.16296 11.8741 4.01852 10.2296 3.21111 8.42222C2.4037 6.61481 2 4.78519 2 2.93333C2 2.66667 2.08889 2.44444 2.26667 2.26667C2.44444 2.08889 2.66667 2 2.93333 2H6.53333C6.74074 2 6.92593 2.07037 7.08889 2.21111C7.25185 2.35185 7.34815 2.51852 7.37778 2.71111L7.95556 5.82222C7.98519 6.05926 7.97778 6.25926 7.93333 6.42222C7.88889 6.58519 7.80741 6.72593 7.68889 6.84444L5.53333 9.02222C5.82963 9.57037 6.18148 10.1 6.58889 10.6111C6.9963 11.1222 7.44444 11.6148 7.93333 12.0889C8.39259 12.5481 8.87407 12.9741 9.37778 13.3667C9.88148 13.7593 10.4148 14.1185 10.9778 14.4444L13.0667 12.3556C13.2 12.2222 13.3741 12.1222 13.5889 12.0556C13.8037 11.9889 14.0148 11.9704 14.2222 12L17.2889 12.6222C17.4963 12.6815 17.6667 12.7889 17.8 12.9444C17.9333 13.1 18 13.2741 18 13.4667V17.0667C18 17.3333 17.9111 17.5556 17.7333 17.7333C17.5556 17.9111 17.3333 18 17.0667 18ZM4.68889 7.33333L6.15556 5.86667L5.77778 3.77778H3.8C3.87407 4.38519 3.97778 4.98519 4.11111 5.57778C4.24444 6.17037 4.43704 6.75556 4.68889 7.33333ZM12.6444 15.2889C13.2222 15.5407 13.8111 15.7407 14.4111 15.8889C15.0111 16.037 15.6148 16.1333 16.2222 16.1778V14.2222L14.1333 13.8L12.6444 15.2889Z'
        fill={fill}
      ></path>
    </svg>
  )
}

export { PhoneIcon }
