import {
  createPortionProductCardBannerViewModel,
  PortionProductCardBanner,
  PortionProductCardTitle,
  ProductPortions,
} from '@dominos/components'
import { DimensionSetState, ProductData } from 'packages/hooks-and-hocs'
import React from 'react'

interface PortionProductDetailsProps {
  testID: string
  productData: ProductData | undefined
  formattedPrice: string | null | undefined
  formattedPromoPrice: string | null | undefined
  dimensionSetState: DimensionSetState | undefined
  portions: Portion[] | undefined
  onProductListOpen: (portionIndex: number) => void
  resetPortion: (portionIndex: number) => void
  onPortionCustomise: (index: number) => void
  t: (key: string) => string
}

const PortionProductDetails: React.FC<PortionProductDetailsProps> = ({
  testID,
  productData,
  formattedPrice,
  formattedPromoPrice,
  dimensionSetState,
  portions,
  onProductListOpen,
  resetPortion,
  onPortionCustomise,
  t,
}) => {
  // TODO: MAB-3647 Replace hardcoded banner logic with upsell data
  const showBanner =
    productData?.defaultPortions?.length === 4 &&
    productData.defaultPortions.every((portion) => portion.defaultProductCode)

  return (
    <>
      <PortionProductCardTitle
        testID={testID}
        title={productData?.media?.name.value || ''}
        price={formattedPrice}
        promoPrice={formattedPromoPrice}
      />
      {showBanner && (
        <PortionProductCardBanner testID={testID} viewModel={createPortionProductCardBannerViewModel({ t })} />
      )}
      <ProductPortions
        t={t}
        testID={`${testID}.product-portions`}
        portions={portions}
        products={productData?.getPossiblePortionProducts(dimensionSetState?.selectedDimensionSet) || []}
        onProductListOpen={onProductListOpen}
        customisable={productData?.isPortionCustomisable(dimensionSetState?.selectedDimensionSet) || false}
        onPortionReset={resetPortion}
        onPortionCustomise={onPortionCustomise}
      />
    </>
  )
}

export { PortionProductDetails }
