import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { offersQuery } from '../../business/queries'
import { prepareQueryVariables } from './offers-helpers'
import { OffersContext } from './context'

export const OffersProvider = ({ children }: { children: React.ReactNode }) => {
  const [sendRequest, { data }] = useLazyQuery<{ offers: Bff.Offers.Offer[] }, Bff.Offers.OffersQueryArgs>(
    offersQuery,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  )
  const { basketHeaderData: header } = useCurrentOrderDetails()

  const fetchOffers = () => {
    const variables = prepareQueryVariables(header!)

    sendRequest({
      variables,
    })
  }

  useEffect(() => {
    if (header?.serviceMethod && header.storeNo && header.time !== undefined) {
      fetchOffers()
    }
  }, [header?.serviceMethod, header?.storeNo, header?.time])

  return <OffersContext.Provider value={{ offers: data?.offers ?? [] }}>{children}</OffersContext.Provider>
}
