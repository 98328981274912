import { isNativeApp } from '@dominos/business/functions/native-app'
import { NavigationConstants } from '@dominos/navigation'

export type RedirectUriType = 'checkout' | 'saved-payment'

export const getRedirectUri = (isTwoTapCheckoutEnabled: boolean, redirectType: RedirectUriType = 'checkout') =>
  `${window.location.origin}${getRedirectUriPath(isTwoTapCheckoutEnabled, redirectType)}`

export const getRedirectUriPath = (isTwoTapCheckoutEnabled: boolean, redirectType: RedirectUriType = 'checkout') => {
  if (redirectType === 'saved-payment') {
    return isNativeApp() ? NavigationConstants.nativeAppSavedPayment : NavigationConstants.savedPayment
  }

  if (isTwoTapCheckoutEnabled) {
    return isNativeApp() ? NavigationConstants.nativeAppCheckout : NavigationConstants.checkout
  }

  return isNativeApp() ? NavigationConstants.nativeAppCheckoutPayment : NavigationConstants.checkoutPayment
}
