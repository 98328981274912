import { useEffect, useState } from 'react'
import { bannerOfferFilter, generalFilter } from './offers-helpers'
import { useOffersContext } from '@dominos/hooks-and-hocs/offers'

const allowedLayouts: BffContext.Layouts[] = ['SingleItem', 'MultiItemGrid', 'MultiItemGridExclusive']

export const useBannerOffers = (locationCode: string = '') => {
  const { offers: allOffers = [] } = useOffersContext()

  const [offers, setOffers] = useState<Bff.Offers.Offer[]>([])
  useEffect(() => {
    if (allOffers.length > 0) {
      const filtered = generalFilter(allOffers, locationCode)
      setOffers(filtered.length > 0 ? bannerOfferFilter(filtered, locationCode, allowedLayouts) : [])
    }
  }, [allOffers, locationCode])

  return offers
}
