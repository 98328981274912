import { useLazyQuery } from '@apollo/client'
import { menuQuery } from '@dominos/business/queries'
import { GetMenuProps } from '../menu-context'
import { useMenuResponse } from './use-menu-response'

export const useMenuDataFull = () => {
  const [fetch, { loading, data, error, refetch }] = useLazyQuery<MenuResponse>(menuQuery, {
    notifyOnNetworkStatusChange: true,
  })

  const menuData = useMenuResponse({ loading, data, error })

  const getMenu = ({ storeNo, serviceMethod, serviceMethodSubtype, requestTime }: GetMenuProps) => {
    fetch({
      variables: {
        storeNo,
        serviceMethod,
        serviceMethodSubtype,
        requestTime,
      },
      context: { timeout: 60000 },
    })
  }

  return {
    getMenu,
    menuData,
    loading,
    error,
    refetch,
  }
}
