import { rootActions } from '@dominos/business'
import { getFormattedPrice } from '@dominos/business/functions/text/text-functions'
import { ActionButton } from '@dominos/components'
import {
  UpsellOfferType,
  useBasket,
  useBasketValidation,
  useCurrentOrderDetails,
  useDominosTheme,
  useFeatures,
  useIsTwoTapCheckoutEnabled,
  useUpsellOffer,
} from '@dominos/hooks-and-hocs'
import React, { useEffect, useRef, useState } from 'react'
import { getLabelText } from './functions'
import css from './omni-bar.less'
import { StaticSvgIcon } from '@dominos/res'
import { useNavigate } from 'react-router-dom'
import { NavigationConstants } from '@dominos/navigation'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions'
import { useDispatch } from 'react-redux'

const DEBOUNCE_TIME = 2000

export const OmniBar = ({ testID, loading, itemCount, text, price }: OmniBarProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { colours } = useDominosTheme()
  const labelText = getLabelText(price)
  const shortLabelText = getFormattedPrice(price)
  const labelContainerRef = useRef<HTMLDivElement>(null)
  const shouldDisable = !itemCount || loading
  const { isBasketValid } = useBasketValidation()
  const { orderId } = useCurrentOrderDetails()
  const { hardUpsellOrderId } = useBasket()
  const hardUpsellOffers = useUpsellOffer(UpsellOfferType.HardUpsell)
  const [hardUpsell] = useFeatures('HardUpsell')
  const isTwoTapCheckoutEnabled = useIsTwoTapCheckoutEnabled()

  const styles = {
    placeOrderContainer: { color: shouldDisable ? colours.buttonDisabledText : colours.defaultButtonText },
  }

  const [displayLabel, setDisplayLabel] = useState<string>(labelText)
  const [labelUpdating, setNewLabelUpdating] = useState<boolean>(false)

  useEffect(() => {
    setDisplayLabel(labelText)
    setNewLabelUpdating(true)
  }, [labelText])

  useEffect(() => {
    if (labelUpdating && labelContainerRef.current) {
      const { clientWidth, scrollWidth } = labelContainerRef.current
      setNewLabelUpdating(false)
      if (scrollWidth > clientWidth) {
        setDisplayLabel(shortLabelText)
      }
    }
  }, [labelUpdating, labelContainerRef])

  const handleCartButtonPressed = () => {
    navigate(NavigationConstants.basket)
  }

  const handlePlaceOrderButtonPressed = () => {
    if (!isBasketValid()) return
    if (isNativeApp()) {
      notifyNativeApp('checkout')
    } else {
      const showHardUpsell = hardUpsellOrderId !== orderId
      if (showHardUpsell) {
        dispatch(rootActions.addHardUpsellOrderId(orderId))
      }

      const shouldNavigateToHardUpsell = hardUpsell && showHardUpsell && hardUpsellOffers.length > 0
      const navigationTarget = shouldNavigateToHardUpsell
        ? NavigationConstants.hardUpsell
        : isTwoTapCheckoutEnabled
        ? NavigationConstants.checkout
        : NavigationConstants.checkoutDetails

      navigate(navigationTarget)
    }
  }

  return (
    <div data-testid={`${testID}.container`} className={css.container}>
      <ActionButton
        className={css.cartButton}
        testID={`${testID}.cart.button`}
        disabled={loading}
        loading={loading}
        onPress={handleCartButtonPressed}
        debounce={DEBOUNCE_TIME}
        textFontWeight={'semibold'}
        disabledBackgroundColor={colours.buttonDisabled}
      >
        <div data-testid={`${testID}.cart.container`} className={css.button} ref={labelContainerRef}>
          <StaticSvgIcon name='cart' fill={'white'} direction='left' width={20} height={16} isUnstyled />
          <div className={css.countBadge}>{itemCount}</div>
          {displayLabel && <div data-testid={`${testID}.cart.text`}>{displayLabel}</div>}
        </div>
      </ActionButton>
      <ActionButton
        className={css.placeOrderButton}
        testID={`${testID}.place.button`}
        disabled={shouldDisable}
        onPress={handlePlaceOrderButtonPressed}
        debounce={DEBOUNCE_TIME}
        textFontWeight={'semibold'}
        disabledBackgroundColor={colours.buttonDisabled}
        disabledTextColor={colours.buttonDisabledText}
      >
        <div
          data-testid={`${testID}.place.container`}
          className={css.button}
          style={styles.placeOrderContainer}
          ref={labelContainerRef}
        >
          <div data-testid={`${testID}.place.text`}>{text}</div>
        </div>
      </ActionButton>
    </div>
  )
}
