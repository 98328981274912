declare global {
  interface VWOVisitorConfig {
    destroySession: () => Promise<void>
  }

  interface VWO {
    visitorConfig?: VWOVisitorConfig
    push: (args: [string, () => void, { count: number }]) => void
  }

  interface Window {
    VWO?: VWO
  }
}

export const deleteVWOCookies = async (): Promise<void> => {
  if (window?.VWO?.visitorConfig?.destroySession) {
    try {
      return await window.VWO.visitorConfig.destroySession()
    } catch (error) {
      return Promise.reject(`Error destroying VWO session: ${error}`)
    }
  }

  return Promise.reject('VWO destroySession not available')
}
