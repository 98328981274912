import { AnalyticSender, SignUpMethod } from './analytics'
import { getProviderLoggingName } from './helpers'
import {
  ReportForgottenPasswordResetRequestedData,
  ReportLoginData,
  ReportLogoutData,
  ReportRegistrationAttemptData,
  ReportRegistrationErrorData,
  ReportRegistrationSuccessData,
} from './report.interfaces'
import { SecuritySender } from './security'

const isUniversalAuth = (authenticationSource: string) => authenticationSource === 'UniversalAuth'

const reportLogin =
  (sendSecurity: SecuritySender, sendAnalytics: AnalyticSender) =>
  async (data: ReportLoginData, forceFlush = false) => {
    if (data.status === 'success' && isUniversalAuth(data.authenticationSource)) {
      sendAnalytics('Quick Signup Account Login Success', {
        login_location: data.url,
        'User Id': data.customerId,
        login_method: data.identityProvider.toLowerCase() as SignUpMethod,
        login_platform: 'Web',
      })
    }

    await sendSecurity(
      data.status === 'success'
        ? `Flow.Account.Login.${getProviderLoggingName(data.identityProvider)}.LoginSucceeded`
        : `Flow.Account.Login.${getProviderLoggingName(data.identityProvider)}.LoginFailed`,
      {
        LoginIdentityProvider: data.identityProvider,
        LoginIdentityProviderId: '',
        EnableLongLived: data.enableLongLived,
        Note: data.status_reason,
        Error: data.status_error,
        'Order ID': data.order_id,
        Source: data.authenticationSource,
        payload: data.payload,
      },
      forceFlush,
    )
  }

const reportLogout = (sendSecurity: SecuritySender) => async (orderId: string, data?: ReportLogoutData) => {
  await sendSecurity('Flow.Account.LoggedOut', {
    'Order ID': orderId,
    Note: data?.status_reason,
    Source: data?.authenticationSource,
    Error: data?.status_error,
  })
}

const reportRegistration =
  (sendSecurity: SecuritySender, sendAnalytics: AnalyticSender) =>
  async (data: ReportRegistrationErrorData | ReportRegistrationSuccessData) => {
    if ((data.success || data.error) && isUniversalAuth(data.authenticationSource)) {
      sendAnalytics(data.error ? 'Quick Signup Account Creation Failed' : 'Quick Signup Account Creation Success', {
        signup_location: data.url,
        'User Id': data.customerId,
        create_account_loyalty_optin: data.loyaltyOptIn,
        signup_method: data.identityProvider == 'dominos' ? 'email' : (data.identityProvider as SignUpMethod),
        signup_platform: 'Web',
        error_message: data.error,
      })
    }

    await sendSecurity(
      data.success
        ? `Flow.Account.Register.${getProviderLoggingName(data.identityProvider)}.RegistrationSucceeded`
        : `Flow.Account.Register.${getProviderLoggingName(data.identityProvider)}.RegistrationDetailsInvalid`,
      {
        LoginIdentityProvider: data.identityProvider,
        LoginIdentityProviderId: data.identityProviderId,
        Note: data.note,
        'Order ID': data.orderId,
        Source: data.authenticationSource,
        payload: data.payload,
      },
    )
  }
const reportRegistrationAttempt =
  (sendSecurity: SecuritySender, sendAnalytics: AnalyticSender) => async (data: ReportRegistrationAttemptData) => {
    if (isUniversalAuth(data.authenticationSource)) {
      sendAnalytics('Quick Signup Account Creation Attempt', {
        signup_method: data.identityProvider == 'dominos' ? 'email' : (data.identityProvider as SignUpMethod),
        signup_platform: 'Web',
        signup_location: data.url,
        loyalty_checked: data.loyaltyOptIn,
      })
    }
    await sendSecurity(`Flow.Account.Register.${getProviderLoggingName(data.identityProvider)}.RegistrationAttempted`, {
      LoginIdentityProvider: data.identityProvider,
      LoginIdentityProviderId: data.identityProviderId,
      Note: data.note,
      'Order ID': data.orderId,
      Source: 'UniversalAuth',
    })
  }

const reportPasswordChanged =
  (sendSecurity: SecuritySender) =>
  async ({
    success,
    orderId,
    note,
    customerId,
  }: {
    success: boolean
    orderId: string
    note?: string
    customerId?: string
  }) => {
    await sendSecurity(
      success
        ? 'Flow.Account.UpdateAccount.PasswordChangeSucceeded'
        : 'Flow.Account.UpdateAccount.PasswordChangeFailed',
      {
        Note: note,
        'Order ID': orderId,
        CustomerId: customerId,
      },
    )
  }

const reportForgottenPasswordResetRequested =
  (sendSecurity: SecuritySender, sendAnalytics: AnalyticSender) =>
  async ({
    success,
    orderId,
    location,
    authenticationSource,
    forceFlush = false,
  }: ReportForgottenPasswordResetRequestedData) => {
    if (authenticationSource == 'UniversalAuth') {
      sendAnalytics('Quick Signup Account Forgot Password', {
        login_location: location,
        login_method: 'email',
        login_platform: 'Web',
      })
    }

    await sendSecurity(
      success
        ? 'Flow.Account.PasswordReset.ForgottenPasswordResetRequestSuccessful'
        : 'Flow.Account.PasswordReset.ForgottenPasswordResetRequestFailed',
      {
        'Order ID': orderId,
      },
      forceFlush,
    )
  }

const reportResetPassword =
  (sendSecurity: SecuritySender) =>
  async ({
    token,
    success,
    orderId,
    forceFlush = false,
  }: {
    token?: string
    success: boolean
    orderId: string
    forceFlush?: boolean
  }) => {
    await sendSecurity(
      success ? 'Flow.Account.PasswordReset.ResetPasswordSuccessful' : 'Flow.Account.PasswordReset.ResetPasswordFailed',
      {
        token,
        'Order ID': orderId,
      },
      forceFlush,
    )
  }

const reportAccountDelete =
  (sendSecurity: SecuritySender) =>
  async ({ success, orderId, deleteNote }: { success: boolean; orderId: string; deleteNote?: string }) => {
    await sendSecurity(
      success ? 'Flow.Account.UpdateAccount.AccountDeleted' : 'Flow.Account.UpdateAccount.AccountDeleteFailure',
      {
        Note: deleteNote,
        'Order ID': orderId,
      },
    )
  }

const reportAccountWasUpdated =
  (sendSecurity: SecuritySender) =>
  async ({
    success,
    orderId,
    fields,
    note,
    payload,
  }: {
    success: boolean
    orderId: string
    fields?: Fields
    note?: string
    payload?: Record<string, unknown>
  }) => {
    await sendSecurity(
      success ? 'Flow.Account.UpdateAccount.AccountWasUpdated' : 'Flow.Account.UpdateAccount.AccountWasUpdatedFailure',
      {
        Fields: fields,
        Note: note,
        'Order ID': orderId,
        payload,
      },
    )
  }

export const reportCustomer = (sendSecurity: SecuritySender, sendAnalytics: AnalyticSender) => ({
  reportLogin: reportLogin(sendSecurity, sendAnalytics),
  reportLogout: reportLogout(sendSecurity),
  reportPasswordChanged: reportPasswordChanged(sendSecurity),
  reportRegistration: reportRegistration(sendSecurity, sendAnalytics),
  reportRegistrationAttempt: reportRegistrationAttempt(sendSecurity, sendAnalytics),
  reportAccountDelete: reportAccountDelete(sendSecurity),
  reportAccountWasUpdated: reportAccountWasUpdated(sendSecurity),
  reportForgottenPasswordResetRequested: reportForgottenPasswordResetRequested(sendSecurity, sendAnalytics),
  reportResetPassword: reportResetPassword(sendSecurity),
})
