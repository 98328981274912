import { createUseClasses } from 'olo-ux'

export const useStyles = createUseClasses((theme, { isMobile }: { isMobile: boolean }) => ({
  container: {
    marginVertical: theme.size[800],
  },
  subheading: {
    ...theme.text.label.lg.demi,
    margin: 0,
    marginBottom: theme.size[200],
  },
  disabledContent: {
    color: theme.colors.content.disabled,
  },
  wrapperInner: {
    marginBottom: theme.size[400],
  },
}))
