import { useCurrentOrderDetails, useCurrentStore, useOrder, useOrderId } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { NavigationConstants } from '@dominos/navigation'

/*
  Provide the current store for either, the current order underway or
  the store associated with the order id provided in query params (tracker?order=xxxxx) 
*/
export const useCurrentOrderStore = () => {
  const currentStore = useCurrentStore()
  const { id: orderId } = useOrderId({ inspectQuery: true })
  const { fetchOrder, order } = useOrder()
  const { orderId: orderIdFromStorage } = useCurrentOrderDetails()
  const { pathname } = useLocation()
  const [store, setStore] = useState<Bff.Stores.Store>()

  const isTrackerPage = pathname == NavigationConstants.tracker

  useEffect(() => {
    if (!orderId) {
      setStore(undefined)

      return
    }

    if (orderIdFromStorage == orderId && currentStore) {
      setStore(currentStore)
    } else if ((!order || order?.id !== orderId) && isTrackerPage) {
      setStore(undefined)
      fetchOrder(orderId, 'cache-first')
    } else if (order && order.id === orderId && order?.store) {
      setStore(order.store)
    } else {
      setStore(undefined)
    }
  }, [orderId, order])

  return store
}
