import { getFullUrl } from '@dominos/business/functions'
import { PopupMedia, UpsellItem } from '@dominos/components/product/product-upsell/product-upsell.interface'
import { TFunction } from 'react-i18next'

export type ProductUpsellPopupViewProps = {
  testID?: string
  viewModel: ProductUpsellPopupModelViewModel
  onYesClicked: () => void
  onNoClicked: () => void
}

type ProductUpsellPopupModelViewModel = {
  popupImageUrl: string
  upgradeButtonText: string
  noButtonText: string
}

type ProductUpsellCardPopupModelProps = {
  t: TFunction<'menu'>
  upsellItem: UpsellItem<PopupMedia>
}

export const createProductUpsellPopupViewModel = ({
  t,
  upsellItem,
}: ProductUpsellCardPopupModelProps): ProductUpsellPopupModelViewModel => {
  const popupImageUrl = getFullUrl(upsellItem.media.image.uri)
  const upgradeButtonText = upsellItem.price ? upsellItem.price : t('ProductComponentUpsellYes')
  const noButtonText = t('ProductComponentUpsellNo')

  return { popupImageUrl, upgradeButtonText, noButtonText }
}
