import { SplashScreen } from '@dominos/components/splash-screen'
import { useCustomer, useNativeAppData } from '@dominos/hooks-and-hocs'
import { Navigate, Route, Routes } from 'react-router-dom'
import React, { useEffect } from 'react'
import { NavigationConstants } from '../../common/navigation'
import { DeleteAccount } from './delete-account'
import { MyDetails } from './my-details'
import { MyPassword } from './my-password'
import { MyRewards } from './my-rewards'
import { MySavedPayments } from './my-saved-payments'
import { MyInvoice } from './my-invoice'

import { LoginSceneMessage, postWebViewMessage } from '@dominos/business/functions/native-app'
import { useStyles } from './my-account-layout.styles'

const MyAccountRoutes = (redirect?: string) => (
  <Routes>
    {redirect ? <Route path='*' element={<Navigate to={redirect} replace />} /> : null}
    <Route path={'my-rewards'} element={<MyRewards />} />
    <Route path={'details'} element={<MyDetails />} />
    <Route path={'password'} element={<MyPassword />} />
    <Route path={'payment-details'} element={<MySavedPayments />} />
    <Route path={'invoice'} element={<MyInvoice />} />
    <Route path={'delete'} element={<DeleteAccount />} />
  </Routes>
)

const NativeAppMyAccountLayout = () => {
  useNativeAppData()
  const { loading, isLoggedIn } = useCustomer()
  const styles = useStyles({ isMobile: true })

  useEffect(() => {
    if (loading) {
      return
    }

    if (isLoggedIn === false) {
      postWebViewMessage<LoginSceneMessage>({ route: NavigationConstants.nativeAppLogin })
    }
  }, [loading, isLoggedIn])

  if (loading) {
    return <SplashScreen testID='native-app-my-account-layout-loading-splash' />
  }

  return (
    <div className={styles.page} data-testid='native-app-my-account-navigation'>
      <div className={styles.wrapper}>{MyAccountRoutes()}</div>
    </div>
  )
}

export { NativeAppMyAccountLayout }
