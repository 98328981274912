import { MutableRefObject } from 'react'
import { useTranslation } from 'react-i18next'
import AdyenCheckoutError from '@adyen/adyen-web/dist/types/core/Errors/AdyenCheckoutError'
import { CoreOptions as AdyenCoreConfig } from '@adyen/adyen-web/dist/types/core/types'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { useReport } from '@dominos/hooks-and-hocs'
import { AdyenPaymentMethodExtended, AdyenState, ReturnUrl } from '../payment-adyen.interface'
import { getCreditCardTranslations, getGiftCardTranslations } from '../payment-adyen-translations'

export const useAdyenConfig = (
  adyenPaymentMethod: AdyenPaymentMethodExtended,
  returnUrl: MutableRefObject<ReturnUrl>,
  setAdyenComponentState?: React.Dispatch<React.SetStateAction<AdyenState | undefined>>,
): AdyenCoreConfig => {
  const { t } = useTranslation('checkout')
  const config = getCountryConfig()
  const { reportPaymentCompleted, reportPaymentFailed } = useReport()

  const handlePaymentCompleted = (result?: { resultCode: string }) => {
    reportPaymentCompleted(result?.resultCode || JSON.stringify(result), 'handlePaymentCompleted')
    window.location.href = returnUrl.current.standard
  }

  const handlePaymentFailed = (error?: Omit<AdyenCheckoutError, 'stack'>) => {
    reportPaymentFailed(JSON.stringify({ error: { name: error?.name, message: error?.message, cause: error?.cause } }))
  }

  const initialAdyenConfig: AdyenCoreConfig = {
    paymentMethodsResponse: {
      paymentMethods: [adyenPaymentMethod],
    },
    environment: config.ADYEN_ENVIRONMENT,
    clientKey: config.ADYEN_CLIENT_KEY,
    onChange: setAdyenComponentState,
    onPaymentCompleted: handlePaymentCompleted,
    onError: handlePaymentFailed,
  }

  switch (adyenPaymentMethod.type) {
    case 'scheme':
    case 'bcmc':
      return {
        ...initialAdyenConfig,
        locale: 'custom',
        translations: getCreditCardTranslations(t),
      }
    case 'giftcard':
      return {
        ...initialAdyenConfig,
        locale: 'custom',
        translations: getGiftCardTranslations(t),
      }
    default:
      return initialAdyenConfig
  }
}
