import { RetrieveTokensOptions, TokenOptions } from 'packages/business/constants'
import { OAuth2Tokens, TokenManager } from '@forgerock/javascript-sdk'

export const getTokenOptions = async (options: TokenOptions): Promise<void | OAuth2Tokens> => {
  if (isRetrieveTokensOptions(options)) {
    return await TokenManager.getTokens(options)
  }

  await TokenManager.getTokens(options)
}

export const isRetrieveTokensOptions = (options: TokenOptions): options is RetrieveTokensOptions =>
  'query' in options && options.query !== undefined && 'code' in options.query && 'state' in options.query
