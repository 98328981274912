import { productChangesTransform } from '@dominos/business/functions'
import { CrustIcon, ProductIngredientCard, SauceIcon, SizeIcon } from '@dominos/components'
import { ToppingProps } from '@dominos/components/product/functions/interfaces'
import {
  BaseElement,
  ProductCustomiserProps,
  SauceElement,
  SelectorProps,
} from '@dominos/components/product/product-customiser/'
import { ProductUpsellComponentBanner } from '@dominos/components/product/product-upsell/product-upsell-component-banner/product-upsell-component-banner'
import { ProductUpsellComponentPopup } from '@dominos/components/product/product-upsell/product-upsell-component-popup/product-upsell-component-popup'
import {
  BannerMedia,
  ItemType,
  MediaBase,
  PopupMedia,
  ProductUpsellZoneId,
  UpsellItem,
} from '@dominos/components/product/product-upsell/product-upsell.interface'
import { useBreakpoints, useDevToggles, useDominosTheme, useFeatures, useProductUpsell } from '@dominos/hooks-and-hocs'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getScrollProvider, scrollTo } from '../product-card/functions'
import { CustomisationCard } from './customisation-card'

export const NUM_COLUMNS = 3

const useBaseUpsellBannerData = (baseData: SelectorProps<BaseElement> | undefined) =>
  useProductUpsell<BannerMedia>({
    zoneId: ProductUpsellZoneId.ProductBaseUpsellBanner,
    triggerContexts: [
      {
        itemType: ItemType.Dimension,
        dimensionType: 'Base',
        selectedCode: baseData?.selectedItemCode || '',
        availableCodes: baseData?.items?.map((item) => item.code) || [],
      },
    ],
  })

const useUpsellPopupData = (
  baseData: SelectorProps<BaseElement> | undefined,
  toppingData: ToppingProps | undefined,
  sauceData: SelectorProps<SauceElement> | undefined,
) =>
  useProductUpsell<PopupMedia>({
    zoneId: ProductUpsellZoneId.ProductUpsellPopup,
    triggerContexts: [
      {
        itemType: ItemType.Dimension,
        dimensionType: 'Base',
        selectedCode: baseData?.selectedItemCode || '',
        availableCodes: baseData?.items?.map((item) => item.code) || [],
      },
      {
        itemType: ItemType.Ingredient,
        ingredientType: 'Topping',
        selectedCodes: toppingData?.currentIngredients.map((item) => item.code) || [],
        availableCodes: toppingData?.possibleIngredients.map((item) => item.code) || [],
      },
      {
        itemType: ItemType.Ingredient,
        ingredientType: 'Sauce',
        selectedCodes: [sauceData?.selectedItemCode || ''],
        availableCodes: sauceData?.items.map((item) => item.code) || [],
      },
    ],
  })

const useToppingsUpsellData = (toppingData: ToppingProps | undefined) =>
  useProductUpsell<MediaBase>({
    zoneId: ProductUpsellZoneId.ProductToppingUpsellList,
    triggerContexts: [
      {
        itemType: ItemType.Ingredient,
        ingredientType: 'Topping',
        selectedCodes: [],
        availableCodes: toppingData?.possibleIngredients.map((item) => item.code) || [],
      },
    ],
  })

export const ProductCustomiser = ({
  sizeData,
  onSizeChange,
  baseData,
  onBaseChange,
  sauceData,
  onSauceChange,
  toppingData,
  onToppingChange,
  calculateCurrentIngredientCount,
}: ProductCustomiserProps) => {
  const { isEnabled } = useDevToggles()
  const upsellBannerComponentEnabled = isEnabled['enable-upsell-banner-component']
  const upsellPopupComponentEnabled = isEnabled['enable-upsell-popup-component']
  const upsellToppingListEnabled = isEnabled['enable-upsell-topping-list-component']
  const [isEditToppingExpandedByDefault] = useFeatures('ExpandEditToppingsByDefault')

  const { t } = useTranslation('menu')
  const { isMobile } = useBreakpoints()
  const scrollRef = useRef<HTMLDivElement>(null)
  const isSizeCustomisable = sizeData && sizeData.items.length > 0
  const isBaseCustomisable = baseData && baseData.items.length > 0
  const isSauceCustomisable = sauceData && sauceData.items.length > 0
  const isToppingCustomisable =
    toppingData && toppingData.currentIngredients.length > 0 && !!calculateCurrentIngredientCount
  const setScrollOffset = (distance: number) => scrollTo(distance, getScrollProvider(isMobile, scrollRef.current))

  const baseUpsellBannerData = useBaseUpsellBannerData(baseData)
  const upsellPopupData = useUpsellPopupData(baseData, toppingData, sauceData)
  const toppingsUpsellData = useToppingsUpsellData(toppingData)

  const theme = useDominosTheme()

  const handleUpsellAccepted = <TMedia extends MediaBase>(upsellData: UpsellItem<TMedia>) => {
    const { itemType } = upsellData

    const code = itemType === ItemType.Dimension ? upsellData.dimensionCode : upsellData.ingredientCode
    const type = itemType === ItemType.Dimension ? upsellData.dimensionType : upsellData.ingredientType

    if (itemType === ItemType.Ingredient) {
      if (type === 'Topping') {
        const toppings = productChangesTransform(1, code, undefined, false, toppingData?.changes)
        onToppingChange && onToppingChange([...toppings])
      } else if (type === 'Sauce') {
        onSauceChange?.(code)
      }
    } else {
      if (type === 'Base') {
        onBaseChange?.(code)
      } else if (type === 'Size') {
        onSizeChange?.(code)
      }
    }
  }

  const filteredToppingsUpsellItems = toppingData?.possibleIngredients.filter((item) =>
    toppingsUpsellData[0]?.upsellItems.find(
      (upsell) => upsell.itemType === ItemType.Ingredient && upsell.ingredientCode === item.code,
    ),
  )

  return (
    <>
      {upsellToppingListEnabled &&
        filteredToppingsUpsellItems &&
        filteredToppingsUpsellItems?.length > 0 &&
        isToppingCustomisable && (
          <ProductIngredientCard
            testID='topping-upsell-list'
            primaryTitle={t('ToppingUpsellTitle', { defaultValue: 'Popular Extras' })}
            recipeIngredientCodes={toppingData?.recipeIngredientCodes}
            currentIngredients={toppingData?.currentIngredients}
            possibleIngredients={filteredToppingsUpsellItems || []}
            rules={toppingData?.ingredientRules}
            onItemChange={onToppingChange}
            toppingLineChange={toppingData?.changes}
            onSectionChange={setScrollOffset}
            calculateCurrentIngredientCount={calculateCurrentIngredientCount}
            innerContainerStyle={{
              backgroundColor: theme.colours.actionGreenBackgound,
            }}
            showCurrentIngredients={false}
            startCollapsed={false}
          />
        )}
      {upsellPopupComponentEnabled && upsellPopupData.length > 0 && (
        <ProductUpsellComponentPopup
          testID={'product-popup-upsell'}
          t={t}
          upsellData={upsellPopupData[0].upsellItems[0]}
          onUpsellAccepted={handleUpsellAccepted}
          options={{ enableTimeout: true, persistDismissed: true, showConfirmation: true }}
          zoneId={ProductUpsellZoneId.ProductUpsellPopup}
        />
      )}
      {isSizeCustomisable && (
        <CustomisationCard
          testID={'Size-Selector'}
          items={sizeData.items}
          selectedItemCode={sizeData.selectedItemCode}
          primaryTitle={t('Size')}
          numColumns={NUM_COLUMNS}
          renderItem={SizeIcon}
          onItemSelected={onSizeChange}
        />
      )}
      {isBaseCustomisable && (
        <>
          {upsellBannerComponentEnabled && baseUpsellBannerData.length > 0 && (
            <ProductUpsellComponentBanner
              testID={`product-base-upsell-banner`}
              onUpsellAccepted={handleUpsellAccepted}
              t={t}
              zoneId={ProductUpsellZoneId.ProductBaseUpsellBanner}
              upsellItem={baseUpsellBannerData[0].upsellItems[0]}
              options={{ showConfirmation: true }}
            />
          )}
          <CustomisationCard
            testID={'Base-Selector'}
            items={baseData.items}
            selectedItemCode={baseData.selectedItemCode}
            primaryTitle={t('Base')}
            numColumns={NUM_COLUMNS}
            renderItem={CrustIcon}
            onItemSelected={onBaseChange}
          />
        </>
      )}
      {isSauceCustomisable && (
        <CustomisationCard
          testID={'Sauce-Selector'}
          items={sauceData.items}
          selectedItemCode={sauceData.selectedItemCode}
          primaryTitle={t('Sauce')}
          numColumns={NUM_COLUMNS}
          renderItem={SauceIcon}
          onItemSelected={onSauceChange}
        />
      )}
      {isToppingCustomisable && (
        <ProductIngredientCard
          testID='product-ingredient-card'
          startCollapsed={!isEditToppingExpandedByDefault}
          primaryTitle={t('Ingredients on this Pizza')}
          secondaryTitle={t('AddIngredients', { defaultValue: 'Add Ingredients' })}
          recipeIngredientCodes={toppingData?.recipeIngredientCodes}
          currentIngredients={toppingData?.currentIngredients}
          possibleIngredients={toppingData?.possibleIngredients}
          rules={toppingData?.ingredientRules}
          onItemChange={onToppingChange}
          toppingLineChange={toppingData?.changes}
          onSectionChange={setScrollOffset}
          calculateCurrentIngredientCount={calculateCurrentIngredientCount}
        />
      )}
    </>
  )
}
