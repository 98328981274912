import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AdyenCheckout from '@adyen/adyen-web'
import CardElement from '@adyen/adyen-web/dist/types/components/Card'
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement'
import Core from '@adyen/adyen-web/dist/types/core'
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types'
import { StoredPaymentMethod } from '@adyen/adyen-web/dist/types/types'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { PaymentSetting, useBasketTotal, useReport } from '@dominos/hooks-and-hocs'
import { analytics } from '@dominos/hooks-and-hocs/logging/analytics'
import { NextStep } from '@dominos/hooks-and-hocs/checkout/place-order.interface'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'
import { getAdyenReturnUrl } from './get-adyen-return-url'
import { getCreditCardTranslations } from './payment-adyen-translations'
import { useSelector } from 'react-redux'
import css from './payment-adyen.less'
import { PaymentSavedAdyenCreditCard } from './payment-saved-adyen-creditcard'
import { getAdyenPaymentMethods } from './get-adyen-payment-methods'
import { AdyenPaymentMethodExtended, AdyenState } from './payment-adyen.interface'

const config = getCountryConfig()

// eslint-disable-next-line max-lines-per-function
export const PaymentSavedAdyen = ({
  paymentSetting,
  adyenPaymentMethod,
  onOutstandingBalance,
  outstandingBalance,
  orderId,
  index,
  savedCreditCardSettings,
  ...props
}: PaymentMethodProps & {
  adyenPaymentMethod: AdyenPaymentMethodExtended
  index: string
  savedCreditCardSettings: PaymentSetting[]
}) => {
  const { t } = useTranslation('checkout')
  const [selectedPaymentSetting, setSelectedPaymentSetting] = useState<PaymentSetting>(paymentSetting)
  const [selectedAdyenPaymentMethod, setSelectedAdyenPaymentMethod] =
    useState<AdyenPaymentMethodExtended>(adyenPaymentMethod)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [adyenComponentState, setAdyenComponentState] = useState<AdyenState>()
  const [hidePaymentButton, setHidePaymentButton] = useState(true)
  const { getGoogleID } = analytics()
  const [paymentNextStep, setPaymentNextStep] = useState<NextStep>()
  const [properties, setProperties] = useState<{ key: string; value: string }[] | undefined>()
  const cardInstance = useRef<UIElement>()
  const [cardInstanceMounted, setCardInstanceMounted] = useState<boolean>(false)
  const { reportNextAction, reportOutstandingBalance } = useReport()
  const orderTotal = useBasketTotal()
  const orderPaymentId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderPaymentId)
  const [adyenCheckout, setAdyenCheckout] = useState<Core>()
  const id = `adyen-saved-${paymentSetting.paymentMethod}-${adyenPaymentMethod.type}-${index}`
  const [returnUrl, setReturnUrl] = useState<string>(
    getAdyenReturnUrl(paymentSetting.paymentMethod, orderId!, orderPaymentId, false, getGoogleID()),
  )

  useEffect(() => {
    setReturnUrl(getAdyenReturnUrl(paymentSetting.paymentMethod, orderId!, orderPaymentId, false, getGoogleID()))
  }, [orderId, paymentSetting, orderPaymentId])

  const onPaymentNextStep = (nextStep?: NextStep) => {
    setPaymentNextStep(nextStep)
  }

  const updateProperties = () => {
    if (!cardInstance.current) {
      return
    }

    setProperties([
      {
        key: 'returnUrl',
        value: returnUrl,
      },
      { key: 'brand', value: (cardInstance.current as CardElement).brand },
      { key: 'binValue', value: '' },
      {
        key: 'browserInfo',
        value: JSON.stringify((cardInstance.current as CardElement).browserInfo),
      },
      { key: 'origin', value: window.location.origin },
      { key: 'savePayment', value: (!!adyenComponentState?.data.storePaymentMethod).toString() },
    ])
  }
  useEffect(() => {
    updateProperties()
  }, [adyenComponentState, returnUrl])

  useEffect(() => {
    let adyenConfig: CoreOptions = {
      paymentMethodsResponse: {
        paymentMethods: [],
        storedPaymentMethods: [
          {
            ...(selectedAdyenPaymentMethod as StoredPaymentMethod),
            storedPaymentMethodId: selectedAdyenPaymentMethod.id,
          },
        ],
      },
      environment: config.ADYEN_ENVIRONMENT,
      clientKey: config.ADYEN_CLIENT_KEY,
      onChange: (state: AdyenState) => {
        setAdyenComponentState(state)
      },
    }

    switch (adyenPaymentMethod.type) {
      case 'scheme':
      case 'bcmc':
        adyenConfig = {
          ...adyenConfig,
          // hideCVC: true,
          locale: 'custom',
          translations: getCreditCardTranslations(t),
        }

        setHidePaymentButton(false)
        break
    }

    AdyenCheckout(adyenConfig).then((instance) => setAdyenCheckout(instance))
  }, [paymentNextStep])

  useEffect(() => {
    if (!adyenCheckout) {
      return
    }

    if (paymentNextStep) {
      if (paymentNextStep.nextStepType === 'NextStepAction') {
        const nextAction = JSON.parse(paymentNextStep.nextStepActionPayload)
        reportNextAction({
          ...nextAction,
          userAgent: window.navigator.userAgent,
        })
        cardInstance.current = adyenCheckout.createFromAction(nextAction)
      }

      if (paymentNextStep.nextStepType === 'OutstandingBalance' && onOutstandingBalance) {
        reportOutstandingBalance(paymentNextStep.outstandingBalance)

        const outstandingBalance = {
          orderTotal: orderTotal!,
          processedPaymentName: paymentSetting.paymentMethod,
          orderOutstandingBalance: paymentNextStep.outstandingBalance,
        }

        onOutstandingBalance(outstandingBalance)
      }
    } else {
      // @ts-ignore
      cardInstance.current = adyenCheckout.create('card', adyenCheckout.paymentMethodsResponse.storedPaymentMethods[0])
    }

    if (
      (!cardInstanceMounted || !(paymentNextStep && paymentNextStep.nextStepType === 'OutstandingBalance')) &&
      cardInstance.current
    ) {
      cardInstance.current.mount(`#${id}-container`)
      setCardInstanceMounted(true)
    }
  }, [adyenCheckout, paymentNextStep])

  useEffect(() => {
    if (!cardInstance.current) {
      return
    }

    cardInstance.current.update({
      paymentMethodsResponse: {
        paymentMethods: [],
        storedPaymentMethods: [
          {
            ...(selectedAdyenPaymentMethod as StoredPaymentMethod),
            storedPaymentMethodId: selectedAdyenPaymentMethod.id,
          },
        ],
      },
      ...selectedAdyenPaymentMethod,
      storedPaymentMethodId: selectedAdyenPaymentMethod.id,
    })
  }, [selectedAdyenPaymentMethod])

  const showModalPopup = () => setShowModal(true)
  const closeModalPopup = () => setShowModal(false)
  const onSelect = (savedCreditCardSetting: PaymentSetting) => {
    if (savedCreditCardSetting !== selectedPaymentSetting) {
      setSelectedPaymentSetting(savedCreditCardSetting)
      setSelectedAdyenPaymentMethod(getAdyenPaymentMethods(savedCreditCardSetting)[0])
    }

    setShowModal(false)
  }

  const renderSavedCard = () => {
    if (!cardInstance.current) {
      return null
    }

    return (
      <div className={css['adyen-saved-payment-container']}>
        <img className={css['brand-icon']} src={cardInstance.current.icon} />
        <div className={css['adyen-saved-card-wrapper']}>
          <div className={css['adyen-saved-card']}>
            {selectedAdyenPaymentMethod.name === 'Bancontact card'
              ? t('SavedBancontact', { defaultValue: 'SAVED BANCONTACT' })
              : t('SavedCreditCard', { defaultValue: 'SAVED CREDIT CARD' })}
          </div>
          <div className={css['adyen-saved-card-digits']}>{`XXXX-${selectedAdyenPaymentMethod.lastFour}`}</div>
        </div>
        {savedCreditCardSettings?.length > 1 && (
          <div>
            <div role='button' className={css.changeButton} onClick={showModalPopup}>
              {t('CHANGE')}
            </div>
            {showModal && (
              <PaymentSavedAdyenCreditCard
                testID={'payment-saved-adyen-credit-card'}
                selectedPaymentSetting={selectedPaymentSetting}
                savedCreditCardSettings={savedCreditCardSettings}
                onSelect={onSelect}
                onClose={closeModalPopup}
              />
            )}
          </div>
        )}
      </div>
    )
  }

  return (
    <PaymentMethod
      methodId={id}
      paymentSetting={selectedPaymentSetting}
      {...props}
      invalid={!adyenComponentState?.isValid}
      hidePaymentButton={hidePaymentButton}
      transactionToken={JSON.stringify(adyenComponentState?.data.paymentMethod)}
      properties={properties}
      onPaymentNextStep={onPaymentNextStep}
      outstandingBalance={outstandingBalance}
      adyenPaymentMethodType={selectedAdyenPaymentMethod.type}
      adyenPaymentDefaultName={selectedAdyenPaymentMethod.name}
      paymentButtonTitle={t('Pay Now')}
      paymentButtonIncludeTotal={true}
    >
      {renderSavedCard()}
      <div id={`${id}-container`} data-testid={`${id}-container`} />
    </PaymentMethod>
  )
}
