import { BasketLineData } from '@dominos/business/functions/basket'
import { getDimensionFromDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'
import { getIngredientsForPricing } from '@dominos/hooks-and-hocs/pricing/get-ingredients-for-pricing'
import { getSauce } from '@dominos/hooks-and-hocs/pricing/get-sauce'

const getRecipeIngredientsForProduct = (productRecipeIngredients: IngredientModel[] | undefined): IngredientModel[] =>
  productRecipeIngredients ? productRecipeIngredients.filter((x) => x.inRecipe) : []

const mapProductContextToPriceProductForPortionProducts = (
  code: string,
  dimensionSet: Bff.Dimensions.DimensionSet | undefined,
  portions: Portion[],
): Bff.PriceProduct.PriceProduct => {
  let priceProduct: Bff.PriceProduct.PriceProduct = {
    quantity: 1,
    productCode: code,
    productSizeCode: getDimensionFromDimensionSet('Size', dimensionSet) || '',
    portions: portions.map((portion) => ({
      productCode: portion.productCode!,
      sauces: getSauce(portion.sauce),
      toppings: [],
      options: [],
    })),
  }
  const base = getDimensionFromDimensionSet('Base', dimensionSet)
  if (base) {
    priceProduct = {
      ...priceProduct,
      crusts: [{ componentCode: base, quantity: 1 }],
    }
  }

  return priceProduct
}

const mapProductContextToPriceProductForNonPortionProducts = (
  code: string,
  dimensionSet: Bff.Dimensions.DimensionSet | undefined,
  basketLineData: BasketLineData,
  ingredientsForProduct: IngredientModel[],
  // options?: [], options changes Need to be reflect on price
): Bff.PriceProduct.PriceProduct => {
  let priceProduct: Bff.PriceProduct.PriceProduct = {
    quantity: 1,
    productCode: code,
    productSizeCode: getDimensionFromDimensionSet('Size', dimensionSet) || '',
    portions: [
      {
        productCode: code,
        sauces: getSauce(basketLineData.sauce),
        toppings: getIngredientsForPricing(
          basketLineData.toppings || [],
          getRecipeIngredientsForProduct(ingredientsForProduct),
        ),
        options: [],
      },
    ],
  }

  const base = getDimensionFromDimensionSet('Base', dimensionSet)

  if (base) {
    priceProduct = {
      ...priceProduct,
      crusts: [{ componentCode: base, quantity: 1 }],
    }
  }

  return priceProduct
}

export { mapProductContextToPriceProductForNonPortionProducts, mapProductContextToPriceProductForPortionProducts }
