export const detailsFieldNames = {
  name: 'name',
  alternateName: 'alternate-name',
  mobileNumber: 'mobile-number',
  email: 'email',
  company: 'company',
  rememberMyDetails: 'remember-my-details',
  vipOffersEmail: 'vip-offers-email',
  vipOffersSMS: 'vip-offers-sms',
  vipOffersOptOutAll: 'vip-offers-opt-out-all',
  vipOffersOptOutEmail: 'vip-offers-opt-out-email',
  vipOffersOptOutSMS: 'vip-offers-opt-out-sms',
  uniformNumber: 'uniform-number',
  mobileCarrierCode: 'mobile-carrier-code',
  citizenCarrierCode: 'citizen-carrier-code',
  charityCode: 'charity-code',
} as { [key: string]: string }
