import React from 'react'
import { View } from 'react-native'
import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import {
  AddressSearchErrorCodes,
  PickupStoreErrorComponent,
  PickupStoreSearchScene,
  TitleLabel,
} from 'olo-feature-address'
import { useTranslation } from 'react-i18next'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

export const PickupStoreSearchAutocomplete = () => {
  const { t } = useTranslation('home')

  const FallbackError = (props: FallbackProps) => (
    <PickupStoreErrorComponent
      error={{
        errorCode: AddressSearchErrorCodes.UnknownErrorCallingSearchProvider,
      }}
      setError={() => props.resetErrorBoundary()}
      setSearchInput={(_) => ({})}
    />
  )

  return (
    <StackNavigationBase
      testID={'pickup-store-search-autocomplete-scene'}
      header={<SimpleNavigationTitle showAccount showBackButton />}
    >
      <TitleLabel label={t('Pick Up')}></TitleLabel>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ErrorBoundary FallbackComponent={FallbackError}>
          <PickupStoreSearchScene />
        </ErrorBoundary>
      </View>
    </StackNavigationBase>
  )
}
