export const findProductsInBasket = (productCodes: string[] | undefined, basket: Basket) => {
  if (!productCodes?.length || !basket.lines.length) {
    return undefined
  }

  let productInBasket: BasketLine | undefined

  for (const basketLine of basket.lines) {
    if (basketLine.type === 'BasketLine') {
      productInBasket = findProductInBasketLine(productCodes, [basketLine])
    } else if (basketLine.type === 'BasketCoupon') {
      for (const item of basketLine.items) {
        productInBasket = findProductInBasketLine(productCodes, item.lines)
      }
    }

    if (productInBasket) {
      break
    }
  }

  return productInBasket
}

const findProductInBasketLine = (productCodes: string[], lines: BasketLine[]) =>
  lines.find((line) => productCodes.some((code) => code === line.productCode))
