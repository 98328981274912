import React, { useState } from 'react'

import { AccountDetails } from '../account-details/account-details'
import { ActionButton } from '@dominos/components/buttons'
import { useUpdateCustomer } from '@dominos/hooks-and-hocs'
import css from './profile-update-form.less'
import { useTranslation } from 'react-i18next'

import { TermsAndConditions } from '@dominos/components/create-account/terms-and-conditions'

interface Props {
  customer: Bff.Customers.Customer
  onSuccessfulUpdate: () => void
}
export const ProfileUpdateForm = ({ customer, onSuccessfulUpdate: onUpdate }: Props) => {
  const { t } = useTranslation('login')
  const [isAccountDetailsValid, setIsAccountDetailsValid] = useState(false)
  const [updatedFields, setUpdatedFields] = useState<Bff.Customers.UpdateCustomerInput>()
  const { updateCustomer, loading: isUpdating } = useUpdateCustomer({
    onCompleted: () => onUpdate(),
  })

  const setTermsValid = (isValid: boolean) => {
    setUpdatedFields((prev) => ({ ...prev, termsAndConditionsAccepted: isValid }))
  }

  const onDetailsChange = (field: string, value: string | undefined) => {
    if (!value) {
      return
    }

    setUpdatedFields((prev) => ({ ...prev, [field]: value }))
  }

  const handleSubmit = () => {
    updateCustomer({
      variables: {
        customerId: customer.id,
        input: updatedFields!,
      },
    })
  }

  const isDisabled = isUpdating || !isAccountDetailsValid || !updatedFields?.termsAndConditionsAccepted

  return (
    <div className={css.container}>
      <p>{t('ProfileUpdateFormDescription', { defaultValue: 'A few more steps to update your profile.' })}</p>
      <AccountDetails
        onChange={onDetailsChange}
        onValidationChange={setIsAccountDetailsValid}
        hideEmail={!!customer?.email}
        hideName={!!customer?.name}
        hidePhoneNumber={!!customer?.phoneNumber}
        hideAlternateName
        email={customer?.email}
        name={customer?.name}
        phoneNumber={customer?.phoneNumber}
      />

      <TermsAndConditions setValid={setTermsValid} />

      <ActionButton
        testID='updateAccount.button'
        onPress={handleSubmit}
        text={t(`ProfileUpdateButton`, { defaultValue: 'Submit' })}
        loading={isUpdating}
        disabled={isDisabled}
        textFontWeight='semibold'
        className={css.updateButton}
      />
    </div>
  )
}
