import { ApolloError, useApolloClient } from '@apollo/client'
import { orderQuery, orderStatusQualityAssuranceQuery, orderStatusQuery } from '@dominos/business/queries'
import { OrderResponse } from '@dominos/interfaces'
import { DocumentNode, GraphQLError } from 'graphql'
import _ from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { ErrorScope, useErrorContext } from '@dominos/components/error'
import { paymentErrors, usePaymentErrorHandlers } from '@dominos/components/checkout'
import { networkErrors } from '@dominos/components/error/definitions'

const defaultDelay = 3000
const milliseconds = 1000
const longDelay = 30000

export const getStatusDelay = (status: Bff.Orders.OrderStatus | undefined) => {
  switch (status && status.status) {
    case 'Timed':
    case 'Complete':
    case 'Failed':
    case 'PaymentFailed':
      return longDelay
    default:
      return defaultDelay
  }
}

const isOrderCooked = (orderStatus: Bff.Orders.OrderStatus) =>
  orderStatus &&
  (orderStatus.status === 'Ready' ||
    orderStatus.status === 'Leaving' ||
    orderStatus.status === 'Complete' ||
    orderStatus.status === 'Cancelled' ||
    orderStatus.status === 'Unknown')

export const useOrderPolling = (initialOrder?: Bff.Orders.Order, handleOrderPollingError?: () => void) => {
  const [mergedResults, setMergedResults] = useState<Bff.Orders.Order | undefined>(initialOrder)
  const [pollError, setPollError] = useState<GraphQLError[] | ApolloError | Error | string | null | undefined>()

  const client = useApolloClient()

  const MAX_ERRORS = 100
  const endTimeRef = useRef(0)
  const cancelled = useRef(false)
  const errorCounterRef = useRef(0)
  const pizzaCheckeOrderReadyMaxEllapsedTime = useRef<number>()
  const cancelTimeout = useRef<NodeJS.Timeout>()
  const { notifyError } = useErrorContext()
  const handlers = usePaymentErrorHandlers()

  const pollStopped = () =>
    (Date.now() >= endTimeRef.current && errorCounterRef.current < MAX_ERRORS) || cancelled.current

  const run = (id: string, order?: Bff.Orders.Order) => {
    if (Date.now() < endTimeRef.current && !cancelled.current) {
      if (errorCounterRef.current < MAX_ERRORS) {
        const result = getQuery(order, pizzaCheckeOrderReadyMaxEllapsedTime.current)
        pizzaCheckeOrderReadyMaxEllapsedTime.current = result.pizzaCheckerOrderReadyMaxElapsedTime
        query(id, result.query, order)
      }
    } else if (handleOrderPollingError && !cancelled.current) {
      handleOrderPollingError()
      notifyError({
        handlers,
        definitions: networkErrors,
        error: new ApolloError({
          networkError: Error('useOrderPolling timed out'),
        }),
        scope: ErrorScope.Payment,
      })
    }
  }

  const query = (id: string, orderQueryType: DocumentNode, order?: Bff.Orders.Order) => {
    client
      .query<OrderResponse>({
        query: orderQueryType,
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        variables: {
          id,
        },
      })
      .then((response) => {
        if (response.data && response.data.order) {
          setPollError(undefined)
          const orderMergeResult = _.merge({}, order, response.data.order)
          setMergedResults(orderMergeResult)
          cancelTimeout.current = setTimeout(
            () => run(id, orderMergeResult),
            getStatusDelay(response.data.order && response.data.order.status),
          )
        } else if (response.errors) {
          errorCounterRef.current = errorCounterRef.current + 1
          setPollError(response.errors as GraphQLError[])
          notifyError({
            handlers,
            definitions: paymentErrors,
            error: { graphQLErrors: response.errors } as ApolloError,
            scope: ErrorScope.Payment,
          })
        }
      })
      .catch((err: Error) => {
        errorCounterRef.current = errorCounterRef.current + 1
        setPollError(err)
        cancelTimeout.current = setTimeout(() => {
          run(id)
        }, defaultDelay)
      })
  }

  useEffect(() => pollStop(), [])

  const pollStart = (id: string, maxSeconds: number) => {
    cancelled.current = false
    endTimeRef.current = Date.now() + maxSeconds * milliseconds
    run(id, mergedResults)
  }

  const pollStop = () => {
    if (!pollStopped()) {
      cancelled.current = true
      // immediately reset result because if initiate-order is ran in quick
      // succession it could read the previous result and cause issues
      setMergedResults(initialOrder)
      if (cancelTimeout.current) {
        clearTimeout(cancelTimeout.current)
      }
    }
  }

  return {
    pollStart,
    pollStop,
    pollError,
    pollStopped,
    pollStarted: () => endTimeRef.current > 0,
    pollResult: mergedResults,
  }
}

export const getQuery = (order?: Bff.Orders.Order, orderReadyMaxEllapsedTime?: number) => {
  const isStatusQuery = !!(order && order.details)

  if (isStatusQuery) {
    const result = keepCallingPizzaCheckerSvc(order, orderReadyMaxEllapsedTime)

    if (result.keepCallingPizzaChecker) {
      return {
        query: orderStatusQualityAssuranceQuery,
        pizzaCheckerOrderReadyMaxElapsedTime: result.pizzaCheckerOrderReadyMaxElapsedTime,
      }
    }

    return {
      query: orderStatusQuery,
      pizzaCheckerOrderReadyMaxElapsedTime: result.pizzaCheckerOrderReadyMaxElapsedTime,
    }
  }

  return { query: orderQuery, pizzaCheckerOrderReadyMaxElapsedTime: undefined }
}

const keepCallingPizzaCheckerSvc = (order?: Bff.Orders.Order, currentOrderMaxReadyEllapsedTime?: number) => {
  let pizzaCheckerOrderReadyMaxElapsedTime = currentOrderMaxReadyEllapsedTime
  if (!order) {
    return { pizzaCheckerOrderReadyMaxElapsedTime, keepCallingPizzaChecker: true }
  }
  if (order.qualityAssurance && order.qualityAssurance.isValidationCompleted) {
    return { pizzaCheckerOrderReadyMaxElapsedTime, keepCallingPizzaChecker: false }
  }
  const orderIsCooked = isOrderCooked(order.status)

  if (!orderIsCooked && pizzaCheckerOrderReadyMaxElapsedTime) {
    pizzaCheckerOrderReadyMaxElapsedTime = undefined
  }

  if (orderIsCooked && pizzaCheckerOrderReadyMaxElapsedTime === undefined) {
    const maxTimeEllipsedAfterOrderReady = 120
    pizzaCheckerOrderReadyMaxElapsedTime = Date.now() + maxTimeEllipsedAfterOrderReady * milliseconds
  }

  const orderCookingStatus = 'Cooking'
  const keepCallingPizzaChecker =
    (order.status && order.status.status === orderCookingStatus) ||
    (orderIsCooked && pizzaCheckerOrderReadyMaxElapsedTime && Date.now() < pizzaCheckerOrderReadyMaxElapsedTime)

  return { keepCallingPizzaChecker, pizzaCheckerOrderReadyMaxElapsedTime }
}
