import classnames from 'classnames'
import React from 'react'
import { ActionButton } from '@dominos/components'
import { useTranslation } from 'react-i18next'
import { StaticSvgIcon } from '@dominos/res'
import { kioskButtonTypes } from './kiosk-order-button-definitions'
import css from './kiosk-order-button.less'

export const KioskOrderButtons = ({ testID, onPress, supportedSubServiceMethods, disabled }: KioskButtonProps) => {
  const { t } = useTranslation('kiosk')

  const buttons = (supportedSubServiceMethods ?? [])
    .map((button) => kioskButtonTypes[button])
    .flatMap((button) => button ?? [])
  buttons.length > 0 ? buttons.unshift(kioskButtonTypes.TakeAway) : buttons.push(kioskButtonTypes.Default)

  return (
    <div className={css.buttonsWrapper}>
      {buttons.map((button, index) => (
        <ActionButton
          key={index}
          testID={`${testID}.${button.testID}`}
          className={classnames(css.container, disabled ? css.disabledContainer : button.containerClass)}
          onPress={() => onPress?.(button.subServiceMethod)}
          disabled={disabled}
        >
          <div className={css.innerContainer}>
            {button.icon && <StaticSvgIcon name={button.icon} fill='white' className={css.buttonIcon} isUnstyled />}
            <span className={css.buttonLabel}>{t(button.label.key, { defaultValue: button.label.defaultValue })}</span>
            {button.icon && (
              <StaticSvgIcon name='chevron' fill='white' direction='right' className={css.buttonIconSmall} isUnstyled />
            )}
          </div>
        </ActionButton>
      ))}
    </div>
  )
}
