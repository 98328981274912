import { formatSuperscript } from '@dominos/business/functions/text'
import { GlutenFreeDisclaimer, ProductPricingDetails } from '@dominos/components'
import { CollapsableTitledCard } from '@dominos/components/cards/collapsable-titled-card'
import { BREAKPOINTS } from '@dominos/hooks-and-hocs'
import classNames from 'classnames'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { TFunction } from 'react-i18next'
import css from './product-description.less'

interface ProductDescriptionProps {
  media: Bff.Products.ProductMedia | undefined
  testID: string
  formattedPrice?: string | null | undefined
  formattedPromoPrice: string | null | undefined
  energyKJ?: number | null | undefined
  showGlutenFreeDisclaimer?: boolean
  t: TFunction<'menu'>
}

export const ProductDescription = ({
  media,
  testID,
  t,
  formattedPrice,
  formattedPromoPrice,
  energyKJ,
  showGlutenFreeDisclaimer = false,
}: ProductDescriptionProps) => {
  const ref = useRef<HTMLParagraphElement>(null)

  const MINIMUM_HEIGHT = 38
  const LARGE_MINIMUM_HEIGHT = 66

  const [open, setOpen] = useState(false)
  const [expandableDescription, setExpandableDescription] = useState(false)

  const toggleText = () => {
    setOpen(!open)
  }

  useLayoutEffect(() => {
    const paragraphHeight = ref.current && ref.current.clientHeight ? ref.current.clientHeight : 0
    const paragraphWidth = ref.current && ref.current.clientWidth ? ref.current.clientWidth : 0
    const minHeight = paragraphWidth > (BREAKPOINTS.MOBILE.MAX ?? 772) ? LARGE_MINIMUM_HEIGHT : MINIMUM_HEIGHT

    if (paragraphHeight >= minHeight) {
      setExpandableDescription(true)
    }
  }, [media?.description])

  return (
    <section className={css.productCard}>
      <CollapsableTitledCard
        primaryTitle={media?.name.value ?? ''}
        startCollapsed={false}
        noPadding={false}
        static
        testID={'product-details-card'}
      >
        <div data-testid={`${testID}.subtitle`} className={css.subtitle}>
          <ProductPricingDetails
            testID={`${testID}.subtitle`}
            price={formattedPrice}
            promoPrice={formattedPromoPrice}
            showPrice={true}
            showEnergyDividerForPromo={true}
            kj={energyKJ}
          />
        </div>
        <div
          ref={ref}
          data-testid={`${testID}.description`}
          className={classNames(css.description, !open && css.closed)}
        >
          {media?.description && formatSuperscript(media.description.value)}
        </div>
        {expandableDescription && (
          <p data-testid={`${testID}.button`} role='button' onClick={toggleText} className={css.more}>
            {`${t('show')} ${open ? t('less') : t('more')}`}
          </p>
        )}

        {showGlutenFreeDisclaimer && <GlutenFreeDisclaimer />}
      </CollapsableTitledCard>
    </section>
  )
}
