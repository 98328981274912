import { ApolloError, useLazyQuery } from '@apollo/client'
import { availableStorePaymentMethodsQuery } from '@dominos/business/queries'
import { useFeatures, useKiosk } from '@dominos/hooks-and-hocs'
import { PaymentProvidersResponse } from '@dominos/interfaces'
import { useEffect, useState } from 'react'

export type PaymentSetting = Pick<
  Bff.Stores.StorePaymentSetting,
  | 'paymentMethod'
  | 'providerCode'
  | 'properties'
  | 'savedPayment'
  | 'donationEnabled'
  | 'completeSplitPaymentEnabled'
  | 'accountId'
  | 'maximumOrderValue'
> & { orderPaymentId: string }

export const defaultPaymentProvider: PaymentSetting[] = []

const isAllowedPayment = (
  outstandingBalance: boolean | undefined,
  method: Pick<Bff.Stores.StorePaymentSetting, 'completeSplitPaymentEnabled' | 'providerCode' | 'paymentMethod'>,
) =>
  !outstandingBalance ||
  (outstandingBalance && method.completeSplitPaymentEnabled && !isGiftCard(method.providerCode, method.paymentMethod))

const isGiftCard = (providerCode: string, paymentMethod: string) =>
  providerCode === 'Adyen' && paymentMethod === 'GiftCard'

export const usePaymentsAvailableAtStore = (
  storeNo: number | undefined,
  serviceMethod: BffContext.ServiceMethods | undefined,
  serviceMethodSubType: Bff.Stores.ServiceMethodSubTypes | undefined,
  orderTime?: string | undefined,
  outstandingBalance?: boolean,
): {
  loading: boolean
  error?: ApolloError
  paymentSettings?: PaymentSetting[]
  called: boolean
} => {
  const { isValidKioskOrder, kioskId } = useKiosk()
  const [paymentSettings, setPaymentSettings] = useState<PaymentSetting[]>()
  const [roundUpForCharityEnabled, adyenGivingEnabled, injectKioskHeaderEnabled] = useFeatures(
    'charity-round-up',
    'AdyenGiving',
    'InjectKioskHeader',
  )
  const [filtering, setFiltering] = useState<boolean>(true)

  const withDonationEnabled = roundUpForCharityEnabled || adyenGivingEnabled
  const addKioskHeader = isValidKioskOrder && injectKioskHeaderEnabled

  const [fetchAvailablePayments, { loading, error, data, called }] = useLazyQuery<PaymentProvidersResponse>(
    availableStorePaymentMethodsQuery,
    addKioskHeader ? { context: { headers: { 'x-forwarded-for': kioskId } } } : undefined,
  )

  const filterMethods = (payments: Bff.Stores.StorePaymentSetting[]) =>
    payments.filter(
      (method) =>
        (serviceMethod === 'Delivery' ? method.deliveryEnabled : method.pickupEnabled) &&
        isAllowedPayment(outstandingBalance, method),
    )

  useEffect(() => {
    if (!data && paymentSettings) {
      setPaymentSettings(paymentSettings.filter((method) => isAllowedPayment(outstandingBalance, method)))
    }
  }, [outstandingBalance])

  useEffect(() => {
    if (!!storeNo) {
      fetchAvailablePayments({
        variables: { storeNo, serviceMethod, serviceMethodSubType, orderTime, withDonationEnabled },
      })
    }
  }, [storeNo])

  useEffect(() => {
    if (!called || loading) {
      return
    }

    setFiltering(false)

    if (error || !data?.availablePayments?.length) {
      setDefault()

      return
    }

    const filteredMethods = filterMethods(data.availablePayments).map((payment) => ({
      ...payment,
      orderPaymentId: '',
    }))

    if (filteredMethods.length > 0) {
      setPaymentSettings(filteredMethods)
    } else {
      setDefault()
    }
  }, [called, loading, error, data, serviceMethod, outstandingBalance])

  const setDefault = () => setPaymentSettings(defaultPaymentProvider)

  return {
    loading: filtering || loading,
    error,
    paymentSettings,
    called,
  }
}
