import { useMemo, useState } from 'react'

type FieldFilters = {
  hideEmail?: boolean
  hideName?: boolean
  hideAlternateName?: boolean
  hidePhoneNumber?: boolean
}

const FIELD_NAMES = {
  email: 'email',
  name: 'name',
  phoneNumber: 'phoneNumber',
  alternateName: 'alternateName',
} as const

const getValuesFromProps = (filters: FieldFilters) =>
  (Object.keys(FIELD_NAMES) as (keyof typeof FIELD_NAMES)[]).reduce(
    (values, key) => ({
      ...values,
      [FIELD_NAMES[key]]:
        (key === 'email' && filters.hideEmail) ||
        (key === 'name' && filters.hideName) ||
        (key === 'alternateName' && filters.hideAlternateName) ||
        (key === 'phoneNumber' && filters.hidePhoneNumber),
    }),
    {},
  )

const useAccountValidation = (filter: FieldFilters = {}) => {
  const [validationState, setValidationState] = useState(getValuesFromProps(filter))

  const detailsValid = useMemo(
    () => Object.values(validationState).reduce((l, r) => l && r, true) as boolean,
    [validationState],
  )

  const updateValidationState = (key: string, value: boolean) =>
    setValidationState((prevValidationState) => ({
      ...prevValidationState,
      [key]: value,
    }))

  return {
    fields: FIELD_NAMES,
    detailsValid,
    updateValidationState,
  }
}

export { useAccountValidation }
