import { useLazyQuery } from '@apollo/client'
import { swapSetProductsQuery } from '@dominos/business/queries'
import { SwapSetProductResponse } from '@dominos/interfaces'
import { useEffect, useState } from 'react'

type SwapSetProduct = Bff.Menu.old.SwapSetProduct

export const useSwapSetProducts = (
  storeNo: number | undefined,
  serviceMethod: BffContext.ServiceMethods | undefined,
  orderTime?: string | undefined,
) => {
  const [swapSetProducts, setSwapSetProducts] = useState<SwapSetProduct[]>([])
  const [fetchSwapSetProducts, { loading, error, data }] = useLazyQuery<SwapSetProductResponse>(swapSetProductsQuery, {
    notifyOnNetworkStatusChange: true,
  })

  const getSwapSetProducts = (voucherCode: string, itemNo: number) => {
    fetchSwapSetProducts({
      fetchPolicy: 'no-cache',
      variables: { storeNo, serviceMethod, voucherCode, orderTime, itemNo },
    })
  }
  useEffect(() => {
    if (loading || error) {
      setSwapSetProducts([])

      return
    }

    if (data?.swapSetProducts) {
      setSwapSetProducts(data.swapSetProducts)
    }
  }, [loading, error, data])

  return {
    getSwapSetProducts,
    loading,
    error,
    swapSetProducts,
  }
}
