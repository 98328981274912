import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { applicationName } from '@dominos/business/functions'
import { AnalyticSender, AutoCompleteEvent } from './analytics'
import { SecuritySender } from './security'

export const reportStore = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => {
  const reportStoreSelected = (selectedStore: Bff.Stores.Store) => {
    sendSecurity('Flow.StoreSearch.StoreSelected', {
      'Order ID': currentOrder?.orderId,
      'Store Number': selectedStore.storeNo,
    })
  }

  const reportStoreDiverted = (selectedStore: Bff.Stores.Store, divertedToStore: Bff.Stores.Store) => {
    sendSecurity('Flow.TimedOrder.StoreDiverted', {
      'Order ID': currentOrder?.orderId,
      'Store Number': selectedStore.storeNo,
      'Diverted To Store Number': divertedToStore.storeNo,
    })
  }

  const reportDeliveryAddressSelected = () => {
    sendSecurity('Flow.AddressSearch.DeliveryAddressSelected', {
      'Order ID': currentOrder?.orderId,
    })
  }

  const reportAlternativeStoreSelected = (storeNo: number, originalStoreNo?: number) => {
    sendSecurity('Flow.OrderTime.AlternativeStoreSelected', {
      'Order ID': currentOrder?.orderId,
      'Store Number': storeNo,
      'Original Store Number': originalStoreNo,
      platform: applicationName(),
    })
  }

  const reportOrderTime = (time: string) => {
    sendSecurity('Flow.OrderTime.TimeSelected', {
      'Order ID': currentOrder?.orderId,
      'Store Number': currentStore?.storeNo,
      Time: time,
    })

    sendAnalytics('order_action', {
      order_timing: time === 'ASAP' ? 'now' : 'later',
    })
  }

  const reportAutoCompleteUsage = (status: AutoCompleteEvent['status']) => {
    sendAnalytics('AutoComplete Address Search', {
      event: 'google_autocomplete_address_search',
      status,
    })
  }

  return {
    reportStoreSelected,
    reportStoreDiverted,
    reportDeliveryAddressSelected,
    reportAlternativeStoreSelected,
    reportOrderTime,
    reportAutoCompleteUsage,
  }
}
