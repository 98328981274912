import React, { useEffect, useMemo, useState } from 'react'
import { AdditionalAddressDetailsUpdate, DeliveryAddressAdditionalFieldsContainer } from '@dominos/components/address'
import { AddressMakeUpContainer } from '@dominos/components'
import { convertToStoredCustomerAddress, getOriginalAddress } from '@dominos/business/functions/address'
import { FormValue } from '../details-container.interface'
import { mapAddressComponentToStoredCustomerAddressUpdate } from '@dominos/business/functions/address'
import { rootActions } from '@dominos/business'
import {
  useAdditionalDeliveryAddressSearchFeatures,
  useWcaAdditionalFields,
} from '@dominos/hooks-and-hocs/checkout/use-checkout-delivery-details'
import { useCheckoutDetails, useCountryCode, useCurrentOrderDetails, useOneTrust } from '@dominos/hooks-and-hocs'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

export const DeliveryWCAAddressFields = ({
  testID,
  placeOrderHookForAddressRef,
  className,
}: {
  testID: string
  placeOrderHookForAddressRef: React.MutableRefObject<() => void>
  className?: string
}) => {
  const dispatch = useDispatch()
  const consents = useOneTrust()
  const countryCode = useCountryCode()
  const { t } = useTranslation('delivery-address')
  const { displayAdditionalFieldsWca, shouldSaveExtraAddress } = useWcaAdditionalFields()
  const { selectedDeliveryAddress } = useCurrentOrderDetails()
  const { autoCompleteDeliveryEnabled, deliveryAddressSearchFeature } = useAdditionalDeliveryAddressSearchFeatures()
  const { setIsAdditionalDetailsValid } = useCheckoutDetails()

  const [extraAddress, setExtraAddress] = useState<{ [key: string]: FormValue }>({
    address: selectedDeliveryAddress?.customerAddress.streetNo || undefined,
    buildingName: selectedDeliveryAddress?.customerAddress.buildingName || undefined,
  })

  const [additionalAddressComponents, setAdditionalAddressComponents] = useState<
    AdditionalAddressDetailsUpdate | undefined
  >()

  const additionalAddressDetailsCorrect = useMemo(() => {
    if (autoCompleteDeliveryEnabled || deliveryAddressSearchFeature)
      return displayAdditionalFieldsWca ? additionalAddressComponents?.isValid ?? false : true

    return shouldSaveExtraAddress ? !!extraAddress.address : true
  }, [
    autoCompleteDeliveryEnabled,
    deliveryAddressSearchFeature,
    additionalAddressComponents,
    shouldSaveExtraAddress,
    extraAddress.address,
    displayAdditionalFieldsWca,
  ])

  const handleAddressChange = (field: string | null, value: FormValue) => {
    setExtraAddress((prev) => ({ ...prev, [field!]: value }))
  }

  const saveExtraAddress = () => {
    const { address, buildingName } = extraAddress

    if (address || buildingName) {
      const parsedAddress = convertToStoredCustomerAddress({
        storeNo: selectedDeliveryAddress!.storeNo!,
        rawAddress: getOriginalAddress(selectedDeliveryAddress!),
        address: address as string,
        buildingName: buildingName as string,
        name: selectedDeliveryAddress?.displayAddress,
      })

      parsedAddress.locale = selectedDeliveryAddress?.locale

      dispatch(rootActions.addCustomerAddressToOrder(parsedAddress))
      dispatch(rootActions.saveCustomerAddress(parsedAddress))
    }
  }

  const saveCustomerAddressAutoComplete = () => {
    if (selectedDeliveryAddress && countryCode) {
      const updatedCustomerAddress = mapAddressComponentToStoredCustomerAddressUpdate(
        t,
        selectedDeliveryAddress,
        additionalAddressComponents?.additionalAddressComponents ?? [],
        countryCode,
      )

      dispatch(rootActions.addCustomerAddressToOrder(updatedCustomerAddress.address))

      if (consents.Functional) dispatch(rootActions.saveCustomerAddress(updatedCustomerAddress.address))
    }
  }

  useEffect(() => {
    if (autoCompleteDeliveryEnabled || deliveryAddressSearchFeature) {
      placeOrderHookForAddressRef.current = saveCustomerAddressAutoComplete
    } else if (shouldSaveExtraAddress) {
      placeOrderHookForAddressRef.current = saveExtraAddress
    }

    setIsAdditionalDetailsValid(additionalAddressDetailsCorrect)
  }, [additionalAddressDetailsCorrect, extraAddress, additionalAddressComponents])

  return (
    <div {...(!!className ? { className } : {})}>
      {displayAdditionalFieldsWca && (
        <DeliveryAddressAdditionalFieldsContainer
          testID={`${testID}.delivery-address-additional-fields`}
          customerAddress={selectedDeliveryAddress?.customerAddress}
          onChange={setAdditionalAddressComponents}
        />
      )}
      {shouldSaveExtraAddress && (
        <AddressMakeUpContainer
          addressDetails={{
            address: extraAddress.address as string,
            buildingName: extraAddress.buildingName as string,
          }}
          onChange={handleAddressChange}
        />
      )}
    </div>
  )
}
