import { useValidation, useValidationJP } from '@dominos/business/functions/validation'
import { CountryToggle } from '@dominos/components'
import { mobileNumber } from './config'

export const useMobileValidationRules = () => {
  const { equalLength, isNumeric, isRequired, maxLength, minLength } = useValidation()
  const { validatePhoneNumber } = useValidationJP()

  const mobileValidationRules = CountryToggle({
    AU: [isRequired, isNumeric, equalLength(mobileNumber.length.AU)],
    BE: [isRequired, isNumeric, minLength(mobileNumber.length.BE.min), maxLength(mobileNumber.length.BE.max)],
    DE: [isRequired, isNumeric, minLength(mobileNumber.length.DE.min), maxLength(mobileNumber.length.DE.max)],
    DK: [isRequired, isNumeric, minLength(mobileNumber.length.DK.min), maxLength(mobileNumber.length.DK.max)],
    FR: [isRequired, isNumeric, minLength(mobileNumber.length.FR.min), maxLength(mobileNumber.length.FR.max)],
    JP: [isRequired, validatePhoneNumber],
    LU: [isRequired, isNumeric, minLength(mobileNumber.length.LU.min), maxLength(mobileNumber.length.LU.max)],
    NL: [isRequired, isNumeric, minLength(mobileNumber.length.NL.min), maxLength(mobileNumber.length.NL.max)],
    NZ: [isRequired, isNumeric, minLength(mobileNumber.length.NZ.min), maxLength(mobileNumber.length.NZ.max)],
    SG: [isRequired, isNumeric, equalLength(mobileNumber.length.SG)],
    MY: [isRequired, isNumeric, minLength(mobileNumber.length.MY.min), maxLength(mobileNumber.length.MY.max)],
    TW: [isRequired, isNumeric, equalLength(mobileNumber.length.TW)],
    default: [isRequired, isNumeric, equalLength(mobileNumber.length.AU)],
  })

  return { mobileValidationRules }
}
