import gql from 'graphql-tag'
import { NONDIMENSIONAL_FIELDS, SIZEBASE_DIMENSIONAL_FIELDS, SIZE_DIMENSIONAL_FIELDS } from './product-fragments'

export const productQuery = gql`
  ${SIZEBASE_DIMENSIONAL_FIELDS}
  ${SIZE_DIMENSIONAL_FIELDS}
  ${NONDIMENSIONAL_FIELDS}
  query product($productCode: String!, $storeNo: Int!, $serviceMethod: ServiceMethodEnum!, $requestTime: DateTime) {
    product(productCode: $productCode, storeNo: $storeNo, serviceMethod: $serviceMethod, requestTime: $requestTime) {
      code
      classCode
      statuses {
        value {
          isAvailable
          status
        }
        dimensionSets {
          ... on SizeBaseDimensionSet {
            ...SizeBaseDimensionSetFields
          }
          ... on SizeDimensionSet {
            ...SizeDimensionSetFields
          }
          ... on NonDimensional {
            ...NonDimensionalFields
          }
        }
      }
      legends {
        code
      }
      tags {
        tag
      }
      media {
        name {
          value
        }
        description {
          value
        }
        smallImage {
          uri
          altText
        }
        largeImage {
          uri
          altText
        }
      }
      defaultDimensionSet {
        ... on SizeBaseDimensionSet {
          ...SizeBaseDimensionSetFields
        }
        ... on SizeDimensionSet {
          ...SizeDimensionSetFields
        }
        ... on NonDimensional {
          ...NonDimensionalFields
        }
      }
      possibleDimensionSets {
        ... on SizeBaseDimensionSet {
          ...SizeBaseDimensionSetFields
        }
        ... on SizeDimensionSet {
          ...SizeDimensionSetFields
        }
        ... on NonDimensional {
          ...NonDimensionalFields
        }
      }
      nutritionals {
        type
        measure
        nutritionals {
          value {
            code
            value
          }
          dimensionSets {
            ... on SizeBaseDimensionSet {
              ...SizeBaseDimensionSetFields
            }
            ... on SizeDimensionSet {
              ...SizeDimensionSetFields
            }
            ... on NonDimensional {
              ...NonDimensionalFields
            }
          }
        }
      }
      allergens {
        code
        levels {
          value
          dimensionSets {
            ... on SizeBaseDimensionSet {
              ...SizeBaseDimensionSetFields
            }
            ... on SizeDimensionSet {
              ...SizeDimensionSetFields
            }
            ... on NonDimensional {
              ...NonDimensionalFields
            }
          }
        }
      }
      currencyPrices {
        currencyCode
        prices {
          value {
            price
            promoPrice
          }
          dimensionSets {
            ... on SizeBaseDimensionSet {
              ...SizeBaseDimensionSetFields
            }
            ... on SizeDimensionSet {
              ...SizeDimensionSetFields
            }
            ... on NonDimensional {
              ...NonDimensionalFields
            }
          }
        }
      }
      type
      dimensionSetType
      features {
        buildYourOwnQuattro {
          isEnabled
          settings {
            productCode
          }
        }
        buildYourOwnHalfHalf {
          isEnabled
          settings {
            productCode
          }
        }
      }
      ... on PortionProduct {
        portions {
          defaultProductCode
          canSwap
        }
        productRules {
          value {
            maxPortionSwap
            maxIdenticalPortions
          }
          dimensionSets {
            ... on SizeBaseDimensionSet {
              ...SizeBaseDimensionSetFields
            }
            ... on SizeDimensionSet {
              ...SizeDimensionSetFields
            }
            ... on NonDimensional {
              ...NonDimensionalFields
            }
          }
        }
        possibleProducts {
          value {
            code
          }
          dimensionSets {
            ... on SizeBaseDimensionSet {
              ...SizeBaseDimensionSetFields
            }
            ... on SizeDimensionSet {
              ...SizeDimensionSetFields
            }
            ... on NonDimensional {
              ...NonDimensionalFields
            }
          }
        }
        portionProductIngredientTypeRules {
          type
          rules {
            value {
              maxChangesPerPortion
              isCommon
            }
            dimensionSets {
              ... on SizeBaseDimensionSet {
                ...SizeBaseDimensionSetFields
              }
              ... on SizeDimensionSet {
                ...SizeDimensionSetFields
              }
              ... on NonDimensional {
                ...NonDimensionalFields
              }
            }
          }
        }
      }
      ... on RecipeProduct {
        ingredients {
          type
          code
          statuses {
            value {
              isAvailable
              status
            }
            dimensionSets {
              ... on SizeBaseDimensionSet {
                ...SizeBaseDimensionSetFields
              }
              ... on SizeDimensionSet {
                ...SizeDimensionSetFields
              }
              ... on NonDimensional {
                ...NonDimensionalFields
              }
            }
          }
          inRecipe {
            value
            dimensionSets {
              ... on SizeBaseDimensionSet {
                ...SizeBaseDimensionSetFields
              }
              ... on SizeDimensionSet {
                ...SizeDimensionSetFields
              }
              ... on NonDimensional {
                ...NonDimensionalFields
              }
            }
          }
          canAdd {
            value
            dimensionSets {
              ... on SizeBaseDimensionSet {
                ...SizeBaseDimensionSetFields
              }
              ... on SizeDimensionSet {
                ...SizeDimensionSetFields
              }
              ... on NonDimensional {
                ...NonDimensionalFields
              }
            }
          }
          currencyPrices {
            currencyCode
            prices {
              value {
                price
              }
              dimensionSets {
                ... on SizeBaseDimensionSet {
                  ...SizeBaseDimensionSetFields
                }
                ... on SizeDimensionSet {
                  ...SizeDimensionSetFields
                }
                ... on NonDimensional {
                  ...NonDimensionalFields
                }
              }
            }
          }
        }
        ingredientTypeRules {
          type
          rules {
            value {
              canAdd
              canRemove
              minQuantity
              maxQuantity
              maxQuantityPerIngredient
            }
            dimensionSets {
              ... on SizeBaseDimensionSet {
                ...SizeBaseDimensionSetFields
              }
              ... on SizeDimensionSet {
                ...SizeDimensionSetFields
              }
              ... on NonDimensional {
                ...NonDimensionalFields
              }
            }
          }
        }
      }
    }
  }
`
