import React from 'react'

import {
  EmptyFooter,
  ErrorNotifier,
  MyAccountLayout,
  SimpleNavigationTitle,
  StackNavigationBase,
} from '@dominos/components'
import { useStyles } from './my-account-scene.styles'

export const MyAccountScene = () => {
  const styles = useStyles()

  return (
    <StackNavigationBase
      testID='my-account-layout'
      header={<SimpleNavigationTitle showAccount />}
      footer={<EmptyFooter />}
      className={styles.navigationBase}
      showBackButton={false}
    >
      <ErrorNotifier>
        <MyAccountLayout />
      </ErrorNotifier>
    </StackNavigationBase>
  )
}
