import { convertQueryStringToObject } from '@dominos/business/functions/text'
import { PopUpNotification } from '@dominos/components/notification'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Error } from '@dominos/components'
import {
  useLanguageDeepLinkHandler,
  useProductDeepLinkHandler,
  useStoreDeepLinkHandler,
  useVoucherDeepLinkHandler,
} from './handlers'
import { saveVoucherErrorDefinitions } from './save-voucher-errors'
import { storeErrorDefinitions } from './store-errors'
import { useDeepLinkAddress } from './use-deep-link-address'
import { useKiosk } from '../kiosk/use-kiosk'

interface DeepLinkHandlerProps {
  children?: React.ReactNode
}

const DeepLinkHandler: React.FC<DeepLinkHandlerProps> = ({ children }) => {
  const { t } = useTranslation('home')
  const { isDeepLinkAddress, search } = useDeepLinkAddress()
  const { isKioskOrder } = useKiosk()
  const [notifyKey, setShouldNotify] = useState<string | undefined>(undefined)

  const { handle: handleStoreQueryString, error: storeError } = useStoreDeepLinkHandler()
  const { handle: handleLanguageQueryString } = useLanguageDeepLinkHandler()
  const { handle: handleVoucherQueryString, error: voucherError } = useVoucherDeepLinkHandler({
    setNotify: setShouldNotify,
  })
  const { handle: handleProductQueryString } = useProductDeepLinkHandler({
    setNotify: setShouldNotify,
  })

  const onNotificationClose = () => {
    setShouldNotify(undefined)
  }

  useEffect(() => {
    if (isDeepLinkAddress) {
      const {
        vb: voucherBox,
        vc: voucherCode,
        storeNo,
        l: language,
        pc: productCode,
      } = convertQueryStringToObject(search) ?? {
        vc: '',
        vb: '',
        storeNo: '',
        l: '',
        pc: '',
      }

      if (language) {
        handleLanguageQueryString({ language })
      }

      if (storeNo && !isKioskOrder) {
        handleStoreQueryString({ storeNo })
      }

      if (voucherCode) {
        handleVoucherQueryString({ voucherBox, voucherCode })
      }

      if (productCode) {
        handleProductQueryString({ productCode })
      }
    }
  }, [isDeepLinkAddress, search])

  // TODO rethink errorDefinitions with multiple handlers
  return (
    <>
      {voucherError && <Error tns={t} errorDefinitions={saveVoucherErrorDefinitions} error={voucherError ?? null} />}
      {storeError && <Error tns={t} errorDefinitions={storeErrorDefinitions} error={storeError ?? null} />}
      {notifyKey !== undefined ? <PopUpNotification heading={t(notifyKey)} onClose={onNotificationClose} /> : null}
      {children}
    </>
  )
}

export { DeepLinkHandler }
