import { ActionButton, Modal } from '@dominos/components'
import { PopupMedia, UpsellItem } from '@dominos/components/product/product-upsell/product-upsell.interface'
import {
  withProductUpsellWrapper,
  WithProductUpsellWrapperProps,
} from '@dominos/components/product/product-upsell/with-product-upsell-wrapper/with-product-upsell-wrapper'
import { StaticSvgIcon } from '@dominos/res'
import React from 'react'
import { TFunction } from 'react-i18next'
import { createProductUpsellPopupViewModel } from './create-product-upsell-popup-view-model'
import css from './product-upsell-component-popup.less'

interface ProductUpsellComponentPopupProps {
  testID?: string
  t: TFunction<'menu'>
  upsellData: UpsellItem<PopupMedia>
}

const ProductUpsellComponentPopup: React.FC<ProductUpsellComponentPopupProps & WithProductUpsellWrapperProps> = ({
  t,
  testID,
  upsellData,
  onUpsellAccepted,
  onUpsellDeclined,
}) => {
  const viewModel = createProductUpsellPopupViewModel({ t: t, upsellItem: upsellData })

  const handleUpsellConfirmation = () => {
    if (upsellData) {
      onUpsellAccepted(upsellData, upsellData.media.name.value)
    }
  }

  const handleUpsellRejection = () => {
    onUpsellDeclined()
  }

  const buttons = (
    <div className={css.buttonsContainer}>
      <ActionButton
        className={css.yesButtonContainer}
        text={viewModel.upgradeButtonText}
        onPress={handleUpsellConfirmation}
        testID={`${testID}-upgrade-button`}
      />
      <ActionButton
        className={css.noButtonContainer}
        text={viewModel.noButtonText}
        onPress={handleUpsellRejection}
        testID={`${testID}-no-thanks-button`}
      />
    </div>
  )

  return (
    <Modal testID={`${testID}`} onModalClose={() => handleUpsellRejection()}>
      <div className={css.popupContent}>
        <img src={viewModel.popupImageUrl} className={css.image} />
        <div data-testid={`${testID}-close-button`} className={css.closeIconContainer} onClick={handleUpsellRejection}>
          <StaticSvgIcon name={'crossCloseDark'} isUnstyled width={34} height={34} />
        </div>
        <div className={css.buttons}>{buttons}</div>
      </div>
    </Modal>
  )
}

const WrappedProductUpsellComponentPopup = withProductUpsellWrapper(ProductUpsellComponentPopup)

export { WrappedProductUpsellComponentPopup as ProductUpsellComponentPopup }
