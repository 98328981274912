import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 20,
  height: 20,
}

const MailIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 20 20`} fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1 4.75C1 3.7835 1.80589 3 2.8 3H17.2C18.1941 3 19 3.7835 19 4.75V15.25C19 16.2165 18.1941 17 17.2 17H2.8C1.80589 17 1 16.2165 1 15.25V4.75ZM2.8 4.75V15.25H17.2V4.75H2.8Z'
        fill={fill}
      ></path>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.53473 10.749L1.43473 5.99304L2.36527 4.49503L10 8.97781L17.6347 4.49503L18.5653 5.99304L10.4653 10.749C10.1792 10.917 9.82084 10.917 9.53473 10.749Z'
        fill={fill}
      ></path>
    </svg>
  )
}

export { MailIcon }
