import { convertQueryStringToObject } from '@dominos/business/functions/text'
import PaymentDetailModel from '@dominos/hooks-and-hocs/order/model/payment-detail-model'
import { HandledByAdditionalDetails } from './../payment-container/payment-method/adyen/use-adyen-checkout-config'

export const getPropertiesFromQueryString = (paymentProvider: BffContext.PaymentProviders, search: string) => {
  let properties

  if (search !== '') {
    const obj = convertQueryStringToObject(search)
    delete obj?.type
    delete obj?.ga_redirect_id
    delete obj?.oid
    delete obj?.opid
    delete obj?.[HandledByAdditionalDetails]

    properties =
      paymentProvider === 'GMO' && obj !== undefined
        ? [
            {
              key: Object.keys(obj)[0], // this is the gmoOrderId
              value: Object.values(obj)[0] as string,
            },
          ]
        : [
            {
              key: 'details',
              value: JSON.stringify(obj),
            },
          ]
  }

  return properties
}

export const createPaymentDetails = (
  paymentDetail: PaymentDetailModel,
  properties: Bff.Orders.PlaceOrder.KeyValueProperties[] | undefined,
  transactionToken: string | undefined,
  orderPaymentId: string,
) => ({
  properties,
  transactionToken,
  orderPaymentId,
  paymentMethod: paymentDetail.paymentMethod,
  providerCode: paymentDetail.providerCode,
  amount: paymentDetail.amount,
})
