import { useEffect, useState } from 'react'
import { useBasket, useKiosk, useMenu, useOffersContext } from '@dominos/hooks-and-hocs'

import { upsellOfferFilter } from './offers-helpers'
import { HardUpsellItem } from '@dominos/components/hard-upsell/types'

const EXCLUDED_CATEGORIES = ['Menu.Pizza', 'Menu.MyBox', 'Menu.MyDominosBox']
const HARDUPSELL_LIMIT_KIOSK = 8
const HARDUPSELL_LIMIT_DEFAULT = 6
const INLINE_UPSELL_LIMIT = 3

export enum UpsellOfferType {
  InlineUpsell = 'inline_upsell',
  HardUpsell = 'hard_upsell',
}

export const useUpsellOffer = (offerType: UpsellOfferType) => {
  const { offers } = useOffersContext()
  const { itemsByCode } = useMenu()
  const { isKioskOrder } = useKiosk()
  const { basket } = useBasket()
  const [offerItems, setOfferItems] = useState<HardUpsellItem[]>([])

  const upsellLimit =
    offerType === UpsellOfferType.InlineUpsell
      ? INLINE_UPSELL_LIMIT
      : isKioskOrder
      ? HARDUPSELL_LIMIT_KIOSK
      : HARDUPSELL_LIMIT_DEFAULT

  useEffect(() => {
    const filteredOffers = upsellOfferFilter(offerType, offers, itemsByCode, upsellLimit, EXCLUDED_CATEGORIES, basket)
    setOfferItems(filteredOffers?.[0]?.items || [])
  }, [JSON.stringify(offers), itemsByCode, isKioskOrder, basket])

  return offerItems
}
