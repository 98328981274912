import { rootActions } from '@dominos/business'
import { useDispatch } from 'react-redux'

export interface HandleProductDeepLinkHandlerProps {
  productCode: string
}

export const useProductDeepLinkHandler = ({ setNotify }: { setNotify: (title: string) => void }) => {
  const dispatch = useDispatch()

  const handle = ({ productCode }: HandleProductDeepLinkHandlerProps) => {
    if (productCode) {
      dispatch(rootActions.storeDeepLinkProduct(productCode))
      setNotify('DeepLinkProductAdded')
    }
  }

  return {
    handle,
  }
}
