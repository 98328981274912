import React from 'react'
import { createProductNutritionalViewModel } from './create-product-nutritional-view-model'
import { ProductNutritionalView } from './product-nutritional-view'
import { ProductNutritionalCardProps } from './product-nutritional.interface'
import { useFeatures, useMarket } from '@dominos/hooks-and-hocs'
import { useProductContext } from '@dominos/components'

export const ProductNutritionalCard = ({ testID, t }: ProductNutritionalCardProps): JSX.Element => {
  const [nutritionalEnabled, nutritionalInfoButtonEnabled] = useFeatures(
    'nutritionals',
    'ProductNutritionalInformationButton',
  )

  const { productData, dimensionSetState } = useProductContext()
  const { getKeyForNutritional, getKeyForAllergen } = useMarket()

  const { productNutritionals, productAllergens } = nutritionalEnabled
    ? {
        productNutritionals: productData?.getNutritionals('Weighted', dimensionSetState?.selectedDimensionSet),
        productAllergens: productData?.getAllergens(dimensionSetState?.selectedDimensionSet) ?? [],
      }
    : { productNutritionals: undefined, productAllergens: [] }

  const viewModel = createProductNutritionalViewModel({
    t,
    nutritionals: productNutritionals,
    allergens: productAllergens,
    showInfoButton: nutritionalInfoButtonEnabled,
    getKeyForNutritional,
    getKeyForAllergen,
  })

  return (
    <>
      {nutritionalInfoButtonEnabled || nutritionalEnabled ? (
        <ProductNutritionalView testID={testID} viewModel={viewModel} t={t} />
      ) : null}
    </>
  )
}
