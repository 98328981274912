import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Modal, Sheet } from 'olo-ux'
import { useFeatures } from '@dominos/hooks-and-hocs/features'
import { useForgeRock } from '@dominos/hooks-and-hocs/authentication/forgerock'
import { useAccountDelete } from '@dominos/hooks-and-hocs/customer/use-account-delete'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import { useStyles } from './delete-account.styles'
import { useBreakpoints } from '@dominos/hooks-and-hocs'

const DeleteAccount = () => {
  const { deleteAccount, inProgress } = useAccountDelete()
  const [forgeRockIdentityProviderEnabled] = useFeatures('ForgeRockIdentityProvider')
  const { deleteAccountForgeRock } = useForgeRock()
  const { t } = useTranslation('login')
  const styles = useStyles()
  const { isMobile } = useBreakpoints()
  const [showConfirm, setShow] = useState(false)

  const onDeleteAccount = () => {
    if (forgeRockIdentityProviderEnabled) {
      isNativeApp() ? notifyNativeApp('delete-oauth-account') : deleteAccountForgeRock()

      return
    }
    deleteAccount()
  }

  const ModalSheet = isMobile ? Sheet : Modal

  return (
    <div className={styles.container}>
      {!isNativeApp() && (
        <h2 className={styles.title}>{t('DeleteAccountHeading', { defaultValue: 'Delete Account' })}</h2>
      )}
      <p className={styles.text}>
        {t('DeleteAccountDescription', {
          defaultValue:
            'This will delete your account and you will not be able to log on again using your saved credentials. This cannot be undone.',
        })}
      </p>
      <Button
        type='destructive'
        size='large'
        disabled={inProgress}
        label={t('DeleteAccountButton', { defaultValue: 'Delete Account' })}
        onPress={() => setShow(true)}
      />
      {showConfirm && (
        <ModalSheet
          title={t('DeleteAccountButton', { defaultValue: 'Delete Account' })}
          footerType='stacked'
          primaryButtonProps={{
            label: t('Delete'),
            onPress: onDeleteAccount,
            type: 'destructive',
          }}
          secondaryButtonProps={{
            label: t('Cancel'),
            onPress: () => setShow(false),
            type: 'tertiary',
          }}
          onClose={() => setShow(false)}
        >
          <div className={styles.sheetText}>
            {t('DeleteAccountConfirmationDialog', {
              defaultValue: 'Are you sure you want to delete your account? This action cannot be undone.',
            })}
          </div>
        </ModalSheet>
      )}
    </div>
  )
}

export { DeleteAccount }
