import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { SwapType } from '@dominos/interfaces'
import { AnalyticSender, EventSourceType, FeatureEventSender } from './analytics'
import { SecurityEventNames, SecuritySender, toProducts } from './security'
import { applicationName } from '@dominos/business/functions'
import { getCountryConfig } from '@dominos/business/functions'

declare type ReportConvertibleProduct =
  | BasketLine[]
  | Bff.Orders.OrderDetails.Basket.ProductLine[]
  | ProductMenuItem[]
  | MenuItemDependents[]
  | BasketLineUnionDependancy[]

declare type CardType = 'Visa' | 'Mastercard' | 'AmericanExpress'

const reportProductAddToCartV2 = (
  sendAnalytics: AnalyticSender,
  lines: ReportConvertibleProduct,
  eventSource: EventSourceType,
  couponCode?: string,
) => {
  const addToCartData = toProducts(lines, couponCode).map(({ currency, ...item }) => item)

  sendAnalytics('add_to_cart', {
    ecommerce: {
      currency: getCountryConfig().CURRENCY_CODE,
      items: [...addToCartData],
    },
    event_source: eventSource,
  })
}

const reportProductAddToCartV1 = (
  sendAnalytics: AnalyticSender,
  lines: ReportConvertibleProduct,
  eventSource: EventSourceType,
  couponCode?: string,
) => {
  sendAnalytics('add_to_cart', {
    items: toProducts(lines, couponCode),
    event_source: eventSource,
  })
}

const reportProductAddToCart =
  (
    sendSecurity: SecuritySender,
    sendAnalytics: AnalyticSender,
    addToCartEventV2: boolean,
    currentOrder: CurrentOrderDetailsReducerProps | undefined,
  ) =>
  (lines: ReportConvertibleProduct, eventSource: EventSourceType, couponCode?: string) => {
    reportProductAction(sendSecurity, currentOrder, 'Flow.Basket.ProductAddedToBasket', lines, couponCode)
    addToCartEventV2
      ? reportProductAddToCartV2(sendAnalytics, lines, eventSource, couponCode)
      : reportProductAddToCartV1(sendAnalytics, lines, eventSource, couponCode)
  }

const reportProductRemoveFromCart =
  (
    sendSecurity: SecuritySender,
    sendAnalytics: AnalyticSender,
    currentOrder: CurrentOrderDetailsReducerProps | undefined,
  ) =>
  (lines: ReportConvertibleProduct, couponCode?: string) => {
    reportProductAction(sendSecurity, currentOrder, 'Flow.Basket.ProductRemovedFromBasket', lines, couponCode)

    sendAnalytics('remove_from_cart', {
      items: toProducts(lines, couponCode),
    })
  }

const reportProductReplaceInCartV1 = (
  sendAnalytics: AnalyticSender,
  add: ReportConvertibleProduct,
  couponCode?: string,
  swapType?: SwapType,
) => {
  sendAnalytics('add_to_cart', {
    items: toProducts(add, couponCode, swapType),
    event_source: EventSourceType.ReplaceInCart,
  })
}
const reportProductReplaceInCartV2 = (
  sendAnalytics: AnalyticSender,
  add: ReportConvertibleProduct,
  couponCode?: string,
  swapType?: SwapType,
) => {
  const addUpdateToCartData = toProducts(add, couponCode, swapType).map(({ currency, ...item }) => item)

  sendAnalytics('add_to_cart', {
    ecommerce: {
      currency: getCountryConfig().CURRENCY_CODE,
      items: [...addUpdateToCartData],
    },
    event_source: EventSourceType.ReplaceInCart,
  })
}

const reportProductReplaceInCart =
  (
    sendSecurity: SecuritySender,
    sendAnalytics: AnalyticSender,
    addToCartEventV2: boolean,
    currentOrder: CurrentOrderDetailsReducerProps | undefined,
  ) =>
  (add: ReportConvertibleProduct, remove: ReportConvertibleProduct, couponCode?: string, swapType?: SwapType) => {
    reportProductAction(sendSecurity, currentOrder, 'Flow.Basket.ProductReplacedInBasket', add, couponCode, remove)
    addToCartEventV2
      ? reportProductReplaceInCartV2(sendAnalytics, add, couponCode, swapType)
      : reportProductReplaceInCartV1(sendAnalytics, add, couponCode, swapType)

    sendAnalytics('remove_from_cart', {
      items: toProducts(remove, couponCode, swapType),
    })
  }

const reportProductAction = (
  sendSecurity: SecuritySender,
  currentOrder: CurrentOrderDetailsReducerProps | undefined,
  name: SecurityEventNames,
  lines: ReportConvertibleProduct,
  couponCode?: string,
  replaced?: ReportConvertibleProduct,
) => {
  const data: IBasketEventParams = {
    'Order ID': currentOrder?.orderId,
    Items: toProducts(lines, couponCode),
  }
  if (replaced) {
    data.Replaced = toProducts(replaced, couponCode)
  }

  sendSecurity(name, data)
}

const reportHardUpsellProductAddedToCart =
  (sendFeatureEvent: FeatureEventSender, sendAnalytics: AnalyticSender) => (lines: ReportConvertibleProduct) => {
    sendFeatureEvent('add_to_basket_hardupsell')
    sendAnalytics('side_added_from_hardupsell', {
      event: 'side_added_from_hardupsell',
      items: toProducts(lines),
      platform: applicationName(),
    })
  }
const reportHardUpsellSkip = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('hardupsell_skip', {
    event: 'hardupsell_skip',
    platform: applicationName(),
  })
}

const reportHardUpsellInitialised = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('hardupsell_initialised', {
    event: 'hardupsell_initialised',
    platform: applicationName(),
  })
}

const reportProductUpsellEnabled = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('premium_upsell_enabled')
}

const reportProductUpsellAdded = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('premium_upsell_added')
}

const reportProductUpsellRemoved = (sendAnalytics: AnalyticSender) => () => {
  sendAnalytics('premium_upsell_removed')
}

const reportPortionSwap =
  (sendAnalytics: AnalyticSender) =>
  (
    productCode: string,
    portionIndex: number,
    swappedInPortionProductCode: string | undefined,
    swappedOutPortionProductCode: string | undefined,
  ) => {
    sendAnalytics('portion_swap', {
      parent_item_id: productCode,
      swap_position: portionIndex,
      swapped_in_item_id: swappedInPortionProductCode,
      swapped_out_item_id: swappedOutPortionProductCode,
    })
  }

const reportCreateHalfHalf = (sendAnalytics: AnalyticSender) => (fromItemId: string, toItemId: string) => {
  sendAnalytics('create_halfhalf', {
    from_item_id: fromItemId,
    to_item_id: toItemId,
  })
}

const reportCreateBYO =
  (sendAnalytics: AnalyticSender) => (byoType: 'halfhalf' | 'quattro', fromItemId: string, toItemId: string) => {
    sendAnalytics('create_byo', {
      byo_type: byoType,
      from_item_id: fromItemId,
      to_item_id: toItemId,
    })
  }

const reportProductCardInteraction =
  (sendAnalytics: AnalyticSender) => (elementId: string, interactionType: string) => {
    sendAnalytics('product_card_interaction', {
      element_id: elementId,
      interaction_type: interactionType,
    })
  }

export const reportProduct = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  sendFeatureEvent: FeatureEventSender,
  addToCartEventV2: boolean,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => ({
  reportPortionSwap: reportPortionSwap(sendAnalytics),
  reportProductAddToCart: reportProductAddToCart(sendSecurity, sendAnalytics, addToCartEventV2, currentOrder),
  reportProductRemoveFromCart: reportProductRemoveFromCart(sendSecurity, sendAnalytics, currentOrder),
  reportProductReplaceInCart: reportProductReplaceInCart(sendSecurity, sendAnalytics, addToCartEventV2, currentOrder),
  reportHardUpsellProductAddedToCart: reportHardUpsellProductAddedToCart(sendFeatureEvent, sendAnalytics),
  reportHardUpsellSkip: reportHardUpsellSkip(sendAnalytics),
  reportHardUpsellInitialised: reportHardUpsellInitialised(sendAnalytics),
  reportProductUpsellEnabled: reportProductUpsellEnabled(sendAnalytics),
  reportProductUpsellAdded: reportProductUpsellAdded(sendAnalytics),
  reportProductUpsellRemoved: reportProductUpsellRemoved(sendAnalytics),
  reportCreateHalfHalf: reportCreateHalfHalf(sendAnalytics),
  reportCreateBYO: reportCreateBYO(sendAnalytics),
  reportProductCardInteraction: reportProductCardInteraction(sendAnalytics),
})
