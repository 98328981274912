import { isNativeApp } from '@dominos/business/functions/native-app'
import { analytics } from '@dominos/hooks-and-hocs/logging/analytics'
import { NavigationConstants } from '@dominos/navigation'

export const useInitialProperties = () => {
  const { getGoogleID } = analytics()

  const getQueryParams = () => {
    const gid = getGoogleID()

    if (gid) {
      const params = new URLSearchParams({ ga_redirect_id: gid })

      return `?${params.toString()}`
    }

    return ''
  }

  const getInitialProperties = (isTwoTapCheckoutEnabled: boolean = false) =>
    isNativeApp()
      ? [
          {
            key: 'returnUrl',
            value: `${window.location.origin}${NavigationConstants.nativeAppCheckoutProcessing}${getQueryParams()}`,
          },
          {
            key: 'cancelUrl',
            value: `${window.location.origin}${
              isTwoTapCheckoutEnabled
                ? NavigationConstants.nativeAppCheckout
                : NavigationConstants.nativeAppCheckoutPayment
            }${getQueryParams()}`,
          },
        ]
      : [
          {
            key: 'returnUrl',
            value: `${window.location.origin}${NavigationConstants.checkoutProcessing}${getQueryParams()}`,
          },
          {
            key: 'cancelUrl',
            value: `${window.location.origin}${
              isTwoTapCheckoutEnabled ? NavigationConstants.checkout : NavigationConstants.checkoutPayment
            }${getQueryParams()}`,
          },
        ]

  return {
    getInitialProperties,
  }
}
