import { findEntityByDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'

type DimensionSet = Bff.Dimensions.DimensionSet

export type Allergen = {
  code: Bff.Common.AllergenValue
  level: Bff.Context.Allergens.Level
}

const flattenAllergensForDimensionSet = (
  dimensionSet: DimensionSet | undefined,
  product: Bff.Products.ProductBase<DimensionSet> | undefined,
): Allergen[] => {
  const allergens = product?.allergens
    .map((item) => {
      const level = findEntityByDimensionSet(item.levels, dimensionSet)

      return level ? { code: item.code, level } : undefined
    })
    .filter((item) => item !== undefined)
    .map((item) => item as Allergen)

  return allergens ?? []
}

export { flattenAllergensForDimensionSet }
