import { getCountryConfig } from '@dominos/business/functions/common/get-config'

let cachedCountryConfig: ReturnType<typeof getCountryConfig> | null = null

const getCachedCountryConfig = () => {
  if (!cachedCountryConfig) {
    cachedCountryConfig = getCountryConfig()
  }

  return cachedCountryConfig
}

const mobileMaxLength: number = 25
const nameMinLength: number = 1
const nameMaxLength: number = 70
const fontWeights = {
  bold: 700,
  light: 200,
  semibold: 600,
  normal: 400,
}

export const calcWebFontWeight = (fontWeight?: CommonFontWeight) =>
  fontWeight && fontWeights[fontWeight] ? fontWeights[fontWeight] : fontWeights.normal

export const isValidEmail = (email: string): boolean =>
  email
    .toLowerCase()
    .match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ) !== null

export const getMinMobileLength = (countryCode: BffContext.Countries): number => {
  const magic = {
    NZ: 9,
    AU: 10,
  }

  switch (countryCode) {
    case 'AU':
      return magic.AU
    case 'NZ':
      return magic.NZ
    default:
      return magic.AU
  }
}

export const isValidMobile = (mobile: string, countryCode: BffContext.Countries): boolean =>
  !!mobile &&
  mobile.length > 0 &&
  mobile.length >= getMinMobileLength(countryCode) &&
  mobile.length <= mobileMaxLength &&
  !!mobile.match(/^[-+\d()\s]+$/)

export const isValidName = (name: string): boolean =>
  !name ||
  name.length === 0 ||
  name.length < nameMinLength ||
  name.length > nameMaxLength ||
  !name.match(/^[a-zA-Zëéï \'\-]*$/)

export const isValidAddress = (address: CustomerAddressRequest) =>
  address.streetName &&
  address.streetName.length > 0 &&
  address.streetNo &&
  address.streetNo.length > 0 &&
  address.suburb &&
  address.suburb.length > 0

interface ValidateText {
  valid: boolean
  didChange: boolean
}

export const revalidateText = (
  valid: boolean,
  text?: string,
  required: boolean = false,
  validationFunction?: (text: string) => boolean,
): ValidateText => {
  if (required && (!text || text.length === 0)) {
    return {
      valid: false,
      didChange: valid,
    }
  }

  if (text && validationFunction && !validationFunction(text)) {
    return {
      valid: false,
      didChange: valid,
    }
  }

  return {
    valid: true,
    didChange: !valid,
  }
}

export const getFormattedPrice = (amount?: number, zeroFraction?: boolean) => {
  try {
    const { CURRENCY_CODE, CURRENCY_FORMAT_LOCALE, CURRENCY_DECIMAL_DIGITS_OVERRIDE } = getCachedCountryConfig()
    const minimumFractionDigits = CURRENCY_DECIMAL_DIGITS_OVERRIDE ?? (zeroFraction ? 0 : undefined)
    const maximumFractionDigits = CURRENCY_DECIMAL_DIGITS_OVERRIDE ?? (zeroFraction ? 0 : undefined)

    return new Intl.NumberFormat(CURRENCY_FORMAT_LOCALE, {
      style: 'currency',
      currency: CURRENCY_CODE,
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(amount || 0)
  } catch (err) {
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: 'AUD',
      minimumFractionDigits: zeroFraction ? 0 : undefined,
      maximumFractionDigits: zeroFraction ? 0 : undefined,
    }).format(amount || 0)
  }
}

export const convertQueryStringToObject = (queryString: string | null | undefined = '') => {
  if (queryString == null || queryString === '') {
    return undefined
  }

  const obj: { [key: string]: string | undefined } = {}

  queryString
    .substr(1)
    .split('&')
    .map((p) => p.split('='))
    .forEach(([key, value]) => {
      obj[decodeURIComponent(key)] = value === undefined ? undefined : decodeURIComponent(value)
    })

  return obj
}

export const toPascalCase = (s: string) =>
  s.replace(/[^\s\/\(-]*/g, (t) => t.charAt(0).toUpperCase() + t.substring(1).toLowerCase())
