/* eslint-disable max-lines-per-function */
import { useNavigate } from 'react-router-dom'
import React, { LegacyRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { rootActions } from '@dominos/business'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions'
import {
  ActionButton,
  CollapsableTitledCard,
  OrderButton,
  OrderDetailsPill,
  SPOTLIGHT_KEY_ORDER_DETAILS_PILL,
  SpotlightElement,
  VoucherInput,
} from '@dominos/components'
import { Breadcrumb, getBreadcrumbTitlesMyOrder } from '@dominos/components/breadcrumb'
import { NewVouchersIndicator } from '@dominos/components/new-vouchers-indicator'
import {
  UpsellOfferType,
  useBasket,
  useBasketValidation,
  useBreakpoints,
  useCurrentOrderDetails,
  useCustomer,
  useFeatures,
  useKiosk,
  useReport,
  useSpotlightContext,
  useSpotlightOrderDetails,
  useSwapSetProductsCaching,
  useUpsellOffer,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { ActiveSavedVouchersModal } from './active-saved-vouchers-modal'
import css from './basket-container.less'
import { OrderDetailsCard } from './order-details-card'
import { LoyaltyBasketRewards } from '../../loyalty/loyaltyTwo'
import { InlineUpsellCard } from './inline-upsell-card'

const DESKTOP_NAV_HEIGHT_IN_NUMBER = 94

export const BasketContainer = ({
  testID,
  orderData,
  basketError,
  addCoupon,
  voucherSelected,
  validationErrors,
  clearErrors,
  removeLine,
  editLine,
  hideOrderButton,
  hideEditButton,
  hideSwapButton,
  readonly,
  loading,
  onSubmit = () => {},
  showSubmitButton,
  breadcrumbFlowDepth = 0,
}: BasketContainerProps) => {
  const dispatch = useDispatch()
  const { isMobile } = useBreakpoints()
  const { t: menuTns } = useTranslation('menu')
  const { t: savedVoucherTns } = useTranslation('saved-vouchers')
  const { t: checkoutTns } = useTranslation('checkout')
  const { reportVoucherUsage } = useReport()
  const { isLoggedIn } = useCustomer()
  const { isSpotlightOn } = useSpotlightContext()
  const { basketHeaderData, orderId } = useCurrentOrderDetails()
  const { isKioskOrder } = useKiosk()
  const [orderDetailsPillRef] = useSpotlightOrderDetails()
  const { hardUpsellOrderId } = useBasket()
  const upsellOffers = useUpsellOffer(UpsellOfferType.HardUpsell)
  const navigate = useNavigate()

  useSwapSetProductsCaching(basketHeaderData?.storeNo, basketHeaderData?.serviceMethod)

  const [savedVouchersEnabled, notificationBadgeBasketSavedCoupons, hardUpsell, nativeAppBasketAddOns] = useFeatures(
    'saved-vouchers',
    'NotificationBadgeBasketSavedCoupons',
    'HardUpsell',
    'NativeAppBasketAddOns',
  )
  const isMobileLayout = isMobile || isKioskOrder
  const [showSavedVouchersModalOpen, setShowSavedVouchersModalOpen] = useState(false)
  const [heightOfContainer, setHeightOfContainer] = useState(window.innerHeight - DESKTOP_NAV_HEIGHT_IN_NUMBER)
  const { isBasketValid } = useBasketValidation()
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)
  const deepLinkCoupon = useSelector((state: RootReducer) => state.basketReducer.pendingDeepLinkCoupon)
  const deepLinkProduct = useSelector((state: RootReducer) => state.basketReducer.pendingDeepLinkProduct)

  useEffect(() => {
    if (deepLinkCoupon) {
      dispatch(rootActions.addDeepLinkCoupon())
      reportVoucherUsage(deepLinkCoupon, 'Deeplink')
    }
  }, [deepLinkCoupon])

  useEffect(() => {
    setHeightOfContainer(window.innerHeight - DESKTOP_NAV_HEIGHT_IN_NUMBER)
  }, [window.innerHeight])

  useEffect(() => {
    if (deepLinkProduct) {
      dispatch(rootActions.addDeepLinkProduct())
    }
  }, [deepLinkProduct])

  const onShowSavedVouchers = () => {
    setShowSavedVouchersModalOpen(true)
  }

  const onClickSavedVoucher = (voucherCode: string) => {
    setShowSavedVouchersModalOpen(false)
    addCoupon(voucherCode)
  }

  const onClickCloseSavedVouchers = () => {
    setShowSavedVouchersModalOpen(false)
  }

  const handlePlaceOrderEvent = () => {
    if (!isBasketValid()) return
    if (isNativeApp()) {
      notifyNativeApp('checkout')
    } else {
      const showHardUpsell = hardUpsellOrderId !== orderId
      if (showHardUpsell) {
        dispatch(rootActions.addHardUpsellOrderId(orderId))
      }

      const shouldNavigateToHardUpsell = hardUpsell && showHardUpsell && upsellOffers.length > 0
      const navigationTarget = shouldNavigateToHardUpsell
        ? NavigationConstants.hardUpsell
        : NavigationConstants.checkout

      navigate(navigationTarget)
    }
  }

  return (
    <div
      data-testid={testID}
      className={classnames(css.container, !hideOrderButton && css.withOrderButton)}
      style={{ maxHeight: !hideOrderButton ? heightOfContainer : 'none' }}
    >
      <Breadcrumb
        testID='basket.breadcrumb'
        entities={getBreadcrumbTitlesMyOrder(basketData.total, basketData.lines.length)}
        flowDepth={breadcrumbFlowDepth}
        textNamespace={['checkout', 'basket']}
      />
      {!isNativeApp() && !isKioskOrder && (
        <SpotlightElement
          ref={orderDetailsPillRef as unknown as LegacyRef<HTMLDivElement>}
          spotlightKey={SPOTLIGHT_KEY_ORDER_DETAILS_PILL}
        >
          <OrderDetailsPill
            testID={`${testID}.order-details-pill`}
            disableClick={isSpotlightOn(SPOTLIGHT_KEY_ORDER_DETAILS_PILL)}
          />
        </SpotlightElement>
      )}

      {!readonly && (
        <CollapsableTitledCard
          primaryTitle={menuTns('AddVoucherCode')}
          noPadding={true}
          startCollapsed={isMobileLayout && !voucherSelected}
          testID={`${testID}.voucher-card`}
          style={{ margin: '0px 0px 8px' }}
        >
          <div className={css.voucherDetails}>
            <VoucherInput
              testID={`${testID}.voucher-input`}
              onApply={addCoupon}
              validationErrors={validationErrors}
              onClear={clearErrors}
              basketLines={basketData.lines}
              autoFocus={voucherSelected}
            />
            {savedVouchersEnabled && isLoggedIn && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <ActionButton
                  text={savedVoucherTns('BasketSavedVouchers', { defaultValue: 'Saved Vouchers' })}
                  onPress={onShowSavedVouchers}
                  testID={`${testID}.saved-vouchers`}
                  containerStyle={{ width: '100%' }}
                  textFontWeight='bold'
                />
                {notificationBadgeBasketSavedCoupons && <NewVouchersIndicator showCount={true} />}
              </div>
            )}
          </div>
        </CollapsableTitledCard>
      )}
      <LoyaltyBasketRewards />
      <OrderDetailsCard
        testID={testID}
        orderData={orderData}
        basketError={basketError}
        removeLine={removeLine}
        editLine={editLine}
        hideOrderButton={hideOrderButton || readonly}
        hideEditButton={hideEditButton || readonly}
        hideSwapButton={hideSwapButton || readonly}
        hideRemoveButton={readonly}
        onPlaceOrder={handlePlaceOrderEvent}
        loading={loading}
      />
      {nativeAppBasketAddOns && <InlineUpsellCard testID={'inline-upsell-card'} />}
      {showSubmitButton && (
        <div className={css.orderButtonContainer}>
          <OrderButton
            testID={`${testID}.place-order.button`}
            onPress={onSubmit}
            text={checkoutTns('Pay Now')}
            disabled={!basketData?.lines?.length}
            loading={loading}
            fill
            hideLabel
          />
        </div>
      )}
      {showSavedVouchersModalOpen && (
        <ActiveSavedVouchersModal onClose={onClickCloseSavedVouchers} onClickVoucher={onClickSavedVoucher} />
      )}
    </div>
  )
}
