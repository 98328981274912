import { useLazyQuery } from '@apollo/client'
import { fetchPriceQuery } from '@dominos/business/queries'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { removeOffset } from './remove-offset'

export const usePriceProduct = () => {
  const { basketHeaderData } = useCurrentOrderDetails()

  const [fetchPriceProduct, { data, error }] = useLazyQuery<
    { priceProduct: Bff.PriceProduct.PriceProductDetail },
    Bff.PriceProduct.PriceProductQueryArgs & { withSalePrice?: boolean }
  >(fetchPriceQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const fetchPrice = (product: Bff.PriceProduct.PriceProduct) => {
    const { storeNo, serviceMethod, time, withSalePrice } = basketHeaderData!

    const debounceKey = `fetchPriceQuery${product.productCode}_${product.productSizeCode}`
    fetchPriceProduct({
      variables: {
        withSalePrice,
        storeNo: storeNo!,
        delivered: serviceMethod === 'Delivery',
        product: product,
        orderTime: removeOffset(time),
      },
      context: {
        debounceKey,
        debounceTimeout: 200,
      },
    })
  }

  return {
    data,
    error,
    fetchPrice,
  }
}
