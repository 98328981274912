import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ActionButton } from '@dominos/components'
import { useDominosTheme, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import css from './address-search-button.less'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

const CONTAINER_STYLE = { width: '100%', marginTop: '8px' }

const useAddressSearchButton = (secondary: boolean | undefined) => {
  const { reportInteraction, reportAutoCompleteUsage } = useReport()
  const theme = useDominosTheme()
  const navigate = useNavigate()

  const styles = {
    primary: {
      container: { ...CONTAINER_STYLE, backgroundColor: theme.colours.deemphasisedBlue },
      text: { color: theme.colours.actionBlue },
    },
    secondary: {
      container: { ...CONTAINER_STYLE, backgroundColor: theme.colours.deemphasisedGrey },
      text: { color: theme.colours.web.grey },
    },
  }

  const textStyle = secondary ? styles.secondary.text : styles.primary.text
  const containerStyle = secondary ? styles.secondary.container : styles.primary.container

  const onManualDeliverySearch = () => {
    navigate(NavigationConstants.deliveryAddress)
  }

  const onWrongDeliveryAddress = () => {
    reportAutoCompleteUsage('address not found')
    onManualDeliverySearch()
  }

  const onManualPickupSearch = () => {
    reportInteraction('Pickup')
    navigate(NavigationConstants.storeSearch)
  }

  return {
    textStyle,
    containerStyle,
    onManualDeliverySearch,
    onManualPickupSearch,
    onWrongDeliveryAddress,
  }
}

interface AddressSearchButtonProps extends BaseProps {
  /**
   * Determines `styles`, `text`, and function to call `onPress`.
   */
  type: 'ManualDelivery' | 'ManualPickup' | 'WrongDeliveryAddress' | 'Retry'
  isLoading: boolean
  secondary?: boolean
  onPress?: () => void
  searchValue?: string
}

export const AddressSearchButton = ({
  testID,
  isLoading,
  type,
  secondary,
  onPress,
  searchValue,
}: AddressSearchButtonProps) => {
  const { t } = useTranslation('delivery-address')
  const { textStyle, containerStyle, onManualDeliverySearch, onManualPickupSearch, onWrongDeliveryAddress } =
    useAddressSearchButton(secondary)
  const { reportSelectedClassicDeliverySearch } = useReportAutoCompleteDelivery()

  const onCantFindAddress = () => {
    reportSelectedClassicDeliverySearch(searchValue)
    onWrongDeliveryAddress()
  }

  if (isLoading) {
    return null
  }

  if (type === 'WrongDeliveryAddress') {
    return (
      <div data-testid={testID} data-type={type} className={css.wrapper}>
        <ActionButton
          testID={`${testID}.button`}
          onPress={onCantFindAddress}
          text={t('AddressSearchButtonWrongAddress')}
          textStyle={textStyle}
          containerStyle={containerStyle}
          textFontWeight={'semibold'}
        />
      </div>
    )
  }

  if (type === 'Retry') {
    return (
      <div data-testid={testID} data-type={type} className={css.wrapper}>
        <ActionButton
          testID={`${testID}.button`}
          onPress={onPress}
          text={t('AddressSearchButtonRetry')}
          textStyle={textStyle}
          containerStyle={containerStyle}
          textFontWeight={'semibold'}
        />
      </div>
    )
  }

  return (
    <div data-testid={testID} data-type={type} className={css.wrapper}>
      <ActionButton
        testID={`${testID}.button`}
        onPress={type === 'ManualPickup' ? onManualPickupSearch : onManualDeliverySearch}
        text={t(type === 'ManualPickup' ? 'AddressSearchButtonPickup' : 'AddressSearchButtonManual')}
        textStyle={textStyle}
        containerStyle={containerStyle}
        textFontWeight={'semibold'}
      />
    </div>
  )
}
