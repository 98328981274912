import { Disclaimer } from '@dominos/res/images/icons/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './menu-section-header.less'

const GlutenFreeDisclaimer = () => {
  const { t } = useTranslation('menu')

  const url = t('nutritional information url', {
    defaultValue: 'https://www.dominos.com.au/menu/nutritional-information?contentonly=true',
  })

  return (
    <div className={css.disclaimerContainer}>
      <div data-testid='menu-section-description' className={css.disclaimer}>
        <Disclaimer />
        <a rel='noopener noreferrer' target='_blank' href={`${url}#nutritional-info-ingredient-chart`}>
          <p className={css.description}>
            We cannot guarantee that 100% of our pizzas are allergen or gluten free. Click to see full disclaimer.
          </p>
        </a>
      </div>
    </div>
  )
}
export { GlutenFreeDisclaimer }
