import React, { ReactNode, useState } from 'react'
import { UniversalAuth } from '@dominos/components/universal-auth'
import { StateName, useQuickUniversalAccountComponent } from '@dominos/hooks-and-hocs'
import { Modal, PopUpNotification } from '@dominos/components'
import { AUTHENTICATION_NOTIFICATIONS, NotificationDefinition, NotificationType } from './authentication-notifications'
import { useTranslation } from 'react-i18next'
import { isNativeApp } from '@dominos/business/functions'

export interface AuthenticationPopupHandlerContextProps {
  popupVisible: boolean
  showPopup: (initial: StateName, props?: Record<string, unknown>) => void
  hidePopup: (type?: NotificationType) => void
}

export const AuthenticationPopupHandlerContext = React.createContext<AuthenticationPopupHandlerContextProps>({
  popupVisible: false,
  showPopup: () => {},
  hidePopup: () => {},
})

const TEST_ID = 'universal-authentication'

export const AuthenticationPopupHandler = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('login')
  const [popupVisible, setPopupVisible] = useState(false)
  const [notification, setNotification] = useState<NotificationDefinition | null>(null)
  const { current, reset, setData } = useQuickUniversalAccountComponent()
  const onModalClose = () => setPopupVisible(false)

  const showPopup = (initialState: StateName, props: Record<string, unknown> = {}) => {
    setData(props)
    reset(initialState)
    setPopupVisible(true)
  }

  const hidePopup = (notificationType?: NotificationType) => {
    if (!isNativeApp() && notificationType && AUTHENTICATION_NOTIFICATIONS[notificationType]) {
      setNotification(AUTHENTICATION_NOTIFICATIONS[notificationType])
    }

    setPopupVisible(false)
  }

  const handleNotificationClose = () => setNotification(null)

  return (
    <AuthenticationPopupHandlerContext.Provider
      value={{
        popupVisible: popupVisible,
        showPopup: showPopup,
        hidePopup: hidePopup,
      }}
    >
      {children}
      {notification && (
        <PopUpNotification
          testID={'notification'}
          heading={t(notification.title.key, notification.title.options)}
          message={t(notification.message.key, notification.message.options)}
          onClose={handleNotificationClose}
        />
      )}
      {popupVisible && (
        <Modal testID={TEST_ID} onModalClose={onModalClose} zIndex={4} suppressBackgroundScroll>
          <UniversalAuth testID={TEST_ID} current={current} />
        </Modal>
      )}
    </AuthenticationPopupHandlerContext.Provider>
  )
}

export const useAuthenticationPopup = () => {
  const context = React.useContext(AuthenticationPopupHandlerContext)
  if (!context) {
    throw new Error("'useAuthenticationPopup' must be a child of 'AuthenticationPopupHandler'")
  }

  return context
}
