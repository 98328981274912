import { rootActions } from '@dominos/business'
import { getFormattedPrice, getPaymentCardFormat } from '@dominos/business/functions'
import { ErrorScope, PaymentCard, useErrorContext } from '@dominos/components'
import { BoxedMessage } from '@dominos/components/boxed-message'
import { paymentBalanceErrors } from '@dominos/components/error/definitions'
import {
  useBasketTotal,
  useCustomer,
  useEdenredAuthCode,
  useEdenredURL,
  useFeatures,
  useIsTwoTapCheckoutEnabled,
  useLocalStorage,
  usePaymentBalance,
  useReport,
} from '@dominos/hooks-and-hocs'
import { NextStep } from '@dominos/hooks-and-hocs/checkout/place-order.interface'
import { SHA256 } from 'crypto-js'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import uuid from 'uuid'
import { SavePaymentSwitch } from './save-payment-switch'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'
import { useInitialProperties } from '../use-initial-properties'
import { getRedirectUri } from '../get-redirect-uri'

interface PaymentEdenredProps extends PaymentMethodProps {
  authorisationCode?: string | null
}

// eslint-disable-next-line max-lines-per-function
export const PaymentEdenred = ({
  paymentSetting,
  selectedPaymentSetting,
  isValidatingBasket,
  orderId,
  authorisationCode,
  onOutstandingBalance,
  outstandingBalance,
}: PaymentEdenredProps) => {
  const { t } = useTranslation('checkout')
  const { t: tValidation } = useTranslation('validation')
  const dispatch = useDispatch()
  const { isLoggedIn } = useCustomer()
  const basketTotal = useBasketTotal()
  const { fetchPaymentBalance, paymentBalance, error, loading } = usePaymentBalance()
  const { getEdenredURL } = useEdenredURL()
  const savedAuthorisationCode = useEdenredAuthCode()
  const {
    storedValue: edenredNonce,
    setValue: setEdenredNonce,
    clearValue: clearEdenredNonce,
  } = useLocalStorage<string>({ key: 'edenred-nonce' })
  const { getInitialProperties } = useInitialProperties()
  const { notifyError } = useErrorContext()
  const { reportOutstandingBalance } = useReport()
  const [properties, setProperties] = useState<{ key: string; value: string }[]>(
    getInitialProperties(useIsTwoTapCheckoutEnabled()),
  )
  const [redirecting, setRedirecting] = useState(false)
  const [paymentNextStep, setPaymentNextStep] = useState<NextStep>()
  const [savePaymentEnabled] = useFeatures('SavePaymentEdenredOnCheckout')
  const paymentCardFormat = getPaymentCardFormat('Edenred')
  const isTwoTapCheckoutEnabled = useIsTwoTapCheckoutEnabled()

  const onSavePaymentToggle = (checked: boolean) => {
    setProperties([...getInitialProperties(isTwoTapCheckoutEnabled), { key: 'savePayment', value: checked.toString() }])
  }

  const onPaymentNextStep = (nextStep?: NextStep) => {
    setPaymentNextStep(nextStep)
  }

  useEffect(() => {
    if (selectedPaymentSetting?.paymentMethod !== paymentSetting.paymentMethod) {
      return
    }

    if (!authorisationCode || authorisationCode === savedAuthorisationCode) {
      fetchPaymentBalance(paymentSetting.providerCode, paymentSetting.paymentMethod, orderId)

      return
    }

    if (!edenredNonce) {
      return
    }

    const redirectUri = getRedirectUri(isTwoTapCheckoutEnabled)
    fetchPaymentBalance(
      paymentSetting.providerCode,
      paymentSetting.paymentMethod,
      orderId,
      authorisationCode,
      edenredNonce,
      redirectUri,
    )

    clearEdenredNonce()
  }, [orderId, authorisationCode, paymentSetting, selectedPaymentSetting])

  useEffect(() => {
    if (paymentBalance?.success && !!authorisationCode && authorisationCode !== savedAuthorisationCode) {
      dispatch(rootActions.setEdenredAuthCode(authorisationCode))
    }
  }, [paymentBalance])

  useEffect(() => {
    if (error) {
      notifyError({ error, handlers: {}, definitions: paymentBalanceErrors, scope: ErrorScope.PaymentBalance })
    }
  }, [error])

  useEffect(() => {
    if (paymentNextStep?.nextStepType === 'OutstandingBalance' && onOutstandingBalance) {
      reportOutstandingBalance(paymentNextStep.outstandingBalance)
      const outstandingBalance = {
        orderTotal: basketTotal!,
        processedPaymentName: paymentSetting.paymentMethod,
        orderOutstandingBalance: paymentNextStep.outstandingBalance,
      }
      onOutstandingBalance(outstandingBalance)
    }
  }, [paymentNextStep])

  const onEdenredLogin = () => {
    setRedirecting(true)
    const nonce = SHA256(uuid()).toString()
    setEdenredNonce(nonce)
    window.location.href = getEdenredURL(orderId, nonce, 'checkout')
  }

  const balanceStyle = { gap: '10px' }

  return loading || paymentBalance?.success ? (
    <PaymentMethod
      methodId={paymentSetting.paymentMethod}
      paymentSetting={paymentSetting}
      invalid={loading || paymentBalance?.amount === 0}
      transactionToken='token'
      paymentLimit={paymentBalance?.amount ?? undefined}
      properties={properties}
      selectedPaymentSetting={selectedPaymentSetting}
      isValidatingBasket={isValidatingBasket}
      paymentButtonTitle={t('Pay Now')}
      paymentButtonIncludeTotal={true}
      onPaymentNextStep={onPaymentNextStep}
      outstandingBalance={outstandingBalance}
    >
      <PaymentCard
        {...paymentCardFormat}
        testID='edenred-btn'
        label={t('Edenred', { defaultValue: 'Edenred' })}
        price={loading || !paymentBalance?.amount ? 0 : paymentBalance.amount}
        pricePrefix={t('Balance', { defaultValue: 'Balance' })}
        containerStyle={balanceStyle}
        loading={loading}
      />
      {!loading && paymentBalance?.amount === 0 && (
        <BoxedMessage
          testID='payment-balance-zero-balance'
          icon='exclamation'
          variant='outlined'
          color='secondary'
          role='status'
        >
          {tValidation('PaymentBalanceZeroBalanceMessage', {
            defaultValue: 'Your balance today is {{amount}}. Please choose another payment method for your order.',
            amount: getFormattedPrice(0),
          })}
        </BoxedMessage>
      )}
      {isLoggedIn && !paymentSetting.savedPayment && savePaymentEnabled && (
        <SavePaymentSwitch testID='edenred' onChange={onSavePaymentToggle} />
      )}
    </PaymentMethod>
  ) : (
    <PaymentMethod
      methodId={paymentSetting.paymentMethod}
      paymentSetting={paymentSetting}
      invalid={loading || redirecting}
      onCustomInitiateOrder={onEdenredLogin}
      transactionToken='token'
      properties={properties}
      selectedPaymentSetting={selectedPaymentSetting}
      isValidatingBasket={isValidatingBasket}
      paymentButtonIcon={paymentCardFormat.icon}
      paymentButtonColor={paymentCardFormat.iconColor}
      paymentButtonTitle={t('LoginEdenred', { defaultValue: 'Sign in to MyEdenred' })}
      paymentContentStyle={{ display: 'flex', flexDirection: 'column-reverse', gap: '5px' }}
    >
      <div id='payment-balance-error' />
    </PaymentMethod>
  )
}
