import { createUseClasses } from 'olo-ux'

export const useClasses = createUseClasses((theme, { isMobile }: { isMobile: boolean }) => ({
  container: {
    padding: isMobile ? 0 : theme.size[400],
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  subContainer: {
    padding: isMobile ? theme.size[400] : 0,
  },
  heading: {
    ...theme.text.heading.xl.bold,
    textTransform: 'uppercase',
    marginBottom: theme.size[200],
    marginTop: 0,
  },
  subheading: {
    ...theme.text.label.lg.demi,
    margin: 0,
    marginBottom: theme.size[200],
  },
  paragraph: {
    ...theme.text.body.lg.book,
    color: theme.colors.content.secondary,
    margin: 0,
    marginBottom: theme.size[isMobile ? 400 : 800],
  },
  wrapperInner: {
    marginBottom: theme.size[400],
  },
  content: {
    ...theme.text.body.lg.book,
    color: theme.colors.content.secondary,
    margin: theme.size[400],
  },
  mobileButtonContainer: {
    padding: theme.size[400],
    marginTop: 'auto',
  },
  buttonContainer: {
    marginTop: theme.size[800],
  },
}))
