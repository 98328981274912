import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { AnalyticSender } from './analytics'
import { SecuritySender } from './security'

export const reportPayment = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => {
  const reportPaymentMethodSelected = (PaymentProvider: string, PaymentMethod: string) => {
    sendSecurity(
      'Flow.Payment.MethodSelected',
      {
        PaymentProvider,
        PaymentMethod,
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
      },
      true,
    )

    sendAnalytics('Payment Method', {
      'Store Name': currentStore?.media?.name,
      'Store Id': currentStore?.storeNo,
      'Service Method': currentOrder?.basketHeaderData?.serviceMethod,
      'Service Method Subtype': currentOrder?.basketHeaderData?.serviceMethodSubType,
      'Payment Provider': PaymentProvider as BffContext.PaymentProviders,
      'Payment Method': PaymentMethod as BffContext.PaymentMethods,
    })
  }

  const reportPaymentCompleted = (PaymentResult?: string, EventSource?: string) => {
    sendSecurity(
      'Flow.Payment.Completed',
      {
        EventSource,
        PaymentResult,
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
      },
      true,
    )
  }

  const reportPaymentFailed = (error: string | undefined) => {
    sendSecurity(
      'Flow.Payment.Failed',
      {
        PaymentResult: error,
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
      },
      true,
    )
  }

  const reportNextAction = (nextActionPayload: { data?: {} }) => {
    const nextActionPayloadClone = {
      ...nextActionPayload,
    }
    delete nextActionPayloadClone.data
    sendSecurity(
      'Flow.Payment.NextAction',
      {
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
        PaymentResult: JSON.stringify(nextActionPayloadClone),
      },
      true,
    )
  }

  const reportOutstandingBalance = (outstandingBalance: number) => {
    sendSecurity('Flow.Payment.OutstandingBalance', {
      'Order ID': currentOrder?.orderId,
      'Store Number': currentStore?.storeNo,
      PaymentResult: JSON.stringify({ outstandingBalance }),
    })
  }

  const reportRedirectBack = (data: {}) => {
    sendSecurity(
      'Flow.Payment.RedirectBack',
      {
        'Order ID': currentOrder?.orderId,
        'Store Number': currentStore?.storeNo,
        PaymentResult: JSON.stringify(data),
      },
      true,
    )
  }

  return {
    reportPaymentMethodSelected,
    reportPaymentCompleted,
    reportPaymentFailed,
    reportNextAction,
    reportOutstandingBalance,
    reportRedirectBack,
  }
}
