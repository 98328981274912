import { useNavigate } from 'react-router-dom'
import React from 'react'

import { getColourBrightness } from '@dominos/business/functions/colour'
import { formatCategory, getFullUrl } from '@dominos/business/functions/menu'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { StaticSvgIcon } from '@dominos/res'

import css from './menu-page-card.less'

type MenuPage = Bff.Menu.old.MenuPage

interface MenuPageCardProps {
  page: MenuPage
  index: number
}

// stolen from the native app
const INVERTED_TOLERANCE = 0.62

export const MenuPageCard = ({ page, index }: MenuPageCardProps) => {
  const theme = useDominosTheme()
  const navigate = useNavigate()
  const isInverted = getColourBrightness(page.media.colour) < INVERTED_TOLERANCE
  const title = page.media.name
  const logo = page.media.logo
  const colour = page.media.colour
  const testID = `menu-page-card.${page.code}`

  if (!logo) {
    return null
  }

  const onPress = () => {
    // Converts from 'Menu.Pizza' to 'pizza'
    const formattedCategory = formatCategory(page.code)

    navigate(`${NavigationConstants.menu}/${formattedCategory}`, {
      state: { index },
    })
  }

  return (
    <div
      onClick={onPress}
      role='button'
      className={css.container}
      style={{
        boxShadow: theme.defaultShadow,
        backgroundImage: `url(${getFullUrl(logo.url)})`,
      }}
      key={index}
      data-testid={testID}
      tabIndex={0}
    >
      <div
        data-testid={`${testID}.innerContainer`}
        className={[
          title ? css.innerContainer : css.innerContainerWithoutTitle,
          isInverted ? css.lightOverlay : css.darkOverlay,
        ].join(' ')}
        z-index={2}
      >
        {title && (
          <p data-testid={`${testID}.title`} className={css.title} style={{ color: colour }}>
            {title}
          </p>
        )}

        <div data-testid={`${testID}.caret`} className={css.iconContainer}>
          <StaticSvgIcon name='chevron' isUnstyled fill={isInverted ? '#000' : '#fff'} />
        </div>
      </div>
    </div>
  )
}
