import { ApolloError } from '@apollo/client'
import { useAlert } from '@dominos/hooks-and-hocs'
import { useReport } from '@dominos/hooks-and-hocs/logging'
import { ErrorDefinition, ErrorDefinitions } from '@dominos/interfaces/errors'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationType, PopUpNotification } from '../notification'
import css from './error.less'
import { getMappedErrorToDisplay } from './helpers'

export interface ErrorProps {
  tns: TFunction
  errorDefinitions: ErrorDefinitions
  error: ApolloError | null
  onClose?: () => void
}

export interface ErrorType extends ErrorDefinition {
  translatedError: string
}

export interface ErrorTypes {
  [key: string]: ErrorType[]
}

export const Error = ({ tns, errorDefinitions, error, onClose }: ErrorProps) => {
  const { showAlert } = useAlert()
  const { reportValidationMessage } = useReport()
  const { t: tValidation } = useTranslation('validation')
  const [showPopup, setShowPopup] = useState(false)

  const errorMessages = error
    ? getMappedErrorToDisplay(error, errorDefinitions, tns, tValidation)
    : { alert: [], popup: [], errorText: [] }

  const errorText = errorMessages.errorText.map(({ translatedError }) => translatedError)
  const popupMessages = errorMessages.popup.map(({ translatedError }) => translatedError)

  useEffect(() => {
    errorMessages.alert.forEach(({ translation: { key }, translatedError, onError, callback }) => {
      if (onError) {
        onError()
      }

      showAlert(translatedError, key)

      if (callback) {
        callback()
      }
    })
  }, [error])

  useEffect(() => {
    if (popupMessages.length > 0) {
      setShowPopup(true)

      errorMessages.popup.forEach(({ translation: { key }, translatedError }) => {
        reportValidationMessage(translatedError, key)
      })
    }
  }, [error])

  const onPopupClose = () => {
    if (onClose) {
      onClose()
    }

    setShowPopup(false)
  }

  return (
    <>
      {showPopup && (
        <PopUpNotification
          notificationType={NotificationType.info}
          heading={popupMessages.join('<br />')}
          onClose={onPopupClose}
        />
      )}

      {errorText.length > 0 && (
        <div data-testid='error-message' className={css.errorMessage}>
          {errorText.map((msg) => (
            <p key={`${msg}-1`}>{msg}</p>
          ))}
        </div>
      )}
    </>
  )
}
