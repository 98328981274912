import gql from 'graphql-tag'
import {
  BASE_DIMENSIONAL_FIELDS,
  NONDIMENSIONAL_FIELDS,
  SIZEBASE_DIMENSIONAL_FIELDS,
  SIZE_DIMENSIONAL_FIELDS,
} from './product-fragments'

export const quickReorderQuery = gql`
  ${SIZEBASE_DIMENSIONAL_FIELDS}
  ${SIZE_DIMENSIONAL_FIELDS}
  ${NONDIMENSIONAL_FIELDS}
  ${BASE_DIMENSIONAL_FIELDS}
  query quickReorder($maxProducts: Int!) {
    quickReorder(maxProducts: $maxProducts) {
      type
      code
      dimensionSetType
      dimensionSet {
        ... on SizeBaseDimensionSet {
          ...SizeBaseDimensionSetFields
        }
        ... on SizeDimensionSet {
          ...SizeDimensionSetFields
        }
        ... on NonDimensional {
          ...NonDimensionalFields
        }
        ... on BaseDimensionSet {
          ...BaseDimensionSetFields
        }
      }
      currencyPrices {
        currencyCode
        price
      }
      ... on PricedPortionProduct {
        portions {
          productCode
          ingredientChanges {
            type
            code
            action
            quantity
          }
        }
      }
      ... on PricedRecipeProduct {
        ingredientChanges {
          type
          code
          action
          quantity
        }
      }
    }
  }
`
