import React from 'react'
import { NutritionalInfoProps } from './nutritional-info.interface'
import { ProductNutritionalLine } from '../product-nutritional-line'
import css from './nutritional-info.less'

export const NutritionalInfo = ({
  t,
  productNutritionals,
  nutritionalTitle,
  testID,
}: NutritionalInfoProps): JSX.Element => (
  <div data-testid={`${testID}.nutritional-info`}>
    <div data-testid={`${testID}.nutritional-title`} className={css.nutritionalTitle}>
      {nutritionalTitle}
    </div>
    {productNutritionals?.map((productNutritional, index) => (
      <ProductNutritionalLine
        key={index}
        info={productNutritional.value}
        keyName={productNutritional.name}
        defaultUnit={productNutritional.unit}
        t={t}
      />
    ))}
  </div>
)
