import {
  isDimensionalPortionProduct,
  isDimensionalRecipeProduct,
  isDimensionalSimpleProduct,
} from '@dominos/components'
import { findEntityByDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'
type DimensionSet = Bff.Dimensions.DimensionSet

export const flattenCurrencyPricesForDimensionSet = (
  dimensionalProduct: Bff.Products.ProductBase<DimensionSet> | undefined,
  dimensionSet?: DimensionSet,
) => {
  if (
    isDimensionalRecipeProduct(dimensionalProduct) ||
    isDimensionalSimpleProduct(dimensionalProduct) ||
    isDimensionalPortionProduct(dimensionalProduct)
  ) {
    return findEntityByDimensionSet(dimensionalProduct.currencyPrices?.[0]?.prices || [], dimensionSet)?.[0]
  }
}
