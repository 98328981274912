import { rootActions } from '@dominos/business/root.actions'
import { getType } from 'typesafe-actions'

export interface ProductUpsellReducerProps {
  dismissedZones: string[]
}

const productUpsellInitState: ProductUpsellReducerProps = {
  dismissedZones: [],
}

export const productUpsellReducer: import('redux').Reducer<ProductUpsellReducerProps> = (
  state: ProductUpsellReducerProps = productUpsellInitState,
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.storeSelected):
    case getType(rootActions.logout):
    case getType(rootActions.placeOrderSuccess):
    case getType(rootActions.restartOrder):
      return {
        dismissedZones: [],
      }
    case getType(rootActions.upsellDismissed):
      return {
        dismissedZones: Array.from(new Set([...state.dismissedZones, action.payload.zoneId])),
      }
    default:
      return state
  }
}
