import { AnalyticSender } from './analytics'

export const reportExpressCheckout = (sendAnalytics: AnalyticSender) => {
  const reportImpression = () => {
    sendAnalytics('express_checkout_impression', {
      event_type: 'impression',
    })
  }

  const reportCheckoutEngagement = () => {
    sendAnalytics('express_checkout_click', {
      event_type: 'click',
    })
  }

  return {
    reportImpression,
    reportCheckoutEngagement,
  }
}
