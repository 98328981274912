import { CreateProductNutritionalViewModelProps, ProductNutritionalViewModel } from './product-nutritional.interface'

export const createProductNutritionalViewModel = ({
  t,
  nutritionals,
  allergens,
  showInfoButton,
  getKeyForNutritional,
  getKeyForAllergen,
}: CreateProductNutritionalViewModelProps): ProductNutritionalViewModel => {
  const title = t('Nutritional & Allergens')
  const description = t('NutritionalInformationDescription', { defaultValue: '' })
  const buttonText = t('View Nutritional Info & Allergens')
  const nutritionalTitle = t('NutritionalValuePer100g')

  const url = t('nutritional information url', {
    defaultValue: 'https://www.dominos.com.au/menu/nutritional-information',
  })

  const productNutritionals =
    nutritionals?.nutritionals.map((nutritional) => ({
      name: getKeyForNutritional(nutritional.code)?.code,
      value: nutritional.value,
      unit: getKeyForNutritional(nutritional.code)?.unit,
    })) ?? []

  const productAllergens =
    allergens?.map((allergen) => ({
      name: getKeyForAllergen(allergen.code)?.name?.value,
      level: allergen.level,
    })) ?? []

  return {
    productNutritionals: productNutritionals ?? [],
    productAllergens: productAllergens ?? [],
    title,
    buttonText,
    description,
    nutritionalTitle,
    url,
    showInfoButton: !!showInfoButton,
  }
}
