import { AllergenInfoViewModel, CreateAllergenInfoViewModelProps } from './allergen-info.interface'

export const createAllergenInfoViewModel = ({
  t,
  info: productAllergens,
}: CreateAllergenInfoViewModelProps): AllergenInfoViewModel | null => {
  if (!productAllergens || !productAllergens.length) {
    return null
  }

  const allergensHeader: string = t('AllergiesTitle', { defaultValue: 'Allergies' })

  const allergens = productAllergens.map((item) => t(item.name)).join(', ')

  return {
    allergens,
    allergensHeader,
  }
}
