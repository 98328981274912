import { useCallback, useRef } from 'react'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = ({ fn, delay = 400 }: { fn: (x?: any) => void; delay?: number }) => {
  const debounce = useRef<ReturnType<typeof setTimeout> | null>(null)

  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any[]) => {
      clearTimeout(debounce.current as unknown as number)
      debounce.current = setTimeout(() => {
        fn.call(null, ...args)
      }, delay)
    },
    [fn],
  )
}
