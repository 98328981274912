import { formatSectionName } from '@dominos/business/functions/menu'
import { GlutenFreeDisclaimer } from '@dominos/components'
import React from 'react'
import css from './menu-section-header.less'

const MenuSectionHeader = ({ menuSection }: { menuSection: ResolvedMenuSection }) => {
  const shouldShowDisclaimer = menuSection.code === 'Menu.Pizza.GlutenFree' || menuSection.code === 'Variety.GlutenFree'

  return (
    <>
      <h1 id={formatSectionName(menuSection.code)} className={css.title} data-testid='menu-section-header'>
        {menuSection.title}
      </h1>
      {shouldShowDisclaimer && <GlutenFreeDisclaimer />}
    </>
  )
}

export { MenuSectionHeader }
