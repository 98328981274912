import OrderModel from '@dominos/hooks-and-hocs/order/model/order-model'
import React, { useEffect } from 'react'
import ServiceMethodDetails from './service-method-details'
import TrackerLoading from './tracker-loading'
import {
  ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS_DELIVERY,
  ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS_PICKUP,
} from './constants'
import { CustomerDetails } from '@dominos/interfaces'
import { ErrorNotifier, ExperienceFeedbackCard, OrderFeedback } from '@dominos/components'
import { getCustomerId, idTokenUtils } from '@dominos/business/functions/customer'
import { getTotalLineItems } from '@dominos/business/functions/basket'
import { GPSTracker } from './gps-tracker'
import { notifyNativeApp } from '@dominos/business/functions/native-app'
import { OrderDetails } from './order-details'
import { OrderTracker } from './order-tracker'
import { TrackerRegister } from './tracker-register/tracker-register'
import { useCustomer, useFeatures, usePageView, useReport, useRokt } from '@dominos/hooks-and-hocs'
import { useOrderStatusToggles } from './use-order-status-toggles'
import { useSelector } from 'react-redux'
import { useLaunchDarklyContext, useSwrve } from '@dominos/hooks-and-hocs'
import { GOLOPickup } from './golo-pickup'
import { StoreTradeDetails } from './store-trade-details'

declare global {
  interface WindowEventMap {
    'user-login-update': CustomEvent
  }
}

export const TrackerPage = ({
  order,
  customerDetails,
}: {
  order: Bff.Orders.Order
  customerDetails?: CustomerDetails
}) => {
  const { saveToken } = idTokenUtils()
  const serviceMethod = order.details!.header.serviceMethod
  const { getCustomer } = useCustomer()
  const { setRoktAttributes } = useRokt()
  const orderModel = new OrderModel(order)
  const { reset } = useSwrve()
  const [isOrderFeedbackEnabled, isOrderExperienceFeedbackEnabled, autoScrollEnabled, hideOrderFeedbackUntilComplete] =
    useFeatures(
      'order-feedback',
      'TrackerOrderingExperienceFeedback',
      'TrackerAutoScroll',
      'HideOrderFeedbackUntilComplete',
    )
  const { reportInfo } = useReport()
  const { launchDarklyID } = useLaunchDarklyContext()
  const settings = useSelector((store: RootReducer) => store.applicationReducer)

  const [orderStatusToggleExperienceFeedback, orderStatusToggleOrderFeedback] = useOrderStatusToggles(order.id, [
    orderModel.serviceMethod == 'Delivery'
      ? ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS_DELIVERY
      : ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS_PICKUP,
    ['Complete'],
  ])
  const shouldDisplayExperienceFeedback = isOrderExperienceFeedbackEnabled && orderStatusToggleExperienceFeedback
  const shouldDisplayOrderFeedback =
    isOrderFeedbackEnabled &&
    (!hideOrderFeedbackUntilComplete || (hideOrderFeedbackUntilComplete && orderStatusToggleOrderFeedback))

  usePageView('Flow.Tracker.ArrivedTracker')
  useEffect(() => {
    const customerId = getCustomerId()
    const externalUserId = customerId || launchDarklyID
    reset(externalUserId, () => {}, true)
    notifyNativeApp('tracker', reportInfo)
    getCustomer()
  }, [])

  useEffect(() => {
    const onTrackerPageUpdating = (event: CustomEvent) => {
      if (event.detail?.nativeAppAuthToken) {
        saveToken(event.detail?.nativeAppAuthToken)
        const customerId = getCustomerId()
        getCustomer(customerId)
      }
    }
    window.addEventListener('user-login-update', onTrackerPageUpdating)

    return () => window.removeEventListener('user-login-update', onTrackerPageUpdating)
  }, [])

  useEffect(() => {
    if (!order) return

    setRoktAttributes(
      {
        name: customerDetails?.name,
        mobile: customerDetails?.mobileNumber,
        email: customerDetails?.emailAddress,
        amount: order.details?.basket.total,
        payments: order.details?.payments,
        quantity: getTotalLineItems(order.details?.basket.lines),
        orderId: order.id,
        postCode: order.details?.header.deliveryAddress?.address.postCode,
        city: order.details?.header.deliveryAddress?.address.suburb,
        state: order.details?.header.deliveryAddress?.address.state,
        delivery: order.details?.header.serviceMethod === 'Delivery',
        country: order.details?.header.countryCode,
      },
      settings.environment === 'PROD' ? 'ordercomplete' : 'testExperience',
    )
  }, [order])

  return (
    <ErrorNotifier namespace={'tracker'}>
      {shouldDisplayExperienceFeedback && (
        <ExperienceFeedbackCard
          testID={`experience-feedback`}
          feedbackType={'OloExperience'}
          orderId={order.id}
          serviceMethod={order.details?.header.serviceMethod}
        />
      )}
      {orderModel.serviceMethod && orderModel.serviceMethodSubType && (
        <ServiceMethodDetails
          orderId={orderModel.id}
          serviceMethod={orderModel.serviceMethod}
          serviceMethodSubType={orderModel.serviceMethodSubType}
          storePhoneNo={orderModel.storePhoneNo}
        />
      )}
      <ErrorNotifier namespace={'login'}>
        <TrackerRegister id={'register'} customer={order.details?.header.customer} />
      </ErrorNotifier>

      {orderModel.serviceMethod ? (
        <>
          <OrderTracker
            id={'tracker'}
            serviceMethod={serviceMethod}
            orderId={order.id}
            timezone={order.store?.timeZone}
          />
          <GPSTracker id={'gps'} order={order} serviceMethod={serviceMethod} />
          <GOLOPickup id={'golo-pickup'} order={order} serviceMethod={serviceMethod} />
        </>
      ) : (
        <TrackerLoading />
      )}
      <div data-testid='rokt-placeholder' id={'34a5264fee234331bd0ad80580ae4731'} />
      <OrderDetails order={order} />
      <StoreTradeDetails store={order.store} serviceMethod={serviceMethod} />
      {shouldDisplayOrderFeedback && (
        <OrderFeedback orderId={order.id} serviceMethod={serviceMethod} autoScroll={autoScrollEnabled} />
      )}
    </ErrorNotifier>
  )
}
