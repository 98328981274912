import {
  BaseElement,
  BottomBar,
  PortionProductDetails,
  PortionProductSelector,
  PortionProductSummary,
  ProductCustomiser,
  ProductEditor,
  ProductEditorContentWrapper,
  ProductImage,
  SauceElement,
  SelectorProps,
  SizeElement,
  useProductContext,
} from '@dominos/components'
import {
  createBasketLineSwap,
  findSauceSwapFromPortions,
  getCommonIngredients,
  getCommonMinQuantityRule,
  useBaseData,
  useSauceData,
  useSizeData,
} from '@dominos/components/product/functions'
import { ProductNutritionalCard } from '@dominos/components/product/product-nutritional-feature-product'
import { useBreakpoints, useFeatures, useKiosk, useProductIngredientsDictionary } from '@dominos/hooks-and-hocs'
import { isEqual, sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { TFunction } from 'react-i18next'
import { PortionProductEditorProps } from './portion-product-editor.interface'
import css from './portion-product-editor.less'

const applySauceToPortions = (
  selectedSauce: string,
  portions: Portion[],
  sauceIngredientsMap: Map<string, IngredientModel[]>,
  handlePortionCustomisations: (
    index: number,
    key: 'sauce' | 'toppings',
    changes: BasketLineChange[] | BasketLineSwap | undefined,
  ) => void,
) => {
  portions.forEach((portion, index) => {
    if (!portion.productCode) return undefined
    const defaultSauce = sauceIngredientsMap.get(portion.productCode)?.find((ingredient) => ingredient.inRecipe)
    const swap = createBasketLineSwap(selectedSauce, defaultSauce?.code)
    handlePortionCustomisations(index, 'sauce', swap)
  })
}

export interface PortionProductEditorContentFilledPortionProps extends BaseProps {
  t: TFunction<'menu'>
  onProductListOpen: (index: number) => void
  onPortionCustomise: (index: number) => void
  sizeData: SelectorProps<SizeElement>
  onSizeChange: (sizeCode: string) => void
  baseData: SelectorProps<BaseElement>
  onBaseChange: (baseCode: string) => void
  sauceData: SelectorProps<SauceElement>
  onSauceChange: (sauceCode: string) => void
  onDismiss: (addedToBasket?: boolean) => void
  isSwapPortionsActive: boolean
  setIsSwapPortionsActive: (value: boolean) => void
}

const PortionProductEditorView = ({
  testID,
  t,
  onDismiss,
  onProductListOpen,
  onPortionCustomise,
  sizeData,
  onSizeChange,
  baseData,
  onBaseChange,
  sauceData,
  onSauceChange,
  isSwapPortionsActive,
  setIsSwapPortionsActive,
}: PortionProductEditorContentFilledPortionProps) => {
  const { isKioskOrder } = useKiosk()
  const { isMobile } = useBreakpoints()
  const isMobileLayout = isMobile || isKioskOrder

  const {
    dimensionSetState,
    portions,
    resetPortion,
    formattedPrice,
    formattedPromoPrice,
    productData,
    isValidForBasket,
    hasPortionChanges,
  } = useProductContext()

  const [showPortionProductSummary] = useFeatures('ShowPortionProductSummary')

  const hasAllDefaultPortions = productData?.defaultPortions?.every((portion) => !!portion.defaultProductCode) ?? false

  const showSummary =
    showPortionProductSummary &&
    !isSwapPortionsActive &&
    isValidForBasket &&
    hasAllDefaultPortions &&
    !hasPortionChanges

  const energyKj = productData
    ?.getNutritionals('Weighted', dimensionSetState?.selectedDimensionSet)
    ?.nutritionals.find((nutritional) => nutritional.code === 'EnergyKj')?.value

  useEffect(() => {
    if (hasPortionChanges) {
      setIsSwapPortionsActive(true)
    }
  }, [hasPortionChanges])

  return (
    <>
      <ProductEditor
        testID={`${testID}.product-editor`}
        onDismiss={onDismiss}
        showBackButton={!!isMobileLayout}
        showCloseButton={!isMobileLayout}
      >
        {showSummary && <ProductImage testID={testID} uri={productData?.media?.largeImage.uri ?? ''}></ProductImage>}
        <ProductEditorContentWrapper
          testID={`${testID}.product-editor-content`}
          className={showSummary ? css.portionProductEditorContent : undefined}
        >
          {showSummary ? (
            <PortionProductSummary
              testID={testID}
              media={productData?.media}
              t={t}
              formattedPrice={formattedPrice}
              formattedPromoPrice={formattedPromoPrice}
              energyKJ={energyKj}
              onSwapPortionsClick={() => setIsSwapPortionsActive(true)}
            />
          ) : (
            <PortionProductDetails
              testID={testID}
              productData={productData}
              formattedPrice={formattedPrice}
              formattedPromoPrice={formattedPromoPrice}
              dimensionSetState={dimensionSetState}
              portions={portions}
              onProductListOpen={onProductListOpen}
              resetPortion={resetPortion}
              onPortionCustomise={onPortionCustomise}
              t={t}
            />
          )}
          <ProductCustomiser
            sizeData={sizeData}
            onSizeChange={onSizeChange}
            baseData={baseData}
            onBaseChange={onBaseChange}
            sauceData={sauceData}
            onSauceChange={onSauceChange}
          />
          <ProductNutritionalCard t={t} testID='nutritional-card' />
        </ProductEditorContentWrapper>
      </ProductEditor>
    </>
  )
}

const PortionProductEditor = ({
  testID,
  onDismiss,
  isEditing,
  onPortionCustomise,
  addToBasket,
  t,
  allowSauceCustomisation,
  isSwapPortionsActive,
  setIsSwapPortionsActive,
}: PortionProductEditorProps) => {
  const {
    createBasketLine,
    dimensionSetState,
    portions,
    handlePortionChange,
    isValidForBasket,
    initialQuantity,
    productData,
    handlePortionCustomisations,
  } = useProductContext()
  const [selectingProductIndex, setSelectingProductIndex] = useState<number | undefined>(undefined)
  const [productCodes, setProductCodes] = useState<string[]>([])
  const [selectedSauce, setSelectedSauce] = useState<string | undefined | null>()
  const { getIngredients, getIngredientTypeRule } = useProductIngredientsDictionary(productCodes)
  useEffect(() => {
    if (!isValidForBasket) {
      setProductCodes([])

      return
    }
    if (portions && allowSauceCustomisation) {
      const newProductCodes: string[] = portions.map((portion) => portion.productCode ?? '')
      if (!isEqual(sortBy(newProductCodes), sortBy(productCodes))) {
        setProductCodes(newProductCodes)
      }
    }
  }, [isValidForBasket, portions, allowSauceCustomisation])
  const sauceIngredientsMap = getIngredients('Sauce', dimensionSetState?.selectedDimensionSet)
  const sauceRulesMap = getIngredientTypeRule('Sauce', dimensionSetState?.selectedDimensionSet)
  const commonSauces = getCommonIngredients(sauceIngredientsMap)
  const sauceSwap = findSauceSwapFromPortions(portions)
  const commonMinQuantityRule = getCommonMinQuantityRule(sauceRulesMap)
  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)
  const sauceData = useSauceData(commonSauces, sauceSwap, commonMinQuantityRule === 0)
  const isAllIngredientsFetched =
    portions && portions.map((portion) => portion.productCode || '').every((code) => sauceIngredientsMap.has(code))

  useEffect(() => {
    if (!(sauceData.selectedItemCode && portions && isAllIngredientsFetched)) return
    const selectedSauceCode =
      selectedSauce && sauceData.items.some((sauce) => sauce.code === selectedSauce)
        ? selectedSauce
        : sauceData.selectedItemCode
    applySauceToPortions(selectedSauceCode, portions, sauceIngredientsMap, handlePortionCustomisations)
  }, [isAllIngredientsFetched])

  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }
  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }
  const onSauceChange = (sauceCode: string) => {
    if (portions) {
      setSelectedSauce(sauceCode)
      applySauceToPortions(sauceCode, portions, sauceIngredientsMap, handlePortionCustomisations)
    }
  }
  const addToBasketHandler = (quantity: number) => {
    addToBasket(createBasketLine(quantity))
    onDismiss(true)
  }

  const onProductListOpen = (index: number) => {
    setSelectingProductIndex(index)
  }

  const onValueChange = (value: string) => {
    if (selectingProductIndex !== undefined) {
      handlePortionChange(selectingProductIndex, value)
    }
    setSelectingProductIndex(undefined)
  }

  if (selectingProductIndex !== undefined) {
    return (
      <PortionProductSelector
        testID={testID}
        onDismiss={onDismiss}
        productList={productData?.getPossiblePortionProducts(dimensionSetState?.selectedDimensionSet) || []}
        t={t}
        onValueChange={onValueChange}
        setSelectingProductIndex={() => setSelectingProductIndex(undefined)}
      />
    )
  }

  return (
    <>
      <PortionProductEditorView
        testID={testID}
        t={t}
        onDismiss={onDismiss}
        onProductListOpen={onProductListOpen}
        onPortionCustomise={onPortionCustomise}
        sizeData={sizeData}
        onSizeChange={onSizeChange}
        baseData={baseData}
        onBaseChange={onBaseChange}
        sauceData={sauceData}
        onSauceChange={onSauceChange}
        isSwapPortionsActive={isSwapPortionsActive}
        setIsSwapPortionsActive={setIsSwapPortionsActive}
      />
      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={addToBasketHandler}
        isEditing={isEditing}
        initialQuantity={initialQuantity}
        isPortionProduct={false}
        forceDisabled={!isValidForBasket}
      />
    </>
  )
}

export { PortionProductEditor, PortionProductEditorView }
