import { CheckoutContainer, SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { UpsellOfferType, useBreakpoints, useKiosk, usePrevious, useUpsellOffer } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { useLocation, useNavigate } from 'react-router-dom'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const shouldShowBackButtonForPath = (path: string) => !path.includes(`${NavigationConstants.checkout}/processing`)

interface KeyValuePair {
  key: string
  defaultValue: string
}

const routeMap = (isMobileLayout: boolean): Record<string, KeyValuePair> => ({
  [NavigationConstants.checkoutDetails]: isMobileLayout
    ? {
        key: 'BackToBasket',
        defaultValue: 'Basket',
      }
    : {
        key: 'BackToMenu',
        defaultValue: 'Menu',
      },
  [NavigationConstants.checkoutPayment]: {
    key: 'BackToDetails',
    defaultValue: 'Details',
  },
  [NavigationConstants.checkoutPlaceOrder]: {
    key: 'BackToDetails',
    defaultValue: 'Details',
  },
})

const defaultBackButton: KeyValuePair = {
  key: 'BackButton',
  defaultValue: 'Back',
}

const getBackButtonTextByPath = (path: string, t: TFunction, isMobileLayout: boolean) => {
  const { key, defaultValue } = routeMap(isMobileLayout)[path] || defaultBackButton

  return t(key, { defaultValue })
}

export const CheckoutScene = () => {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const { t } = useTranslation('navigation')

  const [showBackButton, setShowBackButton] = useState(true)
  const [backButtonText, setBackButtonText] = useState('Back')
  const previousPath = usePrevious(pathname)
  const hardUpsellOffers = useUpsellOffer(UpsellOfferType.HardUpsell)

  const isHardupsellEnable = localStorage.getItem('hardUpsellState')
  const hardUpsellState = state?.isHardupsell || ''

  useEffect(() => {
    if (isHardupsellEnable !== 'hard-upsell') {
      localStorage.setItem('hardUpsellState', hardUpsellState)
    }

    return () => {
      localStorage.removeItem('hardUpsellState')
    }
  }, [])

  const handleOnBack = () => {
    let handled = false
    if (
      (pathname === NavigationConstants.checkoutPlaceOrder && previousPath === NavigationConstants.checkoutPayment) ||
      (isHardupsellEnable === 'hard-upsell' &&
        pathname === NavigationConstants.checkoutDetails &&
        hardUpsellOffers.length === 0)
    ) {
      navigate(-2)
      handled = true
    }

    return handled
  }

  useEffect(() => {
    setShowBackButton(shouldShowBackButtonForPath(pathname))
    setBackButtonText(getBackButtonTextByPath(pathname, t, isMobile || isKioskOrder))
  }, [pathname])

  return (
    <StackNavigationBase
      testID='checkout-scene-stack'
      header={
        <SimpleNavigationTitle showBackButton={showBackButton} backScreen={backButtonText} onBack={handleOnBack} />
      }
    >
      <CheckoutContainer testID={'checkout-container'} />
    </StackNavigationBase>
  )
}
