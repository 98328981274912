import { TFunction } from 'i18next'
import { ApolloError } from '@apollo/client'
import { ErrorDefinitions } from '@dominos/interfaces/errors'
import { GraphQLFormattedError } from 'graphql'
import { getMappedErrorToDefinition } from './get-mapped-error-to-definition'
import { ErrorType, ErrorTypes } from '../error'

export const getMappedErrorToDisplay = (
  apolloError: ApolloError,
  errorDefinitions: ErrorDefinitions,
  tns: TFunction,
  tValidation: TFunction,
): ErrorTypes => {
  const tErrors: ErrorTypes = {
    alert: [],
    popup: [],
    errorText: [],
  }

  apolloError?.graphQLErrors?.forEach((err: GraphQLFormattedError) => {
    const mappedError: ErrorType = getMappedErrorToDefinition(
      err.extensions?.code,
      'Graph',
      errorDefinitions,
      tns,
      tValidation,
    )

    if (mappedError.display && tErrors[mappedError.display as keyof ErrorTypes]) {
      tErrors[mappedError.display as keyof ErrorTypes].push(mappedError)
    }
  })

  if (apolloError?.networkError) {
    const mappedError: ErrorType = getMappedErrorToDefinition(
      'NETWORK_ERROR',
      'Network',
      errorDefinitions,
      tns,
      tValidation,
    )

    if (mappedError.display && tErrors[mappedError.display as keyof ErrorTypes]) {
      tErrors[mappedError.display as keyof ErrorTypes].push(mappedError)
    }
  }

  return tErrors
}
