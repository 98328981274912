export interface NutritionalLookup {
  code: string
  unit: string
}

export interface NutritionalLookupDictionary {
  [key: string]: NutritionalLookup
}

export const mapNutritionalKeys = (): NutritionalLookupDictionary => {
  const nutritionalLookupDictionary: NutritionalLookupDictionary = {
    Calcium: { code: 'calcium', unit: 'mg' },
    Carbohydrate: { code: 'carbohydrate', unit: 'g' },
    EnergyKCal: { code: 'energyKcal', unit: 'kCal' },
    EnergyKj: { code: 'energyKJ', unit: 'kJ' },
    Fat: { code: 'fat', unit: 'g' },
    FatSaturated: { code: 'fatSaturated', unit: 'g' },
    Fibre: { code: 'fibre', unit: 'g' },
    Protein: { code: 'protein', unit: 'g' },
    Salt: { code: 'salt', unit: 'g' },
    Sodium: { code: 'sodium', unit: 'mg' },
    Sugar: { code: 'sugars', unit: 'g' },
  }

  return nutritionalLookupDictionary
}
