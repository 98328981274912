import React from 'react'
import { Modal, Sheet } from 'olo-ux'
import { useTranslation } from 'react-i18next'
import { useClasses } from './remove-payment-modal-sheet.styles'
import { useBreakpoints } from '@dominos/hooks-and-hocs'

interface RemovePaymentModalSheetProps {
  onClose: () => void
  onRemove: () => void
}

const RemovePaymentModalSheet: React.FC<RemovePaymentModalSheetProps> = ({ onClose, onRemove }) => {
  const { t } = useTranslation('login')
  const classes = useClasses()
  const { isMobile } = useBreakpoints()

  const ModalSheet = isMobile ? Sheet : Modal

  return (
    <ModalSheet
      type='default'
      title={t('PaymentMethodRemovedPopupTitle', { defaultValue: 'Remove Payment Method?' })}
      onClose={onClose}
      footerType='stacked'
      primaryButtonProps={{
        label: t('RemoveButton', { defaultValue: 'Remove' }),
        onPress: onRemove,
        type: 'destructive',
        size: isMobile ? 'medium' : 'large',
      }}
      secondaryButtonProps={{
        label: t('CancelButton', { defaultValue: 'Cancel' }),
        onPress: onClose,
        type: 'tertiary',
        size: isMobile ? 'medium' : 'large',
      }}
      children={
        <p className={classes.content}>
          {t('PaymentMethodRemovedPopupContent', {
            defaultValue:
              'Are you sure you want to remove this payment method? You will have to manually enter it again at checkout.',
          })}
        </p>
      }
    />
  )
}

export { RemovePaymentModalSheet }
