import { detailsFieldNames } from '../details-container-config'
import { FormValue } from '../details-container.interface'

export const mapCustomerOrderDetails = (myDetails: { [key: string]: FormValue }, loyaltyEnrolled: boolean) => ({
  name: myDetails[detailsFieldNames.name] as string,
  alternateName: myDetails[detailsFieldNames.alternateName] as string,
  emailAddress: myDetails[detailsFieldNames.email] as string,
  mobileNumber: myDetails[detailsFieldNames.mobileNumber] as string,
  organisationName: myDetails[detailsFieldNames.company] as string,
  uniformNumber: myDetails[detailsFieldNames.uniformNumber] as string,
  mobileCarrierCode: myDetails[detailsFieldNames.mobileCarrierCode] as string,
  citizenCarrierCode: myDetails[detailsFieldNames.citizenCarrierCode] as string,
  charityCode: myDetails[detailsFieldNames.charityCode] as string,
  loyaltyEnrolled: { isEnrolled: loyaltyEnrolled },
})
