import { useApolloClient, useMutation } from '@apollo/client'
import { idTokenUtils } from '@dominos/business/functions/customer'
import { useNavigate } from 'react-router-dom'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import { deleteCustomer, DeleteCustomerResponse } from '@dominos/business/queries/delete-customer'
import { useCurrentOrderDetails, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation/navigation-constants'
import { useAlert } from '@dominos/hooks-and-hocs/application/alert'
import { useTranslation } from 'react-i18next'

export const useAccountDelete = () => {
  const client = useApolloClient()
  const { deleteToken } = idTokenUtils()
  const { reportAccountDelete } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const navigate = useNavigate()
  const { showAlert } = useAlert()
  const { t } = useTranslation('login')

  const [deleteAccount, { loading: inProgress }] = useMutation<DeleteCustomerResponse>(deleteCustomer, {
    onCompleted(data) {
      if (data && data.deleteCustomer) {
        deleteToken()
        reportAccountDelete({ orderId, success: true })
        client.clearStore()
        if (isNativeApp()) {
          notifyNativeApp('delete-account-successful')
        } else {
          navigate(NavigationConstants.home)
        }
      }
    },
    onError(error) {
      reportAccountDelete({ orderId, success: false, deleteNote: error.message })

      showAlert(
        t('DeleteAccountFailedErrorMessage', {
          defaultValue: 'Sorry, something went wrong deleting your account. Please try again later.',
        }),
        'DeleteAccountFailedErrorMessage',
      )
    },
  })

  return {
    deleteAccount,
    inProgress,
  }
}
