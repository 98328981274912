import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ActionButton } from '@dominos/components/buttons'
import { useCurrentOrderDetails, useLazyLoadSdk, useReport, useSocialAuth } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { FacebookIcon } from '@dominos/res/images/icons/components'
import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'
import { useLocation, useNavigate } from 'react-router-dom'
import { InlineError } from '../inline-error'

import { FBLoginResponse, FBRequestedFields } from './facebook.interface'
import css from './facebook.less'
import { safariPopupBlockDetect, setupFacebook } from '@dominos/business/functions'
import { ErrorScope, getStatusReasonFromApolloError, useErrorContext } from '@dominos/components/error'
import { loginErrors } from '../login-errors'

interface Props extends Partial<BaseProps> {
  enableLongLived: boolean
  handlePopupBlock: () => void
}

export const Facebook = ({ testID = 'facebook-login-button', enableLongLived, handlePopupBlock }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation('login')
  const { reportRegistration, reportLogin } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const [loginFailed, setLoginFailed] = useState(false)
  const { state } = useLocation()
  const { notifyError } = useErrorContext()

  const { lazyLoadSdk, isLazyLoading, sdk } = useLazyLoadSdk('FB', {
    onSdkReady: (sdk) => {
      attemptLogin(sdk)
    },
    loadSdk: setupFacebook,
  })

  const { signIn, authCustomerInfo, pending, error: signInError } = useSocialAuth()

  const getCreateAccountSceneProps = (requestedFields: FBRequestedFields) =>
    ({
      redirectTo: (state as { redirectTo?: string })?.redirectTo,
      title: t('signUpFacebookTitle', { defaultValue: 'Sign in with Facebook' }),
      description: t('signUpFacebookDescription', {
        defaultValue: 'Almost there! We just need a few more details to create your account',
      }),
      subtitle: t('signUpFacebookSubtitle', { defaultValue: 'ADDITIONAL DETAILS' }),
      name: requestedFields.name,
      email: requestedFields.email,
      identityProvider: {
        identityProviderId: requestedFields.id,
        externalProviders: [
          {
            providerType: 'facebook',
            providerId: requestedFields.id,
          },
        ],
      },
    } as CreateAccountSceneProps)

  const createAccountCallback = (requestedFields: FBRequestedFields) => {
    reportRegistration({
      success: false,
      orderId,
      identityProvider: 'Facebook',
      note: 'Creating new account',
      authenticationSource: 'Legacy',
      url: window.location.href,
    })
    navigate(NavigationConstants.createAccount, { state: getCreateAccountSceneProps(requestedFields) })
  }

  const handleFBLogin = (response: FBLoginResponse) => {
    if (response.status === 'connected') {
      signIn({ orderId, enableLongLived, providerToken: response.authResponse.accessToken, provider: 'Facebook' })
    } else if (response.status === 'unknown') {
      reportLogin({
        enableLongLived,
        status: 'fail',
        order_id: orderId,
        authenticationSource: 'Legacy',
        customerId: authCustomerInfo?.customerId,
        url: window.location.href,
        identityProvider: 'Facebook',
        status_reason: 'Failed to sign in using Facebook',
      })
      setLoginFailed(true)
    }
  }

  useEffect(() => {
    if (!!authCustomerInfo) {
      reportLogin({
        enableLongLived,
        order_id: orderId,
        authenticationSource: 'Legacy',
        customerId: authCustomerInfo?.customerId,
        url: window.location.href,
        status: 'success',
        identityProvider: 'Facebook',
      })
      if (authCustomerInfo?.customerId) {
        navigate(NavigationConstants.home)
      } else if (sdk) {
        sdk.api('/me', { fields: 'name,email' }, createAccountCallback)
      }
    }
  }, [authCustomerInfo])

  useEffect(() => {
    if (signInError) {
      const statusReason = getStatusReasonFromApolloError(signInError)

      if (statusReason) {
        reportLogin({
          enableLongLived,
          status: 'fail',
          order_id: orderId,
          authenticationSource: 'Legacy',
          customerId: authCustomerInfo?.customerId,
          url: window.location.href,
          identityProvider: 'Facebook',
          status_reason: statusReason,
        })
      }

      notifyError({
        error: signInError,
        definitions: loginErrors,
        handlers: {},
        scope: ErrorScope.CreateAccount,
      })
    }
  }, [signInError])

  const onFacebookLogin = () => {
    setLoginFailed(false)
    lazyLoadSdk()
  }

  const attemptLogin = (facebookInstance: Window['FB']) => {
    facebookInstance?.login(handleFBLogin, { scope: 'public_profile,email' })

    const popupBlockerDetect = safariPopupBlockDetect()
    if (popupBlockerDetect) {
      handlePopupBlock()
    }
  }

  return (
    <React.Fragment>
      <ActionButton
        testID={testID}
        loading={pending || isLazyLoading}
        disabled={pending || isLazyLoading}
        onPress={onFacebookLogin}
        containerStyle={styles.facebookButton}
      >
        <div className={css.facebookicon}>
          <FacebookIcon fill={'#ffffff'} />
        </div>
        <label className={css.buttonLabel}>{t('signUpFacebookTitle', { defaultValue: 'Sign in with Facebook' })}</label>
      </ActionButton>
      <InlineError
        showError={loginFailed}
        message={t('CreateAccountGenericErrorText', { defaultValue: 'Something has gone wrong. Please try again' })}
      />
    </React.Fragment>
  )
}

const styles: { [k: string]: CommonViewStyle } = {
  facebookButton: {
    width: '100%',
    height: '54px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '10px 0',
    cursor: 'pointer',
    backgroundColor: '#1778f2',
  },
}
