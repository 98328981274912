import { rootActions } from '@dominos/business'
import { CollapsableTitledCard, Error, PopUpNotification } from '@dominos/components'
import { useNewVouchersCount, useSavedVouchers } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { MySavedVouchersErrors } from './my-saved-vouchers-errors'
import styles from './my-saved-vouchers.less'
import { SavedVoucher } from './saved-voucher'

const MySavedVouchers = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('saved-vouchers')
  const dispatch = useDispatch()
  const { activeVouchers, expiredVouchers, error, loading } = useSavedVouchers()
  const { markNewVouchersAsSeen } = useNewVouchersCount()
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    if (!loading) markNewVouchersAsSeen()
  }, [loading])

  const handleAddVoucher = (voucherCode: string) => {
    dispatch(rootActions.storeDeepLinkCoupon(voucherCode))
    setShowPopup(true)
  }

  const handlePopupClose = () => {
    setShowPopup(false)
    navigate(NavigationConstants.home)
  }

  return (
    <div data-testid='account-saved-payments'>
      {showPopup && <PopUpNotification heading={t('SavedVoucherAdded')} onClose={handlePopupClose} />}

      {error && <Error tns={t} errorDefinitions={MySavedVouchersErrors} error={error} />}

      {!loading && (
        <>
          <CollapsableTitledCard
            primaryTitle={t('ActiveVouchers')}
            testID='saved-vouchers'
            startCollapsed={false}
            static={activeVouchers.length === 0}
            style={{ marginTop: '20px', marginBottom: '10px' }}
            noPadding
          >
            {activeVouchers.length > 0 ? (
              <>
                <p className={styles.introText}>
                  {t('ActiveVouchersDescription', {
                    defaultValue: 'Select a voucher to add it to your order.',
                  })}
                </p>
                {activeVouchers.map((voucher: Bff.Vouchers.Voucher) => (
                  <SavedVoucher active key={voucher.voucherCode} voucher={voucher} onClick={handleAddVoucher} />
                ))}
              </>
            ) : (
              <div>
                <p className={styles.noVouchers}>
                  {t('NoActiveVouchers', { defaultValue: 'Unfortunately, you do not currently have any vouchers.' })}
                </p>
              </div>
            )}
          </CollapsableTitledCard>
          <CollapsableTitledCard
            primaryTitle={t('ExpiredVouchers')}
            testID='expired-vouchers'
            startCollapsed={false}
            static={expiredVouchers.length === 0}
            noPadding
          >
            {expiredVouchers.length > 0 ? (
              expiredVouchers.map((voucher: Bff.Vouchers.Voucher) => (
                <SavedVoucher active={false} key={voucher.voucherCode} voucher={voucher} />
              ))
            ) : (
              <div>
                <p className={styles.noVouchers}>
                  {t('NoExpiredVouchers', { defaultValue: 'Good news! You do not have any expired vouchers.' })}
                </p>
              </div>
            )}
          </CollapsableTitledCard>
        </>
      )}
    </div>
  )
}

export { MySavedVouchers }
